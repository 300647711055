import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ResourceService } from '../resource.service';
import { Preferences } from './preferences';
import { PreferencesSerializer } from './preferences.serializer';

@Injectable()
export class PreferencesService extends ResourceService<Preferences> {
  baseUrl = environment.serverUrl + 'preferences/';

  constructor(http: Http) {
    super(http, 'preferences/', new PreferencesSerializer());
  }

  listStatic(token: string): Observable<Preferences[]> {
    let ticketUrl = this.baseUrl;

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Token ' + token);

    return this.http.get(ticketUrl, { headers: headers }).pipe(
      map(res => this.captureMetaData(res)),
      map(data => this.convertData(data)),
      catchError((res: Response) => this.handleError(res))
    );
  }
}
