import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';

import { OrganizationService } from '../organizations/organization.service';
import { AuthenticationService } from '../shared/authentication.service';
import { parseErrors } from '../shared/api.service';
import { Organization } from '../organizations/organization';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [OrganizationService]
})
export class SettingsComponent implements OnInit {
  loading = true;
  errors: any[] = [];
  tab = 'organization';
  user = this.authenticationService.user();
  organization!: Organization;
  organizationReq!: any;
  quickbooksVisible = false;

  saveOrganizationCallback = () => {};

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.tab = params['tab'];
      this.getOrganization();
    });
  }

  getOrganization() {
    this.loading = true;
    if (this.organizationReq) {
      this.organizationReq.unsubscribe();
    }

    let org = this.user.organization;
    if (org) {
      this.organizationReq = this.organizationService.get(org.id).
        subscribe(organization => {
          this.organization = organization;
          this.quickbooksVisible = organization.features && organization.features.quickbooks;
          this.loading = false;
        }, err => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
    }
  }
}
