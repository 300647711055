import { Http, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from './user';
import { UserSerializer } from './user.serializer';
import { ResourceService } from '../shared/resource.service';
import { requestHeaders } from '../shared/index';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class UserService extends ResourceService<User> {
  organizationId: string;
  senderId: string;

  constructor(http: Http) {
    super(http, 'users/', new UserSerializer());
  }

  saveWithImage(user, profileImage: File) {
    let _user = clone(user);

    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      // todo: when we start to add tags for users,
      // we will need to be able to submit an empty list to delete all tags.
      if (_user.tags && _user.tags.length === 0) {
        delete _user.tags;
      }
      delete _user.image;
      delete _user.favoriteTags;
      delete _user.favoriteLocation;

      _user = decamelizeKeysDeep(_user);
      Object.keys(_user).map(function (key, index) {
        let value = _user[key];
        formData.append(key, value);
      });

      if (profileImage) {
        formData.append('image', profileImage, profileImage.name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (_user.id) {
        let userUrl = this.baseUrl + 'users/' + _user.id + '/';
        xhr.open('PUT', userUrl, true);
      } else {
        let userUrl = this.baseUrl + 'users/';
        xhr.open('POST', userUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  inviteUser(email: string, recipientOrganization: string, isCustomer: boolean): Observable<boolean> {
    let userUrl = this.baseUrl + 'invitations/';

    const params: { [k: string]: any } = {
      recipient_organization: recipientOrganization,
      kind: 'new_user',
      recipient_email: email,
      sender_user: this.senderId,
      sender_organization: this.organizationId,
    };

    if (!isCustomer) {
      params.result_organization = this.organizationId;
    }

    return this.http.post(userUrl, params, { headers: requestHeaders() }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }
}
