"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./grid-image-panel.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../image-editor/image-editor.component.ngfactory");
var i3 = require("../image-editor/image-editor.component");
var i4 = require("../upload.service");
var i5 = require("../notification/notifications.service");
var i6 = require("@angular/common");
var i7 = require("@ngx-translate/core");
var i8 = require("./grid-image-panel.component");
var i9 = require("../../tickets/ticket.service");
var styles_GridImageToolPanel = [i0.styles];
var RenderType_GridImageToolPanel = i1.ɵcrt({ encapsulation: 0, styles: styles_GridImageToolPanel, data: {} });
exports.RenderType_GridImageToolPanel = RenderType_GridImageToolPanel;
function View_GridImageToolPanel_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.editing = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Image"]))], null, null); }
function View_GridImageToolPanel_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_GridImageToolPanel_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-editor", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ImageEditorComponent_0, i2.RenderType_ImageEditorComponent)), i1.ɵdid(1, 573440, null, 0, i3.ImageEditorComponent, [i4.UploadService, i5.NotificationsService], { id: [0, "id"], imageUrl: [1, "imageUrl"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ticket.id; var currVal_1 = _co.imageUrl; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_GridImageToolPanel_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridImageToolPanel_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridImageToolPanel_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridImageToolPanel_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.editing; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.editing; _ck(_v, 6, 0, currVal_2); }, null); }
function View_GridImageToolPanel_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["No Image Found"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GridImageToolPanel_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { ticketImage: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "sidebar-ticket-image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridImageToolPanel_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noImage", 2]], null, 0, null, View_GridImageToolPanel_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imageUrl && (_co.imageUrl !== "")); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
exports.View_GridImageToolPanel_0 = View_GridImageToolPanel_0;
function View_GridImageToolPanel_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "grid-image-panel", [], null, null, null, View_GridImageToolPanel_0, RenderType_GridImageToolPanel)), i1.ɵdid(1, 49152, null, 0, i8.GridImageToolPanel, [i9.TicketService], null, null)], null, null); }
exports.View_GridImageToolPanel_Host_0 = View_GridImageToolPanel_Host_0;
var GridImageToolPanelNgFactory = i1.ɵccf("grid-image-panel", i8.GridImageToolPanel, View_GridImageToolPanel_Host_0, {}, {}, []);
exports.GridImageToolPanelNgFactory = GridImageToolPanelNgFactory;
