"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var ticket_serializer_1 = require("./ticket.serializer");
var resource_service_1 = require("../shared/resource.service");
var api_service_1 = require("../shared/api.service");
var operators_1 = require("rxjs/operators");
var TicketService = /** @class */ (function (_super) {
    __extends(TicketService, _super);
    function TicketService(http) {
        var _this = _super.call(this, http, 'tickets/', new ticket_serializer_1.TicketSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'tickets/';
        _this.metaUrl = environment_1.environment.serverUrl + 'ticketmetas/';
        _this.scaleSyncUrl = environment_1.environment.serverUrl + 'tickets/origin/scale-sync/';
        _this.ptpRuckitKey = environment_1.environment.ptpRuckitKey;
        _this.ptpApiKey = environment_1.environment.ptpApiKey;
        _this.ptpUrl = environment_1.environment.ptpUrl;
        _this.search = '';
        _this.allSelected = false;
        return _this;
    }
    TicketService.prototype.markInvoiced = function (ticketIds) {
        if (ticketIds === void 0) { ticketIds = []; }
        var ticketUrl = this.baseUrl + 'mark-invoiced/';
        var params = new http_1.URLSearchParams();
        params.set('id__in', ticketIds.join(','));
        return this.http.put(ticketUrl, {}, { headers: api_service_1.requestHeaders(), search: params });
    };
    TicketService.prototype.updateFieldReference = function (ticketId, reference, referenceType) {
        var _this = this;
        var _a;
        var url = "" + this.baseUrl + ticketId + "/set-references/";
        var body = (_a = {},
            _a[referenceType] = reference.id,
            _a);
        return this.http.put(url, body, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (ticket) { return _this.convertRecord(ticket); }));
    };
    TicketService.prototype.updateFieldReferences = function (referenceUpdates) {
        var _this = this;
        var url = "" + this.baseUrl + referenceUpdates.ticketId + "/set-references/";
        var body = {};
        if (referenceUpdates.job && referenceUpdates.job.id) {
            body.job = referenceUpdates.job.id;
        }
        if (referenceUpdates.customer && referenceUpdates.customer.id) {
            body.customer = referenceUpdates.customer.id;
        }
        if (referenceUpdates.driver && referenceUpdates.driver.id) {
            body.driver = referenceUpdates.driver.id;
        }
        return this.http.put(url, body, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (ticket) { return _this.convertRecord(ticket); }));
    };
    ;
    TicketService.prototype.getImage = function (ticket, dimensions) {
        if (!ticket || !ticket.image) {
            return null;
        }
        if (ticket.image && !dimensions) {
            return ticket.image;
        }
        if (!dimensions) {
            return null;
        }
        try {
            // Unescape URL's since some come in weirdly formated due to backend issue
            var imageURL = new URL(unescape(ticket.image));
            // Remove environment from url, and remove extension
            var path = imageURL.pathname.replace(/(ruckit-platform-dev|ruckit-platform-staging|ruckit-platform)\/?/, '');
            // Build resize URL
            var resizeURL = "" + environment_1.environment.resizeImageServerUrl + path + "?d=" + dimensions.x + "x" + dimensions.y;
            return resizeURL;
        }
        catch (e) {
            return ticket.image;
        }
    };
    TicketService.prototype.saveMeta = function (model) {
        var _this = this;
        var metaUrl = this.metaUrl;
        model = lodash_1.clone(model);
        model = this.serializer.toJson(model);
        return this.http.put("" + metaUrl + model.id + "/", model, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
    };
    TicketService.prototype.saveExternal = function (model) {
        var _this = this;
        var url = this.ptpUrl;
        var headers = api_service_1.externalRequestHeaders(this.ptpRuckitKey);
        // this is PTP's auth key; for now we will keep this static
        // until they come up with an auth method for generating a key on the fly
        headers.append('api_key', this.ptpApiKey);
        return this.http.post(url, { ticket: new ticket_serializer_1.ExternalTicketSerializer().toJson(model) }, { headers: headers }).pipe(operators_1.map(function (res) { return _this.convertExternalRecord(res); }));
    };
    TicketService.prototype.saveScaleSync = function (model) {
        var _this = this;
        var url = this.scaleSyncUrl;
        var headers = api_service_1.requestHeaders();
        return this.http.post(url, { image: model.image }, { headers: headers }).pipe(operators_1.map(function (res) { return _this.convertExternalRecord(res); }));
    };
    TicketService.prototype.link = function (ticketIds) {
        // sets up and links tickets by id order. the first id in the array is set as
        // the 'parent' while the rest will be set up as children of that parent.
    };
    TicketService.prototype.unlink = function (ticketIds) {
        // sets up and unlinks tickets by id
    };
    TicketService.prototype.convertExternalRecord = function (data) {
        try {
            data = data.json();
        }
        catch (err) {
            // Ignore
        }
        return new ticket_serializer_1.ExternalTicketSerializer().fromJson(data);
    };
    return TicketService;
}(resource_service_1.ResourceService));
exports.TicketService = TicketService;
