import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/resource.service';
import { DriverReference } from './driver-reference';
import { environment } from '../../../environments/environment';
import { DriverReferenceSerializer } from './driver-reference.serializer';
import { Http } from '@angular/http';

@Injectable()
export class DriverReferenceService extends ResourceService<DriverReference> {
  baseUrl = environment.serverUrl + 'reference/drivers/';
  allSelected = false;

  constructor(http: Http) {
    super(http, 'reference/drivers/', new DriverReferenceSerializer());
  }
}
