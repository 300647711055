import { Component, ViewChild } from '@angular/core';
import {
  IToolPanel, IToolPanelParams, CellFocusedEvent, RowClickedEvent,
  ToolPanelVisibleChangedEvent
} from 'ag-grid-community';
import { DragEvent } from 'ag-grid-community/src/ts/events';

import { Ticket } from '../../tickets/ticket';
import { TicketService } from '../../tickets/ticket.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'grid-image-panel',
  templateUrl: './grid-image-panel.component.html',
  styleUrls: ['./grid-image-panel.component.scss'],
})
export class GridImageToolPanel implements IToolPanel {
  private params!: IToolPanelParams;

  editing = false;
  ticket!: Ticket;
  imageUrl = '';
  imageVisible = false;
  @ViewChild('ticketImage') ticketImage: any;

  constructor(
    private ticketService: TicketService
  ) {}

  agInit(params: IToolPanelParams): void {
    this.params = params;
    this.params.api.addEventListener('rowClicked',  this.onRowClick.bind(this));
    this.params.api.addEventListener('toolPanelVisibleChanged', this.onResize.bind(this));
    this.params.api.addEventListener('dragEvent', this.onResize.bind(this));
  }

  onRowClick(e: RowClickedEvent) {
    this.ticket = e.data;
    this.imageUrl = e.data.image;
    this.editing = false;
  }

  onCellFocused(e: CellFocusedEvent) {
    const row = this.params.api.getDisplayedRowAtIndex(e.rowIndex);

    this.imageUrl = row && row.data && row.data.image ? row.data.image : '';
  }

  refresh(): void { }

  onPanelDisplay(e: ToolPanelVisibleChangedEvent): void {
    if (!e.api) {
      return;
    }

    const visible = e.api.isToolPanelShowing();
    this.imageVisible = visible;
  }

  onResize(e: DragEvent) {
    const itemType = e.type;
  }

  onSave(updateData: any) {
    this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
                      .pipe(first()).subscribe((updatedTicket: Ticket) => {
      const row = this.params.api.getRowNode(updateData.id);
      this.params.api.flashCells({
        rowNodes: [row],
        columns: ['ticketImage']
      });
      row.setData(updatedTicket);
      this.ticket = updatedTicket;
      this.imageUrl = updateData.imageUrl;
      this.editing = false;
    });
  }
}
