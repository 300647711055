"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var NotificationType;
(function (NotificationType) {
    NotificationType["Success"] = "success";
    NotificationType["Danger"] = "danger";
    NotificationType["Warn"] = "warn";
    NotificationType["Info"] = "info";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
;
;
var NotificationsService = /** @class */ (function () {
    function NotificationsService() {
        this.notifications = [];
        this.notifications$ = new rxjs_1.BehaviorSubject([]);
    }
    NotificationsService.prototype.getNotifications = function () {
        return this.notifications$.asObservable();
    };
    NotificationsService.prototype.addNotification = function (notification) {
        this.notifications.push(notification);
        this.notifications$.next(this.notifications);
    };
    NotificationsService.prototype.removeNotification = function (notification) {
        lodash_1.remove(this.notifications, function (n) { return n.id === notification.id; });
        this.notifications$.next(this.notifications);
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
exports.NotificationsService = NotificationsService;
