import { CustomerReference } from './customer-reference';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class CustomerReferenceSerializer {
  fromJson(json: any): CustomerReference {
    json = camelcaseKeysDeep(json);

    const reference = new CustomerReference();

    if (!json) { return reference; }

    reference.id = json.id;
    reference.customerCode = json.customerCode;
    reference.customerName = json.customerName;
    reference.customerAddress1 = json.customerAddress1;
    reference.customerAddress2 = json.customerAddress2;
    reference.customerCity = json.customerCity;
    reference.customerState = json.customerState;
    reference.customerZipcode = json.customerZipcode;
    reference.customerPhoneNumber = json.customerPhoneNumber;
    reference.customerEmail = json.customerEmail;

    return reference;
  }

  toJson(reference: CustomerReference): any {
    const json: { [key: string]: any } = {
      id: reference.id,
      customerCode: reference.customerCode,
      customerName: reference.customerName,
      customerAddress1: reference.customerAddress1,
      customerAddress2: reference.customerAddress2,
      customerCity: reference.customerCity,
      customerState: reference.customerState,
      customerZipcode: reference.customerZipcode,
      customerPhoneNumber: reference.customerPhoneNumber,
      customerEmail: reference.customerEmail,
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
