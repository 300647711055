"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var driver_reference_1 = require("./driver-reference");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DriverReferenceSerializer = /** @class */ (function () {
    function DriverReferenceSerializer() {
    }
    DriverReferenceSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var reference = new driver_reference_1.DriverReference();
        if (!json) {
            return reference;
        }
        reference.id = json.id;
        reference.organization = json.organization;
        reference.value = json.value;
        reference.createdAt = json.createdAt;
        reference.driverCode = json.driverCode;
        reference.driverName = json.driverName;
        reference.carrierName = json.carrierName;
        reference.email = json.driverEmail;
        reference.phoneNumber = json.driverPhoneNumber;
        reference.cdl = json.cdl;
        return reference;
    };
    DriverReferenceSerializer.prototype.toJson = function (reference) {
        var json = {
            id: reference.id,
            organization: reference.organization,
            value: reference.value,
            driverCode: reference.driverCode,
            driverName: reference.driverName,
            carrierName: reference.carrierName,
            driverEmail: reference.email,
            cdl: reference.cdl,
            driverPhoneNumber: reference.phoneNumber,
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return DriverReferenceSerializer;
}());
exports.DriverReferenceSerializer = DriverReferenceSerializer;
