"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h2[_ngcontent-%COMP%]{margin:0 0 15px}.integrations-container[_ngcontent-%COMP%]{padding:30px}.form-block[_ngcontent-%COMP%]{margin-bottom:30px;display:flex;flex:1 1 auto;padding:30px;border-radius:4px;background-color:#fff;border:1px solid #e6eaee}.form-block[_ngcontent-%COMP%]   .left-side[_ngcontent-%COMP%]{flex:1 1 100%;padding-right:60px;margin-right:60px}.form-block[_ngcontent-%COMP%]   .right-side[_ngcontent-%COMP%]{flex:1 1 auto;display:flex;align-items:center}.form-block[_ngcontent-%COMP%]   .logo-image[_ngcontent-%COMP%]{display:flex;margin-bottom:10px;flex-direction:column;align-items:center;height:150px}.flex-field-group[_ngcontent-%COMP%]   .flex-field[_ngcontent-%COMP%]{width:100%}.integrations-state[_ngcontent-%COMP%]{margin-left:20px}.upload-image-dropzone[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:100%;height:auto;max-height:100%}.switch[_ngcontent-%COMP%]{position:relative;display:block;width:60px;height:34px;float:left;margin-right:14px}.switch[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{display:none}.slider[_ngcontent-%COMP%]{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;border-radius:34px;transition:.4s}.slider[_ngcontent-%COMP%]:before{position:absolute;content:'';height:26px;width:26px;left:4px;top:4px;background-color:#fff;border-radius:50%;transition:.4s}input[_ngcontent-%COMP%]:checked + .slider[_ngcontent-%COMP%]{background-color:#00c853}input[_ngcontent-%COMP%]:focus + .slider[_ngcontent-%COMP%]{box-shadow:0 0 1px #00c853}input[_ngcontent-%COMP%]:checked + .slider[_ngcontent-%COMP%]:before{-webkit-transform:translateX(26px);transform:translateX(26px)}"];
exports.styles = styles;
