import { Component, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { TicketService } from '../../../tickets/ticket.service';
import { CustomerReference } from '../customer-reference';

@Component({
  selector: 'customer-reference-cell',
  templateUrl: './customer-reference-cell.component.html',
  styleUrls: ['./customer-reference-cell.component.scss'],
  providers: [TicketService],
})
export class CustomerReferenceCell implements ICellEditorAngularComp, AfterViewInit {
  params!: ICellEditorParams;
  gridApi!: GridApi;
  reference!: CustomerReference;
  value = '';

  @ViewChild('customerReferenceInput', { read: ViewContainerRef }) jobReferenceInput: any;

  constructor(
    private ticketService: TicketService,
  ) { }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.gridApi = params.api;
  }

  ngAfterViewInit(): void { }

  getValue() {
    if (this.reference) {
      return this.reference.customerName;
    }

    const cell = this.gridApi.getFocusedCell();
    const row = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);

    return row && row.data.customerName ? row.data.customerName : '';
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  isPopup() {
    return true;
  }

  onReferenceClicked(reference: CustomerReference): void {
    const cell = this.gridApi.getFocusedCell();
    const row = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);

    this.ticketService.updateFieldReference<CustomerReference>(row.data.id, reference, 'customer').subscribe(ticket => {
      this.reference = reference;

      row.setData(ticket);

      this.gridApi.stopEditing();
    });
  }

  onReferenceClosed(): void {
    this.gridApi.stopEditing();
  }
}
