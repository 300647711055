"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{width:100%;height:100vh}.title[_ngcontent-%COMP%]{width:100%;padding-bottom:10px;margin-bottom:25px;border-bottom:1px solid #ccc;color:#2a81d7;font-size:14px}.grid-display[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-content:space-between;width:100%;height:100%;padding:25px}  grid-image-panel{width:100%;height:100%;padding:10px}"];
exports.styles = styles;
