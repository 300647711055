"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column}.settings[_ngcontent-%COMP%]{display:flex;flex-direction:column;flex:1 1 auto;overflow-y:auto;height:calc(100% - 146px)}"];
exports.styles = styles;
