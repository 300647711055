import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AuthenticationService } from '../shared/index';
import { MatDialog } from '@angular/material';
import { DataService } from '../shared/data.service';
import { RuckitForgotPasswordDialogComponent } from '../shared/forgot-password-dialog/forgot-password-dialog.component';

@Component({
  selector: 'my-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  noLocalStorage = false;
  errors: any[] = [];
  returnUrl: any;
  returnParams!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    public forgotPasswordDialog: MatDialog
  ) {}

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    this.route.queryParams.subscribe((params: Params) => {
      if (params['returnUrl'] && params['returnUrl'].length) {
        let urlParts = params['returnUrl'].split('?');
        this.returnUrl = urlParts[0];
        this.returnParams = urlParts[1];
      }
    });

    try {
      localStorage.setItem('localStorage', '1');
    } catch (e) {
      this.noLocalStorage = true;
      this.errors.push('This website does not support mobile browsers in Private Browsing mode.');
    }
  }

  login() {
    this.loading = true;
    this.errors = [];
    this.authenticationService.login(this.model.email, this.model.password)
      .subscribe(result => {
        if (result === true) {
          this.dataService.changeData({ authenticated: result });
          this.loading = false;
          if (this.returnUrl && this.returnUrl.length && this.returnUrl !== '/logout' && this.returnUrl !== '/') {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['/tickets']);
          }
        } else {
          this.errors.push('Email or password is incorrect');
          this.loading = false;
        }
      }, err => {
        this.loading = false;
        this.errors = err;
      });
  }

  logout() {
    this.loading = true;
    this.authenticationService.logout();
    this.loading = false;
    this.router.navigate(['/login']);
  }

  forgotPassword() {
    let dialogRef = this.forgotPasswordDialog.open(RuckitForgotPasswordDialogComponent, {
      width: '444px'
    });
    dialogRef.componentInstance.model = Object.assign({}, this.model);
    dialogRef.afterClosed().subscribe();
  }
}
