"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var forms_1 = require("@angular/forms");
var customer_reference_service_1 = require("../customer-reference.service");
var CustomerReferenceCreateModalComponent = /** @class */ (function () {
    function CustomerReferenceCreateModalComponent(formBuilder, customerReferenceService, dialogRef, data) {
        this.formBuilder = formBuilder;
        this.customerReferenceService = customerReferenceService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.referenceForm = this.formBuilder.group({
            customerCode: [''],
            customerName: [''],
            customerAddress1: [''],
            customerAddress2: [''],
            customerCity: [''],
            customerState: [''],
            customerZipcode: [''],
            customerPhoneNumber: [''],
            customerEmail: [''],
        });
        this.mode = 'create';
        this.callback = function () { };
    }
    CustomerReferenceCreateModalComponent.prototype.ngOnInit = function () {
        if (this.data.model) {
            this.setFormFieldData(this.data.model);
        }
        this.mode = this.data.mode;
    };
    CustomerReferenceCreateModalComponent.prototype.onSubmit = function () {
        var value = this.referenceForm.value;
        if (this.data.model) {
            value = __assign({}, this.data.model, this.referenceForm.value);
        }
        this.callback(this.customerReferenceService.save(value));
        this.dialogRef.close();
    };
    CustomerReferenceCreateModalComponent.prototype.setFormFieldData = function (data) {
        this.referenceForm.controls['customerCode'].setValue(data.customerCode);
        this.referenceForm.controls['customerName'].setValue(data.customerName);
        this.referenceForm.controls['customerAddress1'].setValue(data.customerAddress1);
        this.referenceForm.controls['customerAddress2'].setValue(data.customerAddress2);
        this.referenceForm.controls['customerCity'].setValue(data.customerCity);
        this.referenceForm.controls['customerState'].setValue(data.customerState);
        this.referenceForm.controls['customerZipcode'].setValue(data.customerZipcode);
        this.referenceForm.controls['customerPhoneNumber'].setValue(data.customerPhoneNumber);
        this.referenceForm.controls['customerEmail'].setValue(data.customerEmail);
    };
    return CustomerReferenceCreateModalComponent;
}());
exports.CustomerReferenceCreateModalComponent = CustomerReferenceCreateModalComponent;
