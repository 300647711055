'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LoaderComponent = /** @class */ (function () {
    function LoaderComponent() {
        this.isDelayedRunning = false;
        this.matTableLoader = false;
        this.delay = 300;
    }
    Object.defineProperty(LoaderComponent.prototype, "isRunning", {
        set: function (value) {
            var _this = this;
            if (!value) {
                this.cancelTimeout();
                this.isDelayedRunning = false;
                return;
            }
            if (this.currentTimeout) {
                return;
            }
            this.currentTimeout = setTimeout(function () {
                _this.isDelayedRunning = value;
                _this.cancelTimeout();
            }, this.delay);
        },
        enumerable: true,
        configurable: true
    });
    LoaderComponent.prototype.cancelTimeout = function () {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = undefined;
    };
    LoaderComponent.prototype.ngOnDestroy = function () {
        this.cancelTimeout();
    };
    return LoaderComponent;
}());
exports.LoaderComponent = LoaderComponent;
