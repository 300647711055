"use strict";
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// `.env.ts` is generated by the `npm run env` command
// import env from './.env';
Object.defineProperty(exports, "__esModule", { value: true });
var production = 'true';
var serverUrl = 'https://ruckit-review-pr-19.herokuapp.com/api/';
var imageServerUrl = 'http://ruckit-review-pr-19.s3-website-us-east-1.amazonaws.com';
var resizeImageServerUrl = 'https://d2o05km0cx02l1.cloudfront.net';
var ticketImageServerUrl = 'https://s3.amazonaws.com/ruckit-review-pr-19/';
var errorTrackingEnv = 'demo';
var googleMapsKey = 'AIzaSyBnyQsblAiJeVVAHKQyo76Nx8PVp-N2mLU';
var applicationVersion = '2021.2.0-37b2cb3-develop';
var scaleitUrl = 'https://api.scaleitusa.com/api/ruckit/v1/';
var ptpRuckitKey = 'ae59267f5b435adfb6f00dbcee33b5341eef94c9';
var ptpApiKey = '958616b01e9bb274ff6278e02b19d9f3';
var ptpUrl = 'https://pitticketpro-staging.herokuapp.com/partner_api/ticket_manager';
exports.environment = {
    production: production === 'false',
    serverUrl: serverUrl,
    imageServerUrl: imageServerUrl,
    resizeImageServerUrl: resizeImageServerUrl,
    ticketImageServerUrl: ticketImageServerUrl,
    scaleitUrl: scaleitUrl,
    ptpRuckitKey: ptpRuckitKey,
    ptpApiKey: ptpApiKey,
    ptpUrl: ptpUrl,
    errorTrackingEnv: errorTrackingEnv,
    applicationVersion: applicationVersion,
    defaultLanguage: 'en-US',
    supportedLanguages: [
        'en-US',
        'fr-FR'
    ],
    googleMapsKey: googleMapsKey
};
