"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var index_1 = require("../index");
var router_1 = require("@angular/router");
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(authenticationService, router) {
        this.authenticationService = authenticationService;
        this.router = router;
        this.hamburgerClicked = new core_1.EventEmitter();
        this.loggedIn = false;
    }
    ToolbarComponent.prototype.ngOnInit = function () {
        this.user = this.authenticationService.user();
        this.loggedIn = this.authenticationService.isLoggedIn();
    };
    ToolbarComponent.prototype.onHamburgerClick = function () {
        if (!this.router.url.includes('uploader') && this.loggedIn) {
            this.hamburgerClicked.emit(true);
        }
    };
    ToolbarComponent.prototype.getFSInfo = function () {
        var fullstory = window.FS;
        if (!fullstory || !fullstory.getCurrentSessionURL) {
            return {};
        }
        return {
            url: fullstory.getCurrentSessionURL(),
        };
    };
    return ToolbarComponent;
}());
exports.ToolbarComponent = ToolbarComponent;
