"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var notifications_service_1 = require("../shared/notification/notifications.service");
var operators_1 = require("rxjs/operators");
var BatchUploadNotificationBarComponent = /** @class */ (function () {
    function BatchUploadNotificationBarComponent(notificationsService) {
        this.notificationsService = notificationsService;
    }
    BatchUploadNotificationBarComponent.prototype.ngOnInit = function () {
        this.notifications$ = this.notificationsService.getNotifications().pipe(operators_1.map(function (notifications) { return notifications.filter(function (notification) { return notification.originator === 'uploader'; }); }));
    };
    BatchUploadNotificationBarComponent.prototype.closeNotification = function (notification) {
        this.notificationsService.removeNotification(notification);
    };
    return BatchUploadNotificationBarComponent;
}());
exports.BatchUploadNotificationBarComponent = BatchUploadNotificationBarComponent;
