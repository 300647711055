import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { environment } from '../../environments/environment';
import { LocationOwner } from './location-owner';
import { LocationOwnerSerializer } from './location-owner.serializer';
import { ResourceService } from '../shared/resource.service';

export type ReferenceEditModel = {
  name: string;
};

@Injectable()
export class LocationOwnerService extends ResourceService<LocationOwner> {
  baseUrl = environment.serverUrl + 'locationowners/';
  public nextUri!: string;
  public count: any;
  public search = '';
  public allSelected = false;
  progress$: any;
  progress: any;

  constructor(http: Http) {
    super(http, 'locationowners/', new LocationOwnerSerializer());
  }
}
