"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var FileUploadService = /** @class */ (function () {
    function FileUploadService() {
    }
    FileUploadService.ngInjectableDef = i0.defineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(); }, token: FileUploadService, providedIn: "root" });
    return FileUploadService;
}());
exports.FileUploadService = FileUploadService;
