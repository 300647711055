"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var shortid = require("shortid");
var moment = require("moment");
var ag_grid_angular_1 = require("ag-grid-angular");
var select_all_header_component_1 = require("../shared/grid/select-all-header.component");
var datepicker_cell_component_1 = require("../shared/grid/datepicker-cell.component");
var ticket_date_cell_component_1 = require("../tickets/ticket-date-cell.component");
var ticket_datetime_cell_component_1 = require("../tickets/ticket-datetime-cell.component");
var autocomplete_cell_component_1 = require("../shared/grid/autocomplete-cell.component");
var image_indicator_cell_component_1 = require("../shared/grid/image-indicator-cell.component");
var checkbox_cell_component_1 = require("../shared/grid/checkbox-cell.component");
var job_reference_cell_component_1 = require("../references/job-reference/job-reference-cell/job-reference-cell.component");
var customer_reference_cell_component_1 = require("../references/customer-reference/customer-reference-cell/customer-reference-cell.component");
var grid_image_panel_component_1 = require("../shared/grid/grid-image-panel.component");
var bulk_action_dialog_component_1 = require("../shared/grid/bulk-action-dialog.component");
var notifications_service_1 = require("../shared/notification/notifications.service");
var authentication_service_1 = require("../shared/authentication.service");
var report_service_1 = require("../reports/report/report.service");
var ticket_service_1 = require("./ticket.service");
var location_owner_service_1 = require("../location-owners/location-owner.service");
var truck_reference_service_1 = require("../references/truck-reference/truck-reference.service");
var preferences_1 = require("../shared/preferences/preferences");
var preferences_service_1 = require("../shared/preferences/preferences.service");
var driver_reference_cell_component_1 = require("../references/driver-reference/driver-reference-cell/driver-reference-cell.component");
var ocr_service_1 = require("../upload/ocr.service");
var truck_reference_cell_component_1 = require("../references/truck-reference/truck-reference-cell/truck-reference-cell.component");
var shared_1 = require("../shared");
var export_modal_component_1 = require("../shared/export-modal/export-modal.component");
var readonly_cell_component_1 = require("../shared/grid/readonly-cell.component");
var ActionType;
(function (ActionType) {
    ActionType["Export"] = "Export";
    ActionType["Delete"] = "Delete";
    ActionType["Process"] = "Run Data Detection";
    ActionType["Edit"] = "Edit";
    ActionType["CreateInvoice"] = "Create Invoice";
    ActionType["CreateSettlement"] = "Create Settlement";
    ActionType["TransactionProExport"] = "Transaction Pro Export";
})(ActionType || (ActionType = {}));
var TicketGridComponent = /** @class */ (function () {
    function TicketGridComponent(router, ticketService, locationOwnerService, reportService, bulkActionDialog, exportDialog, truckReferenceService, preferencesService, authenticationService, notificationService, ocrService, snackBar) {
        var _this = this;
        this.router = router;
        this.ticketService = ticketService;
        this.locationOwnerService = locationOwnerService;
        this.reportService = reportService;
        this.bulkActionDialog = bulkActionDialog;
        this.exportDialog = exportDialog;
        this.truckReferenceService = truckReferenceService;
        this.preferencesService = preferencesService;
        this.authenticationService = authenticationService;
        this.notificationService = notificationService;
        this.ocrService = ocrService;
        this.snackBar = snackBar;
        this.user = this.authenticationService.user();
        this.enabledFeatures = this.authenticationService.enabledFeatures() || [];
        this.usePandasExport = this.enabledFeatures.includes('usePandasExport');
        this.useLaserficheExport = this.enabledFeatures.includes('useLaserficheExport');
        this.transactionProTicketExportFields = this.authenticationService.getFeature('transactionProTicketExportFields');
        this.allowScaleSyncUploader = this.enabledFeatures.includes('allowScaleSyncUploader');
        this.directReferenceEditing = this.enabledFeatures.includes('directReferenceEditing');
        this.hasTicketQcVt = this.enabledFeatures.includes('hasTicketQcVt');
        this.loading = true;
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.rowModelType = 'serverSide';
        this.cacheBlockSize = 100;
        this.maxBlocksInCache = 10;
        this.frameworkComponents = {
            ticketImageToolPanel: grid_image_panel_component_1.GridImageToolPanel,
            jobReferenceCell: job_reference_cell_component_1.JobReferenceCell,
            customerReferenceCell: customer_reference_cell_component_1.CustomerReferenceCell,
            driverReferenceCell: driver_reference_cell_component_1.DriverReferenceCell,
            truckReferenceCell: truck_reference_cell_component_1.TruckReferenceCell,
            selectAllHeader: select_all_header_component_1.SelectAllHeader,
            autocompleteEditor: autocomplete_cell_component_1.AutocompleteCell,
            datepickerCell: datepicker_cell_component_1.DatepickerCell,
            customNoRowsOverlay: shared_1.NoResultsComponent,
            readOnlyCell: readonly_cell_component_1.ReadOnlyCell
        };
        this.noRowsOverlayComponent = 'customNoRowsOverlay';
        this.noRowsOverlayComponentParams = {
            type: 'tickets',
            onClick: function () { return _this.openBatchUploadDialog(); }
        };
        this.statusBar = {
            statusPanels: []
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'ticketImage',
                    labelDefault: 'Ticket Image',
                    labelKey: 'ticketImage',
                    iconKey: 'ticket-image',
                    toolPanel: 'ticketImageToolPanel'
                },
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                }
            ]
        };
        this.defaultColDef = {
            editable: true,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: { newRowsAction: 'keep' },
            cellEditor: 'autocompleteEditor',
            cellEditorParams: {
                service: this.ticketService
            },
            onCellValueChanged: function (e) { return _this.onCellValueChange(e); }
        };
        this.columnDefs = [
            {
                headerName: 'Select All',
                field: 'select',
                headerComponent: 'selectAllHeader',
                pinned: 'left',
                width: 65,
                maxWidth: 65,
                minWidth: 65,
                editable: false,
                filter: false,
                checkboxSelection: true,
                suppressMovable: true,
                suppressNavigable: true,
                suppressMenu: true,
                headerComponentParams: {
                    checkboxSelection: true,
                    service: this.ticketService,
                    selected: this.ticketService.allSelected
                }
            },
            {
                headerName: 'Created At',
                field: 'createdAt',
                filter: 'agDateColumnFilter',
                filterParams: { newRowsAction: 'keep' },
                editable: false,
                cellRendererFramework: ticket_datetime_cell_component_1.TicketGridDatetimeCell,
                width: 160,
                sortable: true
            },
            {
                headerName: 'Created By',
                field: 'createdBy',
                valueGetter: function (params) {
                    if (!params || !params.data || !params.data.createdBy) {
                        return 'Unknown';
                    }
                    var _a = params.data.createdBy, firstName = _a.firstName, lastName = _a.lastName;
                    return firstName + " " + lastName;
                },
                cellRendererFramework: readonly_cell_component_1.ReadOnlyCell,
                editable: false,
                filter: true,
                width: 200,
                sortable: true,
                cellStyle: { textAlign: 'right' }
            },
            {
                headerName: 'Source',
                field: 'ticketOrigin',
                cellRendererFramework: readonly_cell_component_1.ReadOnlyCell,
                editable: false,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('ticketOrigin')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                width: 200,
                sortable: true,
                cellStyle: { textAlign: 'right' }
            },
            {
                headerName: 'Ticket Date',
                field: 'ticketDate',
                filter: 'agDateColumnFilter',
                filterParams: { newRowsAction: 'keep' },
                editable: true,
                cellEditor: 'datepickerCell',
                cellEditorParams: {
                    service: this.ticketService
                },
                cellRendererFramework: ticket_date_cell_component_1.TicketGridDateCell,
                width: 130,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Image',
                field: 'image',
                filter: 'agSetColumnFilter',
                filterParams: {
                    cellRenderer: function (params) {
                        if (params.value === 'True') {
                            return 'Missing Image';
                        }
                        return 'Has Image';
                    },
                    values: ['True', 'False'],
                    newRowsAction: 'keep'
                },
                cellRendererFramework: image_indicator_cell_component_1.ImageIndicatorCell,
                editable: false,
                width: 100,
                onCellClicked: function (e) { return _this.openImagePanel(e); },
                sortable: true
            },
            {
                headerName: 'Verified',
                field: 'verified',
                filter: 'agSetColumnFilter',
                filterParams: {
                    cellRenderer: function (params) {
                        if (params.value === 'True') {
                            return 'Verified';
                        }
                        return 'Unverified';
                    },
                    values: ['True', 'False'],
                    newRowsAction: 'keep'
                },
                cellRendererFramework: checkbox_cell_component_1.CheckboxCell,
                cellRendererParams: {
                    service: this.ticketService,
                    field: 'verified',
                    callback: function (updates) { return _this.updateRowData(updates); }
                },
                editable: false,
                sortable: true
            },
            {
                headerName: 'Billable',
                field: 'billable',
                filter: 'agSetColumnFilter',
                filterParams: {
                    cellRenderer: function (params) {
                        if (params.value === 'True') {
                            return 'Billable';
                        }
                        return 'Non-billable';
                    },
                    values: ['True', 'False'],
                    newRowsAction: 'keep'
                },
                cellRendererFramework: checkbox_cell_component_1.CheckboxCell,
                cellRendererParams: {
                    service: this.ticketService,
                    field: 'billable'
                },
                editable: false,
                sortable: true
            },
            {
                headerName: 'Ticket #',
                field: 'ticketNumber',
                editable: true,
                filter: false,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                width: 110,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Driver',
                field: 'driverName',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: this.directReferenceEditing ? undefined : 'driverReferenceCell',
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 250,
                sortable: true
            },
            {
                headerName: 'Truck',
                field: 'truckNumber',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: this.directReferenceEditing ? undefined : 'truckReferenceCell',
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 250,
                sortable: true
            },
            {
                headerName: 'Truck #',
                field: 'truckNumber',
                filter: 'agSetColumnFilter',
                editable: true,
                width: 110,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Truck Type',
                field: 'truckType',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.truckReferenceService
                            .getValuesForFieldQuery('value')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                width: 160,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Order #',
                field: 'orderNumber',
                filter: 'agSetColumnFilter',
                editable: true,
                width: 110,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Customer',
                field: 'customerName',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: this.directReferenceEditing ? undefined : 'customerReferenceCell',
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 250,
                sortable: true
            },
            {
                headerName: 'Material',
                field: 'material',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 140,
                sortable: true
            },
            {
                headerName: 'Carrier Name',
                field: 'carrierName',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 140,
                sortable: true
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('quantity')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 120,
                sortable: true
            },
            {
                headerName: 'Quantity Type',
                field: 'quantityType',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('quantityType')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Invoice Rate',
                field: 'invoiceRate',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('invoiceRate')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 120,
                sortable: true
            },
            {
                headerName: 'Rate',
                field: 'rate',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('rate')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 120,
                sortable: true
            },
            {
                headerName: 'Origin',
                field: 'origin',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('origin')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 120,
                sortable: true
            },
            {
                headerName: 'Destination',
                field: 'destination',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('destination')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 120,
                sortable: true
            },
            {
                headerName: 'Location Owner',
                field: 'locationOwner',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.locationOwnerService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 140,
                sortable: true
            },
            {
                headerName: 'Haul Rate',
                field: 'haulRate',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('haulRate')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 120,
                sortable: true
            },
            {
                headerName: 'Job',
                field: 'jobName',
                filter: 'agSetColumnFilter',
                editable: true,
                cellEditor: this.directReferenceEditing ? undefined : 'jobReferenceCell',
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                width: 300,
                sortable: true
            },
            {
                headerName: 'Job Code',
                field: 'jobCode',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.ticketService
                            .getValuesForFieldQuery('jobCode')
                            .subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep'
                },
                editable: true,
                width: 500,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
            {
                headerName: 'Invoiced',
                field: 'invoiced',
                cellRendererFramework: checkbox_cell_component_1.CheckboxCell,
                cellRendererParams: {
                    service: this.ticketService,
                    field: 'invoiced'
                },
                editable: false,
                filter: 'agSetColumnFilter',
                filterParams: {
                    cellRenderer: function (params) {
                        if (params.value === 'True') {
                            return 'Invoiced';
                        }
                        return 'Not Invoiced';
                    },
                    values: ['True', 'False'],
                    newRowsAction: 'keep'
                },
                sortable: true
            },
            {
                headerName: 'Review Status',
                field: 'tripReviewedStatus',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        params.success(['Approved', 'Rejected', 'Pending']);
                    },
                    newRowsAction: 'keep'
                },
                cellRendererFramework: shared_1.StatusCell,
                cellRendererParams: {
                    statusList: {
                        pendingValue: 'pending',
                        successValue: 'approved',
                        failureValue: 'rejected'
                    }
                },
                editable: false,
                width: 150,
                sortable: true
            },
            {
                headerName: 'Reviewer',
                field: 'tripReviewedAt',
                valueGetter: function (params) {
                    if (!params || !params.data || !params.data.tripReviewedBy) {
                        return '';
                    }
                    var reviewer = params.data.tripReviewedBy;
                    return reviewer.firstName + " " + reviewer.lastName;
                },
                filter: true,
                editable: false,
            },
            {
                headerName: 'Reviewed At',
                field: 'tripReviewedAt',
                editable: false,
                cellRendererFramework: ticket_date_cell_component_1.TicketGridDateCell,
                width: 140,
                sortable: true
            },
            {
                headerName: 'Ticket Notes',
                field: 'ticketNotes',
                filter: false,
                editable: true,
                width: 500,
                cellEditor: 'autocompleteEditor',
                cellEditorParams: {
                    service: this.ticketService
                },
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                sortable: true
            },
        ];
        this.actionOptions = [];
        this.ticketFiltersString = localStorage.getItem('ticketFilters');
        this.ticketFilters = this.ticketFiltersString && this.ticketFiltersString.length && this.ticketFiltersString[0] === '{' ?
            JSON.parse(this.ticketFiltersString) : {};
        this.searchChanged
            .pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged())
            .subscribe(function (search) {
            _this.loading = true;
            _this.search = search;
            _this.ticketService.search = _this.search;
            _this.refreshTable();
        });
        if (this.hasTicketQcVt) {
            this.columnDefs = this.columnDefs.concat([
                {
                    headerName: 'Plant QC',
                    field: 'tripPlantQc',
                    valueGetter: function (params) {
                        if (!params || !params.data || !params.data.tripPlantQc) {
                            return '';
                        }
                        var reviewer = params.data.tripPlantQcReviewer;
                        return {
                            amount: params.data.tripPlantQc,
                            reviewer: reviewer.firstName + " " + reviewer.lastName
                        };
                    },
                    cellRendererFramework: shared_1.ReviewerCell,
                    editable: false,
                    width: 150,
                    sortable: false
                },
                {
                    headerName: 'Plant VT',
                    field: 'tripPlantVt',
                    valueGetter: function (params) {
                        if (!params || !params.data || !params.data.tripPlantVt) {
                            return '';
                        }
                        var reviewer = params.data.tripPlantVtReviewer;
                        return {
                            amount: params.data.tripPlantVt,
                            reviewer: reviewer.firstName + " " + reviewer.lastName
                        };
                    },
                    cellRendererFramework: shared_1.ReviewerCell,
                    editable: false,
                    width: 150,
                    sortable: false
                },
                {
                    headerName: 'Roadway QC',
                    field: 'tripRoadwayQc',
                    valueGetter: function (params) {
                        if (!params || !params.data || !params.data.tripRoadwayQc) {
                            return '';
                        }
                        var reviewer = params.data.tripRoadwayQcReviewer;
                        return {
                            amount: params.data.tripRoadwayQc,
                            reviewer: reviewer.firstName + " " + reviewer.lastName
                        };
                    },
                    cellRendererFramework: shared_1.ReviewerCell,
                    editable: false,
                    width: 150,
                    sortable: false
                },
                {
                    headerName: 'Roadway VT',
                    field: 'tripRoadwayVt',
                    valueGetter: function (params) {
                        if (!params || !params.data || !params.data.tripRoadwayVt) {
                            return '';
                        }
                        var reviewer = params.data.tripRoadwayVtReviewer;
                        return {
                            amount: params.data.tripRoadwayVt,
                            reviewer: reviewer.firstName + " " + reviewer.lastName
                        };
                    },
                    cellRendererFramework: shared_1.ReviewerCell,
                    editable: false,
                    width: 150,
                    sortable: false
                }
            ]);
        }
    }
    TicketGridComponent.prototype.onGridReady = function (e) {
        var _this = this;
        if (!e.api || !e.columnApi) {
            return;
        }
        this.enabledFeatures = this.authenticationService.enabledFeatures() || [];
        this.gridApi = e.api;
        this.columnApi = e.columnApi;
        this.ticketGrid.defaultColDef = this.defaultColDef;
        this.ticketGrid.gridOptions.getContextMenuItems = function (params) {
            return _this.getContextMenuItems(params);
        };
        this.ticketGrid.gridOptions.getRowNodeId = function (data) {
            return data.id;
        };
        this.gridApi.addEventListener('modelUpdated', function () {
            _this.loading = false;
            if (_this.ticketService.allSelected) {
                _this.gridApi.forEachNode(function (node) {
                    return node.setSelected(_this.ticketService.allSelected);
                });
            }
        });
        this.gridApi.setServerSideDatasource(this.ticketService);
        this.applyPreferences();
        this.ticketService.getFilterValues().subscribe(function (filters) {
            _this.setupFilters(filters);
        });
        var exportOptions = [];
        var exportOption = {
            name: ActionType.Export,
            onClick: function () { return _this.onExport(); },
            disabled: function () { return false; }
        };
        if (this.useLaserficheExport) {
            exportOption = {
                name: ActionType.Export,
                onClick: function () { return _this.onLaserficheExport(); },
                disabled: function () { return false; }
            };
        }
        if (this.usePandasExport) {
            exportOption = {
                name: ActionType.Export,
                onClick: function () { return _this.onPandasExport(); },
                disabled: function () { return false; }
            };
        }
        exportOptions.push(exportOption);
        if (this.transactionProTicketExportFields && this.transactionProTicketExportFields.length) {
            exportOption = {
                name: ActionType.TransactionProExport,
                onClick: function () { return _this.onTransactionProTicketExport(); },
                disabled: function () { return false; }
            };
            exportOptions.push(exportOption);
        }
        this.actionOptions = exportOptions.concat([
            {
                name: ActionType.Edit,
                onClick: function () { return _this.openBatchUploadDialog(); },
                disabled: function () { return _this.numberOfTicketsSelected() === 0; }
            },
            {
                name: ActionType.Delete,
                onClick: function () { return _this.onDeleteTicket(); },
                disabled: function () { return _this.numberOfTicketsSelected() === 0; }
            },
            {
                name: ActionType.Process,
                onClick: function () { return _this.onProcessTicket(); },
                disabled: function () { return _this.numberOfTicketsSelected() === 0; }
            },
            {
                name: ActionType.CreateInvoice,
                onClick: function () { return _this.onCreateInvoice(); },
                disabled: function () { return _this.numberOfTicketsSelected() === 0; }
            },
            {
                name: ActionType.CreateSettlement,
                onClick: function () { return _this.onCreateSettlement(); },
                disabled: function () { return _this.numberOfTicketsSelected() === 0; }
            }
        ]);
        if (this.ticketFilters) {
            this.gridApi.setFilterModel(this.ticketFilters);
        }
    };
    TicketGridComponent.prototype.setupFilters = function (filterValues) {
        this.columnApi
            .getAllColumns()
            .filter(function (column) {
            return Object.keys(filterValues).indexOf(column.getId()) > -1;
        })
            .forEach(function (column) {
            var id = column.getId();
            var newColDef = column.getUserProvidedColDef();
            newColDef.filterParams = {
                values: filterValues[id],
                newRowsAction: 'keep'
            };
            column.setColDef(newColDef, column.getUserProvidedColDef());
        });
    };
    TicketGridComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
        });
        this.columnApi.autoSizeColumns(allColumnIds);
    };
    TicketGridComponent.prototype.getContextMenuItems = function (params) {
        var _this = this;
        var exportOptions = [];
        var exportOption = {
            name: 'Export',
            action: function () { return _this.onExport(); }
        };
        if (this.useLaserficheExport) {
            exportOption = {
                name: 'Export LF',
                action: function () { return _this.onLaserficheExport(); }
            };
        }
        exportOptions.push(exportOption);
        if (this.transactionProTicketExportFields && this.transactionProTicketExportFields.length) {
            exportOption = {
                name: ActionType.TransactionProExport,
                action: function () { return _this.onTransactionProTicketExport(); }
            };
            exportOptions.push(exportOption);
        }
        if (this.usePandasExport) {
            exportOption = {
                name: 'Export',
                action: function () { return _this.onPandasExport(); }
            };
        }
        return [
            {
                name: 'Edit',
                action: function () { return _this.openBatchUploadDialog(); }
            },
            {
                name: 'Delete',
                action: function () { return _this.onDeleteTicket(params); }
            },
            {
                name: 'Run Data Detection',
                action: function () { return _this.onProcessTicket(params); }
            },
            'copy',
            'separator',
            {
                name: 'Create Invoice',
                action: function () { return _this.onCreateInvoice(); }
            },
            {
                name: 'Create Settlement',
                action: function () { return _this.onCreateSettlement(); }
            }
        ].concat(exportOptions);
    };
    TicketGridComponent.prototype.applyPreferences = function () {
        var _this = this;
        // Only update or create a default preference until UI for selection is in place
        this.preferencesService
            .list()
            .pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return p.name === 'tickets-default'; });
            return pref ? pref : new preferences_1.Preferences();
        }))
            .subscribe(function (preferences) {
            _this.preferences = preferences;
            if (preferences.type === 'tickets' && preferences.blob) {
                var createdAtColumn = lodash_1.find(preferences.blob.columnState, { colId: 'createdAt' });
                if (createdAtColumn && createdAtColumn['width'] < 160) {
                    createdAtColumn['width'] = 160;
                }
                _this.columnApi.setColumnState(preferences.blob.columnState);
                _this.gridApi.setSortModel(preferences.blob.sortState);
            }
            var eventList = [
                'gridColumnsChanged',
                'columnPinned',
                'columnVisible',
                'columnResized',
                'columnMoved'
            ];
            eventList.forEach(function (event) {
                _this.gridApi.addEventListener(event, lodash_1.debounce(function () { return _this.savePreferences(); }, 300));
            });
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured saving your preferences.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.savePreferences = function () {
        var _this = this;
        var columnData = this.columnApi.getColumnState();
        var preferencesObj = __assign({}, this.preferences, { profile: this.user.id, type: 'tickets', name: 'tickets-default', blob: {
                columnState: columnData
            } });
        this.preferencesService.save(preferencesObj).subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured saving your preferences.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportSelected = function (includeImages) {
        var _this = this;
        if (includeImages === void 0) { includeImages = false; }
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        this.ticketService
            .exportGrid('tickets', selected, undefined, this.ticketFilters, this.search, undefined, includeImages)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportAll = function (includeImages) {
        var _this = this;
        if (includeImages === void 0) { includeImages = false; }
        this.ticketService
            .exportGrid('tickets', undefined, undefined, this.ticketFilters, this.search, false, includeImages)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportSelectedLaserfiche = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        this.ticketService
            .exportGridLaserfiche(selected, undefined, this.ticketFilters, this.search, this.ticketService.allSelected)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportAllLaserfiche = function () {
        var _this = this;
        this.ticketService
            .exportGridLaserfiche(undefined, undefined, this.ticketFilters, this.search, this.ticketService.allSelected)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportSelectedPandas = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        this.ticketService
            .exportGridPandas(selected, undefined, this.ticketFilters, this.search, this.ticketService.allSelected)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportAllPandas = function () {
        var _this = this;
        this.ticketService
            .exportGridPandas(undefined, undefined, this.ticketFilters, this.search, this.ticketService.allSelected)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportSelectedTransactionPro = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        this.ticketService
            .exportGridPandas(selected, undefined, this.ticketFilters, this.search, this.ticketService.allSelected, this.transactionProTicketExportFields)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onExportAllTransactionPro = function () {
        var _this = this;
        this.ticketService
            .exportGridPandas(undefined, undefined, this.ticketFilters, this.search, this.ticketService.allSelected, this.transactionProTicketExportFields)
            .subscribe(function () {
            _this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        }, function (error) {
            var notification = {
                context: {
                    error: error
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'tickets',
                type: notifications_service_1.NotificationType.Danger
            };
            _this.notificationService.addNotification(notification);
        });
    };
    TicketGridComponent.prototype.onEdit = function (params) {
        this.gridApi.deselectAll();
        params.node.setSelected(true);
        this.openBatchUploadDialog();
    };
    TicketGridComponent.prototype.updateRowData = function (ticket) {
        var row = this.gridApi.getRowNode(ticket.id);
        row.setData(ticket);
    };
    TicketGridComponent.prototype.onExport = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        var dialog = this.exportDialog.open(export_modal_component_1.ExportModalComponent, {
            data: {
                selected: selected && selected.length >= 1
            }
        });
        dialog.componentInstance.callback = function (exportOption, includeImages) {
            if (exportOption === export_modal_component_1.ExportOption.Selected) {
                _this.onExportSelected(includeImages);
            }
            else {
                _this.onExportAll(includeImages);
            }
        };
    };
    TicketGridComponent.prototype.onLaserficheExport = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        var dialog = this.exportDialog.open(export_modal_component_1.ExportModalComponent, {
            data: {
                selected: selected && selected.length >= 1,
                imagesExportable: false
            }
        });
        dialog.componentInstance.callback = function (exportOption) {
            if (exportOption === export_modal_component_1.ExportOption.Selected) {
                _this.onExportSelectedLaserfiche();
            }
            else {
                _this.onExportAllLaserfiche();
            }
        };
    };
    TicketGridComponent.prototype.onPandasExport = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        var dialog = this.exportDialog.open(export_modal_component_1.ExportModalComponent, {
            data: {
                selected: selected && selected.length >= 1,
                imagesExportable: false
            }
        });
        dialog.componentInstance.callback = function (exportOption) {
            if (exportOption === export_modal_component_1.ExportOption.Selected) {
                _this.onExportSelectedPandas();
            }
            else {
                _this.onExportAllPandas();
            }
        };
    };
    TicketGridComponent.prototype.onTransactionProTicketExport = function () {
        var _this = this;
        var selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        var dialog = this.exportDialog.open(export_modal_component_1.ExportModalComponent, {
            data: {
                selected: selected && selected.length >= 1,
                imagesExportable: false
            }
        });
        dialog.componentInstance.callback = function (exportOption) {
            if (exportOption === export_modal_component_1.ExportOption.Selected) {
                _this.onExportSelectedTransactionPro();
            }
            else {
                _this.onExportAllTransactionPro();
            }
        };
    };
    TicketGridComponent.prototype.onDeleteTicket = function (params) {
        var _this = this;
        var selected;
        if (this.numberOfTicketsSelected() > 0) {
            selected = this.gridApi.getSelectedRows().map(function (s) { return s.id; });
        }
        else {
            if (!params.api) {
                return;
            }
            selected = params.api.getSelectedRows();
        }
        if (!selected || !selected.length) {
            selected = [params.node.data];
            params.node.setSelected(true);
        }
        var dialog = this.bulkActionDialog.open(bulk_action_dialog_component_1.BulkActionDialogComponent, {
            width: '500px',
            data: {
                selected: selected
            }
        });
        dialog.componentInstance.callback = function () {
            var tickets = rxjs_1.zip.apply(void 0, selected.map(function (t) {
                return _this.deleteTicket(t);
            }));
            tickets.subscribe(function () {
                _this.refreshTable(true);
            }, function (error) {
                var notification = {
                    context: {
                        error: error
                    },
                    id: shortid.generate(),
                    message: 'An error occured deleting your selections.',
                    originator: 'tickets',
                    type: notifications_service_1.NotificationType.Danger
                };
                _this.notificationService.addNotification(notification);
            });
        };
    };
    TicketGridComponent.prototype.onProcessTicket = function (params) {
        var _this = this;
        var selected;
        if (this.numberOfTicketsSelected() > 0) {
            selected = this.gridApi.getSelectedRows();
        }
        else {
            if (!params.api) {
                return;
            }
            selected = params.api.getSelectedRows();
        }
        if (!selected || !selected.length) {
            selected = [params.node.data];
            params.node.setSelected(true);
        }
        var dialog = this.bulkActionDialog.open(bulk_action_dialog_component_1.BulkActionDialogComponent, {
            width: '500px',
            data: {
                selected: selected,
                action: 'process'
            }
        });
        dialog.componentInstance.callback = function () {
            selected.forEach(function (ticket) {
                rxjs_1.from(_this.processTicket(ticket)).subscribe(function (result) {
                    var updatedColumns = [];
                    var ocrTicketData = { id: ticket.id };
                    if (result.ticketNumber) {
                        ocrTicketData.ticketNumber = result.ticketNumber;
                        updatedColumns.push('ticketNumber');
                    }
                    if (result.ticketDate) {
                        ocrTicketData.ticketDate = moment(result.ticketDate).format('YYYY-MM-DD');
                        updatedColumns.push('ticketDate');
                    }
                    if (result.quantity) {
                        ocrTicketData.quantity = result.quantity;
                        updatedColumns.push('quantity');
                    }
                    _this.saveTicket(ocrTicketData).subscribe(function (ticketUpdates) {
                        var row = _this.gridApi.getRowNode(ticket.id);
                        _this.gridApi.flashCells({
                            rowNodes: [row],
                            columns: updatedColumns
                        });
                        row.setData(ticketUpdates);
                    }, function (error) {
                        var notification = {
                            context: {
                                error: error
                            },
                            id: shortid.generate(),
                            message: 'An error occured processing your selections.',
                            originator: 'tickets',
                            type: notifications_service_1.NotificationType.Danger
                        };
                        _this.notificationService.addNotification(notification);
                    });
                });
            });
        };
    };
    TicketGridComponent.prototype.processTicket = function (ticket) {
        if (!ticket.image) {
            return Promise.resolve({});
        }
        return this.ocrService.getResults(ticket);
    };
    TicketGridComponent.prototype.onCellValueChange = function (e) {
        var _this = this;
        if (e.newValue === e.oldValue) {
            return;
        }
        var ticket = __assign({}, e.data);
        this.saveTicket(ticket).subscribe(function (updates) {
            var row = _this.gridApi.getRowNode(updates.id);
            row.setData(updates);
        });
    };
    TicketGridComponent.prototype.onCreateInvoice = function () {
        var _this = this;
        var selectedTickets = this.gridApi.getSelectedRows();
        var ticketIds = selectedTickets.map(function (ticket) { return ticket.id; });
        var reportData = {
            type: 'invoice',
            blob: {}
        };
        this.reportService.save(reportData).subscribe(function (report) {
            _this.reportService.add(report.id, ticketIds).subscribe(function () {
                _this.router.navigate(['invoices', report.id, 'edit']);
            });
        });
    };
    TicketGridComponent.prototype.onCreateSettlement = function () {
        var _this = this;
        var selectedTickets = this.gridApi.getSelectedRows();
        var ticketIds = selectedTickets.map(function (ticket) { return ticket.id; });
        var reportData = {
            type: 'expense',
            blob: {}
        };
        this.reportService.save(reportData).subscribe(function (report) {
            _this.reportService.add(report.id, ticketIds).subscribe(function () {
                _this.router.navigate(['settlements', report.id, 'edit']);
            });
        });
    };
    TicketGridComponent.prototype.openImagePanel = function (e) {
        if (e.data.image) {
            this.gridApi.openToolPanel('ticketImage');
        }
    };
    TicketGridComponent.prototype.onFirstDataRendered = function () { };
    TicketGridComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    TicketGridComponent.prototype.refreshTable = function (clear) {
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.gridApi.deselectAll();
            this.gridApi.clearRangeSelection();
        }
        this.gridApi.refreshCells();
        this.gridApi.purgeServerSideCache();
    };
    TicketGridComponent.prototype.saveTicket = function (ticket) {
        delete ticket.driver;
        delete ticket.organization;
        delete ticket.ticket_date_raw;
        delete ticket.truck;
        return this.ticketService.save(ticket);
    };
    TicketGridComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    };
    TicketGridComponent.prototype.deleteTicket = function (ticket) {
        return this.ticketService.remove(ticket);
    };
    TicketGridComponent.prototype.numberOfTicketsSelected = function () {
        return this.gridApi && this.gridApi.getSelectedRows().length;
    };
    TicketGridComponent.prototype.openBatchUploadDialog = function () {
        var selectedTickets = this.gridApi.getSelectedRows();
        if (selectedTickets) {
            var constructedParams_1 = {};
            selectedTickets.map(function (ticket) {
                constructedParams_1.tickets = constructedParams_1.tickets
                    ? constructedParams_1.tickets + ',' + ticket.id
                    : ticket.id;
            });
            this.router.navigate(['uploader'], { queryParams: constructedParams_1 });
        }
        else {
            this.router.navigate(['uploader']);
        }
    };
    TicketGridComponent.prototype.openScaleSyncUploadDialog = function () {
        this.router.navigate(['scale-sync-uploader']);
    };
    TicketGridComponent.prototype.addRow = function () {
        var _this = this;
        this.saveTicket({}).subscribe(function () {
            var sort = [{ colId: 'createdAt', sort: 'desc' }];
            _this.gridApi.setSortModel(sort);
            _this.refreshTable();
        });
    };
    TicketGridComponent.prototype.isExternalFilterPresent = function () { };
    TicketGridComponent.prototype.doesExternalFilterPass = function () { };
    TicketGridComponent.prototype.onFilterChanges = function () {
        this.ticketFilters = this.gridApi.getFilterModel();
        localStorage.setItem('ticketFilters', JSON.stringify(this.ticketFilters));
    };
    return TicketGridComponent;
}());
exports.TicketGridComponent = TicketGridComponent;
