"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_cropperjs_1 = require("angular-cropperjs");
var upload_service_1 = require("../upload.service");
var operators_1 = require("rxjs/operators");
var shortid = require("shortid");
var notifications_service_1 = require("../notification/notifications.service");
var ImageEditorComponent = /** @class */ (function () {
    function ImageEditorComponent(uploadService, notificationsService) {
        this.uploadService = uploadService;
        this.notificationsService = notificationsService;
        this.external = false;
        this.onSave = new core_1.EventEmitter();
        this.onImageClick = new core_1.EventEmitter();
        this.loading = false;
        this.error = false;
        this.config = {
            autoCrop: false,
            autoCropArea: 1,
            checkOrientation: false,
            background: false,
            modal: false,
            guides: false,
            center: false,
            highlight: false,
            movable: false,
            scalable: false,
            zoomable: false,
            viewMode: 0,
            strict: false,
            dragCrop: false,
            rotatable: true,
            cropBoxMovable: false,
            cropBoxResizable: false,
            responsive: true
        };
    }
    ImageEditorComponent.prototype.imageError = function () {
        if (this.imageEditor && !this.error) {
            this.error = this.imageEditor.loadError;
            if (this.error) {
                this.pushError();
            }
        }
        return this.error;
    };
    ImageEditorComponent.prototype.editableImage = function () {
        return !this.imageError() && this.imageUrl.match(/.(jpg|jpeg|png|gif)$/i) !== null &&
            this.imageUrl.includes('amazonaws') && !this.imageUrl.includes('twilio');
    };
    ImageEditorComponent.prototype.ngOnChanges = function () {
        if (this.imageEditor && this.imageEditor.cropper) {
            this.imageEditor.cropper.replace(this.imageUrl).destroy();
        }
    };
    ImageEditorComponent.prototype.save = function (e) {
        var _this = this;
        this.loading = true;
        var editTime = Date.now().toString();
        var imageFile = e ? e.target.files[0] : this.uploadService.convertDataUriToFile(this.imageEditor.cropper.getCroppedCanvas().toDataURL('image/jpeg'), 'edited-' + editTime + '.jpg');
        var imageKey = this.imageUrl.includes('tickets') ?
            'tickets/' + this.imageUrl.substring(0, this.imageUrl.lastIndexOf('/') + 1).split('tickets/')[1] :
            this.imageUrl.includes('checkins') ?
                'tickets/' + this.imageUrl.substring(0, this.imageUrl.lastIndexOf('/') + 1).split('checkins/')[1] :
                this.imageUrl.substring(this.imageUrl.indexOf('/', 10) + 1, this.imageUrl.lastIndexOf('/') + 1);
        var updatedKey = imageKey + 'edited-' + editTime + '.jpg';
        this.uploadService.getS3Policy().pipe(operators_1.switchMap(function (policy) {
            policy.fields.key = updatedKey;
            return _this.uploadService.uploadToS3(policy, imageFile);
        })).subscribe(function () {
            if (e) {
                _this.imageEditor && _this.imageEditor.cropper &&
                    _this.imageEditor.cropper.replace(_this.imageUrl.substring(0, _this.imageUrl.lastIndexOf('/') + 1).replace('checkins', 'tickets') + 'edited-' + editTime + '.jpg');
            }
            _this.onSave.emit({ id: _this.id, imageUrl: _this.imageUrl.substring(0, _this.imageUrl.lastIndexOf('/') + 1).replace('checkins', 'tickets') + 'edited-' + editTime + '.jpg' });
            _this.loading = false;
        });
    };
    ImageEditorComponent.prototype.rotate = function (degrees) {
        this.imageEditor.cropper.rotate(degrees);
    };
    ImageEditorComponent.prototype.imageClick = function () {
        this.onImageClick.emit(true);
    };
    ImageEditorComponent.prototype.pushError = function () {
        var notification = {
            context: {},
            id: shortid.generate(),
            message: 'An error occured loading this image in the editor. Please reload and try again.',
            originator: 'image-editor',
            type: notifications_service_1.NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
    };
    return ImageEditorComponent;
}());
exports.ImageEditorComponent = ImageEditorComponent;
