import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomerReference } from '../customer-reference';
import { FormBuilder } from '@angular/forms';
import { CustomerReferenceService } from '../customer-reference.service';
import { Observable } from 'rxjs';

type ExcludedFields = (
  'id'
  | 'createdAt'
  | 'classes'
  | 'loading'
  | 'selected'
  | 'filterOptions'
  | 'organization'
);
export type CustomerReferenceFormModel = Pick<CustomerReference, Exclude<keyof CustomerReference, ExcludedFields>>;
export type CreateCustomerReferenceCallback = (response$: Observable<CustomerReference>) => void;
export type CustomerReferenceDialogData = {
  data: {
    model: CustomerReferenceFormModel,
    mode: string,
  },
  width: string,
};

@Component({
  selector: 'customer-reference-create-modal',
  templateUrl: './customer-reference-create-modal.component.html',
  styleUrls: ['./customer-reference-create-modal.component.scss'],
  providers: [CustomerReferenceService],
})
export class CustomerReferenceCreateModalComponent implements OnInit {
  referenceForm = this.formBuilder.group({
    customerCode: [''],
    customerName: [''],
    customerAddress1: [''],
    customerAddress2: [''],
    customerCity: [''],
    customerState: [''],
    customerZipcode: [''],
    customerPhoneNumber: [''],
    customerEmail: [''],
  });

  mode = 'create';

  public callback: CreateCustomerReferenceCallback = () => {};

  constructor(
    private formBuilder: FormBuilder,
    private customerReferenceService: CustomerReferenceService,
    public dialogRef: MatDialogRef<CustomerReferenceCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerReferenceFormModel,
  ) { }

  ngOnInit() {
    if (this.data.model) {
      this.setFormFieldData(this.data.model);
    }

    this.mode = this.data.mode;
  }

  onSubmit() {
    let value: CustomerReferenceFormModel = this.referenceForm.value;

    if (this.data.model) {
      value = { ...this.data.model, ...this.referenceForm.value };
    }

    this.callback(this.customerReferenceService.save(value));

    this.dialogRef.close();
  }

  setFormFieldData(data: CustomerReferenceFormModel): void {
    this.referenceForm.controls['customerCode'].setValue(data.customerCode);
    this.referenceForm.controls['customerName'].setValue(data.customerName);
    this.referenceForm.controls['customerAddress1'].setValue(data.customerAddress1);
    this.referenceForm.controls['customerAddress2'].setValue(data.customerAddress2);
    this.referenceForm.controls['customerCity'].setValue(data.customerCity);
    this.referenceForm.controls['customerState'].setValue(data.customerState);
    this.referenceForm.controls['customerZipcode'].setValue(data.customerZipcode);
    this.referenceForm.controls['customerPhoneNumber'].setValue(data.customerPhoneNumber);
    this.referenceForm.controls['customerEmail'].setValue(data.customerEmail);
  }
}
