"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["ul[_ngcontent-%COMP%]{position:relative;overflow:auto;padding:0 0 20px;background:#fff;list-style-type:none;height:245px;min-height:215px;margin:12px -10px 0}li[_ngcontent-%COMP%]{cursor:pointer;height:auto;font-size:12px;margin:0;padding:10px;text-align:left}li[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{margin:0;max-width:335px;font-weight:500}li[_ngcontent-%COMP%]   .item-detail[_ngcontent-%COMP%]{color:#72839b;font-size:12px}li[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]{position:absolute;color:#72839b;font-size:24px;right:10px;margin-top:0}li[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]:hover:before, li.selected[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]:before{content:\"\\f058\"}li.selected[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]{color:#00c853}li[_ngcontent-%COMP%]:hover{background:#f4f6f8}li[_ngcontent-%COMP%]:hover   .select-icon[_ngcontent-%COMP%]:before{content:\"\\f058\"}li.unselected[_ngcontent-%COMP%]{opacity:.4}li.no-results[_ngcontent-%COMP%]{text-align:center}li.no-results[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{max-width:none}li.no-results[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]{display:none}li.no-results[_ngcontent-%COMP%]:hover{background:0 0}"];
exports.styles = styles;
