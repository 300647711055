"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/toolbar/toolbar.component.ngfactory");
var i3 = require("./users/user.service");
var i4 = require("@angular/http");
var i5 = require("./shared/toolbar/toolbar.component");
var i6 = require("./shared/authentication.service");
var i7 = require("@angular/router");
var i8 = require("@ngx-translate/core");
var i9 = require("@angular/common");
var i10 = require("./shared/sidebar/sidebar.component.ngfactory");
var i11 = require("./shared/sidebar/sidebar.component");
var i12 = require("./app.component");
var i13 = require("./shared/data.service");
var i14 = require("@angular/material/icon");
var i15 = require("@angular/platform-browser");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "toolbar", [], [[8, "title", 0]], [[null, "hamburgerClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hamburgerClicked" === en)) {
        var pd_0 = (_co.onHamburgerClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵprd(4608, null, i3.UserService, i3.UserService, [i4.Http]), i1.ɵdid(2, 114688, null, 0, i5.ToolbarComponent, [i6.AuthenticationService, i7.Router], null, { hamburgerClicked: "hamburgerClicked" }), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 3).transform("Ticket Manager")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "app"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "sidebar", [["class", "app__sidebar"]], null, null, null, i10.View_SidebarComponent_0, i10.RenderType_SidebarComponent)), i1.ɵdid(4, 114688, null, 0, i11.SidebarComponent, [i7.Router], { drawerVisible: [0, "drawerVisible"], visible: [1, "visible"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "app__sidebar__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loggedIn; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.sidebarOpen && _co.loggedIn); var currVal_2 = (_co.loggedIn && !_co.router.url.includes("uploader")); _ck(_v, 4, 0, currVal_1, currVal_2); _ck(_v, 7, 0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i12.AppComponent, [i13.DataService, i6.AuthenticationService, i14.MatIconRegistry, i15.DomSanitizer, i7.Router, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("my-app", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
