"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./settings.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/router");
var i4 = require("@angular/common");
var i5 = require("./settings-organization/settings-organization.component.ngfactory");
var i6 = require("../shared/api.service");
var i7 = require("../organizations/organization.service");
var i8 = require("@angular/http");
var i9 = require("./settings-organization/settings-organization.component");
var i10 = require("@angular/material/dialog");
var i11 = require("./settings-integrations/settings-integrations.component.ngfactory");
var i12 = require("../integrations/quickbooks/quickbooks.service");
var i13 = require("./settings-integrations/settings-integrations.component");
var i14 = require("../shared/authentication.service");
var i15 = require("../shared/action-bar/action-bar.component.ngfactory");
var i16 = require("../shared/action-bar/action-bar.component");
var i17 = require("./settings.component");
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
exports.RenderType_SettingsComponent = RenderType_SettingsComponent;
function View_SettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["routerLinkActive", "active"], ["translate", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(2, 671744, [[4, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 2), i1.ɵdid(4, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Integrations"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 3, 0, "/settings", "integrations"); _ck(_v, 2, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ruckit-settings-organization", [], null, null, null, i5.View_SettingsOrganizationComponent_0, i5.RenderType_SettingsOrganizationComponent)), i1.ɵprd(512, null, i6.ApiService, i6.ApiService, []), i1.ɵprd(512, null, i7.OrganizationService, i7.OrganizationService, [i8.Http]), i1.ɵdid(3, 114688, null, 0, i9.SettingsOrganizationComponent, [i3.Router, i6.ApiService, i7.OrganizationService, i10.MatDialog], { organization: [0, "organization"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organization; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ruckit-settings-integrations", [], null, null, null, i11.View_SettingsIntegrationsComponent_0, i11.RenderType_SettingsIntegrationsComponent)), i1.ɵprd(512, null, i12.QuickbooksCompanyService, i12.QuickbooksCompanyService, [i8.Http]), i1.ɵdid(2, 114688, null, 0, i13.SettingsIntegrationsComponent, [i14.AuthenticationService, i7.OrganizationService, i12.QuickbooksCompanyService, i10.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "action-bar", [], null, null, null, i15.View_ActionBarComponent_0, i15.RenderType_ActionBarComponent)), i1.ɵdid(2, 49152, null, 0, i16.ActionBarComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 14, "div", [["class", "left-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Settings"])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "subnav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "a", [["routerLinkActive", "active"], ["translate", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(10, 671744, [[2, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 2), i1.ɵdid(12, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Organization"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_1)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "settings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_2)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_3)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); var currVal_3 = _ck(_v, 11, 0, "/settings", "organization"); _ck(_v, 10, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.quickbooksVisible; _ck(_v, 17, 0, currVal_5); var currVal_6 = ((_co.tab === "organization") && _co.organization); _ck(_v, 20, 0, currVal_6); var currVal_7 = ((_co.tab === "integrations") && _co.quickbooksVisible); _ck(_v, 22, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).target; var currVal_1 = i1.ɵnov(_v, 10).href; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
exports.View_SettingsComponent_0 = View_SettingsComponent_0;
function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵprd(512, null, i7.OrganizationService, i7.OrganizationService, [i8.Http]), i1.ɵdid(2, 114688, null, 0, i17.SettingsComponent, [i3.ActivatedRoute, i14.AuthenticationService, i7.OrganizationService, i10.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_SettingsComponent_Host_0 = View_SettingsComponent_Host_0;
var SettingsComponentNgFactory = i1.ɵccf("settings", i17.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
exports.SettingsComponentNgFactory = SettingsComponentNgFactory;
