"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var customer_reference_1 = require("./customer-reference");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CustomerReferenceSerializer = /** @class */ (function () {
    function CustomerReferenceSerializer() {
    }
    CustomerReferenceSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var reference = new customer_reference_1.CustomerReference();
        if (!json) {
            return reference;
        }
        reference.id = json.id;
        reference.customerCode = json.customerCode;
        reference.customerName = json.customerName;
        reference.customerAddress1 = json.customerAddress1;
        reference.customerAddress2 = json.customerAddress2;
        reference.customerCity = json.customerCity;
        reference.customerState = json.customerState;
        reference.customerZipcode = json.customerZipcode;
        reference.customerPhoneNumber = json.customerPhoneNumber;
        reference.customerEmail = json.customerEmail;
        return reference;
    };
    CustomerReferenceSerializer.prototype.toJson = function (reference) {
        var json = {
            id: reference.id,
            customerCode: reference.customerCode,
            customerName: reference.customerName,
            customerAddress1: reference.customerAddress1,
            customerAddress2: reference.customerAddress2,
            customerCity: reference.customerCity,
            customerState: reference.customerState,
            customerZipcode: reference.customerZipcode,
            customerPhoneNumber: reference.customerPhoneNumber,
            customerEmail: reference.customerEmail,
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return CustomerReferenceSerializer;
}());
exports.CustomerReferenceSerializer = CustomerReferenceSerializer;
