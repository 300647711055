"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./readonly-cell.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./readonly-cell.component");
var styles_ReadOnlyCell = [i0.styles];
var RenderType_ReadOnlyCell = i1.ɵcrt({ encapsulation: 0, styles: styles_ReadOnlyCell, data: {} });
exports.RenderType_ReadOnlyCell = RenderType_ReadOnlyCell;
function View_ReadOnlyCell_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "readonly-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "readonly-cell__value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "readonly-cell__lock"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "readonly-cell__lock__icon"], ["src", "assets/img/lock.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 3, 0, currVal_0); }); }
exports.View_ReadOnlyCell_0 = View_ReadOnlyCell_0;
function View_ReadOnlyCell_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "readonly-cell", [], null, null, null, View_ReadOnlyCell_0, RenderType_ReadOnlyCell)), i1.ɵdid(1, 4243456, null, 0, i2.ReadOnlyCell, [], null, null)], null, null); }
exports.View_ReadOnlyCell_Host_0 = View_ReadOnlyCell_Host_0;
var ReadOnlyCellNgFactory = i1.ɵccf("readonly-cell", i2.ReadOnlyCell, View_ReadOnlyCell_Host_0, {}, {}, []);
exports.ReadOnlyCellNgFactory = ReadOnlyCellNgFactory;
