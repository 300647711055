"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
;
var ActionMenuComponent = /** @class */ (function () {
    function ActionMenuComponent() {
        this.options = [];
    }
    ActionMenuComponent.prototype.ngOnInit = function () { };
    ActionMenuComponent.prototype.onOptionClick = function (option) {
        option.onClick();
    };
    return ActionMenuComponent;
}());
exports.ActionMenuComponent = ActionMenuComponent;
