"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./image-editor.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i3 = require("@angular/material/icon");
var i4 = require("../../../../node_modules/angular-cropperjs/angular-cropperjs.ngfactory");
var i5 = require("angular-cropperjs");
var i6 = require("@angular/common");
var i7 = require("../../directives/loader/loader.component.ngfactory");
var i8 = require("../../directives/loader/loader.component");
var i9 = require("./image-editor.component");
var i10 = require("../upload.service");
var i11 = require("../notification/notifications.service");
var styles_ImageEditorComponent = [i0.styles];
var RenderType_ImageEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageEditorComponent, data: {} });
exports.RenderType_ImageEditorComponent = RenderType_ImageEditorComponent;
function View_ImageEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotate((0 - 90)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["rotate_left"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotate(90) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["rotate_right"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["save"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(12, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["upload"])), (_l()(), i1.ɵeld(14, 0, [["fileInput", 1]], null, 0, "input", [["class", "inputfile"], ["id", "file"], ["name", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.save($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).inline; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 12).inline; _ck(_v, 11, 0, currVal_3); }); }
function View_ImageEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["upload"])), (_l()(), i1.ɵeld(4, 0, [["fileInput", 1]], null, 0, "input", [["class", "inputfile"], ["id", "file"], ["name", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.save($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_0); }); }
function View_ImageEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "angular-cropper", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.imageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CropperComponent_0, i4.RenderType_CropperComponent)), i1.ɵdid(1, 114688, [[1, 4], ["imageEditor", 4]], 0, i5.CropperComponent, [], { imageUrl: [0, "imageUrl"], cropperOptions: [1, "cropperOptions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; var currVal_1 = _co.config; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ImageEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.imageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageEditor: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "loading": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "my-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(6, 180224, null, 0, i8.LoaderComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageEditorComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["correctionControls", 2]], null, 0, null, View_ImageEditorComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageEditorComponent_3)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageEditorComponent_4)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container"; var currVal_1 = _ck(_v, 4, 0, _co.loading); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.editableImage(); var currVal_3 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.editableImage(); _ck(_v, 11, 0, currVal_4); var currVal_5 = !_co.editableImage(); _ck(_v, 13, 0, currVal_5); }, null); }
exports.View_ImageEditorComponent_0 = View_ImageEditorComponent_0;
function View_ImageEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-editor", [], null, null, null, View_ImageEditorComponent_0, RenderType_ImageEditorComponent)), i1.ɵdid(1, 573440, null, 0, i9.ImageEditorComponent, [i10.UploadService, i11.NotificationsService], null, null)], null, null); }
exports.View_ImageEditorComponent_Host_0 = View_ImageEditorComponent_Host_0;
var ImageEditorComponentNgFactory = i1.ɵccf("image-editor", i9.ImageEditorComponent, View_ImageEditorComponent_Host_0, { id: "id", imageUrl: "imageUrl", external: "external" }, { onSave: "onSave", onImageClick: "onImageClick" }, []);
exports.ImageEditorComponentNgFactory = ImageEditorComponentNgFactory;
