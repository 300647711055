import { ReportsModule } from './reports/reports.module';
import {
  Injectable, NgModule, ApplicationRef, ErrorHandler
} from '@angular/core';
import {
  BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import * as enterprise from 'ag-grid-enterprise';
enterprise.LicenseManager.setLicenseKey(
  'Ruckit_Inc_Ticket-manager_1Devs_1Deployment_11_April_2020__MTU4NjU1OTYwMDAwMA==4491fa2d150377f9a4eb5cd6b8c41141'
);

import {
  MatButtonModule, MatCardModule, MatCheckboxModule, MatChipsModule,
  MatTabsModule, MatIconModule, MatTableModule, MatAutocompleteModule,
  MatFormFieldModule, MatGridListModule, MAT_DIALOG_DEFAULT_OPTIONS,
  MatInputModule, MatSnackBarModule, MAT_DIALOG_DATA, MatDialogRef, MatExpansionModule, MatTooltipModule, MatSidenav, MatSidenavModule,
} from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
import { MyDatePickerModule } from 'mydatepicker';
import { CurrencyMaskModule } from 'ngx-currency-mask';

import { environment } from '../environments/environment';

import * as Sentry from '@sentry/browser';

import { SharedModule } from './shared/shared.module';

// Shared Components
import { AppComponent } from './app.component';
import { RuckitButtonComponent } from './shared/buttons/index';
import { ExportDialogComponent } from './shared/export-dialog/export-dialog.component';
import { RuckitForgotPasswordDialogComponent } from './shared/forgot-password-dialog/forgot-password-dialog.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';
import { EditUserComponent } from './users/edit-user.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { SampleDialogComponent } from './styleguide/styleguide.component';

// Settings
import { SettingsModule } from './settings/settings.module';
import { AuthGuard } from './guards/auth.guard';

// Services
import { ApiService } from './shared';
import { AuthenticationService } from './shared';
import { UploadService } from './shared/upload.service';
import { TicketFilterService } from './tickets/ticket.filter.service';

import { routing } from './app.routing';

import { NgXtruncateModule } from 'ngx-truncate';

import {
  CdAutocompleteComponent,
  CdAutocompleteDirective
} from './directives/index';

import { MomentModule } from 'angular2-moment';
import { ClipboardModule } from 'ngx-clipboard';

import { APP_BASE_HREF } from '@angular/common';

// Gross but so are sentrys ts defs
// cast to any so we can add a beforeSend
if (!['local-dev', 'dev'].includes(environment.errorTrackingEnv)) {
  (<any>Sentry).init({
    dsn: 'https://3b14efc7832d4b29952f7758c3c72de0@sentry.io/246434',
    environment: environment.errorTrackingEnv,
    release: environment.applicationVersion
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error: any) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    pan: { threshold: 5 },
    swipe: {
      velocity: 0.4,
      threshold: 20,
      direction: 31 // /!\ ugly hack to allow swipe in all direction
    }
  };
}

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatGridListModule,
    MatTabsModule,
    MatIconModule,
    MatTableModule,
    MatAutocompleteModule,
    MatInputModule,
    MatExpansionModule,
    OverlayModule,
    CurrencyMaskModule,
    NgXtruncateModule,
    MomentModule,
    MyDatePickerModule,
    MatTooltipModule,
    ClipboardModule,
    ReactiveFormsModule,
    ReportsModule,
    SettingsModule,
    routing
  ],
  declarations: [
    AppComponent,
    RuckitButtonComponent,
    ExportDialogComponent,
    PasswordResetComponent,
    RuckitForgotPasswordDialogComponent,
    LoginComponent,
    EditUserComponent,
    StyleguideComponent,
    SampleDialogComponent,
    CdAutocompleteComponent,
    CdAutocompleteDirective,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    ApiService,
    TicketFilterService,
    UploadService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  entryComponents: [
    CdAutocompleteComponent,
    RuckitForgotPasswordDialogComponent,
    ExportDialogComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) { }
}
