import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { GridApi } from 'ag-grid-community';

type NoResultsParams = { api?: GridApi, onClick?: () => void, type?: string };

@Component({
  selector: 'no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements INoRowsOverlayAngularComp {
  type = 'items';
  onClick?: () => void;
  showButton = false;

  params?: NoResultsParams = {};

  constructor() { }

  agInit(params: NoResultsParams): void {
    this.params = params;

    if (this.params.onClick) {
      this.onClick = this.params.onClick;
    }

    if (this.params.type) {
      this.type = this.params.type;
    }
  }
}
