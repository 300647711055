"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var api_service_1 = require("../../shared/api.service");
var organization_service_1 = require("../../organizations/organization.service");
var api_service_2 = require("../../shared/api.service");
var lodash_1 = require("lodash");
var SettingsOrganizationComponent = /** @class */ (function () {
    function SettingsOrganizationComponent(router, apiService, organizationService, dialog) {
        this.router = router;
        this.apiService = apiService;
        this.organizationService = organizationService;
        this.dialog = dialog;
        this.loading = true;
        this.imageChanged = false;
        this.stateOptions = [];
        this.errors = [];
        this.logoImage = {
            dataUri: null,
            file: null
        };
    }
    SettingsOrganizationComponent.prototype.ngOnInit = function () {
        this.stateOptions = this.apiService.getStates().map(function (state) {
            return { name: state.abbreviation };
        });
        this.loading = false;
    };
    SettingsOrganizationComponent.prototype.saveOrganization = function () {
        var _this = this;
        this.loading = true;
        var _organization = lodash_1.cloneDeep(this.organization);
        Object.keys(_organization).forEach(function (key) { return (_organization[key] == null) && delete _organization[key]; });
        this.organizationService.saveWithImage(_organization, this.logoImage.file).subscribe(function (organization) {
            _this.organization = organization;
            _this.router.navigate(['/settings', 'organization']);
            _this.editOrganization.form.markAsPristine();
            _this.editOrganization.form.markAsUntouched();
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.loading = false;
        });
    };
    SettingsOrganizationComponent.prototype.selectState = function (state, area) {
        if (area === void 0) { area = 'organization'; }
        if (area === 'organization') {
            this.organization.state = state;
        }
        else {
            this.organization.billingState = state;
        }
    };
    SettingsOrganizationComponent.prototype.fileChange = function (e) {
        var logoImage = this.logoImage;
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
            logoImage.dataUri = loadEvent.target.result;
        };
        var file = e.target.files[0];
        logoImage.file = file;
        reader.readAsDataURL(file);
        this.imageChanged = true;
    };
    return SettingsOrganizationComponent;
}());
exports.SettingsOrganizationComponent = SettingsOrganizationComponent;
