"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var user_serializer_1 = require("./user.serializer");
var resource_service_1 = require("../shared/resource.service");
var index_1 = require("../shared/index");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(http) {
        return _super.call(this, http, 'users/', new user_serializer_1.UserSerializer()) || this;
    }
    UserService.prototype.saveWithImage = function (user, profileImage) {
        var _this = this;
        var _user = lodash_1.clone(user);
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            // todo: when we start to add tags for users,
            // we will need to be able to submit an empty list to delete all tags.
            if (_user.tags && _user.tags.length === 0) {
                delete _user.tags;
            }
            delete _user.image;
            delete _user.favoriteTags;
            delete _user.favoriteLocation;
            _user = decamelizeKeysDeep(_user);
            Object.keys(_user).map(function (key, index) {
                var value = _user[key];
                formData.append(key, value);
            });
            if (profileImage) {
                formData.append('image', profileImage, profileImage.name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            if (_user.id) {
                var userUrl = _this.baseUrl + 'users/' + _user.id + '/';
                xhr.open('PUT', userUrl, true);
            }
            else {
                var userUrl = _this.baseUrl + 'users/';
                xhr.open('POST', userUrl, true);
            }
            index_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    UserService.prototype.inviteUser = function (email, recipientOrganization, isCustomer) {
        var userUrl = this.baseUrl + 'invitations/';
        var params = {
            recipient_organization: recipientOrganization,
            kind: 'new_user',
            recipient_email: email,
            sender_user: this.senderId,
            sender_organization: this.organizationId,
        };
        if (!isCustomer) {
            params.result_organization = this.organizationId;
        }
        return this.http.post(userUrl, params, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    return UserService;
}(resource_service_1.ResourceService));
exports.UserService = UserService;
