"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/http");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var moment = require("moment");
var fileSaver = require("file-saver");
var filter_serializer_1 = require("./filter.serializer");
var ResourceService = /** @class */ (function () {
    function ResourceService(http, endpoint, serializer) {
        this.http = http;
        this.endpoint = endpoint;
        this.serializer = serializer;
        this.baseUrl = environment_1.environment.serverUrl;
        this.appVersion = environment_1.environment.applicationVersion;
        this.mockEndpoint = false;
        this.mockSearchKeys = ['name'];
        this.resourceUrl = '';
        this.search = '';
        this.filters = {};
        this.resourceUrl = this.baseUrl + this.endpoint;
        if (this.endpoint.includes('LOCAL:')) {
            var parts = this.endpoint.split('LOCAL:');
            if (parts) {
                this.resourceUrl = parts[parts.length - 1];
            }
        }
    }
    ResourceService.prototype.exportGrid = function (type, included, excluded, filters, search, summary, images) {
        if (included === void 0) { included = []; }
        if (excluded === void 0) { excluded = []; }
        if (filters === void 0) { filters = {}; }
        if (search === void 0) { search = ''; }
        if (summary === void 0) { summary = false; }
        if (images === void 0) { images = true; }
        var _a;
        var url = this.baseUrl + "export/";
        var params = new http_1.URLSearchParams();
        var _filters = this.buildFilters(filters);
        if (filters && !lodash_1.isEmpty(filters)) {
            params.set('filters', _filters);
        }
        if (search) {
            params.set('search', search);
        }
        var payload = (_a = {},
            _a[type] = included,
            _a["excluded_" + type] = excluded,
            _a.summary_only = summary,
            _a.include_images = images,
            _a);
        return this.http.post(url, payload, { headers: this.requestHeaders(), search: params });
    };
    ResourceService.prototype.exportGridLaserfiche = function (included, excluded, filters, search, allSelected) {
        if (included === void 0) { included = []; }
        if (excluded === void 0) { excluded = []; }
        if (filters === void 0) { filters = {}; }
        if (search === void 0) { search = ''; }
        var url = this.baseUrl + "pandas-export/";
        var params = new http_1.URLSearchParams();
        var _filters = this.buildFilters(filters);
        if (filters && !lodash_1.isEmpty(filters)) {
            params.set('filters', _filters);
        }
        if (search) {
            params.set('search', search);
        }
        var payload = {
            include: included,
            exclude: excluded,
            include_images: true
        };
        if (allSelected) {
            delete payload['include'];
        }
        var headers = this.requestHeaders(undefined, {
            'X-EXPORT-FILENAME': 'ruckit-tickets',
            'X-EXPORT-ACCEPT': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        return this.http.post(url, payload, { headers: headers, search: params });
    };
    ResourceService.prototype.exportGridPandas = function (included, excluded, filters, search, allSelected, fields) {
        if (included === void 0) { included = []; }
        if (excluded === void 0) { excluded = []; }
        if (filters === void 0) { filters = {}; }
        if (search === void 0) { search = ''; }
        if (fields === void 0) { fields = []; }
        var url = this.baseUrl + "pandas-export/";
        var params = new http_1.URLSearchParams();
        var _filters = this.buildFilters(filters);
        var headers = this.requestHeaders();
        if (filters && !lodash_1.isEmpty(filters)) {
            params.set('filters', _filters);
        }
        if (search) {
            params.set('search', search);
        }
        var payload = {
            include: included,
            exclude: excluded,
            include_images: true,
            fields: fields
        };
        if (!fields || !fields.length) {
            delete payload['fields'];
        }
        else {
            headers = this.requestHeaders(undefined, {
                'X-EXPORT-FILENAME': 'ruckit-tickets',
                'X-EXPORT-ACCEPT': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
        }
        if (allSelected) {
            delete payload['include'];
        }
        return this.http.post(url, payload, { headers: headers, search: params });
    };
    ResourceService.prototype.getValuesForFieldQuery = function (field, value) {
        if (value === void 0) { value = ''; }
        var _a;
        var apiField = field.split(/(?=[A-Z])/).map(function (s) { return s.toLowerCase(); }).join('_');
        var param = apiField + "__icontains";
        var query = (_a = {}, _a[param] = value, _a);
        return this.list(query).pipe(operators_1.map(function (tickets) {
            return tickets.map(function (ticket) { return ticket[field] ? ticket[field] : ''; });
        }), operators_1.map(function (fields) {
            return fields.filter(function (f) { return f !== ''; });
        }), operators_1.map(function (fields) {
            return lodash_1.uniq(fields);
        }));
    };
    ResourceService.prototype.list = function (query) {
        var _this = this;
        var params = new http_1.URLSearchParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params.set(key, query[key].toString());
                }
            });
        }
        return this.http.get(this.resourceUrl, {
            headers: this.requestHeaders(),
            search: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.filterLocally(data, params.paramsMap); }), operators_1.map(function (data) { return _this.paginateLocally(data, params.paramsMap); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.get = function (id) {
        var _this = this;
        var resourceUrl = id ? "" + this.resourceUrl + id + "/" : "" + this.resourceUrl;
        return this.http.get(resourceUrl, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.convertRecord(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.save = function (model) {
        var _this = this;
        var resourceUrl = this.resourceUrl;
        model = lodash_1.clone(model);
        model = this.serializer.toJson(model);
        if (!model.id) {
            return this.http.post(resourceUrl, model, {
                headers: this.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
        }
        else {
            return this.http.put("" + resourceUrl + model.id + "/", model, {
                headers: this.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
        }
    };
    ResourceService.prototype.listNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, {
                headers: this.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
        }
        else {
            return null;
        }
    };
    ResourceService.prototype.remove = function (model) {
        var resourceUrl = this.resourceUrl;
        var id = typeof model === 'string' ? model : model.id;
        return this.http.delete("" + resourceUrl + id + "/", {
            headers: this.requestHeaders()
        });
    };
    ResourceService.prototype.listFilters = function (slug, query) {
        var _this = this;
        var filtersUrl = "" + this.resourceUrl + slug + "/";
        var params = new http_1.URLSearchParams();
        params.set('page_size', '6');
        if (query && query['search']) {
            params.set('search', query['search']);
        }
        return this.http.get(filtersUrl, {
            headers: this.requestHeaders(),
            search: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (res) { return _this.convertData(res); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.getFilterValues = function () {
        var _this = this;
        var filtersUrl = this.resourceUrl + "filters/";
        return this.http.get(filtersUrl, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (data) { return _this.convertFilterValues(data); }), operators_1.map(function (data) {
            Object.keys(data).forEach(function (key) {
                data[key] = data[key].filter(function (value) { return value !== ''; });
            });
            return data;
        }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.convertFilterValues = function (filters) {
        try {
            filters = filters.json();
        }
        catch (err) {
            throw err;
        }
        return new filter_serializer_1.FilterValuesSerializer().fromJson(filters);
    };
    ResourceService.prototype.captureMetaData = function (res) {
        var json = res.json();
        this.nextUri = json.next;
        this.previousUri = json.previous;
        this.count = json['count'] || json['results'] && json['results'].length;
        this.unreadCount = json['unread_count'] || 0;
        this.mockEndpoint = json['mock'];
        this.mockSearchKeys = json['mockSearchKeys'];
        return json.results || json;
    };
    ResourceService.prototype.convertData = function (data) {
        var _this = this;
        return data && data.map(function (item) { return _this.serializer.fromJson(item); });
    };
    ResourceService.prototype.convertRecord = function (data) {
        try {
            data = data.json();
        }
        catch (err) {
            // Ignore
        }
        return this.serializer.fromJson(data);
    };
    ResourceService.prototype.removeNulls = function (obj) {
        var _this = this;
        Object.keys(obj).forEach(function (key) {
            if (obj[key] && typeof obj[key] === 'object') {
                _this.removeNulls(obj[key]);
            }
            else if (obj[key] == null) {
                delete obj[key];
            }
        });
        return obj;
    };
    ResourceService.prototype.filterLocally = function (data, params) {
        var _this = this;
        var ignoredKeys = ['ordering', 'filters', 'page', 'page_size'];
        if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
            params.forEach(function (values, key) {
                if (values && values.length && !ignoredKeys.includes(key)) {
                    data = lodash_1.filter(data, function (o) {
                        if (o.hasOwnProperty(key)) {
                            var value = values.join(',');
                            var property = o[key];
                            return property.toLowerCase() === value.toLowerCase();
                        }
                        else if (key === 'search') {
                            var filter = false;
                            for (var _i = 0, _a = _this.mockSearchKeys; _i < _a.length; _i++) {
                                var mockKey = _a[_i];
                                if (o.hasOwnProperty(mockKey)) {
                                    var value = values.join(',');
                                    var property = o[mockKey];
                                    filter = property.toLowerCase() === value.toLowerCase();
                                    if (filter) {
                                        break;
                                    }
                                }
                            }
                            return filter;
                        }
                        else {
                            return true;
                        }
                    });
                }
                else if (values && values.length && key === 'filters') {
                    var _values = lodash_1.clone(values);
                    _values.filter(Boolean).forEach(function (value) {
                        value = value.replace(/\(|\)/g, '');
                        var _a = value.split('='), _key = _a[0], _value = _a[1];
                        data = lodash_1.filter(data, function (o) {
                            if (o.hasOwnProperty(_key)) {
                                var property = o[_key];
                                return property.toLowerCase() === _value.toLowerCase();
                            }
                            return;
                        });
                    });
                }
            });
            this.count = data.length;
        }
        return data;
    };
    ResourceService.prototype.paginateLocally = function (data, params) {
        if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
            this.count = lodash_1.clone(data.length);
            var _params_1 = {};
            params.forEach(function (values, key) {
                if (values && values.length) {
                    _params_1[key] = values.join(',');
                }
            });
            if ((_params_1['page'] && _params_1['page'] !== '1') || !_params_1['page']) {
                _params_1['recordsToSkip'] = (_params_1['page'] - 1) * _params_1['page_size'];
            }
            else {
                _params_1['recordsToSkip'] = 0;
            }
            data = data.slice(_params_1['recordsToSkip'], _params_1['recordsToSkip'] + _params_1['page_size']);
        }
        return data;
    };
    ResourceService.prototype.mergeFilters = function (filters) {
        return filters.map(function (filter) {
            if (filter.multiple && filter.values) {
                return filter.values.map(function (value) {
                    var _value = [filter.key, value].join('=');
                    return "(" + _value + ")";
                }).filter(Boolean).join('|');
            }
            else if (filter.values) {
                var values = filter.values;
                if (values === true) {
                    values = 'True';
                }
                if (values === false) {
                    values = 'False';
                }
                var _value = [filter.key, values].join('=');
                return "(" + _value + ")";
            }
        }).filter(Boolean).join('&');
    };
    ResourceService.prototype.requestHeaders = function (xhr, customHeaders) {
        if (customHeaders === void 0) { customHeaders = null; }
        var headers = new http_1.Headers();
        var headersObj = {};
        if (!xhr) {
            lodash_1.extend(headersObj, {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            });
        }
        var userValue = localStorage.getItem('currentUser');
        var currentUser = userValue ? JSON.parse(userValue) : null;
        if (currentUser && currentUser.token) {
            var token = currentUser && currentUser.token;
            var tokenString = 'Token ' + token;
            headersObj['Authorization'] = tokenString;
        }
        lodash_1.each(headersObj, function (value, header) {
            if (xhr) {
                xhr.setRequestHeader(header, value);
            }
            else {
                headers.append(header, value);
            }
        });
        if (customHeaders) {
            lodash_1.each(customHeaders, function (value, header) {
                if (xhr) {
                    xhr.setRequestHeader(header, value);
                }
                else {
                    headers.append(header, value);
                }
            });
        }
        return headers;
    };
    ResourceService.prototype.handleError = function (error) {
        return rxjs_1.throwError(this.parseErrors(error));
    };
    ResourceService.prototype.parseErrors = function (err) {
        var errors = [];
        if (err.status >= 500) {
            errors.push(err.statusText);
        }
        else if (typeof err._body === 'string') {
            try {
                var body = JSON.parse(err._body);
                if (body.detail) {
                    errors.push(body.detail);
                }
                else {
                    errors = this.rescurseErrorObject(body, errors);
                }
            }
            catch (e) { }
        }
        else {
            errors.push(err);
        }
        return errors;
    };
    ResourceService.prototype.rescurseErrorObject = function (obj, errors) {
        var _this = this;
        lodash_1.each(obj, function (msg, key) {
            if (Array.isArray(msg)) {
                errors = errors.concat(msg.map(function (err) { return (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err; }));
            }
            else if (typeof msg === 'string') {
                errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
            }
            else if (typeof msg === 'object') {
                errors = _this.rescurseErrorObject(msg, errors);
            }
        });
        return errors;
    };
    ResourceService.prototype.unCamelCase = function (value) {
        return value && value.split(/(?=[A-Z])/).map(function (s) { return s.toLowerCase(); }).join('_');
    };
    /**
     * Requests rows from the server-side API for use in AG Grid tables.
     *
     * @param params {IServerSideGetRowsParams} An object containing two callbacks
     * (success and failure) and a request object with details that row the grid
     * is looking for.
     */
    ResourceService.prototype.getRows = function (params) {
        var _this = this;
        var _a = params.request, startRow = _a.startRow, endRow = _a.endRow, sortModel = _a.sortModel, filterModel = _a.filterModel;
        var createOrderField = function (model) {
            var sort = model.sort, colId = model.colId;
            var field = _this.unCamelCase(colId);
            return sort === 'asc' ? field : "-" + field;
        };
        /**
         * Generate / calculate pagination parameters
         */
        var pageSize = endRow - startRow;
        var page = Math.floor(endRow / pageSize);
        var ordering = sortModel.length > 0 ? sortModel.map(createOrderField).join(',') : '-created_at';
        /**
         * Generate request params based on pg grid server data model
         */
        var requestParams = new http_1.URLSearchParams();
        requestParams.set('page_size', pageSize.toString());
        requestParams.set('page', page.toString());
        requestParams.set('ordering', ordering);
        requestParams.set('search', this.search);
        if (this.resourceUrl.includes('tickets')) {
            requestParams.set('include_ticket_metas', 'True');
        }
        /**
         * Build the query filters based on the filter model returned from AG Grid.
         */
        var combinedFilters = __assign({}, this.filters, filterModel);
        if (combinedFilters.image && combinedFilters.image.values) {
            requestParams.set('image__isempty', combinedFilters.image.values[0]);
            delete combinedFilters.image;
        }
        var filters = this.buildFilters(combinedFilters);
        if (filters) {
            requestParams.set('filters', filters);
        }
        /**
         * If an exsiting request is in progress, unsubscribe from it to cancel the
         * request and avoid latent returns.
         */
        if (this.resourceReq && typeof this.resourceReq.unsubscribe === 'function') {
            this.resourceReq.unsubscribe();
        }
        /**
         * Request the resource with the request parameters we've built up.
         *
         * Here, we bind the request to the `resourceReq` property so that we can
         * manage and control the subscription when necessary.
         */
        // Cast to any since the gridApi property is set to private for some reason on `params.parentNode`
        var parent = params.parentNode;
        parent.gridApi.hideOverlay();
        this.resourceReq = this.http.get(this.resourceUrl, {
            headers: this.requestHeaders(),
            search: requestParams
        }).subscribe(function (response) {
            var _a = response.json(), results = _a.results, count = _a.count;
            var records = results.map(function (record) { return _this.serializer.fromJson(record); });
            if (records.length <= 0) {
                parent.gridApi.showNoRowsOverlay();
            }
            params.successCallback(records, count);
        }, function () {
            params.failCallback();
        });
    };
    /**
     * Processes the applied filters with consideration for the filter type,
     * format, binding operator, and multiple conditions.
     *
     * @param {any} filterModel The set of filters to process
     *
     * @returns {string} Conditions for use with the API's `filters` query param
     */
    ResourceService.prototype.buildFilters = function (filterModel) {
        var _this = this;
        var params = [];
        var booleanColumns = ['invoiced', 'billable', 'verified'];
        Object.keys(filterModel).forEach(function (key) {
            var filter = filterModel[key];
            var isBoolean = booleanColumns.includes(key);
            var name = key === 'image' ? _this.unCamelCase(key) + "__isempty" : isBoolean ? _this.unCamelCase(key) : _this.unCamelCase(key) + "__in";
            var values = '';
            if (filter.filterType === undefined) {
                var _params = _this.processConditionalFilters(key, filter);
                var operator = filter.operator === 'AND' ? '&' : '|';
                params.push(_params.filter(Boolean).join(operator));
            }
            else if (filter.filterType === 'date') {
                var _params = _this.buildDateFilter(key, filter);
                params.push(_params.filter(Boolean).join('|'));
            }
            else {
                values = filter.values && filter.values.join(',');
                if (values.includes('-- Blank --')) {
                    values = values.replace(/-- Blank --,*/, '');
                    values = values.replace(/^\,/, '');
                    values = values.concat(',');
                    values = values.replace(/\,{2,}/, ',');
                }
                if (values) {
                    params.push("(" + name + "=" + values + ")");
                }
            }
        });
        return params && params.length ? "" + params.filter(Boolean).join('&') : '';
    };
    /**
     * Processes the date-type filters from the `filter` object and returns each
     * condition's parameter(s).
     *
     * A switch evaluates the `filter.type` property and determines what format
     * and modifier to use when building the query parameters.
     *
     * @param {string} key The query key to use for the filter argument
     * @param {any} filter The filter to process
     *
     * @returns {string[]} Array of prepared query parameters
     */
    ResourceService.prototype.buildDateFilter = function (key, filter) {
        var params = [];
        var name = "" + this.unCamelCase(key);
        var dateType = key === 'createdAt' ? 'datetime' : 'date';
        var dateFrom = filter.dateFrom && moment(filter.dateFrom, 'YYYY-MM-DD').toDate();
        var dateTo = filter.dateTo && moment(filter.dateTo, 'YYYY-MM-DD').toDate();
        var value = dateType === 'datetime' ? dateFrom.toISOString() : dateFrom;
        var valueArray;
        var notEqual = false;
        switch (filter.type) {
            case 'equals':
                name = this.unCamelCase(key) + "__range";
                dateFrom.setHours(0, 0, 0, 0);
                dateTo = lodash_1.clone(dateFrom);
                dateTo.setHours(23, 59, 59, 999);
                valueArray = dateType === 'datetime' ?
                    [dateFrom.toISOString(), dateTo.toISOString()] :
                    [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')];
                value = valueArray.filter(Boolean).join(',');
                break;
            case 'greaterThan':
                name = this.unCamelCase(key) + "__gte";
                dateFrom.setHours(0, 0, 0, 0);
                value = dateType === 'datetime' ?
                    dateFrom.toISOString() : moment(dateFrom).format('YYYY-MM-DD');
                break;
            case 'lessThan':
                name = this.unCamelCase(key) + "__lte";
                dateFrom.setHours(23, 59, 59, 999);
                value = dateType === 'datetime' ?
                    dateFrom.toISOString() : moment(dateFrom).format('YYYY-MM-DD');
                break;
            case 'notEqual':
                name = this.unCamelCase(key) + "__range";
                dateFrom.setHours(0, 0, 0, 0);
                dateTo = lodash_1.clone(dateFrom);
                dateTo.setHours(23, 59, 59, 999);
                valueArray = dateType === 'datetime' ?
                    [dateFrom.toISOString(), dateTo.toISOString()] :
                    [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')];
                value = valueArray.filter(Boolean).join(',');
                notEqual = true;
                break;
            case 'inRange':
                name = this.unCamelCase(key) + "__range";
                dateFrom.setHours(0, 0, 0, 0);
                dateTo.setHours(23, 59, 59, 999);
                valueArray = dateType === 'datetime' ?
                    [dateFrom.toISOString(), dateTo.toISOString()] :
                    [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')];
                value = valueArray.filter(Boolean).join(',');
                break;
        }
        if (value) {
            params.push((notEqual ? '~' : '') + "(" + name + "=" + value + ")");
        }
        return params;
    };
    /**
     * Processes the conditions on the `filter` object and joins each condition's
     * parameters with `&` in the event that there is more than one.
     *
     * @param {string} key The query key to use for the filter argument
     * @param {any} filter The filter to process
     *
     * @returns {string[]} Array of prepared query parameters
     */
    ResourceService.prototype.processConditionalFilters = function (key, filter) {
        var params = [];
        if (filter.condition1) {
            var param = this.buildDateFilter(key, filter.condition1).filter(Boolean).join('&');
            params.push(param);
        }
        if (filter.condition2) {
            var param = this.buildDateFilter(key, filter.condition2).filter(Boolean).join('&');
            params.push(param);
        }
        return params;
    };
    ResourceService.prototype.saveFile = function (response) {
        var blob = new Blob([response._body], { type: 'text/xml' });
        fileSaver.saveAs(blob, 'ruckit-ticket-manager.qwc');
    };
    return ResourceService;
}());
exports.ResourceService = ResourceService;
