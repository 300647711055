"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./customer-reference-cell.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../customer-reference-selector/customer-reference-selector.component.ngfactory");
var i3 = require("../customer-reference.service");
var i4 = require("@angular/http");
var i5 = require("../customer-reference-selector/customer-reference-selector.component");
var i6 = require("@angular/material/dialog");
var i7 = require("../../../tickets/ticket.service");
var i8 = require("./customer-reference-cell.component");
var styles_CustomerReferenceCell = [i0.styles];
var RenderType_CustomerReferenceCell = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerReferenceCell, data: {} });
exports.RenderType_CustomerReferenceCell = RenderType_CustomerReferenceCell;
function View_CustomerReferenceCell_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { jobReferenceInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ruckit-customer-reference-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "customer-reference-selector", [], null, [[null, "referenceSelected"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("referenceSelected" === en)) {
        var pd_0 = (_co.onReferenceClicked($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.onReferenceClosed() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CustomerReferenceSelectorComponent_0, i2.RenderType_CustomerReferenceSelectorComponent)), i1.ɵprd(512, null, i3.CustomerReferenceService, i3.CustomerReferenceService, [i4.Http]), i1.ɵdid(4, 4308992, [["customerReferences", 4]], 0, i5.CustomerReferenceSelectorComponent, [i6.MatDialog, i3.CustomerReferenceService], null, { referenceSelected: "referenceSelected", onClose: "onClose" })], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
exports.View_CustomerReferenceCell_0 = View_CustomerReferenceCell_0;
function View_CustomerReferenceCell_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "customer-reference-cell", [], null, null, null, View_CustomerReferenceCell_0, RenderType_CustomerReferenceCell)), i1.ɵprd(512, null, i7.TicketService, i7.TicketService, [i4.Http]), i1.ɵdid(2, 4243456, null, 0, i8.CustomerReferenceCell, [i7.TicketService], null, null)], null, null); }
exports.View_CustomerReferenceCell_Host_0 = View_CustomerReferenceCell_Host_0;
var CustomerReferenceCellNgFactory = i1.ɵccf("customer-reference-cell", i8.CustomerReferenceCell, View_CustomerReferenceCell_Host_0, {}, {}, []);
exports.CustomerReferenceCellNgFactory = CustomerReferenceCellNgFactory;
