"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./job-reference-cell.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../job-reference-selector/job-reference-selector.component.ngfactory");
var i3 = require("../job-reference.service");
var i4 = require("@angular/http");
var i5 = require("../job-reference-selector/job-reference-selector.component");
var i6 = require("@angular/material/dialog");
var i7 = require("../../../tickets/ticket.service");
var i8 = require("./job-reference-cell.component");
var styles_JobReferenceCell = [i0.styles];
var RenderType_JobReferenceCell = i1.ɵcrt({ encapsulation: 0, styles: styles_JobReferenceCell, data: {} });
exports.RenderType_JobReferenceCell = RenderType_JobReferenceCell;
function View_JobReferenceCell_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ruckit-job-reference-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "job-reference-selector", [], null, [[null, "referenceSelected"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("referenceSelected" === en)) {
        var pd_0 = (_co.onReferenceClicked($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.onReferenceClosed() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_JobReferenceSelectorComponent_0, i2.RenderType_JobReferenceSelectorComponent)), i1.ɵprd(512, null, i3.JobReferenceService, i3.JobReferenceService, [i4.Http]), i1.ɵdid(3, 4308992, [["jobReferenceSelector", 4]], 0, i5.JobReferenceSelectorComponent, [i6.MatDialog, i3.JobReferenceService], null, { referenceSelected: "referenceSelected", onClose: "onClose" })], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
exports.View_JobReferenceCell_0 = View_JobReferenceCell_0;
function View_JobReferenceCell_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "job-reference-cell", [], null, null, null, View_JobReferenceCell_0, RenderType_JobReferenceCell)), i1.ɵprd(512, null, i7.TicketService, i7.TicketService, [i4.Http]), i1.ɵdid(2, 4243456, null, 0, i8.JobReferenceCell, [i7.TicketService], null, null)], null, null); }
exports.View_JobReferenceCell_Host_0 = View_JobReferenceCell_Host_0;
var JobReferenceCellNgFactory = i1.ɵccf("job-reference-cell", i8.JobReferenceCell, View_JobReferenceCell_Host_0, {}, {}, []);
exports.JobReferenceCellNgFactory = JobReferenceCellNgFactory;
