import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { clone } from 'lodash';

import { environment } from '../../environments/environment';
import { Organization } from './organization';
import { OrganizationSerializer } from './organization.serializer';
import { requestHeaders } from '../shared/api.service';
import { ResourceService } from '../shared/resource.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class OrganizationService extends ResourceService<Organization> {
  baseUrl = environment.serverUrl;
  organizationId!: string;
  token!: string;
  public nextUri: any;
  public count = 0;

  constructor(http: Http) {
    super(http, 'organizations/', new OrganizationSerializer());
  }

  saveWithImage(organization: any, logoImage: File) {
    const _organization = decamelizeKeysDeep(clone(organization));

    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void; }) => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      delete _organization.image;
      delete _organization.billing_contact;
      delete _organization.email;

      Object.keys(_organization).map(function (key) {
        let value = _organization[key];
        formData.append(key, value);
      });

      if (logoImage) {
        formData.append('image', logoImage, logoImage.name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (_organization.id) {
        let organizationUrl = this.baseUrl + 'organizations/' + _organization.id + '/';
        xhr.open('PUT', organizationUrl, true);
      } else {
        let organizationUrl = this.baseUrl + 'organizations/';
        xhr.open('POST', organizationUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  saveOnlyImage(organizationId: string, logoImage: File) {

    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void; }) => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('image', logoImage, logoImage.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (organizationId) {
        let organizationUrl = this.baseUrl + 'organizations/' + organizationId + '/';
        xhr.open('PUT', organizationUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }
}
