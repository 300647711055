"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatusCell = /** @class */ (function () {
    function StatusCell() {
    }
    StatusCell.prototype.agInit = function (params) {
        this.value = params.value;
        this.statusList = params.statusList;
    };
    StatusCell.prototype.getValue = function () {
        return this.value;
    };
    return StatusCell;
}());
exports.StatusCell = StatusCell;
