import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, of, empty, combineLatest as observableCombineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { clone } from 'lodash';
import * as shortid from 'shortid';

// @ts-ignore
import * as pdfjs from 'pdfjs-dist';
import * as moment from 'moment';

import 'dwt';

import { environment } from '../../environments/environment';

import { NotificationsService, Notification, NotificationType } from '../shared/notification/notifications.service';
import { AuthenticationService } from '../shared/authentication.service';
import { UploadService, S3PolicyData } from '../shared/upload.service';
import { OcrService } from './ocr.service';
import { Ticket, ExternalTicket } from '../tickets/ticket';
import { TicketService, ReferenceEditModel } from '../tickets/ticket.service';
import { DriverReferenceService } from '../references/driver-reference/driver-reference.service';
import { JobReferenceService } from '../references/job-reference/job-reference.service';
import { TruckReferenceService } from '../references/truck-reference/truck-reference.service';
import { JobReference } from '../references/job-reference/job-reference';
import { CustomerReferenceService } from '../references/customer-reference/customer-reference.service';
import { CustomerReference } from '../references/customer-reference/customer-reference';
import { Preferences } from '../shared/preferences/preferences';
import { PreferencesService } from '../shared/preferences/preferences.service';
import { DriverReference } from '../references/driver-reference/driver-reference';
import { JobReferenceSelectorComponent } from '../references/job-reference/job-reference-selector/job-reference-selector.component';
import { CustomerReferenceSelectorComponent }
  from '../references/customer-reference/customer-reference-selector/customer-reference-selector.component';
import { DriverReferenceSelectorComponent }
  from '../references/driver-reference/driver-reference-selector/driver-reference-selector.component';
import { OcrResults } from './ocr';

type ExcludedFields = (
  'id'
  | 'createdAt'
  | 'classes'
  | 'loading'
  | 'organization'
  | 'selected'
  | 'url'
  | 'filterOptions'
  | 'invoiced'
  | 'ticketNotes'
  | 'verified'
  | 'jobCode'
);
export type TicketEditModel = Pick<Ticket, Exclude<keyof Ticket, ExcludedFields>>;

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'batch-upload',
  templateUrl: './batch-upload.component.html',
  styleUrls: ['./batch-upload.component.scss'],
  providers: [
    NotificationsService,
    AuthenticationService,
    UploadService,
    TicketService,
    OcrService,
    DriverReferenceService,
    JobReferenceService,
    CustomerReferenceService,
    TruckReferenceService,
    PreferencesService,
  ],
})
export class BatchUploadComponent implements OnInit {
  @ViewChild('jobReferenceSelector') jobReferenceSelector!: JobReferenceSelectorComponent;
  @ViewChild('customerReferenceSelector') customerReferenceSelector!: CustomerReferenceSelectorComponent;
  @ViewChild('driverReferenceSelector') driverReferenceSelector!: DriverReferenceSelectorComponent;

  external = false;
  contextId = '';
  dateSelected = false;

  user = this.authenticationService.user();
  callback: any;
  loader = {
    active: false,
    type: 'uploading'
  };
  uploadIndex = 0;
  preferences!: Preferences;

  policyData: any = {};

  model: TicketEditModel = <TicketEditModel>{ ticketDate: '' };

  uploadFiles: any[] = [];
  uploadTotal = 0;
  savedTickets: Ticket[] = [];

  editMode!: boolean;
  viewerType!: string;
  selectedRecordIndex: any = null;
  fieldActive = false;

  jobReferences$!: Observable<JobReference[]>;
  selectedJobReference!: JobReference;

  customerReferences$!: Observable<CustomerReference[]>;
  selectedCustomerReference!: CustomerReference;

  driverReferences$!: Observable<DriverReference[]>;
  selectedDriverReference!: DriverReference;

  // scanning props
  scannerActive = false;
  DWObject!: WebTwain;
  DWTSourceCount!: number;
  EnumDWT_ConvertMode: any;
  detectedScanners: any[] = [];
  scanDriverDetected = true;
  selectedScannerIndex!: number;
  scanResults: any[] = [];

  isJobFieldVisible = false;
  isCustomerFieldVisible = false;
  isDriverFieldVisible = false;

  jobFieldsVisible = false;
  customerFieldsVisible = false;
  driverFieldsVisible = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    public uploadService: UploadService,
    private ocrService: OcrService,
    public ticketService: TicketService,
    private preferencesService: PreferencesService
  ) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.selectedRecordIndex && this.savedTickets.length > 1 && !this.fieldActive) {
      if (event.keyCode === KEY_CODE.RIGHT_ARROW && this.selectedRecordIndex < (this.savedTickets.length - 1)) {
        this.nextTicket(this.savedTickets[this.selectedRecordIndex]);
      }
      if (event.keyCode === KEY_CODE.LEFT_ARROW && this.selectedRecordIndex !== 0) {
        this.previousTicket(this.savedTickets[this.selectedRecordIndex]);
      }
    }
  }

  ticketNumberVisible(): boolean {
    return (this.savedTickets.length > 0 && this.selectedRecordIndex !== null) || this.savedTickets.length === 0;
  }

  ngOnInit() {
    // here we will set up listeners for any url params and set up
    // the callback_url, features, type model, and other config functions necessary
    let combinedParams = observableCombineLatest(
      this.route.url, this.route.params, this.route.queryParams,
      (url, params, qparams) => ({ url, params, qparams })
    );
    combinedParams.subscribe(result => {
      if (result.qparams.tickets) {
        this.editMode = true;
        const ticketIds: string[] = result.qparams.tickets.split(',');
        this.loadTickets(ticketIds);
      } else if (result.url[1] && result.url[1].path === 'external') {
        this.external = true;
        this.contextId = result.qparams.contextId;
        if (result.qparams.date) {
          this.model.ticketDate = moment(result.qparams.date, 'YYYYMMDD');
          this.dateSelected = true;
        }
      } else {
        this.editMode = false;
      }
    });

    if (this.savedTickets.length) { this.preselectDropdowns(false); }
    this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;
  }

  loadTickets(ticketIds: string[]) {
    this.loader.active = true;

    let getTicketRequests: Observable<Ticket>[] = [];

    ticketIds.map(id => getTicketRequests.push(this.ticketService.get(id)));

    forkJoin(getTicketRequests).subscribe((tickets: Ticket[]) => {
      this.savedTickets = tickets;
      this.viewerType = this.savedTickets.length > 1 ? 'grid' : 'single';
      this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;

      if (this.savedTickets.length > 1) {
        this.preselectDropdowns(false);
      } else {
        this.preselectDropdowns(true, this.savedTickets[0]);
      }
    }, (err: any) => {
      const notification: Notification = {
        context: {
          err,
        },
        id: shortid.generate(),
        message: 'An error occured loading these selected tickets. Please refresh and try again.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
      throw err;
    });

    this.loader.active = false;
  }

  // scanner methods
  enableScanning() {
    this.scannerActive = true;
    this.loader = {
      active: true,
      type: 'prescan'
    };
    Dynamsoft.WebTwainEnv.Load();
    Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', () => { this.Dynamsoft_OnReady(); });
    const dynamsoftPrompt = document.getElementsByClassName('dynamsoft-dialog-wrap')[0];
    if (dynamsoftPrompt) { dynamsoftPrompt.setAttribute('style', 'display:block'); }
  }

  disableScanning() {
    this.scannerActive = false;
    this.loader = {
      active: false,
      type: 'uploading'
    };
    Dynamsoft.WebTwainEnv.Unload();
  }

  Dynamsoft_OnReady(): void {
    if (typeof (EnumDWT_ConvertMode) !== 'undefined') {
      this.EnumDWT_ConvertMode = EnumDWT_ConvertMode;
    }
    this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
    if (this.DWObject) {
      this.detectedScanners = [];
      this.DWObject.IfAllowLocalCache = true;
      this.DWObject.ImageCaptureDriverType = 3;
      let vCount = this.DWObject.SourceCount;
      this.DWTSourceCount = vCount;
      for (let i = 0; i < vCount; i++) {
        this.detectedScanners.push(this.DWObject.GetSourceNameItems(i));
      }
      if (Dynamsoft.Lib.env.bMac) {
        Promise.resolve(this.DWObject.CloseSourceManager())
          .then(() => {
            this.DWObject.ImageCaptureDriverType = 0;
            return this.DWObject.OpenSourceManager();
          })
          .then(() => {
            for (let j = vCount; j < vCount + this.DWObject.SourceCount; j++) {
              this.detectedScanners.push(this.DWObject.GetSourceNameItems(j));
            }
          });
      }
      this.loader.active = false;
      // here we determine which scanner to auto-select
      if (this.detectedScanners.length === 1) {
        this.selectedScannerIndex = 0;
      } else {
        this.getPreferences();
      }
    } else {
      setTimeout(() => {
        this.loader.active = false;
        const notification: Notification = {
          context: {},
          id: shortid.generate(),
          message: 'There was an issue initializing the scanner. Please refresh and try again.',
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
      }, 5000);
    }
  }

  refreshScannerList(): void {
    this.loader.active = true;
    Promise.resolve(Dynamsoft.WebTwainEnv.Unload())
      .then(() => Dynamsoft.WebTwainEnv.Load());
    Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', () => this.Dynamsoft_OnReady());
  }

  selectScanner(index: number) {
    this.selectedScannerIndex = index;
    this.savePreferences();
  }

  startScan() {
    this.loader = {
      active: true,
      type: 'scanning'
    };

    // TODO: set up default scan options
    let config = {
      IfShowUI: false,
      PixelType: this.external ? EnumDWT_PixelType.TWPT_RGB : EnumDWT_PixelType.TWPT_GRAY,
      PageSize: EnumDWT_CapSupportedSizes.TWSS_NONE,
      Resolution: 125,
      IfFeederEnabled: true,
      IfDuplexEnabled: false,
      IfShowProgressBar: false
    };

    if (Dynamsoft.Lib.env.bMac) {
      Promise.resolve(this.DWObject.CloseSourceManager())
        .then(() => {
          this.DWObject.ImageCaptureDriverType = 3;
          return this.DWObject.OpenSourceManager();
        })
        .then(() => {
          if (this.selectedScannerIndex >= this.DWTSourceCount) {
            this.selectedScannerIndex = this.selectedScannerIndex - this.DWTSourceCount;
            Promise.resolve(this.DWObject.CloseSourceManager())
              .then(() => {
                this.DWObject.ImageCaptureDriverType = 0;
                return this.DWObject.OpenSourceManager();
              })
              .then(() => this.acquireScans(config));
          } else { this.acquireScans(config); }
        });
    } else {
      this.acquireScans(config);
    }
  }

  acquireScans(config: any) {
    Promise.resolve(this.DWObject.SelectSourceByIndex(this.selectedScannerIndex))
      .then(() => { return this.DWObject.CloseSource(); })
      .then(() => { return this.DWObject.SetOpenSourceTimeout(30000); })
      .then(() => { return this.DWObject.OpenSource(); })
      .then(() => {
        if (this.DWObject.ErrorCode === 0) {
          this.DWObject.AcquireImage(config, () => this.processScans(),
            (errCode, errString) => {
              this.loader.active = false;
              const notification: Notification = {
                context: {},
                id: shortid.generate(),
                message: 'Scanner error ' + errCode + ': ' + errString,
                originator: 'uploader',
                type: NotificationType.Danger,
              };
              this.notificationsService.addNotification(notification);
              if ((errCode === -2129 && !errString.includes('empty')) || errCode === -1020) {
                this.processScans();
              }
            });
        } else {
          const notification: Notification = {
            context: {},
            id: shortid.generate(),
            message: 'Source error ' + this.DWObject.ErrorCode + ': ' + this.DWObject.ErrorString,
            originator: 'uploader',
            type: NotificationType.Danger,
          };
          this.notificationsService.addNotification(notification);
        }
      });
  }

  processScans() {
    // this will hook into the upload pipeline at some point
    // generate records for each image
    this.uploadService.getS3Policy(this.external).subscribe((policy: S3PolicyData) => {
      this.policyData = policy;
      const scanTotal = this.DWObject.HowManyImagesInBuffer;
      this.viewerType = scanTotal > 1 ? 'grid' : 'single';
      this.loader = {
        active: true,
        type: 'uploading'
      };
      this.DWObject.SelectedImagesCount = 1;
      for (let i = 0; i < scanTotal; i++) {
        this.DWObject.SetSelectedImageIndex(0, i);
        this.DWObject.GetSelectedImagesSize(EnumDWT_ImageType.IT_JPG);
        // save image to base64
        let imageData = 'data:image/jpeg;base64,' + this.DWObject.SaveSelectedImagesToBase64Binary();
        let scanIndex = this.savedTickets.length + i + 1;
        // convert to record
        this.cropWhiteSpace(imageData).then(result => {
          this.convertFileToRecord(this.uploadService.convertDataUriToFile(result, 'scannedImage-' + i + '.jpg'), scanIndex);
          if (i + 1 === scanTotal) {
            this.DWObject.RemoveAllSelectedImages();
          } else {
            this.DWObject.RemoveAllImages();
          }
        });
      }
      this.scannerActive = false;
    }, err => {
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error authenticating with our upload server. Please refresh and try again.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  setPreviewMode(colNum: number) {
    this.DWObject.SetViewMode(colNum, colNum);
  }

  resetScans() {
    this.DWObject.RemoveAllImages();
    this.scanResults = [];
  }

  checkForMatchingValue(tickets: Ticket[], key: string): boolean {
    return tickets.every((ticket) => {
      return ticket[key] === tickets[0][key];
    });
  }

  isValidDate(input: any): boolean {
    input = new Date(input);
    return input instanceof Date && !isNaN(input.getTime());
  }

  selectedTicketLength(): number {
    let length = 0;
    this.savedTickets.forEach(ticket => length = ticket.selected ? length + 1 : length);
    return length;
  }

  getPreferences() {
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => p.name === 'uploader');
        return pref ? pref : new Preferences;
      }),
    ).subscribe(preferences => {
      this.preferences = preferences;
      this.selectedScannerIndex = this.preferences.blob && this.preferences.blob.selectedScannerIndex ?
        this.preferences.blob.selectedScannerIndex : null;
    });
  }

  savePreferences() {
    const preferencesObj = {
      ...this.preferences,
      type: 'uploader',
      name: 'uploader',
      blob: {
        selectedScannerIndex: this.selectedScannerIndex,
      }
    };
    this.preferencesService.save(preferencesObj).subscribe();
  }

  updateTicket(ticket: Ticket): Observable<Ticket> {
    if (ticket) {
      if (this.model.customerName) { ticket.customerName = this.model.customerName; }
      if (this.model.jobName) { ticket.jobName = this.model.jobName; }
      if (this.model.ticketDate && this.isValidDate(this.model.ticketDate)) {
        ticket.ticketDate = new Date(this.model.ticketDate).toISOString().split('T')[0];
      }
      if (this.model.quantity) {
        ticket.quantity = this.model.quantity;
        ticket.quantityType = this.model.quantityType;
      }
      if (this.model.invoiceRate) { ticket.invoiceRate = this.model.invoiceRate; }
      if (this.model.material) { ticket.material = this.model.material; }
      if (this.model.carrierName) { ticket.carrierName = this.model.carrierName; }
      if (this.model.truckNumber) { ticket.truckNumber = this.model.truckNumber; }
      if (this.model.truckType) { ticket.truckType = this.model.truckType; }
      delete ticket.organization;
      if (!ticket.image) { delete ticket.image; }

      delete ticket.driver;
      delete ticket.truck;
      delete ticket.customer;
      delete ticket.job;

      delete ticket.jobCode;
      delete ticket.haulRate;
      delete ticket.origin;
      delete ticket.destination;

      return this.ticketService.save(ticket).pipe(
        switchMap(ticketRes => {
          let referenceUpdates: ReferenceEditModel = <ReferenceEditModel>{ ticketId: ticketRes.id };
          if (this.selectedJobReference && !!this.selectedJobReference.id) {
            referenceUpdates.job = this.selectedJobReference;
          }
          if (this.selectedCustomerReference && !!this.selectedCustomerReference.id) {
            referenceUpdates.customer = this.selectedCustomerReference;
          }
          if (this.selectedDriverReference && !!this.selectedDriverReference.id) {
            referenceUpdates.driver = this.selectedDriverReference;
          }
          if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
            return of(ticket);
          } else { return this.ticketService.updateFieldReferences(referenceUpdates); }
        }),
      );
    } else {
      return empty();
    }
  }

  saveImage(updateData: any) {
    this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
                      .subscribe(() => {}, (err: any) => {
                        const notification: Notification = {
                          context: { err },
                          id: shortid.generate(),
                          message: 'There was an error updating that ticket image.',
                          originator: 'uploader',
                          type: NotificationType.Danger,
                        };
                        this.notificationsService.addNotification(notification);
                        throw err;
                      });
  }

  previousTicket(ticket: Ticket) {
    this.updateTicket(ticket).subscribe(() => {
      this.selectedRecordIndex = this.selectedRecordIndex - 1;
      if (this.selectedRecordIndex >= 0) {
        this.preselectDropdowns(true, this.savedTickets[this.selectedRecordIndex]);
      } else { this.preselectDropdowns(false); }
    }, (err: any) => {
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error loading the previous ticket.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  nextTicket(ticket: Ticket) {
    this.updateTicket(ticket).subscribe(() => {
      this.selectedRecordIndex = this.selectedRecordIndex + 1;
      if (this.selectedRecordIndex !== this.savedTickets.length) {
        this.preselectDropdowns(true, this.savedTickets[this.selectedRecordIndex]);
      }
    }, (err: any) => {
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error loading the next ticket.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  preselectDropdowns(single = true, ticket?: Ticket) {
    if (single && ticket) {
      const keys = Object.keys(ticket);
      for (const key of keys) {
        if (ticket[key]) {
          if (key === 'ticketDate') {
            this.model[key] = ticket[key] + 'T00:00:00';
          } else if (key === 'job' && ticket.job) {
            this.selectedJobReference = ticket.job;
            this.jobReferenceSelector.setSelectedReference(ticket.job);
          } else if (key === 'customer' && ticket.customer) {
            this.selectedCustomerReference = ticket.customer;
            this.customerReferenceSelector.setSelectedReference(ticket.customer);
          } else if (key === 'driver' && ticket.driver) {
            this.selectedDriverReference = ticket.driver;
            this.driverReferenceSelector.setSelectedReference(ticket.driver);
          } else {
            this.model[key] = ticket[key];
          }
        }
      }
    } else {
      // get our key list from the first ticket in the batch upload group
      const keys = Object.keys(this.savedTickets[0]);
      for (const key of keys) {
        if (key === 'ticketDate') {
          this.model.ticketDate = this.checkForMatchingValue(this.savedTickets, 'ticketDate') && this.savedTickets[0].ticketDate ?
                                  this.savedTickets[0].ticketDate + 'T00:00:00' : null;
        } else {
          this.model[key] = this.checkForMatchingValue(this.savedTickets, key) ? this.savedTickets[0][key] : null;
        }
      }
    }
  }

  fileChange(e: any) {
    this.uploadService.getS3Policy(this.external).subscribe((policy: S3PolicyData) => {
      this.policyData = policy;
      this.uploadFiles = e.target ? Array.from(e.target.files) : e;
      if (this.uploadFiles.length) {
        this.viewerType = this.uploadTotal + this.uploadFiles.length > 1 ? 'grid' : 'single';
        this.loader = {
          active: true,
          type: 'uploading'
        };
        this.uploadFiles.forEach((file, i) => this.convertFileToRecord(file, i));
      }
    }, err => {
      this.loader.active = false;
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error authentication with our upload server.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  batchRecordOperation(reset = false, close = true) {
    if (this.savedTickets.length > 1) {
      let batchUploadRequests = this.savedTickets.map(ticket => {
        ticket.quantity = this.model.quantity ? this.model.quantity : ticket.quantity;
        ticket.quantityType = this.model.quantityType ? this.model.quantityType : ticket.quantityType;

        let ticketDatetype = this.model.ticketDate ? this.model.ticketDate : ticket.ticketDate;
        if (ticketDatetype && this.isValidDate(ticketDatetype)) {
          ticket.ticketDate = new Date(ticketDatetype).toISOString().split('T')[0];
        } else {
          delete ticket.ticketDate;
        }

        ticket.truckNumber = this.model.truckNumber ? this.model.truckNumber : ticket.truckNumber;
        ticket.truckType = this.model.truckType ? this.model.truckType : ticket.truckType;
        ticket.driverName = this.model.driverName ? this.model.driverName : ticket.driverName;
        ticket.customerName = this.model.customerName ? this.model.customerName : ticket.customerName;
        ticket.jobName = this.model.jobName ? this.model.jobName : ticket.jobName;
        ticket.material = this.model.material ? this.model.material : ticket.material;
        ticket.carrierName = this.model.carrierName ? this.model.carrierName : ticket.carrierName;
        ticket.orderNumber = this.model.orderNumber ? this.model.orderNumber : ticket.orderNumber;
        ticket.invoiceRate = this.model.invoiceRate ? this.model.invoiceRate : ticket.invoiceRate;
        if (!ticket.image) { delete ticket.image; }
        delete ticket.organization;

        delete ticket.driver;
        delete ticket.truck;
        delete ticket.customer;
        delete ticket.job;

        delete ticket.jobCode;
        delete ticket.haulRate;
        delete ticket.origin;
        delete ticket.destination;

        return new Promise((resolve) => {
          this.ticketService.save(ticket).pipe(
            switchMap(ticketRes => {
              let referenceUpdates: ReferenceEditModel = <ReferenceEditModel>{ ticketId: ticketRes.id };
              if (this.selectedJobReference && !!this.selectedJobReference.id) {
                referenceUpdates.job = this.selectedJobReference;
              }
              if (this.selectedCustomerReference && !!this.selectedCustomerReference.id) {
                referenceUpdates.customer = this.selectedCustomerReference;
              }
              if (this.selectedDriverReference && !!this.selectedDriverReference.id) {
                referenceUpdates.driver = this.selectedDriverReference;
              }
              if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
                return of(ticket);
              } else { return this.ticketService.updateFieldReferences(referenceUpdates); }
            }),
          ).subscribe(() => { }, err => {
            const notification: Notification = {
              context: { err },
              id: shortid.generate(),
              message: 'There was an error saving one of these tickets.' +
                       (ticket.ticketNumber ? ' (Ticket #' + ticket.ticketNumber + ')' : ''),
              originator: 'uploader',
              type: NotificationType.Danger,
            };
            this.notificationsService.addNotification(notification);
            throw err;
          }, () => resolve());
        });
      });
      Promise.all(batchUploadRequests).then(() => {
        if (close) {
          this.close();
        } else {
          this.viewerType = 'single';
          this.selectedRecordIndex = this.selectedRecordIndex ? this.selectedRecordIndex : 0;
          this.preselectDropdowns(true, this.savedTickets[this.selectedRecordIndex]);
        }
      });
    } else {
      let newTicketObj: any = clone(this.model);
      newTicketObj.ticketNumber = newTicketObj.ticketNumber || !this.savedTickets[this.selectedRecordIndex] ?
                                  newTicketObj.ticketNumber : this.savedTickets[this.selectedRecordIndex].ticketNumber;
      newTicketObj.ticketDate = this.model.ticketDate ?
        typeof this.model.ticketDate === 'object' ?
        new Date(this.model.ticketDate).toISOString().split('T')[0] :
        this.model.ticketDate.split('T')[0] : null;
      if (this.savedTickets && this.savedTickets.length) {
        newTicketObj.id = this.savedTickets[0].id;
        newTicketObj.image = this.savedTickets[0].image;
      };

      delete newTicketObj.organization;

      delete newTicketObj.driver;
      delete newTicketObj.truck;
      delete newTicketObj.customer;
      delete newTicketObj.job;

      delete newTicketObj.jobCode;
      delete newTicketObj.haulRate;
      delete newTicketObj.origin;
      delete newTicketObj.destination;

      this.ticketService.save(newTicketObj).pipe(
        switchMap(ticketRes => {
          let referenceUpdates: ReferenceEditModel = <ReferenceEditModel>{ ticketId: ticketRes.id };
          if (this.selectedJobReference && !!this.selectedJobReference.id) {
            referenceUpdates.job = this.selectedJobReference;
          }
          if (this.selectedCustomerReference && !!this.selectedCustomerReference.id) {
            referenceUpdates.customer = this.selectedCustomerReference;
          }
          if (this.selectedDriverReference && !!this.selectedDriverReference.id) {
            referenceUpdates.driver = this.selectedDriverReference;
          }
          if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
            return of(ticketRes);
          } else { return this.ticketService.updateFieldReferences(referenceUpdates); }
        }),
      ).subscribe(() => { }, err => {
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error saving this ticket.' +
                   (newTicketObj.ticketNumber ? ' (Ticket #' + newTicketObj.ticketNumber + ')' : ''),
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      }, () => {
        if (reset) { this.reset(); } else { this.close(); }
      });
    }
  }

  close(ticket?: Ticket) {
    this.disableScanning();
    if (ticket) {
      this.updateTicket(ticket).subscribe(() => {
        this.router.navigate(['tickets']);
      }, (err: any) => {
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error saving this ticket.' + (ticket.ticketNumber ? ' (Ticket #' + ticket.ticketNumber + ')' : ''),
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      });
    } else {
      this.router.navigate(['tickets']);
    }
  }

  reset() {
    this.savedTickets.length = 0;
    this.uploadFiles.length = 0;
    this.uploadTotal = 0;
    this.selectedRecordIndex = null;
    this.model = <TicketEditModel>{ ticketDate: '' };
  }

  cropWhiteSpace(base64Image: string) {
    return new Promise((resolve) => {
      this.loadImage(base64Image).then((image: any) => {
        // draw canvas with base64 image data (maintain size)
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext('2d');
        if (ctx) {
          // remove any white pixels that the scan driver will try to fill in
          // and return the cropped image base64 data
          ctx.drawImage(image, 0, 0);
          let copy = document.createElement('canvas').getContext('2d');
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixel = imageData.data;
          let bound: any = {};
          let x, y;

          for (let i = 0; i < pixel.length; i += 4) {
            if (pixel[i] !== 255 || pixel[i + 1] !== 255 || pixel[i + 2] !== 255) {
              x = (i / 4) % canvas.width;
              y = ~~((i / 4) / canvas.width);

              if (!bound.top) {
                bound.top = y;
              }

              if (!bound.left) {
                bound.left = x;
              } else if (x < bound.left) {
                bound.left = x;
              }

              if (!bound.right) {
                bound.right = x;
              } else if (bound.right < x) {
                bound.right = x;
              }

              if (!bound.bottom) {
                bound.bottom = y;
              } else if (bound.bottom < y) {
                bound.bottom = y;
              }
            }
          }

          let trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left,
            trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

          if (copy) {
            copy.canvas.width = trimWidth;
            copy.canvas.height = trimHeight;
            copy.putImageData(trimmed, 0, 0);
          }
          resolve(copy ? copy.canvas.toDataURL('image/jpeg', 1.0) : base64Image);
        } else {
          resolve(base64Image);
        }
      });
    });
  }

  loadImage(base64Image: string) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = base64Image;
      image.onload = () => resolve(image);
      image.onerror = (e) => reject(e);
    });
  }

  convertPDFtoImages(pdfFile: ArrayBuffer) {
    return new Promise((resolve) => {
      pdfjs.getDocument(pdfFile).then((pdf: any) => {
        let pdfImages: File[] = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          pdf.getPage(i).then((page: any) => {
            const scale = 2;
            const viewport = page.getViewport(scale);
            let canvas = document.createElement('canvas');
            canvas.setAttribute('id', 'canvas-' + i);
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const task = page.render({ canvasContext: context, viewport: viewport });
            task.promise.then(() => {
              pdfImages.push(this.uploadService.convertDataUriToFile(canvas.toDataURL('image/jpeg'), 'pdfImage-' + i + '.jpg'));
              if (pdfImages.length === pdf.numPages) { resolve(pdfImages); }
            });
          });
        }
      });
    });
  }

  convertFileToRecord(file: File, uploadIndex?: number) {
    let reader = new FileReader();
    reader.onload = () => this.fileLoad(reader, file, uploadIndex);
    reader.readAsArrayBuffer(file);
  }

  fileLoad(reader: FileReader, file: File, uploadIndex?: number) {
    if (file.type.includes('pdf')) {
      Promise.resolve(this.convertPDFtoImages(<ArrayBuffer>reader.result)).then((results) => {
        const pdfImages: File[] = <File[]>results;
        this.uploadTotal = this.uploadTotal + pdfImages.length;
        this.viewerType = this.uploadTotal > 1 ? 'grid' : 'single';
        pdfImages.forEach((image) => this.generateRecordFromImage(image));
      });
    } else if (file.type.startsWith('image/') && !file.type.includes('heic')) {
      this.uploadTotal++;
      this.generateRecordFromImage(file, uploadIndex);
    } else {
      const notification: Notification = {
        context: {},
        id: shortid.generate(),
        message: 'There was an error uploading this image. Please upload a valid image format (JPEG/PNG/GIF) or a PDF.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
    }
  };

  generateRecordFromImage(imageFile: any, uploadIndex?: number) {
    Promise.resolve(this.uploadService.generateUUID()).then(uuid => {
      this.policyData['fields']['key'] = 'tickets/' + uuid + '/original.jpg';
      let ticketData = <Ticket>{
        id: uuid,
        image: environment.ticketImageServerUrl + this.policyData['fields']['key'],
        ticketDate: '',
        ticketNumber: '',
        quantity: ''
      };
      const thisUploadIndex = uploadIndex ? uploadIndex : this.uploadIndex;
      this.uploadIndex++;
      this.uploadService.uploadToS3(this.policyData, imageFile).subscribe(() => {
        this.loader.type = 'analyzing';
        this.ocrService.getResults(ticketData, this.external).then((results: OcrResults) => {
          ticketData.ticketNumber = results.ticketNumber && results.ticketNumber.toString();
          ticketData.quantity = results.quantity && results.quantity.toString();
          const detectedDate = results.ticketDate && results.ticketDate.length > 0 ? results.ticketDate.toString() : null;
          if (detectedDate && this.isValidDate(detectedDate)) {
            ticketData.ticketDate = new Date(detectedDate).toISOString().split('T')[0];
          } else {
            delete ticketData.ticketDate;
          }
          if (this.external) {
            const externalTicketData: ExternalTicket = <ExternalTicket>{
              quantity: ticketData.quantity,
              ticketDate: this.model.ticketDate ? new Date(this.model.ticketDate).toISOString().split('T')[0] : ticketData.ticketDate,
              ticketNumber: ticketData.ticketNumber,
              image: ticketData.image,
              contextId: this.contextId
            };
            this.ticketService.saveExternal(externalTicketData).subscribe(ticketRes => {
              ticketRes.uploadIndex = thisUploadIndex;
              this.savedTickets.push(ticketRes);
              this.loader.active = this.savedTickets.length !== this.uploadTotal;
              this.selectedRecordIndex = !this.loader.active && this.savedTickets.length === 1 ? 0 : null;
            }, err => {
              const notification: Notification = {
                context: { err },
                id: shortid.generate(),
                message: 'There was an error creating this ticket.' +
                        (externalTicketData.ticketNumber ? ' (Ticket #' + externalTicketData.ticketNumber + ')' : ''),
                originator: 'uploader',
                type: NotificationType.Danger,
              };
              this.notificationsService.addNotification(notification);
              throw err;
            });
          } else {
            delete ticketData.id;
            let ticketUploadData = ticketData;
            this.ticketService.save(ticketUploadData).subscribe(ticketRes => {
              ticketRes.uploadIndex = thisUploadIndex;
              this.savedTickets.push(ticketRes);
              this.loader.active = this.savedTickets.length !== this.uploadTotal;
              this.selectedRecordIndex = !this.loader.active && this.savedTickets.length === 1 ? 0 : null;
            }, err => {
              const notification: Notification = {
                context: { err },
                id: shortid.generate(),
                message: 'There was an error creating this ticket.' +
                        (ticketUploadData.ticketNumber ? ' (Ticket #' + ticketUploadData.ticketNumber + ')' : ''),
                originator: 'uploader',
                type: NotificationType.Danger,
              };
              this.notificationsService.addNotification(notification);
              throw err;
            });
          }
        });
      }, err => {
        this.uploadTotal = Number(this.uploadTotal) - 1;
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error uploading this image.',
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      });
    });
  }

  toggleView(type: string, index?: number) {
    if (!this.external) {
      this.viewerType = type;
      if (type === 'grid') {
        this.selectedRecordIndex = null;
        this.preselectDropdowns(false);
      } else {
        this.selectedRecordIndex = index ? index : 0;
        this.batchRecordOperation(false, false);
      }
    }
  }

  onJobReferenceSelected(reference: JobReference): void {
    this.selectedJobReference = reference;
    this.isJobFieldVisible = false;
  }

  onJobReferenceBlur(): void {
    this.isJobFieldVisible = false;
  }

  onJobReferenceCreated(reference: JobReference): void {
    this.selectedJobReference = reference;
  }

  onCustomerReferenceSelected(reference: CustomerReference): void {
    this.selectedCustomerReference = reference;
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceBlur(): void {
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceCreated(reference: CustomerReference): void {
    this.selectedCustomerReference = reference;
  }

  onDriverReferenceSelected(reference: DriverReference): void {
    this.selectedDriverReference = reference;
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceBlur(): void {
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceCreated(reference: DriverReference): void {
    this.selectedDriverReference = reference;
  }

  onJobReferenceToggle(): void {
    this.jobFieldsVisible = !this.jobFieldsVisible;
  }

  onDriverReferenceToggle(): void {
    this.driverFieldsVisible = !this.driverFieldsVisible;
  }

  onCustomerReferenceToggle(): void {
    this.customerFieldsVisible = !this.customerFieldsVisible;
  }

  onJobReferenceFocused(): void {
    this.isCustomerFieldVisible = false;
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceFocused(): void {
    this.isJobFieldVisible = false;
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceFocused(): void {
    this.isJobFieldVisible = false;
    this.isDriverFieldVisible = false;
  }
}
