"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/http");
var environment_1 = require("../../environments/environment");
var ticket_filter_1 = require("./ticket.filter");
var api_service_1 = require("../shared/api.service");
var TicketFilterService = /** @class */ (function () {
    function TicketFilterService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.nextUris = {};
    }
    TicketFilterService.prototype.getFilters = function (filterType, search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        var params = new http_1.URLSearchParams();
        params.set('page_size', '6');
        if (search) {
            params.set('search', search);
        }
        var filtersUrl = this.baseUrl + 'scaletickets/' + filterType + '/';
        return this.http.get(filtersUrl, {
            headers: api_service_1.requestHeaders(),
            search: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(this.handleError));
    };
    TicketFilterService.prototype.getNext = function (filterType) {
        var _this = this;
        if (this.nextUris[filterType]) {
            return this.http.get(this.nextUris[filterType], { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(this.handleError));
        }
        else {
            return null;
        }
    };
    TicketFilterService.prototype.extractData = function (filterType, res) {
        var resObj = res.json();
        this.nextUris[filterType] = resObj.next;
        var body = resObj.results;
        if (body) {
            return body.map(function (filter) {
                return new ticket_filter_1.TicketFilter(filter);
            });
        }
        else {
            return {};
        }
    };
    TicketFilterService.prototype.handleError = function (error) {
        var errMsg;
        if (error instanceof http_1.Response) {
            var body = error.json() || '';
            var err = body.error || JSON.stringify(body);
            errMsg = error.status + " - " + (error.statusText || '') + " " + err;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return rxjs_1.throwError(errMsg);
    };
    return TicketFilterService;
}());
exports.TicketFilterService = TicketFilterService;
