"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var environment_1 = require("../../../environments/environment");
var resource_service_1 = require("../../shared/resource.service");
var api_service_1 = require("../../shared/api.service");
var quickbooks_serializer_1 = require("./quickbooks.serializer");
var operators_1 = require("rxjs/operators");
var QuickbooksCompanyService = /** @class */ (function (_super) {
    __extends(QuickbooksCompanyService, _super);
    function QuickbooksCompanyService(http) {
        var _this = _super.call(this, http, 'qb/companies/', new quickbooks_serializer_1.QuickbooksCompanySerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'qb/companies/';
        return _this;
    }
    QuickbooksCompanyService.prototype.downloadQwcFile = function (companyId) {
        var _this = this;
        var url = this.baseUrl + companyId + '/qwc/';
        var headers = api_service_1.requestHeaders();
        if (headers) {
            headers.delete('Accept');
        }
        return this.http.get(url, { headers: headers }).pipe(operators_1.map(function (response) { return _this.saveFile(response); }));
    };
    return QuickbooksCompanyService;
}(resource_service_1.ResourceService));
exports.QuickbooksCompanyService = QuickbooksCompanyService;
