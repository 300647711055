"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/http");
var environment_1 = require("../../environments/environment");
var index_1 = require("../shared/index");
var UploadService = /** @class */ (function () {
    function UploadService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.ptpRuckitKey = environment_1.environment.ptpRuckitKey;
    }
    UploadService.prototype.getS3Policy = function (externalUpload) {
        if (externalUpload === void 0) { externalUpload = false; }
        var url = this.baseUrl + 'tickets/s3/';
        return externalUpload ?
            this.http.get(url, { headers: index_1.externalRequestHeaders(this.ptpRuckitKey) }).pipe(operators_1.map(function (res) { return res.json(); }), operators_1.catchError(this.handleError)) :
            this.http.get(url, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return res.json(); }), operators_1.catchError(this.handleError));
    };
    UploadService.prototype.uploadToS3 = function (policy, file) {
        var body = new FormData();
        body.append('Content-Type', 'image/jpeg');
        body.append('key', policy.fields.key);
        body.append('AWSAccessKeyId', policy.fields.AWSAccessKeyId);
        body.append('acl', 'public-read');
        body.append('Policy', policy.fields.policy);
        body.append('Signature', policy.fields.signature);
        body.append('file', file);
        return this.http.post(policy.url, body);
    };
    UploadService.prototype.generateUUID = function () {
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now();
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };
    UploadService.prototype.convertDataUriToFile = function (data, fileName) {
        var arr = data.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };
    UploadService.prototype.handleError = function (error) {
        var errMsg;
        if (error instanceof http_1.Response) {
            var body = error.json() || '';
            var err = body.error || JSON.stringify(body);
            errMsg = error.status + " - " + (error.statusText || '') + " " + err;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return rxjs_1.throwError(errMsg);
    };
    return UploadService;
}());
exports.UploadService = UploadService;
