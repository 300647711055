import { Component, OnInit, ViewChild } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

import { AuthenticationService, parseErrors } from '../../../app/shared';
import { QuickbooksCompanyService } from '../../integrations/quickbooks/quickbooks.service';
import { QuickbooksCompany } from '../../integrations/quickbooks/quickbooks';
import { User } from '../../users/user';
import { OrganizationService } from '../../organizations/organization.service';
import { Organization } from '../../organizations/organization';
import { empty, of } from 'rxjs';

@Component({
  selector: 'ruckit-settings-integrations',
  templateUrl: './settings-integrations.component.html',
  styleUrls: ['./settings-integrations.component.scss'],
  providers: [QuickbooksCompanyService]
})
export class SettingsIntegrationsComponent implements OnInit {
  loading = false;
  @ViewChild('getQuickbooksFile') getQuickbooksFile: any;
  hasQuickbooksEnabled = false;
  user: User = <User>this.authenticationService.user();
  errors: any;

  constructor(
    private authenticationService: AuthenticationService,
    public organizationService: OrganizationService,
    public quickbooksCompanyService: QuickbooksCompanyService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loading = true;
    if (this.user.organization) {
      this.organizationService.get(this.user.organization.id).subscribe(org => {
        this.hasQuickbooksEnabled = org.hasQuickbooksEnabled;
        this.loading = false;
      });
    }
  }

  toggleQuickbooks() {
    this.loading = true;
    const organization = {
      id: this.user.organization && this.user.organization.id,
      hasQuickbooksEnabled: this.hasQuickbooksEnabled
    };
    this.organizationService.save(organization).subscribe((org: Organization) => {
      this.hasQuickbooksEnabled = org.hasQuickbooksEnabled;
    }, (err: any) => {
      this.errors = parseErrors(err);
    }, () => { this.loading = false; });
  }

  setupQuickbooks() {
    this.loading = true;
    this.quickbooksCompanyService.list().pipe(
      switchMap((companies: QuickbooksCompany[]) => {
        return companies.length && companies[0].id ?
               of(companies[0]) : this.quickbooksCompanyService.save({});
      }),
      switchMap((company: QuickbooksCompany) => {
        return this.quickbooksCompanyService.downloadQwcFile(company.id);
      }),
      tap(() => window.open('https://s3.amazonaws.com/ruckit-platform/quickbooks/webconnector/Quickbooks_WebConnector.zip', '_blank'))
    ).subscribe(() => { this.loading = false; });
  }
}
