"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".customer-reference-grid-actions[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:row;align-content:flex-end;justify-content:flex-end;margin-bottom:25px}.customer-reference-grid-actions[_ngcontent-%COMP%]   .search-container[_ngcontent-%COMP%]{flex:1 1 auto;display:flex}.customer-reference-grid-actions[_ngcontent-%COMP%]   .search-container[_ngcontent-%COMP%]   search[_ngcontent-%COMP%]{flex:1 1 auto}.customer-reference-grid-actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin-left:25px;width:250px}.customer-grid[_ngcontent-%COMP%]{width:100%;height:calc(100% - 150px)}.selected-indicator[_ngcontent-%COMP%]{position:absolute;bottom:20px;left:50px;color:#2a81d7;font-weight:600}  .ag-tool-panel-wrapper{width:auto}  .ag-theme-material .ag-icon-checkbox-checked:empty{background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDBIMmEyIDIgMCAwIDAtMiAydjE0YTIgMiAwIDAgMCAyIDJoMTRhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJ6TTcgMTRMMiA5bDEuNDEtMS40MUw3IDExLjE3bDcuNTktNy41OUwxNiA1bC05IDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+)}  .cdk-overlay-pane{overflow:hidden}.add-customer[_ngcontent-%COMP%]{width:200px}"];
exports.styles = styles;
