"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var driver_reference_service_1 = require("../driver-reference.service");
var autocomplete_cell_component_1 = require("../../../shared/grid/autocomplete-cell.component");
var datepicker_cell_component_1 = require("../../../shared/grid/datepicker-cell.component");
var ag_grid_angular_1 = require("ag-grid-angular");
var material_1 = require("@angular/material");
var bulk_action_dialog_component_1 = require("../../../shared/grid/bulk-action-dialog.component");
var rxjs_1 = require("rxjs");
var select_all_header_component_1 = require("../../../shared/grid/select-all-header.component");
var preferences_1 = require("../../../shared/preferences/preferences");
var shared_1 = require("../../../shared");
var notifications_service_1 = require("../../../shared/notification/notifications.service");
var preferences_service_1 = require("../../../shared/preferences/preferences.service");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var shortid = require("shortid");
var driver_reference_create_modal_component_1 = require("../driver-reference-create-modal/driver-reference-create-modal.component");
var DriverReferenceGridComponent = /** @class */ (function () {
    function DriverReferenceGridComponent(driverReferenceService, bulkDeleteDialog, driverReferenceCreateModal, authenticationService, notificationsService, preferencesService) {
        var _this = this;
        this.driverReferenceService = driverReferenceService;
        this.bulkDeleteDialog = bulkDeleteDialog;
        this.driverReferenceCreateModal = driverReferenceCreateModal;
        this.authenticationService = authenticationService;
        this.notificationsService = notificationsService;
        this.preferencesService = preferencesService;
        this.frameworkComponents = {
            autocompleteCell: autocomplete_cell_component_1.AutocompleteCell,
            datepickerCell: datepicker_cell_component_1.DatepickerCell,
            selectAllHeader: select_all_header_component_1.SelectAllHeader,
            customNoRowsOverlay: shared_1.NoResultsComponent,
        };
        this.noRowsOverlayComponent = 'customNoRowsOverlay';
        this.noRowsOverlayComponentParams = {
            type: 'driver references',
            onClick: function () { return _this.onAddDriverClick(); },
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel'
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ]
        };
        this.columnDefs = [
            {
                headerName: 'Select All',
                field: 'select',
                headerComponent: 'selectAllHeader',
                pinned: 'left',
                width: 70,
                editable: false,
                checkboxSelection: true,
                headerComponentParams: {
                    checkboxSelection: true,
                    service: this.driverReferenceService,
                    selected: this.driverReferenceService.allSelected
                }
            },
            {
                headerName: 'Driver Code',
                field: 'driverCode',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.driverReferenceService.getValuesForFieldQuery('driverCode').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.driverReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Driver Name',
                field: 'driverName',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.driverReferenceService.getValuesForFieldQuery('driverName').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.driverReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Carrier',
                field: 'carrierName',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.driverReferenceService.getValuesForFieldQuery('carrierName').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.driverReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Email Address',
                field: 'email',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.driverReferenceService.getValuesForFieldQuery('email').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.driverReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Phone Number',
                field: 'phoneNumber',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.driverReferenceService.getValuesForFieldQuery('phoneNumber').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.driverReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'CDL',
                field: 'cdl',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.driverReferenceService.getValuesForFieldQuery('cdl').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.driverReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
        ];
        this.rowModelType = 'serverSide';
        this.cacheBlockSize = 100;
        this.maxBlocksInCache = 10;
        this.loading = false;
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.user = this.authenticationService.user();
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.driverReferenceService.search = _this.search;
            _this.refreshTable();
        });
    }
    DriverReferenceGridComponent.prototype.getContextMenuItems = function (params) {
        var _this = this;
        return [
            {
                name: 'Delete',
                action: function () { return _this.onDeleteDriverReference(params); }
            },
            {
                name: 'Export',
                subMenu: [
                    {
                        name: 'Export Selected',
                        action: function () { return _this.onExportSelected(params); },
                    },
                    {
                        name: 'Export All',
                        action: function () { return _this.onExportAll(); },
                    },
                ]
            },
        ];
    };
    DriverReferenceGridComponent.prototype.onDeleteDriverReference = function (params) {
        var _this = this;
        if (!params.api) {
            return;
        }
        var selected = params.api.getSelectedRows();
        if (!selected || !selected.length) {
            selected = [params.node.data];
            params.node.setSelected(true);
        }
        var dialog = this.bulkDeleteDialog.open(bulk_action_dialog_component_1.BulkActionDialogComponent, {
            width: '500px',
            data: {
                selected: selected
            },
        });
        dialog.componentInstance.callback = function () {
            var jobs = rxjs_1.zip.apply(void 0, selected.map(function (driver) {
                return _this.deleteDriverReference(driver);
            }));
            jobs.subscribe(function () {
                _this.refreshTable(true);
            });
        };
    };
    DriverReferenceGridComponent.prototype.deleteDriverReference = function (driver) {
        return this.driverReferenceService.remove(driver);
    };
    DriverReferenceGridComponent.prototype.onExportSelected = function (params) {
        var _this = this;
        var selected;
        selected = params.api ?
            params.api.getSelectedRows().map(function (s) { return s.id; }) :
            params.map(function (s) { return s.id; });
        if ((!selected || !selected.length) && params.node && params.node.data) {
            selected = [params.node.data.id];
            params.node.setSelected(true);
        }
        this.driverReferenceService.exportGrid('drivers', selected).subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'drivers',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    DriverReferenceGridComponent.prototype.onExportAll = function () {
        var _this = this;
        this.driverReferenceService.exportGrid('drivers').subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'drivers',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    DriverReferenceGridComponent.prototype.refreshTable = function (clear) {
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.gridApi.deselectAll();
            this.gridApi.clearRangeSelection();
        }
        this.gridApi.refreshCells();
        this.gridApi.purgeServerSideCache();
    };
    DriverReferenceGridComponent.prototype.onCellValueChanged = function (e) {
        var driver = __assign({}, e.data);
        delete driver.classes;
        delete driver.createdAt;
        delete driver.loading;
        delete driver.selected;
        this.driverReferenceService.save(driver).subscribe();
    };
    DriverReferenceGridComponent.prototype.onGridReady = function (e) {
        var _this = this;
        if (!e.columnApi || !e.api) {
            return;
        }
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
        this.applyPreferences();
        this.driversGrid.gridOptions.getContextMenuItems = function (params) { return _this.getContextMenuItems(params); };
        e.api.setServerSideDatasource(this.driverReferenceService);
    };
    DriverReferenceGridComponent.prototype.onFirstDataRendered = function () {
        this.autoSizeAll();
    };
    DriverReferenceGridComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
        });
        this.columnApi.autoSizeColumns(allColumnIds);
    };
    DriverReferenceGridComponent.prototype.onAddDriverClick = function () {
        var _this = this;
        var dialog = this.driverReferenceCreateModal.open(driver_reference_create_modal_component_1.DriverReferenceCreateModalComponent, {
            width: '500px',
        });
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function () { return _this.refreshTable(); });
        };
    };
    DriverReferenceGridComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    DriverReferenceGridComponent.prototype.applyPreferences = function () {
        var _this = this;
        // Only update or create a default preference until UI for selection is in place
        this.preferencesService.list().pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return p.name === 'driverreference-default'; });
            return pref ? pref : new preferences_1.Preferences;
        })).subscribe(function (preferences) {
            _this.preferences = preferences;
            if (preferences.type === 'driverreference' && preferences.blob) {
                _this.columnApi.setColumnState(preferences.blob.columnState);
                _this.gridApi.setSortModel(preferences.blob.sortState);
            }
            var eventList = [
                'gridColumnsChanged',
                'columnPinned',
                'columnVisible',
                'columnResized',
                'columnMoved'
            ];
            eventList.forEach(function (event) {
                _this.gridApi.addEventListener(event, lodash_1.debounce(function () { return _this.savePreferences(); }, 300));
            });
        }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured saving your preferences.',
                originator: 'driverreferences',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    DriverReferenceGridComponent.prototype.savePreferences = function () {
        var _this = this;
        var columnData = this.columnApi.getColumnState();
        var preferencesObj = __assign({}, this.preferences, { profile: this.user.id, type: 'driverreference', name: 'driverreference-default', blob: {
                columnState: columnData,
            } });
        this.preferencesService.save(preferencesObj).subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured saving your preferences.',
                originator: 'driverreferences',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    return DriverReferenceGridComponent;
}());
exports.DriverReferenceGridComponent = DriverReferenceGridComponent;
