import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ReportService } from '../report/report.service';
import { parseErrors } from '../../shared';

@Component({
  selector: 'send-report-dialog',
  templateUrl: './send-report-dialog.component.html',
  styleUrls: ['./send-report-dialog.component.scss'],
  providers: [ ReportService ]
})
export class SendReportDialogComponent implements OnInit {
  errors: string[] = [];
  type = 'report';
  reportId: string;
  number: string;
  recipientEmail!: string;
  sent = false;

  callback: any;

  constructor(
    private reportService: ReportService,
    public dialogRef: MatDialogRef<SendReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.type = data['type'] ? data['type'] : this.type;
    this.reportId = data['id'];
    this.number = data['number'];
  }

  ngOnInit() { }

  send() {
    this.reportService.send(this.reportId, this.recipientEmail).subscribe(() => {
      this.sent = true;
      this.callback();
      setTimeout(() => this.dialogRef.close(), 5000);
    }, err => { this.errors = parseErrors(err); });
  }
}
