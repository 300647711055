"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var moment = require("moment");
var ag_grid_angular_1 = require("ag-grid-angular");
var select_all_header_component_1 = require("../../shared/grid/select-all-header.component");
var autocomplete_cell_component_1 = require("../../shared/grid/autocomplete-cell.component");
var checkbox_cell_component_1 = require("../../shared/grid/checkbox-cell.component");
var ticket_date_cell_component_1 = require("../../tickets/ticket-date-cell.component");
var bulk_action_dialog_component_1 = require("../../shared/grid/bulk-action-dialog.component");
var authentication_service_1 = require("../../shared/authentication.service");
var settlement_service_1 = require("../settlement/settlement.service");
var shared_1 = require("../../../app/shared");
var SettlementGridComponent = /** @class */ (function () {
    function SettlementGridComponent(router, authenticationService, reportService, bulkDeleteDialog) {
        var _this = this;
        this.router = router;
        this.authenticationService = authenticationService;
        this.reportService = reportService;
        this.bulkDeleteDialog = bulkDeleteDialog;
        this.user = this.authenticationService.user();
        this.loading = true;
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.frameworkComponents = {
            autocompleteCell: autocomplete_cell_component_1.AutocompleteCell,
            selectAllHeader: select_all_header_component_1.SelectAllHeader,
            customNoRowsOverlay: shared_1.NoResultsComponent
        };
        this.noRowsOverlayComponent = 'customNoRowsOverlay';
        this.noRowsOverlayComponentParams = {
            type: 'settlements',
        };
        this.columnDefs = [
            {
                headerName: 'Select All',
                field: 'select',
                headerComponent: 'selectAllHeader',
                pinned: 'left',
                width: 70,
                editable: false,
                checkboxSelection: true,
                headerComponentParams: {
                    checkboxSelection: true,
                    service: this.reportService,
                    selected: this.reportService.allSelected
                }
            },
            {
                headerName: 'Created Date',
                field: 'reportDate',
                filter: 'agDateColumnFilter',
                filterParams: { newRowsAction: 'keep' },
                editable: false,
                cellRendererFramework: ticket_date_cell_component_1.TicketGridDateCell,
                width: 140,
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Due Date',
                field: 'dueDate',
                filter: 'agDateColumnFilter',
                filterParams: { newRowsAction: 'keep' },
                editable: false,
                cellRendererFramework: ticket_date_cell_component_1.TicketGridDateCell,
                width: 140,
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Settlement #',
                field: 'reportNumber',
                filter: false,
                editable: false,
                menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Driver',
                field: 'customer.name',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.reportService.getValuesForFieldQuery('customerName').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: false,
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Created By',
                field: 'createdBy',
                cellRenderer: function (params) {
                    var value = params.value;
                    if (!value) {
                        return '';
                    }
                    var firstName = value.firstName ? value.firstName : '';
                    var lastName = value.lastName ? value.lastName : '';
                    return firstName + " " + lastName;
                },
                editable: false,
                menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Last Edited',
                field: 'lastModified',
                cellRenderer: function (params) {
                    var lastModified = params.value;
                    var lastModifiedBy = params.data && params.data.lastModifiedBy;
                    if (!lastModified || !lastModifiedBy) {
                        return '';
                    }
                    var firstName = lastModifiedBy.firstName ? lastModifiedBy.firstName : '';
                    var lastName = lastModifiedBy.lastName ? lastModifiedBy.lastName : '';
                    return moment(lastModified).format('MM/DD/YY h:mma') + (" " + firstName + " " + lastName);
                },
                editable: false,
                menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Total',
                field: 'total',
                editable: false,
                menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'Sent',
                field: 'sent',
                cellRendererFramework: checkbox_cell_component_1.CheckboxCell,
                cellRendererParams: {
                    service: this.reportService,
                    field: 'sent'
                },
                filter: 'agSetColumnFilter',
                filterParams: {
                    cellRenderer: function (params) {
                        if (params.value === 'True') {
                            return 'Sent';
                        }
                        return 'Not Sent';
                    },
                    values: ['True', 'False'],
                    newRowsAction: 'keep',
                },
                editable: false,
                sortable: true,
                resizable: true,
            },
            {
                headerName: 'PDF',
                field: 'pdfUrl',
                cellRenderer: linkRenderer,
                editable: false,
                sortable: true,
                resizable: true,
            }
        ];
        this.rowModelType = 'serverSide';
        this.cacheBlockSize = 100;
        this.maxBlocksInCache = 10;
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.loading = true;
            _this.search = search;
            _this.reportService.search = _this.search;
            _this.refreshTable();
        });
    }
    SettlementGridComponent.prototype.getContextMenuItems = function (params) {
        var _this = this;
        return [
            {
                name: 'Edit',
                action: function () { return _this.onEditSettlement(params); }
            },
            {
                name: 'Delete',
                action: function () { return _this.onDeleteSettlement(params); }
            },
            'separator',
            {
                name: 'CSV Export',
                action: function () { return _this.onExport('csv'); }
            },
            {
                name: 'Excel Export',
                action: function () { return _this.onExport('excel'); }
            },
        ];
    };
    SettlementGridComponent.prototype.onEditSettlement = function (params) {
        if (!params.api) {
            return;
        }
        var selected = params.api.getSelectedRows()[0];
        if (!selected) {
            selected = params.node.data;
        }
        this.router.navigate(['settlements', selected.id, 'edit']);
    };
    SettlementGridComponent.prototype.onDeleteSettlement = function (params) {
        var _this = this;
        if (!params.api) {
            return;
        }
        var selected = params.api.getSelectedRows();
        if (!selected || !selected.length) {
            selected = [params.node.data];
            params.node.setSelected(true);
        }
        var dialog = this.bulkDeleteDialog.open(bulk_action_dialog_component_1.BulkActionDialogComponent, {
            width: '500px',
            data: {
                selected: selected,
                type: 'Expense',
            },
        });
        dialog.componentInstance.callback = function () {
            var jobs = rxjs_1.zip.apply(void 0, selected.map(function (j) {
                return _this.deleteSettlement(j);
            }));
            jobs.subscribe(function () {
                _this.refreshTable(true);
            });
        };
    };
    SettlementGridComponent.prototype.onExport = function (type) {
        var exportFlags = {
            onlySelected: !this.reportService.allSelected,
            processCellCallback: function (cell) {
                // if a name object is detected, lets format for the export
                if (cell.value && cell.value.firstName) {
                    return cell.value.firstName + ' ' + cell.value.lastName;
                }
                else {
                    return cell.value;
                }
            }
        };
        if (type === 'csv') {
            this.gridApi.exportDataAsCsv(exportFlags);
        }
        else if (type === 'excel') {
            this.gridApi.exportDataAsExcel(exportFlags);
        }
    };
    SettlementGridComponent.prototype.deleteSettlement = function (settlement) {
        return this.reportService.remove(settlement);
    };
    SettlementGridComponent.prototype.refreshTable = function (clear) {
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.gridApi.deselectAll();
            this.gridApi.clearRangeSelection();
        }
        this.gridApi.refreshCells();
        this.gridApi.purgeServerSideCache();
    };
    SettlementGridComponent.prototype.onCellValueChanged = function (e) {
        var settlement = __assign({}, e.data, { type: 'expense' });
        delete settlement.selected;
        this.reportService.save(settlement).subscribe();
    };
    SettlementGridComponent.prototype.onGridReady = function (e) {
        var _this = this;
        if (!e.columnApi || !e.api) {
            return;
        }
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
        this.settlementGrid.gridOptions.getContextMenuItems = function (params) { return _this.getContextMenuItems(params); };
        this.gridApi.setServerSideDatasource(this.reportService);
    };
    SettlementGridComponent.prototype.onFirstDataRendered = function () {
        this.autoSizeAll();
    };
    SettlementGridComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    SettlementGridComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
        });
        this.columnApi.autoSizeColumns(allColumnIds);
    };
    SettlementGridComponent.prototype.onAddSettlementClick = function () {
        var _this = this;
        this.reportService.save({ type: 'expense' }).subscribe(function () { return _this.refreshTable(); });
    };
    return SettlementGridComponent;
}());
exports.SettlementGridComponent = SettlementGridComponent;
function linkRenderer(params) {
    return params.value ? '<a href="' + params.value + '" target="_blank" translate>View</a>' : '';
}
