"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".modal-title[_ngcontent-%COMP%]{margin:0;background-color:#2a81d7;min-height:60px;padding:18px 30px;position:relative}.modal-title[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{font-size:18px;font-weight:400;margin:0;color:#fff}.modal-title[_ngcontent-%COMP%]   .close-action[_ngcontent-%COMP%]{position:absolute;top:18px;right:30px;color:rgba(255,255,255,.4);font-size:24px}.modal-title[_ngcontent-%COMP%]   .close-action[_ngcontent-%COMP%]:hover{color:#fff}.modal-footer[_ngcontent-%COMP%]{min-height:60px;background-color:#f0f3f8;padding:12px 24px;text-align:center}[mat-dialog-content][_ngcontent-%COMP%], [md-dialog-content][_ngcontent-%COMP%], mat-dialog-content[_ngcontent-%COMP%], md-dialog-content[_ngcontent-%COMP%]{margin:0}p[_ngcontent-%COMP%]{text-align:left;font-size:14px;line-height:1;font-weight:400;color:#72839b;margin-top:30px;margin-bottom:40px}.alert-success[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{text-align:center;color:#333;font-size:22px;font-weight:300;margin:17px 0 0}"];
exports.styles = styles;
