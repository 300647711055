"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./image-indicator-cell.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("../../tickets/ticket.service");
var i5 = require("@angular/http");
var i6 = require("../upload.service");
var i7 = require("./image-indicator-cell.component");
var styles_ImageIndicatorCell = [i0.styles];
var RenderType_ImageIndicatorCell = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageIndicatorCell, data: {} });
exports.RenderType_ImageIndicatorCell = RenderType_ImageIndicatorCell;
function View_ImageIndicatorCell_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "thumbnail"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageIndicatorCell_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "upload-image-dropzone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["fileInput", 1]], null, 0, "input", [["class", "inputfile"], ["id", "file"], ["name", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [["class", "upload-label"], ["for", "file"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Upload Image"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-no-results"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ImageIndicatorCell_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ruckit-image-cell"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageIndicatorCell_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageIndicatorCell_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.value; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_ImageIndicatorCell_0 = View_ImageIndicatorCell_0;
function View_ImageIndicatorCell_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "image-indicator-cell", [], null, null, null, View_ImageIndicatorCell_0, RenderType_ImageIndicatorCell)), i1.ɵprd(512, null, i4.TicketService, i4.TicketService, [i5.Http]), i1.ɵprd(512, null, i6.UploadService, i6.UploadService, [i5.Http]), i1.ɵdid(3, 4243456, null, 0, i7.ImageIndicatorCell, [i4.TicketService, i6.UploadService], null, null)], null, null); }
exports.View_ImageIndicatorCell_Host_0 = View_ImageIndicatorCell_Host_0;
var ImageIndicatorCellNgFactory = i1.ɵccf("image-indicator-cell", i7.ImageIndicatorCell, View_ImageIndicatorCell_Host_0, {}, {}, []);
exports.ImageIndicatorCellNgFactory = ImageIndicatorCellNgFactory;
