import { JobReference } from './job-reference';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class JobReferenceSerializer {
  fromJson(json: any): JobReference {
    json = camelcaseKeysDeep(json);

    const reference = new JobReference();

    if (!json) { return reference; }

    reference.id = json.id;
    reference.organization = json.organization;
    reference.value = json.value;
    reference.createdAt = json.createdAt;
    reference.destination = json.destination;
    reference.haulRate = json.haulRate;
    reference.invoiceRate = json.invoiceRate;
    reference.jobCode = json.jobCode;
    reference.jobName = json.jobName;
    reference.material = json.material;
    reference.origin = json.origin;
    reference.orderNumber = json.orderNumber;

    return reference;
  }

  toJson(reference: JobReference): any {
    const json: any = {
      id: reference.id,
      organization: reference.organization,
      value: reference.value,
      createdAt: reference.createdAt,
      destination: reference.destination,
      haulRate: reference.haulRate,
      invoiceRate: reference.invoiceRate,
      jobCode: reference.jobCode,
      jobName: reference.jobName,
      material: reference.material,
      origin: reference.origin,
      orderNumber: reference.orderNumber
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
