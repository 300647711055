"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ExportOption;
(function (ExportOption) {
    ExportOption["All"] = "All";
    ExportOption["Selected"] = "Selected";
})(ExportOption = exports.ExportOption || (exports.ExportOption = {}));
;
var ExportModalComponent = /** @class */ (function () {
    function ExportModalComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.exportOptions = ExportOption;
        this.includeImages = false;
        this.imagesExportable = true;
        this.exportOption = ExportOption.All;
        this.callback = function () { };
    }
    ExportModalComponent.prototype.ngOnInit = function () {
        if (this.data.selected) {
            this.exportOption = ExportOption.Selected;
        }
        if (this.data.hasOwnProperty('imagesExportable')) {
            this.imagesExportable = this.data.imagesExportable;
        }
    };
    ExportModalComponent.prototype.onSubmit = function () {
        this.callback(this.exportOption, this.includeImages);
        this.dialogRef.close();
    };
    return ExportModalComponent;
}());
exports.ExportModalComponent = ExportModalComponent;
