"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./vendor.ts");
var __NgCli_bootstrap_1 = require("./app/app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
// depending on the env mode, enable prod mode or add debugging modules
// enableProdMode();
function main() {
    return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
}
exports.main = main;
if (document.readyState === 'complete') {
    main();
}
else {
    document.addEventListener('DOMContentLoaded', main);
}
