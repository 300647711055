"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var charge_serializer_1 = require("./charge.serializer");
var resource_service_1 = require("../shared/resource.service");
var ChargeService = /** @class */ (function (_super) {
    __extends(ChargeService, _super);
    function ChargeService(http) {
        var _this = _super.call(this, http, 'charges/', new charge_serializer_1.ChargeSerializer()) || this;
        _this.search = '';
        _this.allSelected = false;
        return _this;
    }
    return ChargeService;
}(resource_service_1.ResourceService));
exports.ChargeService = ChargeService;
