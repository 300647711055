"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var index_1 = require("./shared/index");
var data_service_1 = require("./shared/data.service");
require("../style/app.scss");
var material_1 = require("@angular/material");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var AppComponent = /** @class */ (function () {
    function AppComponent(dataService, authenticationService, matIconRegistry, domSanitizer, router, translate) {
        this.dataService = dataService;
        this.authenticationService = authenticationService;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.router = router;
        this.url = 'https://goruckit.com';
        this.sidebarOpen = false;
        this.loggedIn = false;
        this.displaySidebar = true;
        this.hiddenRoutes = ['/uploader'];
        translate.addLangs(['en', 'es', 'fr']);
        translate.setDefaultLang('en');
        var browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');
        this.matIconRegistry.addSvgIcon('icn-ticket', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/list-alt-24-px.svg'));
        this.matIconRegistry.addSvgIcon('icn-link', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/link-24-px.svg'));
        this.matIconRegistry.addSvgIcon('icn-invoices', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/monetization-on-24-px.svg'));
        this.matIconRegistry.addSvgIcon('icn-truck', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/local-shipping-24-px.svg'));
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.dataService.data$.subscribe(function (data) {
            Promise.resolve(null).then(function () {
                _this.data = data;
                if (_this.data && _this.data.authenticated === undefined) {
                    _this.loggedIn = _this.authenticationService.isLoggedIn();
                }
                else {
                    _this.loggedIn = _this.data.authenticated;
                }
            });
        });
    };
    AppComponent.prototype.onLogout = function () {
        this.loggedIn = false;
        this.displaySidebar = false;
        this.authenticationService.logout();
    };
    AppComponent.prototype.onHamburgerClicked = function () {
        this.sidebarOpen = !this.sidebarOpen;
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
