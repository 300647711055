"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var forms_1 = require("@angular/forms");
var driver_reference_service_1 = require("../driver-reference.service");
var DriverReferenceCreateModalComponent = /** @class */ (function () {
    function DriverReferenceCreateModalComponent(formBuilder, driverReferenceService, dialogRef, data) {
        this.formBuilder = formBuilder;
        this.driverReferenceService = driverReferenceService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.referenceForm = this.formBuilder.group({
            driverCode: [''],
            driverName: [''],
            carrierName: [''],
            email: [''],
            phoneNumber: [''],
            cdl: [''],
        });
        this.mode = 'create';
    }
    DriverReferenceCreateModalComponent.prototype.ngOnInit = function () {
        if (this.data.model) {
            this.setFormFieldData(this.data.model);
        }
        this.mode = this.data.mode;
    };
    DriverReferenceCreateModalComponent.prototype.onSubmit = function () {
        var value = this.referenceForm.value;
        if (this.data.model) {
            value = __assign({}, this.data.model, this.referenceForm.value);
        }
        this.callback(this.driverReferenceService.save(value));
        this.dialogRef.close();
    };
    DriverReferenceCreateModalComponent.prototype.setFormFieldData = function (data) {
        this.referenceForm.patchValue(data);
    };
    return DriverReferenceCreateModalComponent;
}());
exports.DriverReferenceCreateModalComponent = DriverReferenceCreateModalComponent;
