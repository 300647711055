"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0;padding-top:0}  .mat-form-field-infix{border:none;padding:0}.ruckit-autocomplete-cell__input[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{border-radius:0;width:100%;height:initial;border:none}"];
exports.styles = styles;
