"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./no-results.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./no-results.component");
var styles_NoResultsComponent = [i0.styles];
var RenderType_NoResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoResultsComponent, data: {} });
exports.RenderType_NoResultsComponent = RenderType_NoResultsComponent;
function View_NoResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "no-results__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["Create ", ""])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.type)); _ck(_v, 2, 0, currVal_0); }); }
function View_NoResultsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "ag-overlay-loading-center no-results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "no-results__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-no-results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "no-results__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" No ", " "])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "no-results__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["When you add ", " you'll see them here."])), i1.ɵppd(10, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoResultsComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.onClick; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.type)); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.type)); _ck(_v, 9, 0, currVal_1); }); }
exports.View_NoResultsComponent_0 = View_NoResultsComponent_0;
function View_NoResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "no-results", [], null, null, null, View_NoResultsComponent_0, RenderType_NoResultsComponent)), i1.ɵdid(1, 49152, null, 0, i3.NoResultsComponent, [], null, null)], null, null); }
exports.View_NoResultsComponent_Host_0 = View_NoResultsComponent_Host_0;
var NoResultsComponentNgFactory = i1.ɵccf("no-results", i3.NoResultsComponent, View_NoResultsComponent_Host_0, {}, {}, []);
exports.NoResultsComponentNgFactory = NoResultsComponentNgFactory;
