"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./ticket-date-cell.component");
var styles_TicketGridDateCell = [];
var RenderType_TicketGridDateCell = i0.ɵcrt({ encapsulation: 2, styles: styles_TicketGridDateCell, data: {} });
exports.RenderType_TicketGridDateCell = RenderType_TicketGridDateCell;
function View_TicketGridDateCell_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params == null) ? null : _co.params.value); _ck(_v, 0, 0, currVal_0); }); }
exports.View_TicketGridDateCell_0 = View_TicketGridDateCell_0;
function View_TicketGridDateCell_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ticket-grid-date-cell", [], null, null, null, View_TicketGridDateCell_0, RenderType_TicketGridDateCell)), i0.ɵdid(1, 49152, null, 0, i1.TicketGridDateCell, [], null, null)], null, null); }
exports.View_TicketGridDateCell_Host_0 = View_TicketGridDateCell_Host_0;
var TicketGridDateCellNgFactory = i0.ɵccf("ticket-grid-date-cell", i1.TicketGridDateCell, View_TicketGridDateCell_Host_0, {}, {}, []);
exports.TicketGridDateCellNgFactory = TicketGridDateCellNgFactory;
