"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var AutocompleteCell = /** @class */ (function () {
    function AutocompleteCell() {
        this.autocompleteControl = new forms_1.FormControl();
        this.options = [];
    }
    AutocompleteCell.prototype.agInit = function (params) {
        this.params = params;
        this.value = this.params.value;
        this.autocompleteControl.setValue(this.value);
        this.onChanges();
    };
    AutocompleteCell.prototype.ngAfterViewInit = function () {
        this.focusOnInputNextTick(this.autocompleteInput);
    };
    AutocompleteCell.prototype.focusOnInputNextTick = function (input) {
        setTimeout(function () {
            input.element.nativeElement.focus();
        }, 0);
    };
    AutocompleteCell.prototype.onChanges = function () {
        var _this = this;
        var service = this.params.service;
        var field = this.params.column.colId;
        this.autocompleteControl.valueChanges.subscribe(function (v) {
            _this.value = v;
        });
        this.filteredOptions = this.autocompleteControl.valueChanges.pipe(operators_1.startWith(this.value), operators_1.switchMap(function (f) {
            return service.getValuesForFieldQuery(field, f);
        }), operators_1.map(function (fields) {
            var value = _this.value ? _this.value : '';
            return fields.filter(function (f) {
                return f.toLowerCase().indexOf(value.toLowerCase()) === 0;
            });
        }));
    };
    AutocompleteCell.prototype.getValue = function () {
        return this.value;
    };
    AutocompleteCell.prototype.isPopup = function () {
        return false;
    };
    AutocompleteCell.prototype.isCancelBeforeStart = function () {
        return false;
    };
    AutocompleteCell.prototype.isCancelAfterEnd = function () {
        return false;
    };
    AutocompleteCell.prototype.onKeyDown = function (e) {
        var key = e.which || e.keyCode;
        if (key === 37 || key === 38) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    return AutocompleteCell;
}());
exports.AutocompleteCell = AutocompleteCell;
