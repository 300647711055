"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./sidebar.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i4 = require("@angular/material/icon");
var i5 = require("./sidebar.component");
var i6 = require("@angular/router");
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
exports.RenderType_SidebarComponent = RenderType_SidebarComponent;
function View_SidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar__sidenav__options__option__label__display"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar__sidenav__options__option__label__display--toggled"]], null, null, null, null, null))], null, null); }
function View_SidebarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar__sidenav__options__option__label__display--untoggled"]], null, null, null, null, null))], null, null); }
function View_SidebarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar__sidenav__options__option__children__child"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOptionClick(_v.parent.parent.context.index, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "sidebar__sidenav__options__option__children__child--selected": 0 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "sidebar__sidenav__options__option__children__child"; var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit.active); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_2); }); }
function View_SidebarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar__sidenav__options__option__children"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_7)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "sidebar__sidenav__options__option"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "sidebar__sidenav__options__option--active": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "sidebar__sidenav__options__option__label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOptionClick(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(5, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_6)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar__sidenav__options__option"; var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit.active); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 5, 0); var currVal_4 = (_co.visible && _co.drawerVisible); _ck(_v, 8, 0, currVal_4); var currVal_5 = (((_co.visible && _co.drawerVisible) && _v.context.$implicit.children) && _v.context.$implicit.toggled); _ck(_v, 10, 0, currVal_5); var currVal_6 = (((_co.visible && _co.drawerVisible) && _v.context.$implicit.children) && !_v.context.$implicit.toggled); _ck(_v, 12, 0, currVal_6); var currVal_7 = ((_v.context.$implicit.children && _v.context.$implicit.toggled) && _co.drawerVisible); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 5).inline; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.icon; _ck(_v, 6, 0, currVal_3); }); }
function View_SidebarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar__sidenav__bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar__sidenav__bottom__version"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" build ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 2, 0, currVal_0); }); }
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "sidebar__sidenav"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "sidebar__sidenav--opened": 0, "sidebar__sidenav--closed": 1, "sidebar__sidenav--hidden": 2 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "sidebar__sidenav__options"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_8)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar__sidenav"; var currVal_1 = _ck(_v, 2, 0, (_co.drawerVisible && _co.visible), (!_co.drawerVisible && _co.visible), !_co.visible); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.menuOptions; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.drawerVisible; _ck(_v, 7, 0, currVal_3); }, null); }
function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "sidebar__content"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "sidebar__content--opened": 0, "sidebar__content--hidden": 1, "sidebar__content--closed": 2 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 2, 0, currVal_0); var currVal_1 = "sidebar__content"; var currVal_2 = _ck(_v, 5, 0, (_co.drawerVisible && _co.visible), (!_co.visible && !_co.drawerVisible), (_co.visible && !_co.drawerVisible)); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
exports.View_SidebarComponent_0 = View_SidebarComponent_0;
function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i5.SidebarComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SidebarComponent_Host_0 = View_SidebarComponent_Host_0;
var SidebarComponentNgFactory = i1.ɵccf("sidebar", i5.SidebarComponent, View_SidebarComponent_Host_0, { drawerVisible: "drawerVisible", visible: "visible" }, {}, ["*"]);
exports.SidebarComponentNgFactory = SidebarComponentNgFactory;
