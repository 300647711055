<div class="ruckit-truck-reference-create">
  <form [formGroup]="referenceForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="modal-title ruckit-truck-reference-create__title">
      <h1 *ngIf="mode === 'create'" translate>Create New Truck Reference</h1>
      <h1 *ngIf="mode === 'edit'" translate>Edit Truck Reference</h1>
      <div class="close-action" (click)="dialogRef.close()">
        <i class="icomoon icon-close"></i>
      </div>
    </div>
    <div mat-dialog-content class="ruckit-truck-reference-create__content">
      <mat-form-field>
        <input matInput placeholder="License Plate" formControlName="licensePlate">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Truck Code" formControlName="truckCode">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Truck Number" formControlName="truckNumber">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Truck Type" formControlName="truckType">
      </mat-form-field>
    </div>
    <div class="ruckit-truck-reference-create__submit">
      <button type="submit" class="btn btn-primary" [disabled]="!referenceForm.valid">
        Submit
      </button>
    </div>
  </form>
</div>
