<batch-upload-notification-bar></batch-upload-notification-bar>
<div class="container" [ngClass]="{ 'external': external, 'ptp-fullview-hack': dateSelected }">
  <div class="upload-images">
    <div class="upload-image-dropzone" [ngClass]="{filled: savedTickets.length}">
      <div *ngIf="loader.active" class="loader-container">
        <my-loader></my-loader>
        <p *ngIf="loader.type === 'uploading'" translate>Uploading Ticket Images</p>
        <p *ngIf="loader.type === 'analyzing'" translate>{{ savedTickets.length }}/{{ uploadTotal }} Tickets Analyzed</p>
        <p *ngIf="loader.type === 'scanning'" translate>Scanning Tickets</p>
      </div>
      <ng-container *ngIf="savedTickets.length && selectedRecordIndex === null">
        <div *ngFor="let ticket of savedTickets | sort: 'uploadIndex'; let i = index" class="image-container {{ viewerType }}">
          <div class="detection-label-container" (click)="toggleView('single', i)">
            <div *ngIf="ticket.ticketNumber" class="detection-label">
              Ticket #:<br/><span class="data">{{ ticket.ticketNumber }}</span>
            </div>
            <div *ngIf="ticket.ticketDate" class="detection-label date">
              Ticket Date:<br/><span class="data">{{ ticket.ticketDate }}</span>
            </div>
            <div *ngIf="ticket.quantity" class="detection-label quantity">
              Quantity:<br/><span class="data">{{ ticket.quantity }}</span>
            </div>
          </div>
          <image-editor *ngIf="ticket.image" [id]="ticket.id" [imageUrl]="ticket.image"
                        (onSave)="saveImage($event)" (onImageClick)="toggleView('single', i)"></image-editor>
          <div *ngIf="!ticket.image" class="no-image">
            <i class="icon-no-results" (click)="toggleView('single', i)"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="scannerActive">
        <div id="dwtcontrolContainer"></div>
      </ng-container>
      <ng-container *ngIf="selectedRecordIndex !== null">
        <div class="image-container single">
          <image-editor *ngIf="savedTickets[selectedRecordIndex] && savedTickets[selectedRecordIndex].image" 
                        [id]="savedTickets[selectedRecordIndex].id"
                        [imageUrl]="savedTickets[selectedRecordIndex].image"
                        (onSave)="saveImage($event)"></image-editor>
        </div>
      </ng-container>
      <div *ngIf="!scannerActive" class="viewer-controls" [ngClass]="{'has-images': savedTickets.length}">
        <div *ngIf="savedTickets.length" class="left-controls">
          <ng-container *ngIf="savedTickets.length > 1 && !external">
            <mat-icon [ngClass]="{'active': viewerType === 'single'}" (click)="toggleView('single', 0)">insert_photo</mat-icon>
            <mat-icon [ngClass]="{'active': viewerType === 'grid'}" (click)="toggleView('grid')">grid_on</mat-icon>
          </ng-container>
          <p *ngIf="viewerType === 'grid'">{{ savedTickets.length }} TICKET<span *ngIf="savedTickets.length > 1">S</span></p>
          <p *ngIf="viewerType === 'single'">TICKET {{ selectedRecordIndex + 1 }} OF {{ savedTickets.length }}</p>
        </div>
        <input type="file" multiple name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
        <label class="upload-label" for="file" *ngIf="!savedTickets.length">
          <i class="icon-upload"></i>
          <span translate>Drag and drop images, choose images<br>or scan images to upload.</span>
        </label>
        <button class="btn btn-secondary upload" *ngIf="!savedTickets.length">CHOOSE IMAGES</button>
        <button class="btn btn-secondary upload" *ngIf="savedTickets.length">ADD MORE IMAGES</button>
        <button *ngIf="!selectedScannerIndex" class="btn btn-secondary" (click)="enableScanning()">ENABLE SCANNER</button>
        <button *ngIf="selectedScannerIndex" class="btn btn-secondary" (click)="startScan()">CONTINUE SCANNING</button>
      </div>
    </div>
  </div>
  <div class="upload-fields" [ngClass]="{ 'ptp-field-visibilty-hack': scannerActive && external }">
    <a *ngIf="!external" class="back" (click)="close()">&times; Close</a>
    <ng-container *ngIf="scannerActive">
      <i class="icon-digital-scale"></i>
      <h2>Select Scanner and Begin</h2>
      <p>Select your scanner in the dropdown below. Make sure the selection matches the brand and model of your scanner. Once your selection is make, begin the scan. <b>Be sure your tickets are loaded before starting the scan</b></p>
      <select class="scanner-list" (change)="selectScanner($event.target.value)">
        <option selected="selected">Select a Scanner</option>
        <option *ngFor="let scanner of detectedScanners; let i = index"
                [value]="i">
          {{ scanner }}
        </option>
      </select>
      <button *ngIf="!selectedScannerIndex || !detectedScanners.length" class="btn btn-warning" (click)="refreshScannerList()" [disabled]="this.loader.active">Refresh Scanner List</button>
      <button *ngIf="selectedScannerIndex && detectedScanners.length" class="btn btn-primary" (click)="startScan()" [disabled]="this.loader.active">Start Scan</button>

    </ng-container>
    <ng-container *ngIf="!scannerActive && !external">
      <div class="form-container">
        <div class="ticket-fields">
          <div class="flex-field-group">
            <div class="flex-field flex-field__fill" *ngIf="ticketNumberVisible()">
              <label translate>Ticket Number</label>
              <mat-form-field>
                <input
                  *ngIf="selectedRecordIndex === null || !savedTickets[selectedRecordIndex]"
                  type="text" aria-label="{ 'Ticket Number' | translate }"
                  (change)="model.ticketNumber = $event.target.value"
                  (focus)="fieldActive = true" (blur)="fieldActive = false"
                  matInput [(ngModel)]="model.ticketNumber" name="ticketNumber"
                >
                <input
                  *ngIf="selectedRecordIndex !== null && savedTickets[selectedRecordIndex]"
                  type="text" aria-label="{ 'Ticket Number' | translate }"
                  (change)="model.ticketNumber = $event.target.value"
                  (focus)="fieldActive = true" (blur)="fieldActive = false"
                  matInput [(ngModel)]="savedTickets[selectedRecordIndex].ticketNumber"
                  [name]="'ticketNumber-'+ selectedRecordIndex"
                >
              </mat-form-field>
            </div>

            <div class="flex-field flex-field__fill">
              <label translate>Ticket Date</label>
              <mat-form-field>
                <input
                  name="date"
                  aria-label="{ 'Date' | translate }"
                  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="model.ticketDate"
                  (focus)="fieldActive = true"
                  (blur)="fieldActive = false"
                >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>

          <div class="flex-field-group">
            <div class="flex-field quantity">
              <label translate>Quantity</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Quantity' | translate }" (focus)="fieldActive = true" (blur)="fieldActive = false"
                      matInput [(ngModel)]="model.quantity" name="quantity">
              </mat-form-field>
            </div>

            <div class="flex-field quantity-type">
              <label translate>Quantity Type</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Quantity' | translate }" (focus)="fieldActive = true" (blur)="fieldActive = false"
                      matInput [(ngModel)]="model.quantityType" name="quantity-type">
              </mat-form-field>
            </div>
          </div>
          <div class="flex-field-group">
            <div class="flex-field truck">
              <label translate>Truck Number</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Truck Number' | translate }" (focus)="fieldActive = true" (blur)="fieldActive = false"
                      matInput [(ngModel)]="model.truckNumber" name="truck-number">
              </mat-form-field>
            </div>

            <div class="flex-field quantity-type">
              <label translate>Truck Type</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Truck Type' | translate }" (focus)="fieldActive = true" (blur)="fieldActive = false"
                      matInput [(ngModel)]="model.truckType" name="truck-type">
              </mat-form-field>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="reference-label">
          <img src="assets/img/insert-link-24-px.svg" class="icon-info">

          <span>References</span>
        </div>

        <div class="customer-reference-fields">
          <div class="field-group dropdown customer-field">
            <label translate>Customer</label>
            <customer-reference-selector
              #customerReferenceSelector
              placeholder=""
              (referenceFocused)="onCustomerReferenceFocused()"
              (referenceSelected)="onCustomerReferenceSelected($event)"
              (referenceCreated)="onCustomerReferenceCreated($event)"
              (onBlur)="onCustomerReferenceBlur()"
              [(visible)]="isCustomerFieldVisible"
            ></customer-reference-selector>
          </div>
          <div (click)="onCustomerReferenceToggle()" class="customer-reference-fields__more">
            <span *ngIf="!customerFieldsVisible" class="icon-down-arrow"></span>
            <span *ngIf="customerFieldsVisible" class="icon-up-arrow"></span>
            More
          </div>
          <div *ngIf="customerFieldsVisible" class="customer-reference-fields__fields">
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>CODE</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.customerCode ? selectedCustomerReference.customerCode : ''" matInput disabled type="text" aria-label="{ 'Code' | translate }" name="customer-code" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>EMAIL</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.email ? selectedCustomerReference.email: ''" matInput disabled type="text" aria-label="{ 'EMail' | translate }" name="customer-email" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>ADDRESS 1</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.customerAddress1 ? selectedCustomerReference.customerAddress1 : ''" matInput disabled type="text" aria-label="{ 'Customer Address 1' | translate }" name="customer-address-1" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>CITY</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.city ? selectedCustomerReference.city : ''" matInput disabled type="text" aria-label="{ 'City' | translate }" name="customer-city" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>STATE</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.customerState ? selectedCustomerReference.customerState : ''" matInput disabled type="text" aria-label="{ 'State' | translate }" name="customer-state" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>ZIP CODE</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.customerZipcode ? selectedCustomerReference.customerZipcode : ''" matInput disabled type="text" aria-label="{ 'Zip Code' | translate }" name="customer-zip" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field flex-field__fill">
                <label translate>PHONE NUMBER</label>
                <mat-form-field>
                  <input [value]="!!selectedCustomerReference && selectedCustomerReference.phoneNumber ? selectedCustomerReference.phoneNumber : ''" matInput disabled type="text" aria-label="{ 'Phone Number' | translate }" name="customer-phone-number" />
                </mat-form-field>
              </div>
            </div>
          </div>
          </div>

        <div class="job-reference-fields">
          <div class="field-group dropdown job-field">
            <label translate>Job</label>
            <job-reference-selector
              #jobReferenceSelector
              hasControls="{{ true }}"
              placeholder=""
              (referenceFocused)="onJobReferenceFocused()"
              (referenceSelected)="onJobReferenceSelected($event)"
              (referenceCreated)="onJobReferenceCreated($event)"
              (onBlur)="onJobReferenceBlur()"
              [(visible)]="isJobFieldVisible"
            ></job-reference-selector>
          </div>
          <div (click)="onJobReferenceToggle()" class="job-reference-fields__more">
            <span *ngIf="!jobFieldsVisible" class="icon-down-arrow"></span>
            <span *ngIf="jobFieldsVisible" class="icon-up-arrow"></span>
            More
          </div>
          <div *ngIf="jobFieldsVisible" class="job-reference-fields__fields">
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>CODE</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.jobCode ? selectedJobReference.jobCode : ''" matInput disabled type="text" aria-label="{ 'Code' | translate }" name="job-code" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>ORDER NUMBER</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.orderNumber ? selectedJobReference.orderNumber: ''" matInput disabled type="text" aria-label="{ 'Order Number' | translate }" name="job-order-number" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>MATERIAL</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.material ? selectedJobReference.material : ''" matInput disabled type="text" aria-label="{ 'Material' | translate }" name="job-material" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>INVOICE RATE</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.invoiceRate ? selectedJobReference.invoiceRate : ''" matInput disabled type="text" aria-label="{ 'Invoice Rate' | translate }" name="job-invoice-rate" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>HAUL RATE</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.haulRate ? selectedJobReference.haulRate : ''" matInput disabled type="text" aria-label="{ 'Haul Rate' | translate }" name="job-haul-rate" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>ORIGIN</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.origin ? selectedJobReference.origin : ''" matInput disabled type="text" aria-label="{ 'Origin' | translate }" name="job-origin" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field flex-field__fill">
                <label translate>DESTINATION</label>
                <mat-form-field>
                  <input [value]="!!selectedJobReference && selectedJobReference.destination ? selectedJobReference.destination : ''" matInput disabled type="text" aria-label="{ 'Destination' | translate }" name="job-destination" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="driver-reference-fields">
          <div class="field-group dropdown driver-field">
            <label translate>Driver</label>
            <driver-reference-selector
              #driverReferenceSelector
              hasControls="{{ true }}"
              placeholder=""
              (referenceFocused)="onDriverReferenceFocused()"
              (referenceSelected)="onDriverReferenceSelected($event)"
              (referenceCreated)="onDriverReferenceCreated($event)"
              (onBlur)="onDriverReferenceBlur()"
              [(visible)]="isDriverFieldVisible"
            ></driver-reference-selector>
          </div>
          <div (click)="onDriverReferenceToggle()" class="driver-reference-fields__more">
            <span *ngIf="!driverFieldsVisible" class="icon-down-arrow"></span>
            <span *ngIf="driverFieldsVisible" class="icon-up-arrow"></span>
            More
          </div>
          <div *ngIf="driverFieldsVisible" class="driver-reference-fields__fields">
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>CODE</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.driverCode ? selectedDriverReference.driverCode : ''" matInput disabled type="text" aria-label="{ 'Code' | translate }" name="driver-code" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>TRUCK NUMBER</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.truckNumber ? selectedDriverReference.truckNumber: ''" matInput disabled type="text" aria-label="{ 'Truck Number' | translate }" name="driver-truck-number" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>TRUCK TYPE</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.truckType ? selectedDriverReference.truckType : ''" matInput disabled type="text" aria-label="{ 'Truck Type' | translate }" name="driver-truck-type" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>CDL</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.cdl ? selectedDriverReference.cdl : ''" matInput disabled type="text" aria-label="{ 'CDL' | translate }" name="driver-cdl" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field">
                <label translate>CARRIER</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.haulRate ? selectedDriverReference.haulRate : ''" matInput disabled type="text" aria-label="{ 'Carrier' | translate }" name="driver-carrier" />
                </mat-form-field>
              </div>
              <div class="flex-field">
                <label translate>EMAIL</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.email ? selectedDriverReference.email : ''" matInput disabled type="text" aria-label="{ 'Email' | translate }" name="driver-email" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-field-group">
              <div class="flex-field flex-field__fill">
                <label translate>PHONE NUMBER</label>
                <mat-form-field>
                  <input [value]="!!selectedDriverReference && selectedDriverReference.phoneNumber ? selectedDriverReference.phoneNumber : ''" matInput disabled type="text" aria-label="{ 'Phone Number' | translate }" name="driver-phone-number" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="form-actions" *ngIf="savedTickets.length <= 1">
        <button class="btn btn-primary" (click)="batchRecordOperation()" translate>
          <span *ngIf="!editMode">Create</span><span *ngIf="editMode">Edit</span> Ticket and Close
        </button>
        <button class="btn btn-default" (click)="batchRecordOperation(true)" translate>
          <span *ngIf="!editMode">Create</span><span *ngIf="editMode">Edit</span> and Add Another
        </button>
      </div>
      <div class="form-actions" *ngIf="selectedRecordIndex === null && savedTickets.length > 1">
        <button type="submit" class="btn btn-primary" [disabled]="loader.active" (click)="batchRecordOperation()" translate>
          <span *ngIf="editMode">Edit Tickets</span>
          <span *ngIf="!editMode">Finish Editing</span>
        </button>
        <button *ngIf="!editMode" [disabled]="loader.active" class="btn btn-primary-alt" (click)="batchRecordOperation(false, false)" translate>
          Edit Tickets
        </button>
        <button *ngIf="uploadFiles.length" class="btn btn-default" (click)="reset()" translate>
          Start Over
        </button>
        <button *ngIf="editMode" class="btn btn-default" (click)="close()" translate>
          Cancel
        </button>
      </div>
      <div class="form-actions" *ngIf="selectedRecordIndex !== null && savedTickets.length > 1">
        <button class="btn btn-primary" (click)="close(savedTickets[selectedRecordIndex])" translate>
          Finish
        </button>
        <button *ngIf="selectedRecordIndex > 0" class="btn btn-primary-alt" (click)="previousTicket(savedTickets[selectedRecordIndex])" translate>
          Prev Ticket
        </button>
        <button *ngIf="savedTickets.length !== (selectedRecordIndex + 1)" class="btn btn-primary-alt" (click)="nextTicket(savedTickets[selectedRecordIndex])" translate>
          Next Ticket
        </button>
        <a *ngIf="savedTickets.length !== (selectedRecordIndex + 1)" (click)="reset()" translate>
          Start Over
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!scannerActive && external && !dateSelected">
      <div class="form-container">
        <div class="ticket-fields">
          <div class="flex-field-group">
            <div class="flex-field flex-field__fill">
              <label translate>Ticket Date</label>
              <mat-form-field>
                <input
                  name="date"
                  aria-label="{ 'Date' | translate }"
                  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="model.ticketDate"
                  (focus)="fieldActive = true"
                  (blur)="fieldActive = false"
                >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
