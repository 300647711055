"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".ruckit-driver-reference-create[_ngcontent-%COMP%]{width:100%;height:565px;display:flex;flex-direction:column}.ruckit-driver-reference-create__content[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:space-between;align-content:space-between;padding-top:25px;width:100%;max-height:435px;overflow-y:scroll}.ruckit-driver-reference-create__content[_ngcontent-%COMP%]   .mat-input-element[_ngcontent-%COMP%]{border:none;padding-top:25px;padding-bottom:25px;border-bottom:1px solid #aaa;border-radius:0;margin-bottom:25px;width:100%}.ruckit-driver-reference-create__submit[_ngcontent-%COMP%]{border-top:1px solid #eee;display:flex;padding:15px 15px 0;width:100%}.ruckit-driver-reference-create__submit[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:100%}  .mat-form-field-appearance-legacy .mat-form-field-infix{padding:0;border:none}"];
exports.styles = styles;
