"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SearchComponent = /** @class */ (function () {
    function SearchComponent() {
        this.searchTermChange = new core_1.EventEmitter();
        this.searchOpen = false;
    }
    SearchComponent.prototype.ngOnInit = function () {
        this.placeholder = (this.searchBarType === 'map') ? '' : 'Search';
    };
    SearchComponent.prototype.openSearchBar = function (bool) {
        this.searchOpen = bool;
        if (bool) {
            this.placeholder = 'Search';
            this.searchInput.nativeElement.focus();
        }
        else {
            this.placeholder = '';
        }
    };
    SearchComponent.prototype.changeTerm = function () {
        this.searchTermChange.emit(this.searchTerm);
    };
    SearchComponent.prototype.clearTerm = function () {
        this.searchTerm = '';
        this.searchTermChange.emit(this.searchTerm);
    };
    return SearchComponent;
}());
exports.SearchComponent = SearchComponent;
