import { QuickbooksCompany } from './quickbooks';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class QuickbooksCompanySerializer {
  fromJson(json: any): QuickbooksCompany {
    json = camelcaseKeysDeep(json);
    const quickbooksCompany = new QuickbooksCompany();
    if (!json) { return quickbooksCompany; }

    Object.keys(json).forEach(key => {
      quickbooksCompany[key] = json[key];
    });

    return quickbooksCompany;
  }

  toJson(company: QuickbooksCompany): any {
    let json: { [key: string]: any } = {
      id: company.id,
      organization: company.organization,
      owner: company.owner,
      filePath: company.filePath
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
};
