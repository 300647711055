import { User } from '../users/user';
import { Resource } from '../shared/resource';

export class Organization extends Resource {
  id!: string;
  createdAt!: string;
  lastModified!: string;
  name!: string;
  email!: string;
  contactName!: string;
  inviteCode!: string;
  image!: string;
  street!: string;
  address2!: string;
  city!: string;
  state!: string;
  zipcode!: string;
  phoneNumber!: string;
  faxNumber!: string;
  paymentTerms!: string;
  primaryContact!: User;
  billingContact!: User;
  billingAddress1!: string;
  billingAddress2!: string;
  billingCity!: string;
  billingState!: string;
  billingZipcode!: string;
  billingPhoneNumber!: string;
  billingEmail!: string;
  startingInvoiceNumber!: string;
  remittanceInfo!: string;
  driverFleetCanViewRates!: boolean;
  driverFleetQuickTicket!: boolean;
  dvirRequired!: boolean;
  driverFleetRequireAssignmentConfirmation!: boolean;
  avoidTolls!: boolean;
  activeTrackingEnabled!: boolean;
  canCreateJobs!: boolean;
  invoiceablePunchcardCount!: string;
  invoiceableTripCount!: string;
  posEnabled!: boolean;
  advBillingEnabled!: boolean;
  allDriversEnabled!: boolean;
  driverFleetCanViewOrderDetails!: boolean;
  leasedOrg!: boolean;
  hasLeasedOrgs!: boolean;
  startingTicketNumber!: string;
  uniqueBillingId!: string;
  qrEnabled!: boolean;
  hasQuickbooksEnabled!: boolean;
  features!: any;
  enabledFeatures?: string[];
}
