import { Organization } from './organization';
import { UserSerializer } from '../users/user.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class OrganizationSerializer {
  fromJson(json: any): Organization {
    json = camelcaseKeysDeep(json);
    const organization = new Organization();
    if (!json) { return organization; }

    organization.id = json.id;
    organization.name = json.name;
    organization.email = json.email;
    organization.image = json.image;
    organization.contactName = json.contactName;
    if (json.primaryContact && typeof (json.primaryContact) === 'object') {
      organization.primaryContact = (new UserSerializer).fromJson(json.primaryContact);
    } else if (json.primaryContact) {
      organization.primaryContact = (new UserSerializer).fromJson({ id: json.primaryContact });
    } else {
      organization.primaryContact = (new UserSerializer).fromJson({});
    }
    organization.createdAt = json.createdAt;
    organization.lastModified = json.lastModified;
    organization.street = json.street;
    organization.address2 = json.address2;
    organization.city = json.city;
    organization.state = json.state;
    organization.inviteCode = json.inviteCode;
    organization.zipcode = json.zipcode;
    organization.phoneNumber = json.phoneNumber;
    organization.faxNumber = json.faxNumber;
    organization.paymentTerms = json.paymentTerms;
    if (json.billingContact) {
      organization.billingContact = (new UserSerializer).fromJson(json.billingContact);
    }
    organization.billingAddress1 = json.billingAddress1;
    organization.billingAddress2 = json.billingAddress2;
    organization.billingCity = json.billingCity;
    organization.billingState = json.billingState;
    organization.billingZipcode = json.billingZipcode;
    organization.billingPhoneNumber = json.billingPhoneNumber;
    organization.billingEmail = json.billingEmail;
    organization.startingInvoiceNumber = json.startingInvoiceNumber;
    organization.remittanceInfo = json.remittanceInfo;
    organization.driverFleetCanViewRates = json.driverFleetCanViewRates;
    organization.driverFleetQuickTicket = json.driverFleetQuickTicket;
    organization.activeTrackingEnabled = json.activeTrackingEnabled;
    organization.canCreateJobs = json.canCreateJobs;
    organization.invoiceablePunchcardCount = json.invoiceablePunchcardCount;
    organization.invoiceableTripCount = json.invoiceableTripCount;
    organization.posEnabled = json.posEnabled;
    organization.advBillingEnabled = json.advBillingEnabled;
    organization.allDriversEnabled = json.allDriversEnabled;
    organization.driverFleetCanViewOrderDetails = json.driverFleetCanViewOrderDetails;
    organization.leasedOrg = json.leasedOrg;
    organization.hasLeasedOrgs = json.hasLeasedOrgs;
    organization.startingTicketNumber = json.startingTicketNumber;
    organization.dvirRequired = json.dvirRequired;
    organization.driverFleetRequireAssignmentConfirmation = json.driverFleetRequireAssignmentConfirmation;
    organization.avoidTolls = json.avoidTolls;
    organization.uniqueBillingId = json.uniqueBillingId;
    organization.qrEnabled = json.qrEnabled;
    organization.hasQuickbooksEnabled = json.hasQuickbooksEnabled;
    organization.features = json.features;
    if (organization.features) {
      organization.enabledFeatures = Object.keys(organization.features) ? Object.keys(organization.features) : [];
    }

    return organization;
  }

  toJson(organization: Organization): any {
    let json: { [key: string]: any } = {
      id: organization.id,
      createdAt: organization.createdAt,
      lastModified: organization.lastModified,
      name: organization.name,
      email: organization.email,
      contactName: organization.contactName,
      inviteCode: organization.inviteCode,
      image: organization.image,
      street: organization.street,
      address2: organization.address2,
      city: organization.city,
      state: organization.state,
      zipcode: organization.zipcode,
      phoneNumber: organization.phoneNumber,
      faxNumber: organization.faxNumber,
      paymentTerms: organization.paymentTerms,
      primaryContact: organization.primaryContact,
      billingContact: organization.billingContact,
      billingAddress1: organization.billingAddress1,
      billingAddress2: organization.billingAddress2,
      billingCity: organization.billingCity,
      billingState: organization.billingState,
      billingZipcode: organization.billingZipcode,
      billingPhoneNumber: organization.billingPhoneNumber,
      billingEmail: organization.billingEmail,
      startingInvoiceNumber: organization.startingInvoiceNumber,
      remittanceInfo: organization.remittanceInfo,
      driverFleetCanViewRates: organization.driverFleetCanViewRates,
      driverFleetQuickTicket: organization.driverFleetQuickTicket,
      dvirRequired: organization.dvirRequired,
      driverFleetRequireAssignmentConfirmation: organization.driverFleetRequireAssignmentConfirmation,
      avoidTolls: organization.avoidTolls,
      activeTrackingEnabled: organization.activeTrackingEnabled,
      canCreateJobs: organization.canCreateJobs,
      invoiceablePunchcardCount: organization.invoiceablePunchcardCount,
      invoiceableTripCount: organization.invoiceableTripCount,
      posEnabled: organization.posEnabled,
      advBillingEnabled: organization.advBillingEnabled,
      allDriversEnabled: organization.allDriversEnabled,
      driverFleetCanViewOrderDetails: organization.driverFleetCanViewOrderDetails,
      leasedOrg: organization.leasedOrg,
      hasLeasedOrgs: organization.hasLeasedOrgs,
      startingTicketNumber: organization.startingTicketNumber,
      uniqueBillingId: organization.uniqueBillingId,
      qrEnabled: organization.qrEnabled,
      hasQuickbooksEnabled: organization.hasQuickbooksEnabled,
      features: organization.features
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
