"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./checkbox-cell.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./checkbox-cell.component");
var styles_CheckboxCell = [i0.styles];
var RenderType_CheckboxCell = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxCell, data: {} });
exports.RenderType_CheckboxCell = RenderType_CheckboxCell;
function View_CheckboxCell_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-accept"]], null, null, null, null, null))], null, null); }
function View_CheckboxCell_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-none"]], null, null, null, null, null))], null, null); }
function View_CheckboxCell_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.valueChange(!_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxCell_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxCell_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.value; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_CheckboxCell_0 = View_CheckboxCell_0;
function View_CheckboxCell_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "checkbox-cell", [], null, null, null, View_CheckboxCell_0, RenderType_CheckboxCell)), i1.ɵdid(1, 49152, null, 0, i3.CheckboxCell, [], null, null)], null, null); }
exports.View_CheckboxCell_Host_0 = View_CheckboxCell_Host_0;
var CheckboxCellNgFactory = i1.ɵccf("checkbox-cell", i3.CheckboxCell, View_CheckboxCell_Host_0, {}, {}, []);
exports.CheckboxCellNgFactory = CheckboxCellNgFactory;
