"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var TicketGridDateCell = /** @class */ (function () {
    function TicketGridDateCell() {
        this.dateformat = 'MM/DD/YYYY';
    }
    TicketGridDateCell.prototype.agInit = function (params) {
        this.params = params;
        if (params.colDef.dateformat) {
            this.dateformat = params.colDef.dateformat;
        }
        var value = this.params && this.params.value && typeof this.params.value === 'string' ?
            this.params.value.split('T')[0] : '';
        var date = moment(value);
        this.params.value = date.isValid() ? date.format(this.dateformat) : value;
    };
    TicketGridDateCell.prototype.refresh = function () {
        return false;
    };
    return TicketGridDateCell;
}());
exports.TicketGridDateCell = TicketGridDateCell;
