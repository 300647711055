import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { zip, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import * as moment from 'moment';

import { AgGridNg2 } from 'ag-grid-angular';
import { ValueSetterParams, GridReadyEvent, ColumnApi, GridApi } from 'ag-grid-community';
import { CellContextMenuEvent } from 'ag-grid-community/src/ts/events';

import { SelectAllHeader } from '../../shared/grid/select-all-header.component';
import { AutocompleteCell } from '../../shared/grid/autocomplete-cell.component';
import { CheckboxCell } from '../../shared/grid/checkbox-cell.component';
import { TicketGridDateCell } from '../../tickets/ticket-date-cell.component';
import { BulkActionDialogComponent } from '../../shared/grid/bulk-action-dialog.component';

import { AuthenticationService } from '../../shared/authentication.service';
import { Invoice } from '../invoice/invoice';
import { ReportProfile } from '../report/report';
import { InvoiceService } from '../invoice/invoice.service';
import { NoResultsComponent } from '../../../app/shared/no-results/no-results.component';

@Component({
  selector: 'invoice-grid',
  templateUrl: './invoice-grid.component.html',
  styleUrls: ['../../../style/grid.scss', './invoice-grid.component.scss'],
  providers: [AuthenticationService, InvoiceService],
})
export class InvoiceGridComponent {
  user = this.authenticationService.user();
  loading = true;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  quickbooksEnabled = false;

  @ViewChild('invoiceGrid') invoiceGrid!: AgGridNg2;
  columnApi!: ColumnApi;
  gridApi!: GridApi;

  frameworkComponents = {
    autocompleteCell: AutocompleteCell,
    selectAllHeader: SelectAllHeader,
    customNoRowsOverlay: NoResultsComponent,
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'invoices',
  };

  columnDefs = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 70,
      editable: false,
      checkboxSelection: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.reportService,
        selected: this.reportService.allSelected
      }
    },
    {
      headerName: 'Created Date',
      field: 'reportDate',
      filter: 'agDateColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      editable: false,
      cellRendererFramework: TicketGridDateCell,
      width: 140,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      filter: 'agDateColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      editable: false,
      cellRendererFramework: TicketGridDateCell,
      width: 140,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Invoice #',
      field: 'reportNumber',
      filter: false,
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Customer',
      field: 'customer.name',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.reportService.getValuesForFieldQuery('customerName').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
      },
      editable: false,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      cellRenderer: (params: any): string => {
        const value: ReportProfile = params.value;

        if (!value) {
          return '';
        }

        const firstName = value.firstName ? value.firstName : '';
        const lastName = value.lastName ? value.lastName : '';

        return `${firstName} ${lastName}`;
      },
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Last Edited',
      field: 'lastModified',
      cellRenderer: (params: any): any => {
        const lastModified: string = params.value;
        const lastModifiedBy: ReportProfile = params.data && params.data.lastModifiedBy;

        if (!lastModified || !lastModifiedBy) {
          return '';
        }

        const firstName = lastModifiedBy.firstName ? lastModifiedBy.firstName : '';
        const lastName = lastModifiedBy.lastName ? lastModifiedBy.lastName : '';

        return moment(lastModified).format('MM/DD/YY h:mma') + ` ${firstName} ${lastName}`;
      },
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Quickbooks Sync',
      field: 'lastQuickbooksSync',
      cellRenderer: (params: any): any => {
        return params.value ? moment(params.value).format('MM/DD/YY h:mma') : '';
      },
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
      hide: true
    },
    {
      headerName: 'Total',
      field: 'total',
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    },
    {
      headerName: 'Sent',
      field: 'sent',
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        service: this.reportService,
        field: 'sent'
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Sent';
          }

          return 'Not Sent';
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
      },
      editable: false,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'PDF',
      field: 'pdfUrl',
      cellRenderer: linkRenderer,
      editable: false,
      sortable: true,
      resizable: true,
    }
  ];

  rowModelType = 'serverSide';
  cacheBlockSize = 100;
  maxBlocksInCache = 10;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private reportService: InvoiceService,
    private bulkActionDialog: MatDialog
  ) {
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.loading = true;
      this.search = search;
      this.reportService.search = this.search;
      this.refreshTable();
    });
  }

  getContextMenuItems(params: any) {
    return [
      {
        name: 'Edit',
        action: () => this.onEditInvoice(params)
      },
      {
        name: 'Delete',
        action: () => this.onDeleteInvoice(params)
      },
      'separator',
      {
        name: 'CSV Export',
        action: () => this.onExport('csv')
      },
      {
        name: 'Excel Export',
        action: () => this.onExport('excel')
      },
    ];
  }

  onEditInvoice(params: CellContextMenuEvent) {
    if (!params.api) {
      return;
    }
    let selected = params.api.getSelectedRows()[0];
    if (!selected) {
      selected = params.node.data;
    }
    this.router.navigate(['invoices', selected.id, 'edit']);
  }

  onDeleteInvoice(params: CellContextMenuEvent) {
    if (!params.api) {
      return;
    }
    let selected = params.api.getSelectedRows();
    if (!selected || !selected.length) {
      selected = [params.node.data];
      params.node.setSelected(true);
    }
    const dialog = this.bulkActionDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected,
        type: 'Invoice',
      },
    });

    dialog.componentInstance.callback = () => {
      const jobs = zip(
        ...selected.map((j: Invoice) => {
          return this.deleteInvoice(j);
        })
      );

      jobs.subscribe(() => {
        this.refreshTable(true);
      });
    };
  }

  deleteInvoice(invoice: Invoice): Observable<any> {
    return this.reportService.remove(invoice);
  }

  onExport(type: string) {
    let exportFlags = {
      onlySelected: !this.reportService.allSelected,
      processCellCallback: (cell: any) => {
        // if a name object is detected, lets format for the export
        if (cell.value && cell.value.firstName) {
          return cell.value.firstName + ' ' + cell.value.lastName;
        } else { return cell.value; }
      }
    };
    if (type === 'csv') {
      this.gridApi.exportDataAsCsv(exportFlags);
    } else if (type === 'excel') {
      this.gridApi.exportDataAsExcel(exportFlags);
    }
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  onCellValueChanged(e: ValueSetterParams): void {
    const invoice: Invoice = { ...e.data };

    delete invoice.selected;

    this.reportService.save(invoice).subscribe();
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.columnApi || !e.api) {
      return;
    }

    this.columnApi = e.columnApi;
    this.gridApi = e.api;
    this.quickbooksEnabled = this.user.organization &&
                              this.user.organization.features &&
                              this.user.organization.features.quickbooks &&
                              this.user.organization.hasQuickbooksEnabled;
    this.columnApi.setColumnVisible('lastQuickbooksSync', this.quickbooksEnabled);
    this.invoiceGrid.gridOptions.getContextMenuItems = (params) => this.getContextMenuItems(params);

    this.gridApi.setServerSideDatasource(this.reportService);
  }

  onFirstDataRendered(): void {
    this.autoSizeAll();
  }

  changeSearch(term?: string) {
    this.searchChanged.next(term);
  }

  autoSizeAll(): void {
    const allColumnIds: string[] = [];

    this.columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  onAddInvoiceClick(): void {
    this.reportService.save({ type: 'invoice' }).subscribe(() => this.refreshTable());
  }
}

function linkRenderer(params: any) {
  return params.value ? '<a href="' + params.value + '" target="_blank" translate>View</a>' : '';
}
