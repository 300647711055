import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams } from '@angular/http';

import { environment } from '../../environments/environment';
import { TicketFilter } from './ticket.filter';
import { requestHeaders } from '../shared/api.service';

@Injectable()
export class TicketFilterService {
  baseUrl = environment.serverUrl;
  public nextUris: { [key: string]: any } = {};

  constructor(private http: Http) { }

  getFilters(filterType: string, search = ''): Observable<TicketFilter[]> {
    let params: URLSearchParams = new URLSearchParams();
    params.set('page_size', '6');
    if (search) { params.set('search', search); }

    let filtersUrl = this.baseUrl + 'scaletickets/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      search: params
    }).pipe(
      map(res => { return this.extractData(filterType, res); }),
      catchError(this.handleError)
    );
  }

  getNext(filterType: string): Observable<TicketFilter[]> | null {
    if (this.nextUris[filterType]) {
      return this.http.get(this.nextUris[filterType], { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(filterType, res); }),
        catchError(this.handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(filterType: string, res: Response) {
    let resObj = res.json();
    this.nextUris[filterType] = resObj.next;
    let body = resObj.results;
    if (body) {
      return body.map((filter: any) => {
        return new TicketFilter(filter);
      });
    } else {
      return {};
    }
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
