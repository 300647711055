"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".readonly-cell[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-content:space-between;justify-content:space-between;text-align:center;width:100%;margin-left:-24px;margin-right:-24px;padding:0 1px}.readonly-cell__value[_ngcontent-%COMP%]{text-align:center;height:5px}.readonly-cell__value[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{background-color:#f3f3f3;padding:5px 7px;border-radius:5px}.readonly-cell__lock__icon[_ngcontent-%COMP%]{width:10px;height:10px;-o-object-fit:contain;object-fit:contain}"];
exports.styles = styles;
