"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ticket_service_1 = require("../../tickets/ticket.service");
var operators_1 = require("rxjs/operators");
var GridImageToolPanel = /** @class */ (function () {
    function GridImageToolPanel(ticketService) {
        this.ticketService = ticketService;
        this.editing = false;
        this.imageUrl = '';
        this.imageVisible = false;
    }
    GridImageToolPanel.prototype.agInit = function (params) {
        this.params = params;
        this.params.api.addEventListener('rowClicked', this.onRowClick.bind(this));
        this.params.api.addEventListener('toolPanelVisibleChanged', this.onResize.bind(this));
        this.params.api.addEventListener('dragEvent', this.onResize.bind(this));
    };
    GridImageToolPanel.prototype.onRowClick = function (e) {
        this.ticket = e.data;
        this.imageUrl = e.data.image;
        this.editing = false;
    };
    GridImageToolPanel.prototype.onCellFocused = function (e) {
        var row = this.params.api.getDisplayedRowAtIndex(e.rowIndex);
        this.imageUrl = row && row.data && row.data.image ? row.data.image : '';
    };
    GridImageToolPanel.prototype.refresh = function () { };
    GridImageToolPanel.prototype.onPanelDisplay = function (e) {
        if (!e.api) {
            return;
        }
        var visible = e.api.isToolPanelShowing();
        this.imageVisible = visible;
    };
    GridImageToolPanel.prototype.onResize = function (e) {
        var itemType = e.type;
    };
    GridImageToolPanel.prototype.onSave = function (updateData) {
        var _this = this;
        this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
            .pipe(operators_1.first()).subscribe(function (updatedTicket) {
            var row = _this.params.api.getRowNode(updateData.id);
            _this.params.api.flashCells({
                rowNodes: [row],
                columns: ['ticketImage']
            });
            row.setData(updatedTicket);
            _this.ticket = updatedTicket;
            _this.imageUrl = updateData.imageUrl;
            _this.editing = false;
        });
    };
    return GridImageToolPanel;
}());
exports.GridImageToolPanel = GridImageToolPanel;
