import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, Component } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'readonly-cell',
  templateUrl: './readonly-cell.component.html',
  styleUrls: ['./readonly-cell.component.scss'],
})
export class ReadOnlyCell implements ICellEditorAngularComp, AfterViewInit {
  params?: ICellEditorParams;
  value?: string;

  ngAfterViewInit() { }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue() {
    throw new Error("Method not implemented.");
  }
}
