import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'ticket-grid-date-cell',
  template: `{{ params?.value }}`
})
export class TicketGridDateCell implements AgRendererComponent {
  public params: any;
  dateformat = 'MM/DD/YYYY';

  agInit(params: any): void {
    this.params = params;

    if (params.colDef.dateformat) { this.dateformat = params.colDef.dateformat; }

    const value = this.params && this.params.value && typeof this.params.value === 'string' ?
                  this.params.value.split('T')[0] : '';

    const date = moment(value);

    this.params.value = date.isValid() ? date.format(this.dateformat) : value;
  }

  refresh(): boolean {
    return false;
  }
}
