"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h2[_ngcontent-%COMP%]{margin:0 0 15px}.organization-container[_ngcontent-%COMP%]{padding:30px}.form-block[_ngcontent-%COMP%]{margin-bottom:30px;display:flex;flex:1 1 auto;padding:30px;border-radius:4px;background-color:#fff;border:1px solid #e6eaee}.form-block[_ngcontent-%COMP%]   .left-side[_ngcontent-%COMP%]{flex:0 1 600px;padding-right:60px;border-right:1px solid #dfe2e5;margin-right:60px}.form-block[_ngcontent-%COMP%]   .right-side[_ngcontent-%COMP%]{flex:1 1 auto;display:flex;align-items:center}.form-block[_ngcontent-%COMP%]   .logo-image[_ngcontent-%COMP%]{display:flex;margin-bottom:10px;flex-direction:column;align-items:center;height:150px}.flex-field-group[_ngcontent-%COMP%]   .flex-field[_ngcontent-%COMP%]{width:100%}.flex-field-group[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin-right:auto}.organization-state[_ngcontent-%COMP%]{margin-left:20px}.upload-image-dropzone[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:100%;height:auto;max-height:100%}"];
exports.styles = styles;
