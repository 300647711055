"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var libphonenumber_js_1 = require("libphonenumber-js");
var PhoneNumberPipe = /** @class */ (function () {
    function PhoneNumberPipe() {
    }
    PhoneNumberPipe.prototype.transform = function (value) {
        var phoneNumber = libphonenumber_js_1.parsePhoneNumberFromString(value, 'US');
        if (!phoneNumber || !phoneNumber.isValid() || !phoneNumber.isPossible()) {
            return value;
        }
        return phoneNumber.format('NATIONAL');
    };
    return PhoneNumberPipe;
}());
exports.PhoneNumberPipe = PhoneNumberPipe;
