"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["ruckit-button[_ngcontent-%COMP%]{display:inline-block}md-icon[_ngcontent-%COMP%]{font-size:24px;margin-right:24px}.ruckit-button[_ngcontent-%COMP%]{display:inline-block;padding:0 20px;height:36px;line-height:36px;vertical-align:middle;margin:0 auto;border-radius:4px;text-align:center}.ruckit-button.full-width[_ngcontent-%COMP%]{width:100%}.ruckit-button.loading[_ngcontent-%COMP%]{content:''}.ruckit-button.disabled[_ngcontent-%COMP%]{pointer-events:none;cursor:default;opacity:.3;background-color:#00c853}.ruckit-button.disabled.error[_ngcontent-%COMP%]{background-color:#d0011b}.ruckit-button.secondary[_ngcontent-%COMP%]{background-color:#2a81d7}.ruckit-button.secondary[_ngcontent-%COMP%]:hover{background-color:#69a6e2}.ruckit-button.secondary.active[_ngcontent-%COMP%], .ruckit-button.secondary[_ngcontent-%COMP%]:active{background-color:#2167ac}.ruckit-button.secondary.disabled[_ngcontent-%COMP%]{pointer-events:none;cursor:default;opacity:.3;background-color:#2a81d7}"];
exports.styles = styles;
