import { Ticket, ExternalTicket } from './ticket';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TicketSerializer {
  fromJson(json: any): Ticket {
    json = camelcaseKeysDeep(json);
    const ticket = new Ticket();
    if (!json) { return ticket; }

    ticket.id = json.id;
    ticket.organization = json.organization;
    ticket.ticketDateRaw = json.ticketDateRaw;
    ticket.ticketDate = json.ticketDate;
    ticket.ticketNumber = json.ticketNumber;
    ticket.ticketOrigin = json.ticketOrigin;
    ticket.driver = json.driver;
    ticket.driverName = json.driverName;
    ticket.truck = json.truck;
    ticket.truckNumber = json.truckNumber;
    ticket.truckType = json.truckType;
    ticket.customer = json.customer;
    ticket.customerName = json.customerName;
    ticket.job = json.job;
    ticket.jobName = json.jobName;
    ticket.jobCode = json.jobCode;
    ticket.image = json.image && json.image.replace('#', '%23');
    ticket.createdAt = json.createdAt;
    ticket.weight = json.weight;
    ticket.material = json.material;
    ticket.carrierName = json.carrierName;
    ticket.quantity = json.quantity;
    ticket.quantityType = json.quantityType;
    ticket.verified = json.verified;
    ticket.invoiceRate = json.invoiceRate;
    ticket.total = json.total;
    ticket.invoiced = json.invoiced;
    ticket.ticketNotes = json.ticketNotes;
    ticket.orderNumber = json.orderNumber;
    ticket.origin = json.origin;
    ticket.haulRate = json.haulRate;
    ticket.destination = json.destination;
    ticket.billable = json.billable;
    ticket.ticketMetas = json.ticketMetas;
    ticket.createdBy = json.createdBy;
    ticket.rate = json.rate;
    ticket.locationOwner = json.locationOwner;
    ticket.tripReviewedStatus = json.tripReviewedStatus;
    ticket.tripReviewedBy = json.tripReviewedBy;
    ticket.tripReviewedAt = json.tripReviewedAt;
    ticket.tripPlantQc = json.tripPlantQc;
    ticket.tripPlantQcReviewer = json.tripPlantQcReviewer;
    ticket.tripPlantVt = json.tripPlantVt;
    ticket.tripPlantVtReviewer = json.tripPlantVtReviewer;
    ticket.tripRoadwayQc = json.tripRoadwayQc;
    ticket.tripRoadwayQcReviewer = json.tripRoadwayQcReviewer;
    ticket.tripRoadwayVt = json.tripRoadwayVt;
    ticket.tripRoadwayVtReviewer = json.tripRoadwayVtReviewer;

    return ticket;
  }

  toJson(ticket: Ticket): any {
    let json: { [key: string]: any } = {
      id: ticket.id,
      organization: ticket.organization,
      ticketDateRaw: ticket.ticketDateRaw,
      ticketDate: ticket.ticketDate,
      ticketNumber: ticket.ticketNumber,
      ticketOrigin: ticket.ticketOrigin,
      driverName: ticket.driverName,
      truck: ticket.truck,
      truckNumber: ticket.truckNumber,
      truckType: ticket.truckType,
      customerName: ticket.customerName,
      jobName: ticket.jobName,
      jobCode: ticket.jobCode,
      image: ticket.image,
      createdAt: ticket.createdAt,
      weight: ticket.weight,
      material: ticket.material,
      carrierName: ticket.carrierName,
      quantity: ticket.quantity,
      quantityType: ticket.quantityType,
      verified: ticket.verified,
      invoiceRate: ticket.invoiceRate,
      total: ticket.total,
      invoiced: ticket.invoiced,
      ticketNotes: ticket.ticketNotes,
      orderNumber: ticket.orderNumber,
      origin: ticket.origin,
      haulRate: ticket.haulRate,
      billable: ticket.billable,
      destination: ticket.destination,
      createdBy: ticket.createdBy,
      rate: ticket.rate,
      locationOwner: ticket.locationOwner,
      tripReviewedStatus: ticket.tripReviewedStatus,
      tripReviewedBy: ticket.tripReviewedBy,
      tripReviewedAt: ticket.tripReviewedAt,
      tripPlantQc: ticket.tripPlantQc,
      tripPlantQcReviewer: ticket.tripPlantQcReviewer,
      tripPlantVt: ticket.tripPlantVt,
      tripPlantVtReviewer: ticket.tripPlantVtReviewer,
      tripRoadwayQc: ticket.tripRoadwayQc,
      tripRoadwayQcReviewer: ticket.tripRoadwayQcReviewer,
      tripRoadwayVt: ticket.tripRoadwayVt,
      tripRoadwayVtReviewer: ticket.tripRoadwayVtReviewer
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}

export class ExternalTicketSerializer {
  fromJson(json: any): ExternalTicket {
    json = camelcaseKeysDeep(json);
    const ticket = new ExternalTicket();
    if (!json) { return ticket; }

    ticket.quantity = json.quantity;
    ticket.quantityType = json.unit;
    ticket.ticketNumber = json.number;
    ticket.contextId = json.contextId;
    ticket.image = json.paperTicketImageUrl;
    ticket.ticketDate = json.signedAt;

    return ticket;
  }

  toJson(ticket: ExternalTicket): any {
    let json: { [key: string]: any } = {
      quantity: ticket.quantity,
      unit: ticket.quantityType,
      number: ticket.ticketNumber,
      contextId: ticket.contextId,
      paperTicketImageUrl: ticket.image,
      signedAt: ticket.ticketDate,
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
