<form #tripExport="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Export to CSV</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content" *ngIf="exported">
      <p class="export-submitted">
         Your files are on the way and should arrive in your email in a few minutes.
      </p>
    </div>

    <div class="body form-content" *ngIf="!exported">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group ticket-images" fxFlex="100">
          <label>
            Your files failed to create. Please try again.
          </label>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button class="btn" [ngClass]="{'btn-primary': exported, 'btn-danger': !exported}"
            (click)="dialogRef.close()">
      {{ exportButtonLabel }}
    </button>
  </div>

</form>
