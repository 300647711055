"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var filter_1 = require("./filter");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var FilterValuesSerializer = /** @class */ (function () {
    function FilterValuesSerializer() {
    }
    FilterValuesSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var filterValues = new filter_1.FilterValues();
        if (!json) {
            return filterValues;
        }
        Object.keys(json).forEach(function (key) {
            filterValues[key] = json[key];
        });
        return filterValues;
    };
    return FilterValuesSerializer;
}());
exports.FilterValuesSerializer = FilterValuesSerializer;
;
