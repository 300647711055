"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var phone_number_pipe_1 = require("./../../../shared/pipes/phone-number.pipe");
var customer_reference_service_1 = require("../customer-reference.service");
var ag_grid_angular_1 = require("ag-grid-angular");
var shared_1 = require("../../../../app/shared");
var rxjs_1 = require("rxjs");
var material_1 = require("@angular/material");
var customer_reference_create_modal_component_1 = require("../customer-reference-create-modal/customer-reference-create-modal.component");
var operators_1 = require("rxjs/operators");
var preferences_1 = require("../../../shared/preferences/preferences");
var preferences_service_1 = require("../../../shared/preferences/preferences.service");
var notifications_service_1 = require("../../../shared/notification/notifications.service");
var lodash_1 = require("lodash");
var shortid = require("shortid");
var organization_service_1 = require("../../../organizations/organization.service");
var CustomerReferenceGridComponent = /** @class */ (function () {
    function CustomerReferenceGridComponent(customerReferenceService, bulkDeleteDialog, customerReferenceCreateModal, preferencesService, authenticationService, notificationsService, organizationService, phoneNumberPipe) {
        var _this = this;
        this.customerReferenceService = customerReferenceService;
        this.bulkDeleteDialog = bulkDeleteDialog;
        this.customerReferenceCreateModal = customerReferenceCreateModal;
        this.preferencesService = preferencesService;
        this.authenticationService = authenticationService;
        this.notificationsService = notificationsService;
        this.organizationService = organizationService;
        this.phoneNumberPipe = phoneNumberPipe;
        this.rowModelType = 'serverSide';
        this.cacheBlockSize = 100;
        this.maxBlocksInCache = 10;
        this.frameworkComponents = {
            autocompleteCell: shared_1.AutocompleteCell,
            datepikcerCell: shared_1.DatepickerCell,
            selectAllHeader: shared_1.SelectAllHeader,
            customNoRowsOverlay: shared_1.NoResultsComponent,
        };
        this.noRowsOverlayComponent = 'customNoRowsOverlay';
        this.noRowsOverlayComponentParams = {
            type: 'customer references',
            onClick: function () { return _this.onAddCustomerClick(); },
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel'
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ]
        };
        this.columnDefs = [
            {
                headerName: 'Select All',
                field: 'select',
                headerComponent: 'selectAllHeader',
                pinned: 'left',
                width: 70,
                editable: false,
                checkboxSelection: true,
                headerComponentParams: {
                    checkboxSelection: true,
                    service: this.customerReferenceService,
                    selected: this.customerReferenceService.allSelected
                }
            },
            {
                headerName: 'Customer Code',
                field: 'customerCode',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerCode').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Customer Name',
                field: 'customerName',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerName').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Email Address',
                field: 'customerEmail',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerEmail').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Address 1',
                field: 'customerAddress1',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerAddress1').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Address 2',
                field: 'customerAddress2',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerAddress2').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'City',
                field: 'customerCity',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerCity').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'State',
                field: 'customerState',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerState').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Zip Code',
                field: 'customerZipcode',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerZipcode').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Phone Number',
                field: 'customerPhoneNumber',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: function (params) {
                        _this.customerReferenceService.getValuesForFieldQuery('customerCode').subscribe(function (fields) {
                            fields.push('-- Blank --');
                            params.success(fields);
                        });
                    },
                    newRowsAction: 'keep',
                },
                cellRenderer: function (params) {
                    var value = params.value;
                    if (!value) {
                        return '';
                    }
                    return _this.phoneNumberPipe.transform(value);
                },
                editable: true,
                cellEditor: 'autocompleteCell',
                cellEditorParams: {
                    service: this.customerReferenceService,
                },
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
        ];
        this.searchChanged = new rxjs_1.Subject();
        this.search = '';
        this.loading = false;
        this.hasQuickbooksEnabled = true;
        this.user = this.authenticationService.user();
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.customerReferenceService.search = _this.search;
            _this.refreshTable();
        });
    }
    CustomerReferenceGridComponent.prototype.getContextMenuItems = function (params) {
        var _this = this;
        var menuItems = [];
        if (!this.hasQuickbooksEnabled) {
            menuItems.push({
                name: 'Delete',
                action: function () { return _this.onDeleteCustomerReference(params); }
            });
        }
        menuItems.push({
            name: 'Export',
            subMenu: [
                {
                    name: 'Export Selected',
                    action: function () { return _this.onExportSelected(params); },
                },
                {
                    name: 'Export All',
                    action: function () { return _this.onExportAll(); },
                },
            ]
        });
        return menuItems;
    };
    CustomerReferenceGridComponent.prototype.onCellValueChanged = function (e) {
        var customer = __assign({}, e.data);
        delete customer.classes;
        delete customer.loading;
        delete customer.selected;
        this.customerReferenceService.save(customer).subscribe();
    };
    CustomerReferenceGridComponent.prototype.refreshTable = function (clear) {
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.gridApi.deselectAll();
            this.gridApi.clearRangeSelection();
        }
        this.gridApi.refreshCells();
        this.gridApi.purgeServerSideCache();
    };
    CustomerReferenceGridComponent.prototype.onDeleteCustomerReference = function (params) {
        var _this = this;
        if (!params.api) {
            return;
        }
        var selected = params.api.getSelectedRows();
        if (!selected || !selected.length) {
            selected = [params.node.data];
            params.node.setSelected(true);
        }
        var dialog = this.bulkDeleteDialog.open(shared_1.BulkActionDialogComponent, {
            width: '500px',
            data: {
                selected: selected,
                type: 'Customer Reference',
            },
        });
        dialog.componentInstance.callback = function () {
            var customers = rxjs_1.zip.apply(void 0, selected.map(function (customer) {
                return _this.deleteCustomerReference(customer);
            }));
            customers.subscribe(function () {
                _this.refreshTable(true);
            });
        };
    };
    CustomerReferenceGridComponent.prototype.deleteCustomerReference = function (customer) {
        return this.customerReferenceService.remove(customer);
    };
    CustomerReferenceGridComponent.prototype.onGridReady = function (e) {
        var _this = this;
        if (!e.columnApi || !e.api) {
            return;
        }
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
        this.applyPreferences();
        if (this.user.organization) {
            this.organizationService.get(this.user.organization.id).subscribe(function (org) {
                if (org.hasQuickbooksEnabled) {
                    _this.disableEditing();
                }
                else {
                    _this.hasQuickbooksEnabled = false;
                }
            });
        }
        e.api.setServerSideDatasource(this.customerReferenceService);
        this.customerGrid.gridOptions.getContextMenuItems = function (params) { return _this.getContextMenuItems(params); };
    };
    CustomerReferenceGridComponent.prototype.onFirstDataRendered = function () {
        this.autoSizeAll();
    };
    CustomerReferenceGridComponent.prototype.disableEditing = function () {
        this.hasQuickbooksEnabled = true;
        this.columnApi.getAllColumns().forEach(function (column) {
            var newColDef = column.getUserProvidedColDef();
            newColDef.editable = false;
            column.setColDef(newColDef, column.getUserProvidedColDef());
        });
    };
    CustomerReferenceGridComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
        });
        this.columnApi.autoSizeColumns(allColumnIds);
    };
    CustomerReferenceGridComponent.prototype.onAddCustomerClick = function () {
        var _this = this;
        var dialog = this.customerReferenceCreateModal.open(customer_reference_create_modal_component_1.CustomerReferenceCreateModalComponent, {
            width: '500px',
        });
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function () { return _this.refreshTable(); });
        };
    };
    CustomerReferenceGridComponent.prototype.onExportSelected = function (params) {
        var _this = this;
        var selected;
        selected = params.api ?
            params.api.getSelectedRows().map(function (s) { return s.id; }) :
            params.map(function (s) { return s.id; });
        if ((!selected || !selected.length) && params.node && params.node.data) {
            selected = [params.node.data.id];
            params.node.setSelected(true);
        }
        this.customerReferenceService.exportGrid('customers', selected).subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'customers',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    CustomerReferenceGridComponent.prototype.onExportAll = function () {
        var _this = this;
        this.customerReferenceService.exportGrid('customers').subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured exporting your data.',
                originator: 'customers',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    CustomerReferenceGridComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    CustomerReferenceGridComponent.prototype.applyPreferences = function () {
        var _this = this;
        // Only update or create a default preference until UI for selection is in place
        this.preferencesService.list().pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return p.name === 'customerreference-default'; });
            return pref ? pref : new preferences_1.Preferences;
        })).subscribe(function (preferences) {
            _this.preferences = preferences;
            if (preferences.type === 'customerreference' && preferences.blob) {
                _this.columnApi.setColumnState(preferences.blob.columnState);
                _this.gridApi.setSortModel(preferences.blob.sortState);
            }
            var eventList = [
                'gridColumnsChanged',
                'columnPinned',
                'columnVisible',
                'columnResized',
                'columnMoved'
            ];
            eventList.forEach(function (event) {
                _this.gridApi.addEventListener(event, lodash_1.debounce(function () { return _this.savePreferences(); }, 300));
            });
        }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured saving your preferences.',
                originator: 'customerreferences',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    CustomerReferenceGridComponent.prototype.savePreferences = function () {
        var _this = this;
        var columnData = this.columnApi.getColumnState();
        var preferencesObj = __assign({}, this.preferences, { profile: this.user.id, type: 'customerreference', name: 'customerreference-default', blob: {
                columnState: columnData,
            } });
        this.preferencesService.save(preferencesObj).subscribe(function () { }, function (error) {
            var notification = {
                context: {
                    error: error,
                },
                id: shortid.generate(),
                message: 'An error occured saving your preferences.',
                originator: 'customerreferences',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
        });
    };
    return CustomerReferenceGridComponent;
}());
exports.CustomerReferenceGridComponent = CustomerReferenceGridComponent;
