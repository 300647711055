"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["button[_ngcontent-%COMP%]{border:none;background:0 0;width:100%;height:100%;cursor:pointer}button[_ngcontent-%COMP%]   .icon-accept[_ngcontent-%COMP%]{color:#00c853;font-size:15px}button[_ngcontent-%COMP%]   .icon-none[_ngcontent-%COMP%]{opacity:.5;font-size:18px}button[_ngcontent-%COMP%]:active, button[_ngcontent-%COMP%]:focus{outline:0}"];
exports.styles = styles;
