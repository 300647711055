"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".ruckit-image-cell[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{margin-top:5px;max-height:35px;max-width:100%}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone[_ngcontent-%COMP%]{width:100%;height:50px;margin-bottom:0;border:none;border-radius:0;padding:0;background-color:transparent;position:absolute;top:0;left:0;text-align:left}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:18px;margin:4px 26px}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{background-color:transparent;padding:0;top:0;left:0;position:absolute;z-index:999;cursor:pointer}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{height:45px;padding:0 10px;font-weight:600;border:none;color:rgba(0,0,0,.25);background:0 0;display:none;flex-direction:column;align-items:flex-start;justify-content:center}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone[_ngcontent-%COMP%]:hover   label[_ngcontent-%COMP%]{display:flex}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone[_ngcontent-%COMP%]:hover   i[_ngcontent-%COMP%]{display:none}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone.filled[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap;overflow-y:scroll}.ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone.filled[_ngcontent-%COMP%]   input[_ngcontent-%COMP%], .ruckit-image-cell[_ngcontent-%COMP%]   .upload-image-dropzone.filled[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{display:none}"];
exports.styles = styles;
