"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dropdown.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/forms");
var i4 = require("../../directives/loader/loader.component.ngfactory");
var i5 = require("../../directives/loader/loader.component");
var i6 = require("./dropdown.component");
var styles_DropdownComponent = [i0.styles];
var RenderType_DropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownComponent, data: {} });
exports.RenderType_DropdownComponent = RenderType_DropdownComponent;
function View_DropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.image; _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.user.name[0]; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "user-pic"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.config.user && _co.config.user.image); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.config.user || !_co.config.user.image); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["aria-haspopup", "true"], ["class", "dropdown-button"], ["tabindex", "0"]], [[1, "aria-disabled", 0]], [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.buttonKeyPress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "custom-icon": 0 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-color": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "dropdown-button"; var currVal_2 = _ck(_v, 2, 0, _co.icon); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 4, 0, _co.backgroundColor); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.config.user; _ck(_v, 6, 0, currVal_4); var currVal_6 = _co.icon; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled ? "true" : "false"); _ck(_v, 0, 0, currVal_0); var currVal_5 = _co.buttonText; _ck(_v, 7, 0, currVal_5); }); }
function View_DropdownComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dropdown-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["autocomplete", "off"], ["class", "autocomplete"], ["name", "autocomplete"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.autocompleteTerm = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.changeAutocomplete() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "autocomplete"; var currVal_9 = _co.autocompleteTerm; _ck(_v, 4, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonText; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_DropdownComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "form", [["class", "option search-option"], ["id", "dropdown-search"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(2, 4210688, null, 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "input", [["autocomplete", "off"], ["class", "search"], ["name", "search"], ["placeholder", "Search"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchTerm = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.changeSearch() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(8, 671744, null, 0, i3.NgModel, [[2, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "icomoon icon-search"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_14 = "search"; var currVal_15 = _co.searchTerm; _ck(_v, 8, 0, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 10).ngClassValid; var currVal_12 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }); }
function View_DropdownComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "group-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.optionImageDisplay(_v.parent.parent.context.$implicit), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "default-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-fleet"]], null, null, null, null, null))], null, null); }
function View_DropdownComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_15)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionImageDisplay(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.optionImageDisplay(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DropdownComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionSubtitleDisplay(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "option"], ["role", "menuitem"], ["tabindex", "-1"]], [[1, "data-id", 0]], [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOption(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.optionKeyPress($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { selected: 0, button: 1, "has-image": 2, disabled: 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "option-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_13)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "option-right"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "no-subtitle": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_16)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "option"; var currVal_2 = _ck(_v, 2, 0, _co.isSelected(_v.context.$implicit), _v.context.$implicit.button, _co.config.image, _v.context.$implicit.disabled); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.config.image; _ck(_v, 5, 0, currVal_3); var currVal_4 = "option-right"; var currVal_5 = _ck(_v, 8, 0, !_co.config.subtitle); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_7 = _co.config.subtitle; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_6 = _co.optionDisplay(_v.context.$implicit); _ck(_v, 10, 0, currVal_6); }); }
function View_DropdownComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "group"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { empty: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_11)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_12)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.length === 0)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit && (_v.context.$implicit.length > 0)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.groups[_v.context.$implicit]; _ck(_v, 6, 0, currVal_3); }, null); }
function View_DropdownComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_10)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupKeys; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DropdownComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "option"], ["role", "menuitem"], ["tabindex", "-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deselectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "option-right no-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.deselectOption; _ck(_v, 3, 0, currVal_0); }); }
function View_DropdownComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.optionImageDisplay(_v.parent.parent.context.$implicit), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "default-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-fleet"]], null, null, null, null, null))], null, null); }
function View_DropdownComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_21)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_22)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionImageDisplay(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.optionImageDisplay(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DropdownComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.optionSubtitleDisplay(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "option"], ["role", "menuitem"], ["tabindex", "-1"]], [[1, "data-id", 0]], [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOption(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.optionKeyPress($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { selected: 0, button: 1, "has-image": 2, disabled: 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "option-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_20)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "option-right"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "no-subtitle": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_23)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "option"; var currVal_2 = _ck(_v, 2, 0, _co.isSelected(_v.context.$implicit), _v.context.$implicit.button, _co.config.image, _v.context.$implicit.disabled); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.config.image; _ck(_v, 5, 0, currVal_3); var currVal_4 = "option-right"; var currVal_5 = _ck(_v, 8, 0, !_co.config.subtitle); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_7 = _co.config.subtitle; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_6 = _co.optionDisplay(_v.context.$implicit); _ck(_v, 10, 0, currVal_6); }); }
function View_DropdownComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_18)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_19)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.deselectOption; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.options; _ck(_v, 4, 0, currVal_1); }, null); }
function View_DropdownComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "option loading-option"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "my-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(2, 180224, null, 0, i5.LoaderComponent, [], null, null)], null, null); }
function View_DropdownComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "dropdown-menu"]], [[4, "height", null], [4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["optionsEl", 1]], null, 9, "div", [["aria-label", "submenu"], ["class", "dropdown-options"]], [[1, "aria-hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "scrollable"]], null, [[null, "mousewheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousewheel" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_9)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_17)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_24)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.config.searchable && !_co.config.autocomplete); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.config.group; _ck(_v, 6, 0, currVal_4); var currVal_5 = !_co.config.group; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.config.loadingOptions; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuHeight; var currVal_1 = "auto"; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.open ? "false" : "true"); _ck(_v, 1, 0, currVal_2); }); }
function View_DropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { optionsElRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "dropdown"], ["role", "menu"]], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (_co.onKeyPress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { open: 0, disabled: 1, empty: 2, action: 3, user: 4, "bottom-right": 5, "custom-icon": 6, searchable: 7, "loading-options": 8, "right-align": 9 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_7)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown"; var currVal_1 = _ck(_v, 3, 0, _co.open, _co.disabled, (!_co.selectedOption && !_co.config.multiselect), (_co.config.style === "action"), _co.config.user, (_co.config.pin === "bottom-right"), _co.icon, _co.config.searchable, _co.config.loadingOptions, _co.config.rightAlign); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.config.autocomplete; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.config.autocomplete; _ck(_v, 7, 0, currVal_3); var currVal_4 = (((_co.groupKeys.length > 0) || (_co.options.length > 0)) || _co.searching); _ck(_v, 9, 0, currVal_4); }, null); }
exports.View_DropdownComponent_0 = View_DropdownComponent_0;
function View_DropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dropdown", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DropdownComponent_0, RenderType_DropdownComponent)), i1.ɵdid(1, 638976, null, 0, i6.DropdownComponent, [i1.ElementRef, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DropdownComponent_Host_0 = View_DropdownComponent_Host_0;
var DropdownComponentNgFactory = i1.ɵccf("dropdown", i6.DropdownComponent, View_DropdownComponent_Host_0, { selectedOption: "selectedOption", selectedItems: "selectedItems", title: "title", icon: "icon", setTitle: "setTitle", changeTitle: "changeTitle", config: "config", options: "options", backgroundColor: "backgroundColor", disabled: "disabled" }, { onSelect: "onSelect", nextPage: "nextPage", onSearch: "onSearch" }, []);
exports.DropdownComponentNgFactory = DropdownComponentNgFactory;
