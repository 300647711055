"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./action-menu.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/menu/typings/index.ngfactory");
var i3 = require("@angular/material/menu");
var i4 = require("@angular/common");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/cdk/overlay");
var i7 = require("@angular/cdk/bidi");
var i8 = require("./action-menu.component");
var styles_ActionMenuComponent = [i0.styles];
var RenderType_ActionMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionMenuComponent, data: {} });
exports.RenderType_ActionMenuComponent = RenderType_ActionMenuComponent;
function View_ActionMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "action-menu-icon icomoon ", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ActionMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""], ["role", "menuitem"]], [[2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onOptionClick(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, [[1, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.ɵf23]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_5 = _v.context.$implicit.disabled(); _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._highlighted; var currVal_1 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_2 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_3 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_6); }); }
function View_ActionMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["aria-haspopup", "true"], ["class", "btn btn-default action-menu__toggle"], ["mat-icon-button", ""]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 1196032, null, 0, i3.MatMenuTrigger, [i6.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i7.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionMenuComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", "\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "mat-menu", [], null, null, null, i2.View_MatMenu_0, i2.RenderType_MatMenu)), i1.ɵprd(6144, null, i3.ɵf23, null, [i3.MatMenu]), i1.ɵdid(7, 1294336, [["menu", 4]], 2, i3.MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ActionMenuComponent_2)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 3, 0, currVal_2); _ck(_v, 7, 0); var currVal_4 = _co.options; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).menuOpen || null); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.title; _ck(_v, 4, 0, currVal_3); }); }
exports.View_ActionMenuComponent_0 = View_ActionMenuComponent_0;
function View_ActionMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "action-menu", [], null, null, null, View_ActionMenuComponent_0, RenderType_ActionMenuComponent)), i1.ɵdid(1, 114688, null, 0, i8.ActionMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ActionMenuComponent_Host_0 = View_ActionMenuComponent_Host_0;
var ActionMenuComponentNgFactory = i1.ɵccf("action-menu", i8.ActionMenuComponent, View_ActionMenuComponent_Host_0, { options: "options", icon: "icon", title: "title" }, {}, []);
exports.ActionMenuComponentNgFactory = ActionMenuComponentNgFactory;
