"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var shortid = require("shortid");
// @ts-ignore
var pdfjs = require("pdfjs-dist");
require("dwt");
var environment_1 = require("../../environments/environment");
var notifications_service_1 = require("../shared/notification/notifications.service");
var authentication_service_1 = require("../shared/authentication.service");
var upload_service_1 = require("../shared/upload.service");
var ticket_service_1 = require("../tickets/ticket.service");
var preferences_1 = require("../shared/preferences/preferences");
var preferences_service_1 = require("../shared/preferences/preferences.service");
var ScaleSyncUploadComponent = /** @class */ (function () {
    function ScaleSyncUploadComponent(router, route, notificationsService, authenticationService, uploadService, ticketService, preferencesService) {
        this.router = router;
        this.route = route;
        this.notificationsService = notificationsService;
        this.authenticationService = authenticationService;
        this.uploadService = uploadService;
        this.ticketService = ticketService;
        this.preferencesService = preferencesService;
        this.external = false;
        this.contextId = '';
        this.dateSelected = false;
        this.user = this.authenticationService.user();
        this.loader = {
            active: false,
            type: 'uploading'
        };
        this.uploadIndex = 0;
        this.policyData = {};
        this.uploadFiles = [];
        this.uploadTotal = 0;
        this.savedTickets = [];
        this.selectedRecordIndex = null;
    }
    ScaleSyncUploadComponent.prototype.ticketNumberVisible = function () {
        return (this.savedTickets.length > 0 && this.selectedRecordIndex !== null) || this.savedTickets.length === 0;
    };
    ScaleSyncUploadComponent.prototype.ngOnInit = function () {
        // here we will set up listeners for any url params and set up
        // the callback_url, features, type model, and other config functions necessary
        var combinedParams = rxjs_1.combineLatest(this.route.url, this.route.params, this.route.queryParams, function (url, params, qparams) { return ({ url: url, params: params, qparams: qparams }); });
        combinedParams.subscribe(function (_result) {
        });
        this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;
    };
    ScaleSyncUploadComponent.prototype.checkForMatchingValue = function (tickets, key) {
        return tickets.every(function (ticket) {
            return ticket[key] === tickets[0][key];
        });
    };
    ScaleSyncUploadComponent.prototype.isValidDate = function (input) {
        input = new Date(input);
        return input instanceof Date && !isNaN(input.getTime());
    };
    ScaleSyncUploadComponent.prototype.selectedTicketLength = function () {
        var length = 0;
        this.savedTickets.forEach(function (ticket) { return length = ticket.selected ? length + 1 : length; });
        return length;
    };
    ScaleSyncUploadComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferencesService.list().pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return p.name === 'uploader'; });
            return pref ? pref : new preferences_1.Preferences;
        })).subscribe(function (preferences) {
            _this.preferences = preferences;
        });
    };
    ScaleSyncUploadComponent.prototype.savePreferences = function () {
        var preferencesObj = __assign({}, this.preferences, { type: 'scale-sync-uploader', name: 'scale-sync-uploader', blob: {} });
        this.preferencesService.save(preferencesObj).subscribe();
    };
    ScaleSyncUploadComponent.prototype.saveImage = function (updateData) {
        var _this = this;
        this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
            .subscribe(function () { }, function (err) {
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error updating that ticket image.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    ScaleSyncUploadComponent.prototype.fileChange = function (e) {
        var _this = this;
        this.uploadService.getS3Policy(this.external).subscribe(function (policy) {
            _this.policyData = policy;
            _this.uploadFiles = e.target ? Array.from(e.target.files) : e;
            if (_this.uploadFiles.length) {
                _this.loader = {
                    active: true,
                    type: 'uploading'
                };
                _this.uploadFiles.forEach(function (file, i) { return _this.convertFileToRecord(file, i); });
            }
        }, function (err) {
            _this.loader.active = false;
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error authentication with our upload server.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    ScaleSyncUploadComponent.prototype.close = function () {
        this.router.navigate(['tickets']);
    };
    ScaleSyncUploadComponent.prototype.cropWhiteSpace = function (base64Image) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loadImage(base64Image).then(function (image) {
                // draw canvas with base64 image data (maintain size)
                var canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext('2d');
                if (ctx) {
                    // remove any white pixels that the scan driver will try to fill in
                    // and return the cropped image base64 data
                    ctx.drawImage(image, 0, 0);
                    var copy = document.createElement('canvas').getContext('2d');
                    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    var pixel = imageData.data;
                    var bound = {};
                    var x = void 0, y = void 0;
                    for (var i = 0; i < pixel.length; i += 4) {
                        if (pixel[i] !== 255 || pixel[i + 1] !== 255 || pixel[i + 2] !== 255) {
                            x = (i / 4) % canvas.width;
                            y = ~~((i / 4) / canvas.width);
                            if (!bound.top) {
                                bound.top = y;
                            }
                            if (!bound.left) {
                                bound.left = x;
                            }
                            else if (x < bound.left) {
                                bound.left = x;
                            }
                            if (!bound.right) {
                                bound.right = x;
                            }
                            else if (bound.right < x) {
                                bound.right = x;
                            }
                            if (!bound.bottom) {
                                bound.bottom = y;
                            }
                            else if (bound.bottom < y) {
                                bound.bottom = y;
                            }
                        }
                    }
                    var trimHeight = bound.bottom - bound.top, trimWidth = bound.right - bound.left, trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
                    if (copy) {
                        copy.canvas.width = trimWidth;
                        copy.canvas.height = trimHeight;
                        copy.putImageData(trimmed, 0, 0);
                    }
                    resolve(copy ? copy.canvas.toDataURL('image/jpeg', 1.0) : base64Image);
                }
                else {
                    resolve(base64Image);
                }
            });
        });
    };
    ScaleSyncUploadComponent.prototype.loadImage = function (base64Image) {
        return new Promise(function (resolve, reject) {
            var image = new Image();
            image.src = base64Image;
            image.onload = function () { return resolve(image); };
            image.onerror = function (e) { return reject(e); };
        });
    };
    ScaleSyncUploadComponent.prototype.convertPDFtoImages = function (pdfFile) {
        var _this = this;
        return new Promise(function (resolve) {
            pdfjs.getDocument(pdfFile).then(function (pdf) {
                var pdfImages = [];
                var _loop_1 = function (i) {
                    pdf.getPage(i).then(function (page) {
                        var scale = 2;
                        var viewport = page.getViewport(scale);
                        var canvas = document.createElement('canvas');
                        canvas.setAttribute('id', 'canvas-' + i);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        var task = page.render({ canvasContext: context, viewport: viewport });
                        task.promise.then(function () {
                            pdfImages.push(_this.uploadService.convertDataUriToFile(canvas.toDataURL('image/jpeg'), 'pdfImage-' + i + '.jpg'));
                            if (pdfImages.length === pdf.numPages) {
                                resolve(pdfImages);
                            }
                        });
                    });
                };
                for (var i = 1; i <= pdf.numPages; i++) {
                    _loop_1(i);
                }
            });
        });
    };
    ScaleSyncUploadComponent.prototype.convertFileToRecord = function (file, uploadIndex) {
        var _this = this;
        var reader = new FileReader();
        reader.onload = function () { return _this.fileLoad(reader, file, uploadIndex); };
        reader.readAsArrayBuffer(file);
    };
    ScaleSyncUploadComponent.prototype.fileLoad = function (reader, file, uploadIndex) {
        var _this = this;
        if (file.type.includes('pdf')) {
            Promise.resolve(this.convertPDFtoImages(reader.result)).then(function (results) {
                var pdfImages = results;
                _this.uploadTotal = _this.uploadTotal + pdfImages.length;
                pdfImages.forEach(function (image) { return _this.generateRecordFromImage(image); });
            });
        }
        else if (file.type.startsWith('image/') && !file.type.includes('heic')) {
            this.uploadTotal++;
            this.generateRecordFromImage(file, uploadIndex);
        }
        else {
            var notification = {
                context: {},
                id: shortid.generate(),
                message: 'There was an error uploading this image. Please upload a valid image format (JPEG/PNG/GIF) or a PDF.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            this.notificationsService.addNotification(notification);
        }
    };
    ;
    ScaleSyncUploadComponent.prototype.generateRecordFromImage = function (imageFile, _uploadIndex) {
        var _this = this;
        Promise.resolve(this.uploadService.generateUUID()).then(function (uuid) {
            _this.policyData['fields']['key'] = 'tickets/' + uuid + '/original.jpg';
            var ticketData = {
                id: uuid,
                image: environment_1.environment.ticketImageServerUrl + _this.policyData['fields']['key'],
                ticketDate: '',
                ticketNumber: '',
                quantity: ''
            };
            _this.uploadIndex++;
            _this.uploadService.uploadToS3(_this.policyData, imageFile).subscribe(function () {
                _this.loader.type = 'analyzing';
                _this.ticketService.saveScaleSync({ image: ticketData.image }).subscribe(function () {
                    _this.loader.active = false;
                    _this.close();
                }, function (err) {
                    var notification = {
                        context: { err: err },
                        id: shortid.generate(),
                        message: 'There was an error creating this ticket.',
                        originator: 'uploader',
                        type: notifications_service_1.NotificationType.Danger,
                    };
                    _this.notificationsService.addNotification(notification);
                    throw err;
                });
            }, function (err) {
                _this.uploadTotal = Number(_this.uploadTotal) - 1;
                var notification = {
                    context: { err: err },
                    id: shortid.generate(),
                    message: 'There was an error uploading this image.',
                    originator: 'uploader',
                    type: notifications_service_1.NotificationType.Danger,
                };
                _this.notificationsService.addNotification(notification);
                throw err;
            });
        });
    };
    return ScaleSyncUploadComponent;
}());
exports.ScaleSyncUploadComponent = ScaleSyncUploadComponent;
