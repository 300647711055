"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".no-results[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-content:center;justify-content:center;padding:50px;height:100%;width:100%;margin-top:0;pointer-events:all}.no-results__icon[_ngcontent-%COMP%]{text-align:center;margin-left:12px;margin-bottom:20px}.no-results__icon[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:48px}.no-results__header[_ngcontent-%COMP%]{font-size:18px;margin-bottom:10px}.no-results__content[_ngcontent-%COMP%]{font-size:14px;color:#7f8fa4;font-weight:500;margin-bottom:15px}  .ag-overlay-panel{pointer-events:auto}"];
exports.styles = styles;
