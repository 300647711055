"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var authentication_service_1 = require("../authentication.service");
var RuckitForgotPasswordDialogComponent = /** @class */ (function () {
    function RuckitForgotPasswordDialogComponent(dialogRef, authenticationService) {
        this.dialogRef = dialogRef;
        this.authenticationService = authenticationService;
        this.model = {};
        this.loading = false;
        this.button = {
            title: 'Send',
            disabled: false,
            type: 'primary'
        };
        this.errors = [];
    }
    RuckitForgotPasswordDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.authenticationService.forgot(this.model.username)
            .subscribe(function () {
            _this.success = true;
            _this.button = {
                title: 'Email Sent!',
                disabled: true,
                type: 'primary'
            };
            setTimeout(function () {
                _this.loading = false;
                _this.dialogRef.close();
            }, 4000);
        }, function (err) {
            _this.errors = err;
            _this.success = false;
            _this.button = {
                title: 'Email Failed!',
                disabled: true,
                type: 'error'
            };
            setTimeout(function () {
                _this.loading = false;
                _this.dialogRef.close();
                _this.button = {
                    title: 'Send',
                    disabled: false,
                    type: 'primary'
                };
            }, 4000);
        });
    };
    return RuckitForgotPasswordDialogComponent;
}());
exports.RuckitForgotPasswordDialogComponent = RuckitForgotPasswordDialogComponent;
