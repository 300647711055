<div class="ruckit-driver-reference-create">
  <form [formGroup]="referenceForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="modal-title ruckit-driver-reference-create__title">
      <h1 *ngIf="mode === 'create'" translate>Create New Driver Reference</h1>
      <h1 *ngIf="mode === 'edit'" translate>Edit Driver Reference</h1>
      <div class="close-action" (click)="dialogRef.close()">
        <i class="icomoon icon-close"></i>
      </div>
    </div>
    <div mat-dialog-content class="ruckit-driver-reference-create__content">
      <mat-form-field>
        <input matInput placeholder="Driver Code" formControlName="driverCode">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Driver Name" formControlName="driverName">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Carrier Name" formControlName="carrierName">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Email Address" formControlName="email">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Phone Number" formControlName="phoneNumber">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="CDL" formControlName="cdl">
      </mat-form-field>
    </div>
    <div class="ruckit-driver-reference-create__submit">
      <button type="submit" class="btn btn-primary" [disabled]="!referenceForm.valid">
        Submit
      </button>
    </div>
  </form>
</div>
