"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var autocomplete_cell_component_1 = require("../../../shared/grid/autocomplete-cell.component");
var datepicker_cell_component_1 = require("../../../shared/grid/datepicker-cell.component");
var ag_grid_angular_1 = require("ag-grid-angular");
var material_1 = require("@angular/material");
var bulk_action_dialog_component_1 = require("../../../shared/grid/bulk-action-dialog.component");
var rxjs_1 = require("rxjs");
var select_all_header_component_1 = require("../../../shared/grid/select-all-header.component");
var truck_reference_service_1 = require("../truck-reference.service");
var truck_reference_create_modal_component_1 = require("../truck-reference-create-modal/truck-reference-create-modal.component");
var operators_1 = require("rxjs/operators");
var shared_1 = require("../../../../app/shared");
var TruckReferenceGridComponent = /** @class */ (function () {
    function TruckReferenceGridComponent(truckReferenceService, bulkDeleteDialog, truckReferenceCreateModal) {
        var _this = this;
        this.truckReferenceService = truckReferenceService;
        this.bulkDeleteDialog = bulkDeleteDialog;
        this.truckReferenceCreateModal = truckReferenceCreateModal;
        this.frameworkComponents = {
            autocompleteCell: autocomplete_cell_component_1.AutocompleteCell,
            datepickerCell: datepicker_cell_component_1.DatepickerCell,
            selectAllHeader: select_all_header_component_1.SelectAllHeader,
            customNoRowsOverlay: shared_1.NoResultsComponent,
        };
        this.noRowsOverlayComponent = 'customNoRowsOverlay';
        this.noRowsOverlayComponentParams = {
            type: 'truck references',
            onClick: function () { return _this.onAddTruckClick(); },
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel'
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ]
        };
        this.columnDefs = [
            {
                headerName: 'Select All',
                field: 'select',
                headerComponent: 'selectAllHeader',
                pinned: 'left',
                width: 70,
                editable: false,
                checkboxSelection: true,
                headerComponentParams: {
                    checkboxSelection: true,
                    service: this.truckReferenceService,
                    selected: this.truckReferenceService.allSelected
                }
            },
            {
                headerName: 'License Plate',
                field: 'licensePlate',
                sortable: true,
                resizable: true,
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Truck Code',
                field: 'truckCode',
                sortable: true,
                resizable: true,
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Truck Number',
                field: 'truckNumber',
                sortable: true,
                resizable: true,
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
            {
                headerName: 'Truck Type',
                field: 'truckType',
                sortable: true,
                resizable: true,
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChanged(e); },
            },
        ];
        this.rowModelType = 'serverSide';
        this.cacheBlockSize = 100;
        this.maxBlocksInCache = 10;
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.truckReferenceService.search = _this.search;
            _this.refreshTable();
        });
    }
    TruckReferenceGridComponent.prototype.getContextMenuItems = function (params) {
        var _this = this;
        return [
            {
                name: 'Delete',
                action: function () { return _this.onDeleteTruckReference(params); }
            },
        ];
    };
    TruckReferenceGridComponent.prototype.onDeleteTruckReference = function (params) {
        var _this = this;
        if (!params || !params.api) {
            return;
        }
        var selected = params.api.getSelectedRows();
        if (!selected || !selected.length) {
            selected = [params.node.data];
            params.node.setSelected(true);
        }
        var dialog = this.bulkDeleteDialog.open(bulk_action_dialog_component_1.BulkActionDialogComponent, {
            width: '500px',
            data: {
                selected: selected
            },
        });
        dialog.componentInstance.callback = function () {
            var jobs = rxjs_1.zip.apply(void 0, selected.map(function (truck) {
                return _this.deleteTruckReference(truck);
            }));
            jobs.subscribe(function () {
                _this.refreshTable(true);
            });
        };
    };
    TruckReferenceGridComponent.prototype.deleteTruckReference = function (truck) {
        return this.truckReferenceService.remove(truck);
    };
    TruckReferenceGridComponent.prototype.refreshTable = function (clear) {
        if (clear === void 0) { clear = false; }
        if (clear) {
            this.gridApi.deselectAll();
            this.gridApi.clearRangeSelection();
        }
        this.gridApi.refreshCells();
        this.gridApi.purgeServerSideCache();
    };
    TruckReferenceGridComponent.prototype.onCellValueChanged = function (e) {
        var truck = __assign({}, e.data);
        delete truck.classes;
        delete truck.createdAt;
        delete truck.loading;
        delete truck.selected;
        this.truckReferenceService.save(truck).subscribe();
    };
    TruckReferenceGridComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    TruckReferenceGridComponent.prototype.onGridReady = function (e) {
        var _this = this;
        if (!e.columnApi || !e.api) {
            return;
        }
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
        this.trucksGrid.gridOptions.getContextMenuItems = function (params) { return _this.getContextMenuItems(params); };
        e.api.setServerSideDatasource(this.truckReferenceService);
    };
    TruckReferenceGridComponent.prototype.onFirstDataRendered = function () {
        this.autoSizeAll();
    };
    TruckReferenceGridComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
        });
        this.columnApi.autoSizeColumns(allColumnIds);
    };
    TruckReferenceGridComponent.prototype.onAddTruckClick = function () {
        var _this = this;
        var dialog = this.truckReferenceCreateModal.open(truck_reference_create_modal_component_1.TruckReferenceCreateModalComponent, {
            width: '500px',
        });
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function () { return _this.refreshTable(); });
        };
    };
    return TruckReferenceGridComponent;
}());
exports.TruckReferenceGridComponent = TruckReferenceGridComponent;
