"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var report_service_1 = require("../report/report.service");
var InvoiceService = /** @class */ (function (_super) {
    __extends(InvoiceService, _super);
    function InvoiceService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filters = {
            type: {
                filterType: 'field',
                values: ['invoice'],
            }
        };
        return _this;
    }
    return InvoiceService;
}(report_service_1.ReportService));
exports.InvoiceService = InvoiceService;
