"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NoResultsComponent = /** @class */ (function () {
    function NoResultsComponent() {
        this.type = 'items';
        this.showButton = false;
        this.params = {};
    }
    NoResultsComponent.prototype.agInit = function (params) {
        this.params = params;
        if (this.params.onClick) {
            this.onClick = this.params.onClick;
        }
        if (this.params.type) {
            this.type = this.params.type;
        }
    };
    return NoResultsComponent;
}());
exports.NoResultsComponent = NoResultsComponent;
