"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReviewerCell = /** @class */ (function () {
    function ReviewerCell() {
    }
    ReviewerCell.prototype.agInit = function (params) {
        this.value = params.value;
        this.reviewer = params.reviewer;
    };
    ReviewerCell.prototype.getValue = function () {
        return this.value;
    };
    return ReviewerCell;
}());
exports.ReviewerCell = ReviewerCell;
