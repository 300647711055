import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterUnique', pure: false })

export class UniqueFilterPipe implements PipeTransform {
  transform(values: any[], prop: string): any {
    // Remove the duplicate elements
    return Array.from(new Set(values.map(x => x[prop])))
                .map(propVal => {
                  return values.find(y => y[prop] === propVal);
                });
  }
}
