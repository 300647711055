"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var TicketFilter = /** @class */ (function () {
    function TicketFilter(filterInfo) {
        filterInfo = camelcaseKeysDeep(filterInfo);
        this.id = filterInfo.id;
        this.name = filterInfo.name;
    }
    return TicketFilter;
}());
exports.TicketFilter = TicketFilter;
