<div class="sidebar-ticket-image">
  <ng-container *ngIf="imageUrl && imageUrl !== ''; else noImage">
    <button class="edit" *ngIf="!editing" (click)="editing = true">Edit Image</button>
    <img *ngIf="!editing" [src]="imageUrl">
    <image-editor *ngIf="editing" [id]="ticket.id" [imageUrl]="imageUrl" (onSave)="onSave($event)"></image-editor>
  </ng-container>
  <ng-template #noImage>
    <p translate>No Image Found</p>
  </ng-template>
</div>

