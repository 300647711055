"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CheckboxCell = /** @class */ (function () {
    function CheckboxCell() {
    }
    CheckboxCell.prototype.agInit = function (params) {
        this.value = params.value;
        this.rowId = params.data && params.data.id;
        this.field = params.field;
        this.callback = params.callback;
        this.service = params.service;
    };
    CheckboxCell.prototype.getValue = function () {
        return this.value;
    };
    CheckboxCell.prototype.valueChange = function (value) {
        var _this = this;
        var updates = {};
        updates['id'] = this.rowId;
        updates[this.field] = value;
        if (this.service) {
            this.service.save(updates).subscribe(function (result) {
                _this.value = result[_this.field];
                _this.callback(result);
            });
        }
    };
    return CheckboxCell;
}());
exports.CheckboxCell = CheckboxCell;
