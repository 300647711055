"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SelectAllHeader = /** @class */ (function () {
    function SelectAllHeader() {
        this.selected = false;
    }
    SelectAllHeader.prototype.agInit = function (params) {
        this.params = params;
    };
    SelectAllHeader.prototype.selectionChanged = function () {
        var _this = this;
        this.params.service.allSelected = !this.params.service.allSelected;
        this.params.api.forEachNode(function (node) { return node.setSelected(_this.params.service.allSelected); });
    };
    return SelectAllHeader;
}());
exports.SelectAllHeader = SelectAllHeader;
