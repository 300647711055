"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router) {
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (_, state) {
        var userValue = localStorage.getItem('currentUser');
        if (userValue) {
            var currentUser = JSON.parse(userValue);
            if (currentUser && (!currentUser.driver || currentUser.organization)) {
                // logged in so return true
                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;
