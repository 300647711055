"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var driver_reference_service_1 = require("../driver-reference.service");
var driver_reference_1 = require("../driver-reference");
var driver_reference_create_modal_component_1 = require("../driver-reference-create-modal/driver-reference-create-modal.component");
var DriverReferenceSelectorComponent = /** @class */ (function () {
    function DriverReferenceSelectorComponent(driverReferenceCreateModal, driverReferenceService) {
        this.driverReferenceCreateModal = driverReferenceCreateModal;
        this.driverReferenceService = driverReferenceService;
        this.visible = true;
        this.placeholder = 'Driver Name or Driver Code';
        this.value = '';
        this.hasControls = true;
        this.referenceFocused = new core_1.EventEmitter();
        this.referenceSelected = new core_1.EventEmitter();
        this.referenceCreated = new core_1.EventEmitter();
        this.onBlur = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.loading = true;
        this.scrollDistance = 5;
        this.scrollUpDistance = 5;
        this.throttle = 100;
        this.query = '';
        this.editing = false;
        this.references = [];
    }
    DriverReferenceSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.query = this.value;
        this.driverReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    DriverReferenceSelectorComponent.prototype.ngAfterViewInit = function () { };
    DriverReferenceSelectorComponent.prototype.setSelectedReference = function (reference) {
        this.selectedDriverReference = reference;
        this.value = reference.driverName ? reference.driverName : '';
    };
    DriverReferenceSelectorComponent.prototype.buildQueryString = function (query) {
        if (query === void 0) { query = ''; }
        if (!query || query === '') {
            return {
                page_size: 10,
            };
        }
        return {
            filters: "(driver_name__icontains=" + query + ") | (driver_code__icontains=" + query + ")",
            page_size: 10,
        };
    };
    DriverReferenceSelectorComponent.prototype.onInputFocus = function () {
        var _this = this;
        this.visible = true;
        this.loading = true;
        this.references = [];
        this.referenceFocused.emit(true);
        this.driverReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    DriverReferenceSelectorComponent.prototype.onInputBlur = function () {
        this.visible = false;
        this.references = [];
        this.onBlur.emit(true);
        if (this.selectedDriverReference) {
            if (this.selectedDriverReference.driverName && this.selectedDriverReference.driverName !== this.value) {
                this.value = this.selectedDriverReference.driverName;
            }
        }
        else {
            this.value = '';
        }
    };
    DriverReferenceSelectorComponent.prototype.onReferenceClicked = function (event, driverReference) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.referenceSelected.emit(driverReference);
        this.visible = false;
        this.selectedDriverReference = driverReference;
        this.value = driverReference.driverName ? driverReference.driverName : '';
    };
    DriverReferenceSelectorComponent.prototype.onSearchChange = function (query) {
        var _this = this;
        query = query.toUpperCase();
        this.loading = true;
        this.references = [];
        this.driverReferenceService.list(this.buildQueryString(query)).subscribe(function (results) {
            _this.references = results;
            _this.loading = false;
        });
    };
    DriverReferenceSelectorComponent.prototype.onCloseClick = function () {
        this.visible = false;
        this.onClose.emit(true);
        this.references = [];
    };
    DriverReferenceSelectorComponent.prototype.onAddClick = function () {
        var _this = this;
        var data = {
            width: '500px',
            data: {
                model: this.data,
                mode: 'create',
            }
        };
        var dialog = this.driverReferenceCreateModal.open(driver_reference_create_modal_component_1.DriverReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (driverReference) {
                _this.referenceCreated.emit(driverReference);
                _this.selectedDriverReference = driverReference;
                _this.visible = false;
            });
        };
    };
    DriverReferenceSelectorComponent.prototype.onScrollDown = function () {
        var _this = this;
        var newResults = this.driverReferenceService.listNext();
        if (newResults) {
            newResults.subscribe(function (results) {
                _this.references = _this.references.concat(results);
            });
        }
    };
    DriverReferenceSelectorComponent.prototype.onScrollUp = function () { };
    DriverReferenceSelectorComponent.prototype.onEditReferenceClick = function () {
        var _this = this;
        if (!this.selectedDriverReference) {
            return;
        }
        this.visible = false;
        this.references = [];
        var data = {
            width: '500px',
            data: {
                model: this.selectedDriverReference,
                mode: 'edit',
            },
        };
        var dialog = this.driverReferenceCreateModal.open(driver_reference_create_modal_component_1.DriverReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (driverReference) {
                _this.referenceCreated.emit(driverReference);
                _this.selectedDriverReference = driverReference;
                _this.references.push(driverReference);
                _this.visible = false;
            });
        };
        return false;
    };
    return DriverReferenceSelectorComponent;
}());
exports.DriverReferenceSelectorComponent = DriverReferenceSelectorComponent;
