"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resource_1 = require("../shared/resource");
var Ocr = /** @class */ (function (_super) {
    __extends(Ocr, _super);
    function Ocr() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        _this.export = false;
        return _this;
    }
    Ocr.prototype.updateBoundingBoxCoordinates = function (attachmentWidth, imageWidth) {
        if (this.textAnnotations && this.textAnnotations.length) {
            this.boundingBoxes = this.textAnnotations.map(function (annotation) {
                try {
                    var description = annotation['description'];
                    var verticies = annotation['boundingPoly']['vertices'];
                    var top_1 = Math.min.apply(Math, verticies.map(function (v) { return v['y']; }));
                    var left = Math.min.apply(Math, verticies.map(function (v) { return v['x']; }));
                    var width = Math.max.apply(Math, verticies.map(function (v) { return v['x']; })) - left;
                    var height = Math.max.apply(Math, verticies.map(function (v) { return v['y']; })) - top_1;
                    var fontSize = height * 0.40;
                    // Transfor for scaled images
                    var scale = imageWidth / attachmentWidth;
                    return {
                        description: description,
                        top: (top_1 * scale) - (top_1 * scale * 0.05),
                        left: left * scale,
                        width: width * scale,
                        height: (height * scale) / 2,
                        fontSize: (fontSize * scale)
                    };
                }
                catch (err) {
                    return;
                }
            });
        }
    };
    return Ocr;
}(resource_1.Resource));
exports.Ocr = Ocr;
var OcrResults = /** @class */ (function () {
    function OcrResults() {
    }
    return OcrResults;
}());
exports.OcrResults = OcrResults;
var TextAnnotation = /** @class */ (function () {
    function TextAnnotation() {
    }
    return TextAnnotation;
}());
exports.TextAnnotation = TextAnnotation;
