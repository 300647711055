"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MultiValueCell = /** @class */ (function () {
    function MultiValueCell() {
        this.valueArray = [];
    }
    MultiValueCell.prototype.isPopup = function () {
        return false;
    };
    MultiValueCell.prototype.isCancelBeforeStart = function () {
        return false;
    };
    MultiValueCell.prototype.isCancelAfterEnd = function () {
        return false;
    };
    MultiValueCell.prototype.agInit = function (params) {
        this.params = params;
        this.value = params.value;
        if (this.value) {
            this.valueArray = this.value.split(', ');
        }
    };
    MultiValueCell.prototype.getValue = function () {
        return this.value;
    };
    return MultiValueCell;
}());
exports.MultiValueCell = MultiValueCell;
