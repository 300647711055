import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export enum ExportOption {
  All = 'All',
  Selected = 'Selected',
};

type DialogData = { selected: boolean, imagesExportable: boolean };

type ExportCallback = (exportOption: ExportOption, includeImages: boolean) => void;

@Component({
  selector: 'export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent implements OnInit {
  public exportOptions = ExportOption;

  includeImages = false;
  imagesExportable = true;
  exportOption = ExportOption.All;

  public callback: ExportCallback = () => {};

  constructor(
    public dialogRef: MatDialogRef<ExportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if (this.data.selected) {
      this.exportOption = ExportOption.Selected;
    }
    if (this.data.hasOwnProperty('imagesExportable')) {
      this.imagesExportable = this.data.imagesExportable;
    }
  }

  onSubmit() {
    this.callback(this.exportOption, this.includeImages);
    this.dialogRef.close();
  }
}
