<mat-toolbar class="toolbar" *ngIf="!router.url.includes('external')">
  <div class="toolbar__logo-nav">
    <div class="toolbar__logo-nav__menu-button" (click)="onHamburgerClick()">
      <i class="icon-rows"></i>
    </div>
    <div class="toolbar__logo-nav__logo">
      <img src="assets/img/nav-logo.svg" alt="Ruckit Logo" />
    </div>
  </div>
  <div class="toolbar__title">
    <span>Ticket Manager</span>
  </div>
  <div class="toolbar__user" [matMenuTriggerFor]="menu">
    <div class="toolbar__user__image">
      <span *ngIf="user && !user.image">{{ user && user.name && user.name[0] }}</span>
      <img *ngIf="user && user.image" [src]="user.image" />
    </div>
    <div class="toolbar__user__info">
      <div *ngIf="user && user.name" class="toolbar__user__info__name">
        {{ user.name }}
      </div>
      <div *ngIf="user && user.organization && user.organization.name" class="toolbar__user__info__org">
        {{ user.organization.name }}
      </div>
    </div>
    <div class="toolbar__user__toggle"></div>
    <mat-menu #menu="matMenu">
      <a mat-menu-item routerLink="/logout">Logout</a>
    </mat-menu>
  </div>
</mat-toolbar>
