<div class="container">
  <div class="grid-display">
    <div class="title">Customers</div>
    <div class="customer-reference-grid-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <button *ngIf="!hasQuickbooksEnabled" class="btn btn-primary add-customer" [disabled]="hasQuickbooksEnabled" (click)="onAddCustomerClick()">Add New Customer Reference</button>
    </div>
    <ag-grid-angular
      #customerGrid
      class="ag-theme-material customer-grid"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [enableSorting]="true"
      [enableColResize]="true"
      [pagination]="true"
      [rowModelType]="rowModelType"
      [cacheBlockSize]="cacheBlockSize"
      [maxBlocksInCache]="maxBlocksInCache"
      [frameworkComponents]="frameworkComponents"
      [sideBar]="sideBar"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
      (gridReady)="onGridReady($event)"
      rowSelection="multiple"
      suppressBrowserResizeObserver="true"
    ></ag-grid-angular>
  </div>
</div>

