<form #generatePdfConfirmation="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Send {{ type | titlecase | translate }}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content" *ngIf="!sent">
      <h2 translate>Send Invoice #{{ number }}</h2>
      <h3 translate>We will generate a PDF link for this {{ type | translate }} and send it to:</h3>
      <input name="recipient-email" type="email" placeholder="{{ 'Recipient Email' | translate }}" [(ngModel)]="recipientEmail" email/>
    </div>
    <div class="body form-content" *ngIf="sent">
      <h2 translate>Invoice #{{ number }} Sent!</h2>
      <h3 translate>You will receive a link in your <b>{{ recipientEmail }}</b> inbox in the next few minutes.</h3>
    </div>
    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>
  <div class="modal-footer">
    <button *ngIf="!sent" class="btn btn-primary-alt" (click)="send()" translate>
      Generate and Send Invoice
    </button>
    <button *ngIf="sent" class="btn btn-primary" (click)="dialogRef.close()" translate>
      Close
    </button>
  </div>
</form>
