"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".body[_ngcontent-%COMP%]{padding-top:20px}  .mat-form-field-appearance-legacy .mat-form-field-infix{padding:0;border:none}button[_ngcontent-%COMP%]{margin-right:10px}a[_ngcontent-%COMP%]{text-decoration:none;color:#aaa}a.back[_ngcontent-%COMP%]{color:#2a81d7;position:absolute;top:110px}"];
exports.styles = styles;
