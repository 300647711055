"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var http_loader_1 = require("@ngx-translate/http-loader");
var environment_1 = require("../../environments/environment");
function HttpLoaderFactory(http) {
    return new http_loader_1.TranslateHttpLoader(http, './assets/i18n/');
}
exports.HttpLoaderFactory = HttpLoaderFactory;
var mapsApiUrl = 'https://maps.google.com/maps/api/js?libraries=geometry,visualization,places,drawing&key=' +
    environment_1.environment.googleMapsKey;
var ɵ0 = { useUtc: true };
exports.ɵ0 = ɵ0;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
exports.SharedModule = SharedModule;
