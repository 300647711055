"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/http");
var lodash_1 = require("lodash");
var Sentry = require("@sentry/browser");
var environment_1 = require("../../environments/environment");
var user_serializer_1 = require("../users/user.serializer");
var data_service_1 = require("./data.service");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var api_service_1 = require("../shared/api.service");
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, dataService) {
        this.http = http;
        this.dataService = dataService;
        this.loggedIn = false;
        this.ruckit = false;
        this.scaleit = false;
        this.posEnabled = false;
        this.advancedBilling = false;
        this.allDriversEnabled = false;
        this.sidebar = true;
        this.createJobs = false;
        this.leasedOrgs = false;
        this.favoriteTags = false;
        this.baseUrl = environment_1.environment.serverUrl;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    AuthenticationService.prototype.login = function (username, password) {
        var params = { username: username, password: password };
        var headers = new http_1.Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + 'auth/login/', params, { headers: headers }).pipe(operators_1.map(this.extractData), operators_1.catchError(function (error) {
            return rxjs_1.throwError(api_service_1.parseErrors(error));
        }), operators_1.map(this.storeUser));
    };
    AuthenticationService.prototype.logout = function () {
        // clear token and remove user from local storage to log out
        this.token = null;
        this.loggedIn = false;
        this.dataService.changeData({ authenticated: false, sidebar: false });
        localStorage.removeItem('currentUser');
        localStorage.removeItem('ticketFilters');
        Sentry.configureScope(function (scope) {
            scope.setUser({});
        });
    };
    AuthenticationService.prototype.forgot = function (username) {
        var params = { username: username };
        var headers = new http_1.Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + 'auth/forgot/', params, { headers: headers }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }), operators_1.catchError(function (error) {
            return rxjs_1.throwError(api_service_1.parseErrors(error));
        }));
    };
    AuthenticationService.prototype.reset = function (token, uid, password) {
        var params = { token: token, uid: uid, password: password };
        var headers = new http_1.Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + 'auth/reset/', params, { headers: headers }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }), operators_1.catchError(function (error) {
            return rxjs_1.throwError(api_service_1.parseErrors(error));
        }));
    };
    AuthenticationService.prototype.getProfile = function (token, uid) {
        var params = new http_1.URLSearchParams();
        params.set('token', token);
        params.set('uid', uid);
        var requestOptions = new http_1.RequestOptions();
        requestOptions.search = params;
        return this.http.get(this.baseUrl + 'auth/reset/', requestOptions).pipe(operators_1.map(this.extractData), operators_1.catchError(function (error) {
            return rxjs_1.throwError(api_service_1.parseErrors(error));
        }));
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        var storedUser = localStorage.getItem('currentUser');
        var currentUser = storedUser && JSON.parse(storedUser);
        if (currentUser && currentUser.token) {
            this.loggedIn = true;
        }
        return this.loggedIn;
    };
    AuthenticationService.prototype.user = function () {
        var storedUser = localStorage.getItem('currentUser');
        var currentUser = storedUser && JSON.parse(storedUser);
        return currentUser;
    };
    AuthenticationService.prototype.isRuckit = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.ruckit) {
            this.ruckit = true;
        }
        return this.ruckit;
    };
    AuthenticationService.prototype.isScaleit = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.posEnabled) {
            this.scaleit = true;
        }
        return this.scaleit;
    };
    AuthenticationService.prototype.hasPOSEnabled = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.posEnabled) {
            this.posEnabled = true;
        }
        return this.posEnabled;
    };
    AuthenticationService.prototype.hasAdvancedBilling = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.advancedBilling) {
            this.advancedBilling = true;
        }
        return this.advancedBilling;
    };
    AuthenticationService.prototype.hasAllDriversEnabled = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.allDriversEnabled) {
            this.allDriversEnabled = true;
        }
        return this.allDriversEnabled;
    };
    AuthenticationService.prototype.canCreateJobs = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if ((currentUser && currentUser.canCreateJobs) ||
            (currentUser && currentUser.organization && currentUser.organization.can_create_jobs)) {
            this.createJobs = true;
        }
        return this.createJobs;
    };
    AuthenticationService.prototype.hasLeasedOrgs = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.hasLeasedOrgs) {
            this.leasedOrgs = true;
        }
        return this.leasedOrgs;
    };
    AuthenticationService.prototype.hasFavoriteTags = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.favoriteTags && currentUser.favoriteTags.length > 0) {
            this.favoriteTags = true;
        }
        return this.favoriteTags;
    };
    AuthenticationService.prototype.getOrganization = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.organization) {
            return currentUser.organization;
        }
        return null;
    };
    AuthenticationService.prototype.updateOrganization = function (organization) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            currentUser.organization = organization;
            this.storeUser(currentUser);
        }
    };
    AuthenticationService.prototype.myFavoriteTags = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser.favoriteTags && currentUser.favoriteTags.map(function (tag) { return tag.name; });
    };
    AuthenticationService.prototype.getFilterProperty = function (filter) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.hasOwnProperty('filters')) {
            return currentUser.filters[filter];
        }
        else {
            return false;
        }
    };
    AuthenticationService.prototype.setFilterProperty = function (filter, state) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && !currentUser.hasOwnProperty('filters')) {
            currentUser['filters'] = {};
        }
        currentUser['filters'][filter] = state;
        this.storeUser(currentUser);
    };
    AuthenticationService.prototype.displaySidebar = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.sidebar === false) {
            this.sidebar = false;
        }
        else {
            this.sidebar = true;
        }
        return this.sidebar;
    };
    AuthenticationService.prototype.extractData = function (res) {
        var body = res.json().results;
        if (body) {
            return body.map(function (user) {
                user = camelcaseKeysDeep(user);
                return (new user_serializer_1.UserSerializer).fromJson(user);
            });
        }
        else if (res.json()) {
            var user = camelcaseKeysDeep(res.json());
            return (new user_serializer_1.UserSerializer).fromJson(user);
        }
        else {
            return [];
        }
    };
    AuthenticationService.prototype.storeUser = function (user) {
        if (user.token) {
            // set token property
            this.token = user.token;
            var enabledFeatures = [];
            if (user.organization && user.organization.enabledFeatures) {
                enabledFeatures = enabledFeatures.concat(user.organization.enabledFeatures);
            }
            if (user.enabledFeatures) {
                enabledFeatures = enabledFeatures.concat(user.enabledFeatures);
            }
            var userInfo_1 = {
                username: user.email,
                name: user.name,
                organization: user.organization,
                product: 'ticket-manager',
                driver: user.isDriver,
                carrier: user.isCarrier,
                ruckit: user.isRuckit,
                id: user.id,
                canCreateJobs: user.organization && user.organization.canCreateJobs,
                posEnabled: user.organization && user.organization.posEnabled,
                advancedBilling: user.organization && user.organization.advBillingEnabled,
                scaleit: user.organization && user.organization.posEnabled,
                allDriversEnabled: user.organization && user.organization.allDriversEnabled,
                hasLeasedOrgs: user.organization && user.organization.hasLeasedOrgs,
                favoriteTags: user.favoriteTags, filters: user['filters'] || {},
                enabledFeatures: lodash_1.uniq(enabledFeatures)
            };
            // store user data and JWT token in local storage to persist user session
            localStorage.setItem('currentUser', JSON.stringify(__assign({}, userInfo_1, { token: user.token, image: user.image })));
            // return true to indicate successful login
            this.loggedIn = true;
            // sentry integration
            Sentry.configureScope(function (scope) {
                scope.setUser(userInfo_1);
            });
            // fullstory integration
            var fullstory = window.FS;
            if (fullstory) {
                fullstory.setUserVars({
                    product: 'ticket-manager',
                });
                fullstory.identify(user.id, __assign({}, userInfo_1, { email: user.email, displayName: user.email, product: 'ticket-manager' }));
            }
            return true;
        }
        else {
            // return false to indicate failed login
            this.loggedIn = true;
            return false;
        }
    };
    AuthenticationService.prototype.enabledFeatures = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser && currentUser.enabledFeatures ? currentUser.enabledFeatures : [];
    };
    AuthenticationService.prototype.getFeature = function (feature) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        try {
            if (currentUser.features && currentUser.features[feature]) {
                return currentUser.features[feature];
            }
            if (currentUser.organization.features && currentUser.organization.features[feature]) {
                return currentUser.organization.features[feature];
            }
        }
        catch (_a) {
            return null;
        }
    };
    return AuthenticationService;
}());
exports.AuthenticationService = AuthenticationService;
