<div class="container">
  <div class="grid-display">
    <div class="title">Trucks</div>
    <div class="truck-reference-grid-actions">
      <div class="search-container">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <button class="btn btn-primary add-truck" (click)="onAddTruckClick()">{{ 'Add New Truck Reference' | translate }}</button>
    </div>
    <ag-grid-angular
      #trucksGrid
      class="ag-theme-material trucks-grid"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [pagination]="true"
      [rowModelType]="rowModelType"
      [cacheBlockSize]="cacheBlockSize"
      [maxBlocksInCache]="maxBlocksInCache"
      [sideBar]="sideBar"
      [frameworkComponents]="frameworkComponents"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
      (gridReady)="onGridReady($event)"
      rowSelection="multiple"
      suppressBrowserResizeObserver="true"
    ></ag-grid-angular>
  </div>
</div>
