import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

import { UploadService, S3PolicyData } from '../upload.service';
import { switchMap } from 'rxjs/operators';
import shortid = require('shortid');
import { NotificationsService, Notification, NotificationType } from '../notification/notifications.service';

@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent {
  @ViewChild('imageEditor') imageEditor!: CropperComponent;
  @Input() id!: string;
  @Input() imageUrl!: string;
  @Input() external = false;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onImageClick: EventEmitter<any> = new EventEmitter();
  loading = false;
  error = false;

  config = {
    autoCrop: false,
    autoCropArea: 1,
    checkOrientation: false,
    background: false,
    modal: false,
    guides: false,
    center: false,
    highlight: false,
    movable: false,
    scalable: false,
    zoomable: false,
    viewMode: 0,
    strict: false,
    dragCrop: false,
    rotatable: true,
    cropBoxMovable: false,
    cropBoxResizable: false,
    responsive: true
  };

  constructor(
    public uploadService: UploadService,
    public notificationsService: NotificationsService
  ) {}

  imageError(): boolean {
    if (this.imageEditor && !this.error) {
      this.error = this.imageEditor.loadError;
      if (this.error) { this.pushError(); }
    }
    return this.error;
  }

  editableImage(): boolean {
    return !this.imageError() && this.imageUrl.match(/.(jpg|jpeg|png|gif)$/i) !== null &&
           this.imageUrl.includes('amazonaws') && !this.imageUrl.includes('twilio');
  }

  ngOnChanges() {
    if (this.imageEditor && this.imageEditor.cropper) {
      this.imageEditor.cropper.replace(this.imageUrl).destroy();
    }
  }

  save(e?: any) {
    this.loading = true;
    let editTime: string = Date.now().toString();
    let imageFile: File = e ? e.target.files[0] : this.uploadService.convertDataUriToFile(this.imageEditor.cropper.getCroppedCanvas().toDataURL('image/jpeg'), 'edited-' + editTime + '.jpg');
    let imageKey: string = this.imageUrl.includes('tickets') ?
      'tickets/' + this.imageUrl.substring(0, this.imageUrl.lastIndexOf('/') + 1).split('tickets/')[1] :
      this.imageUrl.includes('checkins') ?
      'tickets/' + this.imageUrl.substring(0, this.imageUrl.lastIndexOf('/') + 1).split('checkins/')[1] :
      this.imageUrl.substring(this.imageUrl.indexOf('/', 10) + 1, this.imageUrl.lastIndexOf('/') + 1);
    let updatedKey: string = imageKey + 'edited-' + editTime + '.jpg';
    this.uploadService.getS3Policy().pipe(
      switchMap((policy: S3PolicyData) => {
        policy.fields.key = updatedKey;
        return this.uploadService.uploadToS3(policy, imageFile);
      })
    ).subscribe(() => {
      if (e) {
        this.imageEditor && this.imageEditor.cropper &&
        this.imageEditor.cropper.replace(
          this.imageUrl.substring(0, this.imageUrl.lastIndexOf('/') + 1).replace('checkins', 'tickets') + 'edited-' + editTime + '.jpg'
        );
      }
      this.onSave.emit({ id: this.id, imageUrl: this.imageUrl.substring(0, this.imageUrl.lastIndexOf('/') + 1).replace('checkins', 'tickets') + 'edited-' + editTime + '.jpg' });
      this.loading = false;
    });
  }

  rotate(degrees: number) {
    this.imageEditor.cropper.rotate(degrees);
  }

  imageClick() {
    this.onImageClick.emit(true);
  }

  pushError() {
    const notification: Notification = {
      context: {},
      id: shortid.generate(),
      message: 'An error occured loading this image in the editor. Please reload and try again.',
      originator: 'image-editor',
      type: NotificationType.Danger,
    };

    this.notificationsService.addNotification(notification);
  }
}
