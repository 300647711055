"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("./reviewer-cell.component");
var styles_ReviewerCell = [".value[_ngcontent-%COMP%] {\n      height: 50%;\n      font-weight: 600;\n      line-height: 34px;\n    }\n    .label[_ngcontent-%COMP%] {\n      opacity: 0.75;\n      height: 50%;\n      font-size: 12px;\n      line-height: 16px;\n    }"];
var RenderType_ReviewerCell = i0.ɵcrt({ encapsulation: 0, styles: styles_ReviewerCell, data: {} });
exports.RenderType_ReviewerCell = RenderType_ReviewerCell;
function View_ReviewerCell_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.value.amount)) || "N/A"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.value.reviewer && ("By " + _co.value.reviewer)); _ck(_v, 4, 0, currVal_1); }); }
exports.View_ReviewerCell_0 = View_ReviewerCell_0;
function View_ReviewerCell_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "reviewer-cell", [], null, null, null, View_ReviewerCell_0, RenderType_ReviewerCell)), i0.ɵdid(1, 49152, null, 0, i2.ReviewerCell, [], null, null)], null, null); }
exports.View_ReviewerCell_Host_0 = View_ReviewerCell_Host_0;
var ReviewerCellNgFactory = i0.ɵccf("reviewer-cell", i2.ReviewerCell, View_ReviewerCell_Host_0, {}, {}, []);
exports.ReviewerCellNgFactory = ReviewerCellNgFactory;
