"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ticket_service_1 = require("../../../tickets/ticket.service");
var TruckReferenceCell = /** @class */ (function () {
    function TruckReferenceCell(ticketService) {
        this.ticketService = ticketService;
        this.value = '';
    }
    TruckReferenceCell.prototype.agInit = function (params) {
        this.params = params;
        if (params && params.api) {
            this.gridApi = params.api;
        }
    };
    TruckReferenceCell.prototype.ngAfterViewInit = function () { };
    TruckReferenceCell.prototype.getValue = function () {
        if (this.reference) {
            return this.reference.truckNumber;
        }
        var cell = this.gridApi.getFocusedCell();
        var row = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
        return row && row.data && row.data.truckNumber ? row.data.truckNumber : '';
    };
    TruckReferenceCell.prototype.isCancelBeforeStart = function () {
        return false;
    };
    TruckReferenceCell.prototype.isCancelAfterEnd = function () {
        return false;
    };
    TruckReferenceCell.prototype.isPopup = function () {
        return true;
    };
    TruckReferenceCell.prototype.onReferenceClicked = function (reference) {
        var _this = this;
        var cell = this.gridApi.getFocusedCell();
        var row = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
        this.ticketService.updateFieldReference(row.data.id, reference, 'truck').subscribe(function (ticket) {
            _this.reference = reference;
            row.setData(ticket);
            _this.gridApi.stopEditing();
        });
    };
    TruckReferenceCell.prototype.onReferenceClosed = function () {
        this.gridApi.stopEditing();
    };
    return TruckReferenceCell;
}());
exports.TruckReferenceCell = TruckReferenceCell;
