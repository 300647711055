import { Component, ViewChild } from '@angular/core';
import { AutocompleteCell } from '../../../shared/grid/autocomplete-cell.component';
import { DatepickerCell } from '../../../shared/grid/datepicker-cell.component';
import { AgGridNg2 } from 'ag-grid-angular';
import { ValueSetterParams, GridReadyEvent, ColumnApi, GridApi } from 'ag-grid-community';
import { CellContextMenuEvent } from 'ag-grid-community/dist/lib/events';
import { MatDialog } from '@angular/material';
import { BulkActionDialogComponent } from '../../../shared/grid/bulk-action-dialog.component';
import { zip, Observable, Subject } from 'rxjs';
import { SelectAllHeader } from '../../../shared/grid/select-all-header.component';
import { TruckReferenceService } from '../truck-reference.service';
import { TruckReference } from '../truck-reference';
import { TruckReferenceCreateModalComponent } from '../truck-reference-create-modal/truck-reference-create-modal.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NoResultsComponent } from '../../../../app/shared';

@Component({
  selector: 'truck-reference-grid',
  templateUrl: './truck-reference-grid.component.html',
  styleUrls: ['../../../../style/grid.scss', './truck-reference-grid.component.scss'],
  providers: [TruckReferenceService],
})
export class TruckReferenceGridComponent {
  @ViewChild('trucksGrid') trucksGrid!: AgGridNg2;
  columnApi!: ColumnApi;
  gridApi!: GridApi;

  frameworkComponents = {
    autocompleteCell: AutocompleteCell,
    datepickerCell: DatepickerCell,
    selectAllHeader: SelectAllHeader,
    customNoRowsOverlay: NoResultsComponent,
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'truck references',
    onClick: () => this.onAddTruckClick(),
  };

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel'
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ]
  };

  columnDefs = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 70,
      editable: false,
      checkboxSelection: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.truckReferenceService,
        selected: this.truckReferenceService.allSelected
      }
    },
    {
      headerName: 'License Plate',
      field: 'licensePlate',
      sortable: true,
      resizable: true,
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Truck Code',
      field: 'truckCode',
      sortable: true,
      resizable: true,
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Truck Number',
      field: 'truckNumber',
      sortable: true,
      resizable: true,
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Truck Type',
      field: 'truckType',
      sortable: true,
      resizable: true,
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
  ];

  rowModelType = 'serverSide';
  cacheBlockSize = 100;
  maxBlocksInCache = 10;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();

  constructor(
    private truckReferenceService: TruckReferenceService,
    private bulkDeleteDialog: MatDialog,
    private truckReferenceCreateModal: MatDialog,
  ) {
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.search = search;
      this.truckReferenceService.search = this.search;
      this.refreshTable();
    });
  }

  getContextMenuItems(params: any) {
    return [
      {
        name: 'Delete',
        action: () => this.onDeleteTruckReference(params)
      },
    ];
  }

  onDeleteTruckReference(params: CellContextMenuEvent) {
    if (!params || !params.api) {
      return;
    }

    let selected = params.api.getSelectedRows();
    if (!selected || !selected.length) {
      selected = [params.node.data];
      params.node.setSelected(true);
    }
    const dialog = this.bulkDeleteDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected
      },
    });

    dialog.componentInstance.callback = () => {
      const jobs = zip(
        ...selected.map((truck: TruckReference) => {
          return this.deleteTruckReference(truck);
        })
      );

      jobs.subscribe(() => {
        this.refreshTable(true);
      });
    };
  }

  deleteTruckReference(truck: TruckReference): Observable<any> {
    return this.truckReferenceService.remove(truck);
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  onCellValueChanged(e: ValueSetterParams): void {
    const truck: TruckReference = { ...e.data };

    delete truck.classes;
    delete truck.createdAt;
    delete truck.loading;
    delete truck.selected;

    this.truckReferenceService.save(truck).subscribe();
  }

  changeSearch(term?: string): void {
    this.searchChanged.next(term);
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.columnApi || !e.api) {
      return;
    }

    this.columnApi = e.columnApi;
    this.gridApi = e.api;

    this.trucksGrid.gridOptions.getContextMenuItems = (params) => this.getContextMenuItems(params);

    e.api.setServerSideDatasource(this.truckReferenceService);
  }

  onFirstDataRendered(): void {
    this.autoSizeAll();
  }

  autoSizeAll(): void {
    const allColumnIds: any[] = [];

    this.columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  onAddTruckClick(): void {
    const dialog = this.truckReferenceCreateModal.open(TruckReferenceCreateModalComponent, {
      width: '500px',
    });

    dialog.componentInstance.callback = (response$: Observable<TruckReference>) => {
      response$.subscribe(() => this.refreshTable());
    };
  }
}
