"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent() {
        this.level = 'danger';
        this.open = true;
        this.transition = '';
    }
    NotificationComponent.prototype.close = function () {
        var _this = this;
        this.transition = 'fade';
        setTimeout(function () {
            _this.open = false;
        }, 1000);
        setTimeout(function () {
            _this.transition = 'fade slide';
        }, 500);
    };
    NotificationComponent.prototype.reset = function () {
        this.transition = '';
        this.open = true;
    };
    return NotificationComponent;
}());
exports.NotificationComponent = NotificationComponent;
