"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var organization_service_1 = require("../organizations/organization.service");
var authentication_service_1 = require("../shared/authentication.service");
var api_service_1 = require("../shared/api.service");
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(route, authenticationService, organizationService, dialog) {
        this.route = route;
        this.authenticationService = authenticationService;
        this.organizationService = organizationService;
        this.dialog = dialog;
        this.loading = true;
        this.errors = [];
        this.tab = 'organization';
        this.user = this.authenticationService.user();
        this.quickbooksVisible = false;
        this.saveOrganizationCallback = function () { };
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.tab = params['tab'];
            _this.getOrganization();
        });
    };
    SettingsComponent.prototype.getOrganization = function () {
        var _this = this;
        this.loading = true;
        if (this.organizationReq) {
            this.organizationReq.unsubscribe();
        }
        var org = this.user.organization;
        if (org) {
            this.organizationReq = this.organizationService.get(org.id).
                subscribe(function (organization) {
                _this.organization = organization;
                _this.quickbooksVisible = organization.features && organization.features.quickbooks;
                _this.loading = false;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    return SettingsComponent;
}());
exports.SettingsComponent = SettingsComponent;
