"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var report_service_1 = require("../report/report.service");
var shared_1 = require("../../shared");
var SendReportDialogComponent = /** @class */ (function () {
    function SendReportDialogComponent(reportService, dialogRef, data) {
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.errors = [];
        this.type = 'report';
        this.sent = false;
        this.type = data['type'] ? data['type'] : this.type;
        this.reportId = data['id'];
        this.number = data['number'];
    }
    SendReportDialogComponent.prototype.ngOnInit = function () { };
    SendReportDialogComponent.prototype.send = function () {
        var _this = this;
        this.reportService.send(this.reportId, this.recipientEmail).subscribe(function () {
            _this.sent = true;
            _this.callback();
            setTimeout(function () { return _this.dialogRef.close(); }, 5000);
        }, function (err) { _this.errors = shared_1.parseErrors(err); });
    };
    return SendReportDialogComponent;
}());
exports.SendReportDialogComponent = SendReportDialogComponent;
