"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".password-reset-form[_ngcontent-%COMP%]{width:370px}.password-reset-form[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{font-size:26px}.password-reset-form[_ngcontent-%COMP%]   form[_ngcontent-%COMP%]{width:100%;float:left;clear:both}.password-reset-header[_ngcontent-%COMP%]{float:left;margin-bottom:40px}.password-reset-header[_ngcontent-%COMP%] > .user-photo[_ngcontent-%COMP%]{float:left}.password-reset-header[_ngcontent-%COMP%] > .user-photo[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:100%;height:100%;margin-right:30px;border-radius:50%;vertical-align:middle}.password-reset-header[_ngcontent-%COMP%] > .user-photo[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{color:#fff;font-weight:400;font-size:36px;line-height:52px;text-align:center;display:inline-block;width:52px;height:52px;background:#4990e2;margin-right:30px;border-radius:50%;vertical-align:middle}.password-reset-header[_ngcontent-%COMP%]   .user-details[_ngcontent-%COMP%]{float:left;height:52px;padding-top:4px}.password-reset-header[_ngcontent-%COMP%]   .user-details[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{font-size:16px;font-weight:600;color:#354052;display:inline;margin:0}.password-reset-header[_ngcontent-%COMP%]   .user-details[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-weight:400;font-size:12px;color:#7f8fa4;display:inline}.field-group[_ngcontent-%COMP%]{margin-bottom:4px}.field-group[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{color:#7f8fa4;font-size:14px;margin:12px 0 5px;display:block}ruckit-button[_ngcontent-%COMP%]{margin-top:40px;float:right}.alert[_ngcontent-%COMP%]{margin-bottom:20px}"];
exports.styles = styles;
