"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var moment = require("moment");
var decimal_js_1 = require("decimal.js");
var ag_grid_angular_1 = require("ag-grid-angular");
var multi_value_cell_component_1 = require("../../shared/grid/multi-value-cell.component");
var send_report_dialog_component_1 = require("../send-report-dialog/send-report-dialog.component");
var arrange_modal_component_1 = require("../../shared/arrange-modal/arrange-modal.component");
var authentication_service_1 = require("../../shared/authentication.service");
var preferences_service_1 = require("../../shared/preferences/preferences.service");
var preferences_1 = require("../../shared/preferences/preferences");
var ticket_service_1 = require("../../tickets/ticket.service");
var charge_service_1 = require("../../charges/charge.service");
var shared_1 = require("../../shared");
var report_service_1 = require("../report/report.service");
var ActionType;
(function (ActionType) {
    ActionType["AutosizeColumns"] = "Autosize Columns";
    ActionType["ArrangeTickets"] = "Arrange Tickets";
    ActionType["LinkTickets"] = "Link Tickets";
    ActionType["UnlinkTickets"] = "Unlink Tickets";
})(ActionType || (ActionType = {}));
var ReportBuilderComponent = /** @class */ (function () {
    function ReportBuilderComponent(router, route, preferencesService, authenticationService, ticketService, chargeService, reportService, sendReportDialog, arrangeModal) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.preferencesService = preferencesService;
        this.authenticationService = authenticationService;
        this.ticketService = ticketService;
        this.chargeService = chargeService;
        this.reportService = reportService;
        this.sendReportDialog = sendReportDialog;
        this.arrangeModal = arrangeModal;
        this.errors = [];
        this.loading = false;
        this.static = false;
        this.chargeList = [];
        this.checkboxes = [];
        this.actionOptions = [];
        this.columnDefs = [
            {
                colId: 'chargeIndex',
                valueGetter: 'node.level === 0 ? node.childIndex + 1 : ""',
                cellStyle: {
                    'font-size': '15px',
                    'font-weight': '800',
                    'white-space': 'normal !important'
                },
                editable: false,
                pinned: 'left',
                suppressMovable: true,
                lockPinned: true,
                suppressMenu: true,
                suppressNavigable: true
            },
            {
                headerName: 'Ticket Date',
                field: 'blob.ticket.ticketDate',
                editable: false,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Ticket #',
                field: 'blob.ticket.ticketNumber',
                autoHeight: true,
                editable: false,
                suppressMenu: true,
                cellRendererFramework: multi_value_cell_component_1.MultiValueCell,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Driver',
                field: 'blob.ticket.driverName',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Truck',
                field: 'blob.ticket.truckNumber',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Job',
                field: 'blob.ticket.jobName',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Origin',
                field: 'blob.ticket.origin',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Destination',
                field: 'blob.ticket.destination',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Customer',
                field: 'blob.ticket.customerName',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Material',
                field: 'blob.ticket.material',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Carrier Name',
                field: 'blob.ticket.carrierName',
                cellStyle: { 'white-space': 'normal !important' },
                autoHeight: true,
                editable: true,
                hide: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'QTY',
                field: 'quantity',
                valueGetter: 'Number(data.quantity)',
                editable: true,
                suppressMenu: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Type',
                field: 'blob.ticket.quantityType',
                editable: true,
                hide: true,
                suppressMenu: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Rate',
                field: 'rate',
                valueFormatter: currencyFormatter,
                editable: true,
                onCellValueChanged: function (e) { return _this.onCellValueChange(e); },
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Total',
                field: 'total',
                valueGetter: 'Number(data.total)',
                valueFormatter: currencyFormatter,
                cellStyle: { 'text-align': 'right' },
                width: 90,
                editable: false,
                pinned: 'right',
                suppressMovable: true,
                lockPinned: true,
                lockVisible: true,
                resizable: true,
                sortable: true,
            },
        ];
        this.user = this.authenticationService.user();
        this.preferences = {
            blob: {
                reportData: {},
                columnData: []
            }
        };
        this.report = {};
        this.reportImages = [];
        this.imagesVisible = true;
        this.unbillableVisible = false;
        this.isDriverFieldVisible = false;
        this.isCustomerFieldVisible = false;
        this.surcharges = 0;
        this.expandedColumns = [];
    }
    ReportBuilderComponent.prototype.numberOfTicketsSelected = function () {
        return this.gridApi && this.gridApi.getSelectedRows().length;
    };
    ReportBuilderComponent.prototype.enableLinking = function () {
        var selectedCharges = this.gridApi && this.gridApi.getSelectedRows();
        return selectedCharges.length > 1 && selectedCharges.every(function (charge) { return charge.attachments.length === 0; });
    };
    ReportBuilderComponent.prototype.enableEditing = function () {
        var selectedCharges = this.gridApi && this.gridApi.getSelectedRows();
        return selectedCharges.length === 1 && selectedCharges[0].attachments.length > 0;
    };
    ReportBuilderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.url, this.route.params, this.route.queryParams, function (url, params, qparams) { return ({ url: url, params: params, qparams: qparams }); });
        combinedParams.subscribe(function (result) {
            _this.loading = true;
            if (result.url && result.url[0]) {
                if (result.url[0].path === 'settlements') {
                    _this.reportType = 'expense';
                }
                else {
                    _this.reportType = 'invoice';
                }
            }
            if (result.url && result.url[2].path === 'static') {
                _this.static = true;
                _this.staticToken = result.qparams['token'];
                _this.getStaticReport(result.params['id'], result.qparams['token']);
            }
            else {
                _this.getReport(result.params['id']);
            }
        });
    };
    ReportBuilderComponent.prototype.getReport = function (id) {
        var _this = this;
        this.reportService.get(id).subscribe(function (report) { return _this.setReportData(report); });
    };
    ReportBuilderComponent.prototype.getStaticReport = function (id, token) {
        var _this = this;
        this.reportService.getStatic(id, token)
            .subscribe(function (report) { return _this.setReportData(report, true); }, function () { _this.errors = ['Invalid token! Please make sure your URL is correct and try again.']; });
    };
    ReportBuilderComponent.prototype.setReportData = function (report, staticReport) {
        var _this = this;
        if (staticReport === void 0) { staticReport = false; }
        if (!staticReport) {
            report.reportDate = report.reportDate ? report.reportDate : new Date().toISOString();
            report.charges.map(function (charge) {
                if (charge.blob && charge.blob.ticket) {
                    charge.quantity = Number(charge.quantity) === 0 ? charge.blob.ticket.quantity : charge.quantity;
                    charge.rate = Number(charge.rate) === 0 ? charge.blob.ticket.haulRate : charge.rate;
                    _this.getChargeTotal(charge);
                }
                return charge;
            });
        }
        this.report = report;
        this.chargeList = this.getReportCharges(report);
        this.preferences = __assign({}, this.preferences, { name: this.reportType, type: this.reportType });
        if (!this.report.dueDate) {
            this.report.dueDate = moment().format();
        }
    };
    ReportBuilderComponent.prototype.getReportCharges = function (report) {
        var chargeList = [];
        report.charges.forEach(function (charge) {
            charge.blob.dataPath = [charge.id];
            chargeList.push(charge);
            if (charge.attachments) {
                charge.attachments.forEach(function (attachment) {
                    attachment.blob.dataPath = [charge.id, attachment.id];
                    chargeList.push(attachment);
                });
            }
        });
        return chargeList;
    };
    ReportBuilderComponent.prototype.getChargeDataPath = function (charge) {
        return charge.blob.dataPath;
    };
    ReportBuilderComponent.prototype.getReportTaxTotal = function () {
        var charges = this.report && this.report.charges ? this.report.charges : [];
        var taxRate = this.report && this.report.taxRate ? this.report.taxRate : 0;
        var subtotal = charges.reduce(function (accum, charge) {
            return accum.plus(charge.total ? charge.total.toString() : 0);
        }, new decimal_js_1.Decimal(0));
        var taxTotal = subtotal.dividedBy(100).times(taxRate);
        return currencyFormatter({ value: taxTotal });
    };
    ReportBuilderComponent.prototype.print = function () {
        if (!this.static) {
            this.save(false);
        }
        this.gridApi.deselectAll();
        this.gridApi.setDomLayout('print');
        window.print();
        this.gridApi.setDomLayout('autoHeight');
    };
    ReportBuilderComponent.prototype.save = function (navigate) {
        var _this = this;
        if (navigate === void 0) { navigate = true; }
        // remove all null values in the save ticket object
        this.reportService.removeNulls(this.report);
        if (this.report.reportDate && typeof this.report.reportDate !== 'string') {
            this.report.reportDate = new Date(this.report.reportDate).toISOString();
        }
        if (this.report.dueDate && typeof this.report.dueDate !== 'string') {
            this.report.dueDate = new Date(this.report.dueDate).toISOString();
        }
        this.reportService.save(this.report).subscribe(function () {
            _this.saveReportPreferences();
        }, function (err) {
            _this.errors = _this.errors.concat(shared_1.parseErrors(err));
            throw err;
        }, function () {
            if (navigate) {
                if (_this.reportType === 'invoice') {
                    _this.router.navigate(['invoices']);
                }
                else {
                    _this.router.navigate(['settlements']);
                }
            }
        });
    };
    ReportBuilderComponent.prototype.send = function () {
        var _this = this;
        if (!this.static) {
            this.save(false);
        }
        var dialog = this.sendReportDialog.open(send_report_dialog_component_1.SendReportDialogComponent, {
            width: '500px',
            data: {
                id: this.report.id,
                type: this.reportType,
                number: this.report.reportNumber,
                email: this.user.username
            },
        });
        dialog.componentInstance.callback = function () {
            var lockReportObj = { id: _this.report.id, isLocked: true };
            _this.reportService.save(lockReportObj).subscribe();
        };
    };
    ReportBuilderComponent.prototype.getAddressString = function (data) {
        var address = data;
        return (address.street ? address.street + '\n' : '') +
            (address.address2 ? address.address2 + '\n' : '') +
            (address.city ? address.city + ', ' : '') +
            (address.state ? address.state + ' ' : '') +
            (address.zipcode ? address.zipcode : '');
    };
    ReportBuilderComponent.prototype.getReportSubtotal = function () {
        if (this.report && this.report.charges) {
            var charges = this.report.charges.reduce(function (accum, charge) {
                return accum.plus(charge.total ? charge.total.toString() : 0);
            }, new decimal_js_1.Decimal(0));
            this.report.subtotal = charges.toFixed(2);
            return currencyFormatter({ value: new decimal_js_1.Decimal(this.report.subtotal) });
        }
        else {
            return '';
        }
    };
    ReportBuilderComponent.prototype.getReportTotal = function () {
        if (this.report && this.report.charges) {
            var charges = this.report.charges.reduce(function (accum, charge) {
                return accum.plus(charge.total ? charge.total.toString() : 0);
            }, new decimal_js_1.Decimal(0));
            this.report.subtotal = charges.toFixed(2);
            var taxAmount = this.report.taxRate && new decimal_js_1.Decimal(this.report.taxRate).gt(0) ? new decimal_js_1.Decimal(this.report.taxRate).dividedBy(100).times(this.report.subtotal) : 0;
            this.report.total = new decimal_js_1.Decimal(this.report.subtotal).plus(taxAmount).plus(this.report.surchargeAmount).toFixed(2);
            return currencyFormatter({ value: new decimal_js_1.Decimal(this.report.total) });
        }
        else {
            return '';
        }
    };
    ReportBuilderComponent.prototype.getChargeTotal = function (charge) {
        var newTotal = charge.rate && charge.quantity ? decimal_js_1.Decimal.mul(charge.rate.replace('$', ''), charge.quantity).toString() : '';
        charge.total = newTotal;
    };
    ReportBuilderComponent.prototype.getReportImages = function () {
        var _this = this;
        this.reportImages = [];
        this.gridApi.forEachNode(function (row) { return _this.imageGroupSetup(row); });
        this.reportImages.sort(function (a, b) { return a.index - b.index; });
    };
    ReportBuilderComponent.prototype.toggleImages = function () {
        this.imagesVisible = !this.imagesVisible;
    };
    ReportBuilderComponent.prototype.toggleBillable = function () {
        var _this = this;
        this.unbillableVisible = !this.unbillableVisible;
        this.gridApi.forEachNode(function (row) { return row.expanded = _this.unbillableVisible; });
        this.gridApi.onGroupExpandedOrCollapsed();
    };
    ReportBuilderComponent.prototype.imageGroupSetup = function (row) {
        var _this = this;
        var imageGroup = {
            index: row.childIndex + 1,
            images: []
        };
        if (row.allChildrenCount) {
            imageGroup.index = row.childIndex + 1;
            row.allLeafChildren.forEach(function (child) {
                imageGroup.images.push({
                    number: child.data.blob.ticket.ticketNumber,
                    date: child.data.blob.ticket.ticketDate,
                    resized: _this.ticketService.getImage(child.data.blob.ticket, { x: 600, y: 480 }),
                    url: child.data.blob.ticket.image
                });
            });
            this.reportImages.push(imageGroup);
        }
        else if (row.level === 0 && row.data && row.data.blob && row.data.blob.ticket) {
            imageGroup.images.push({
                number: row.data.blob.ticket.ticketNumber,
                date: row.data.blob.ticket.ticketDate,
                resized: this.ticketService.getImage(row.data.blob.ticket, { x: 600, y: 480 }),
                url: row.data.blob.ticket.image
            });
            this.reportImages.push(imageGroup);
        }
    };
    ReportBuilderComponent.prototype.saveReportPreferences = function () {
        if (!this.static) {
            this.preferences.profile = this.user.id;
            this.preferences.blob.columnData = this.columnApi.getColumnState();
            this.preferences.blob.unbillableVisible = this.unbillableVisible;
            this.preferencesService.save(this.preferences).subscribe();
        }
    };
    ReportBuilderComponent.prototype.applyReportPreferences = function () {
        var _this = this;
        this.preferencesService.list().pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return (p.name === 'expense') || (p.name === 'invoice'); });
            return pref ? pref : new preferences_1.Preferences;
        })).subscribe(function (preferences) {
            if (preferences.blob) {
                _this.preferences = preferences;
                if (preferences.blob.columnData) {
                    _this.columnApi.setColumnState(preferences.blob.columnData);
                }
                else {
                    _this.autoSizeAll();
                }
                if (preferences.blob.unbillableVisible) {
                    _this.unbillableVisible = preferences.blob.unbillableVisible;
                    _this.gridApi.forEachNode(function (row) { return row.expanded = _this.unbillableVisible; });
                }
            }
            else {
                _this.autoSizeAll();
            }
            _this.gridApi.resetRowHeights();
            _this.setPreferences(_this.preferences);
            var eventList = [
                'gridColumnsChanged',
                'columnPinned',
                'columnVisible',
                'columnResized',
                'columnMoved',
            ];
            eventList.forEach(function (event) {
                _this.gridApi.addEventListener(event, lodash_1.debounce(function () { return _this.saveReportPreferences(); }, 300));
            });
        });
    };
    ReportBuilderComponent.prototype.getStaticPreferences = function (token) {
        var _this = this;
        this.preferencesService.listStatic(token).pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return (p.name === 'expense') || (p.name === 'invoice'); });
            return pref ? pref : new preferences_1.Preferences;
        })).subscribe(function (preferences) {
            if (preferences.blob) {
                _this.setPreferences(preferences);
                if (preferences.blob.columnData) {
                    _this.columnApi.setColumnState(preferences.blob.columnData);
                }
                if (preferences.blob.unbillableVisible) {
                    _this.unbillableVisible = preferences.blob.unbillableVisible;
                    _this.gridApi.forEachNode(function (row) { return row.expanded = _this.unbillableVisible; });
                }
            }
            else {
                _this.autoSizeAll();
            }
            _this.gridApi.resetRowHeights();
        });
    };
    ReportBuilderComponent.prototype.setPreferences = function (preferences) {
        var reportData;
        if (Object.keys(preferences.blob).find(function (key) { return key === 'invoiceData'; })) {
            reportData = preferences.blob.invoiceData;
        }
        else if (Object.keys(preferences.blob).find(function (key) { return key === 'settlementData'; })) {
            reportData = preferences.blob.settlementData;
        }
        else {
            reportData = preferences.blob.reportData;
        }
        var billFromEmpty = reportData.sender &&
            !reportData.sender.name &&
            !reportData.sender.phoneNumber &&
            !reportData.sender.address;
        if ((lodash_1.isEmpty(reportData) || billFromEmpty) && this.user && this.user.organization) {
            reportData.logo = this.user.organization.image;
            reportData.notes = this.user.organization.remittanceInfo;
            reportData.sender = {
                name: this.user.organization.name,
                phoneNumber: this.user.organization.phoneNumber,
                address: this.getAddressString(this.user.organization)
            };
            this.preferences.blob.reportData = reportData;
        }
        this.report.logo = this.report.logo ? this.report.logo : reportData.logo;
        this.report.notes = this.report.notes ? this.report.notes : reportData.notes;
        // should potentially check to see if theres a better way to see if all of the obj props are empty
        this.report.sender = this.report.sender &&
            (this.report.sender.name || this.report.sender.phoneNumber || this.report.sender.address) ?
            this.report.sender : reportData.sender;
    };
    ReportBuilderComponent.prototype.getCheckboxes = function () {
        var _this = this;
        this.checkboxes = [];
        this.gridApi.forEachNode(function (node) { return _this.constructCheckbox(node); });
    };
    ReportBuilderComponent.prototype.constructCheckbox = function (node) {
        if (node.expanded || node.level === 0) {
            var checkboxObj = {
                enabled: node.level === 0,
                height: node.rowHeight,
                selected: node.isSelected(),
                onClick: function () { node.setSelected(!node.isSelected()); },
            };
            this.checkboxes.push(checkboxObj);
        }
    };
    ReportBuilderComponent.prototype.selectCheckboxes = function () {
        var _this = this;
        this.gridApi.forEachNode(function (node) {
            if (node.expanded || node.level === 0) {
                _this.checkboxes[node.rowIndex].selected = node.isSelected();
            }
        });
    };
    ReportBuilderComponent.prototype.onGridReady = function (e) {
        var _this = this;
        if (!e.api || !e.columnApi) {
            return;
        }
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
        if (this.static) {
            this.columnApi.getAllColumns().forEach(function (column) {
                var colDef = column.getColDef();
                colDef.editable = false;
            });
            this.getStaticPreferences(this.staticToken);
        }
        else {
            this.applyReportPreferences();
        }
        this.gridApi.sizeColumnsToFit();
        this.gridApi.resetRowHeights();
        this.gridApi.setDomLayout('autoHeight');
        this.gridApi.addEventListener('modelUpdated', function () {
            _this.gridApi.forEachNode(function (row) { return row.expanded = _this.unbillableVisible; });
            _this.getReportImages();
            _this.getCheckboxes();
        });
        this.gridApi.addEventListener('rowSelected', function () { return _this.selectCheckboxes(); });
        this.gridApi.addEventListener('columnRowGroupChanged', function () { return _this.rowGroupCallback(); });
        this.gridApi.addEventListener('onGroupExpandedOrCollapsed', function () { return _this.gridApi.redrawRows(); });
        this.gridApi.addEventListener('sortChanged', function () { return _this.rowSortCallback(); });
        this.gridApi.addEventListener('displayedColumnsChanged', function () {
            _this.gridApi.sizeColumnsToFit();
            _this.gridApi.resetRowHeights();
        });
        this.columnApi.setColumnWidth('chargeId', 28);
        this.chargeGrid.gridOptions.getMainMenuItems = function () { return _this.getMainMenuItems(); };
        this.chargeGrid.gridOptions.getContextMenuItems = function (params) { return _this.getContextMenuItems(params); };
        this.getActionMenuOptions();
    };
    ReportBuilderComponent.prototype.onCellValueChange = function (e) {
        if (e.newValue === e.oldValue) {
            return;
        }
        else {
            this.getChargeTotal(e.data);
            this.chargeService.save(e.data).subscribe();
            this.gridApi.refreshCells();
        }
    };
    ReportBuilderComponent.prototype.onDeleteRow = function (params) {
        var _this = this;
        if (!params || !params.node || !params.node.data) {
            return;
        }
        this.chargeService.remove(params.node.data.id).subscribe(function () {
            var selected = [params.node.data];
            _this.gridApi.updateRowData({ remove: selected });
        });
    };
    ReportBuilderComponent.prototype.linkCharges = function () {
        var selectedCharges = this.gridApi.getSelectedRows();
        this.arrangeLinkedCharges(selectedCharges);
    };
    ReportBuilderComponent.prototype.unlinkCharges = function (charge) {
        var chargeOrderData = [{ id: charge.id, order: 0, parent: null }];
        // get group and perform a single unlinking action,
        // probably display a warning message?
        if (charge.attachments) {
            charge.attachments.forEach(function (attachment) {
                chargeOrderData.push({ id: attachment.id, order: 0, parent: null });
            });
        }
        this.bulkSaveCharges(chargeOrderData);
    };
    ReportBuilderComponent.prototype.arrangeLinkedCharges = function (charges) {
        var _this = this;
        var tickets = [];
        charges.forEach(function (charge) {
            var chargeTicketData = charge.blob.ticket;
            // mapping the charge id to the ticket object so we
            // ensure the correct parent FK is set in the callback
            chargeTicketData.id = charge.id;
            if (charge.attachments.length) {
                // reset the ticket array to only include the parent if attachments are detected
                tickets = [chargeTicketData];
                charge.attachments.forEach(function (attachedCharge) {
                    var attachedChargeTicketData = attachedCharge.blob.ticket;
                    attachedChargeTicketData.id = attachedCharge.id;
                    tickets.push(attachedChargeTicketData);
                });
            }
            else {
                tickets.push(chargeTicketData);
            }
        });
        var modal = this.arrangeModal.open(arrange_modal_component_1.ArrangeModalComponent, {
            width: '652px',
            data: tickets
        });
        modal.componentInstance.callback = function (chargeOrderData) { return _this.bulkSaveCharges(chargeOrderData); };
        modal.componentInstance.unlinkCallback = function (charge) { return _this.unlinkCharges(charge); };
    };
    ReportBuilderComponent.prototype.bulkSaveCharges = function (chargeOrderData) {
        var _this = this;
        var chargeObservables = [];
        chargeOrderData.forEach(function (chargeOrderObj) {
            chargeObservables.push(_this.chargeService.save(chargeOrderObj));
        });
        rxjs_1.combineLatest(chargeObservables).pipe(operators_1.mergeMap(function (charge) { return (_this.reportService.get(charge[0].report)); })).subscribe(function (report) {
            _this.setReportData(report);
            _this.gridApi.forEachNode(function (row) { return row.expanded = _this.unbillableVisible; });
        });
    };
    ReportBuilderComponent.prototype.rowGroupCallback = function () {
        this.gridApi.refreshCells();
        this.autoSizeAll();
        this.saveReportPreferences();
        this.getReportImages();
    };
    ReportBuilderComponent.prototype.rowSortCallback = function () {
        this.gridApi.refreshCells();
        this.getReportImages();
    };
    ReportBuilderComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.columnApi.setColumnWidth('chargeId', 70);
        this.columnApi.setColumnWidth('total', 90);
        this.gridApi.sizeColumnsToFit();
        this.gridApi.resetRowHeights();
        this.saveReportPreferences();
    };
    ReportBuilderComponent.prototype.addCharge = function () {
        var _this = this;
        var newCharge = { report: this.report.id };
        this.chargeService.save(newCharge).pipe(operators_1.mergeMap(function (charge) {
            return _this.reportService.get(charge.report);
        })).subscribe(function (report) { return _this.report = report; });
    };
    ReportBuilderComponent.prototype.getContextMenuItems = function (params) {
        var _this = this;
        var selectedCharges = this.gridApi && this.gridApi.getSelectedRows();
        var menuOptions = [];
        if (this.static) {
            menuOptions = [
                {
                    name: 'Autosize Columns',
                    action: function () { return _this.autoSizeAll(); }
                }
            ];
        }
        else {
            menuOptions = [
                'copy',
                {
                    name: 'Delete Row',
                    action: function () { return _this.onDeleteRow(params); }
                },
                'separator',
                {
                    name: 'Autosize Columns',
                    action: function () { return _this.autoSizeAll(); }
                }
            ];
            if (this.enableLinking()) {
                menuOptions.push('separator');
                menuOptions.push({ name: 'Link Tickets', action: function () { return _this.linkCharges(); } });
            }
            else if (this.enableEditing()) {
                menuOptions.push('separator');
                menuOptions.push({ name: 'Arrange Tickets', action: function () { return _this.linkCharges(); } });
                menuOptions.push({ name: 'Unlink Tickets', action: function () { return _this.unlinkCharges(selectedCharges[0]); } });
            }
        }
        return menuOptions;
    };
    ReportBuilderComponent.prototype.getActionMenuOptions = function () {
        var _this = this;
        var selectedCharges = this.gridApi.getSelectedRows();
        this.actionOptions = [
            {
                name: ActionType.AutosizeColumns,
                onClick: function () { return _this.autoSizeAll(); },
                disabled: function () { return false; },
            },
            {
                name: ActionType.LinkTickets,
                onClick: function () { return _this.linkCharges(); },
                disabled: function () { return !_this.enableLinking(); },
            },
            {
                name: ActionType.ArrangeTickets,
                onClick: function () { return _this.linkCharges(); },
                disabled: function () { return !_this.enableEditing(); },
            },
            {
                name: ActionType.UnlinkTickets,
                onClick: function () { return _this.unlinkCharges(selectedCharges[0]); },
                disabled: function () { return !_this.enableEditing(); },
            },
        ];
    };
    ReportBuilderComponent.prototype.getMainMenuItems = function () {
        var _this = this;
        return [
            {
                name: 'Autosize Columns',
                action: function () { return _this.autoSizeAll(); }
            },
            'resetColumns'
        ];
    };
    ReportBuilderComponent.prototype.onCustomerReferenceSelected = function (reference) {
        this.selectedCustomerReference = reference;
        var address = {
            street: this.selectedCustomerReference.customerAddress1,
            address2: this.selectedCustomerReference.customerAddress2,
            city: this.selectedCustomerReference.customerCity,
            state: this.selectedCustomerReference.customerState,
            zipcode: this.selectedCustomerReference.customerZipcode
        };
        this.report.customer.name = reference.customerName ? reference.customerName : '';
        this.report.customer.phoneNumber = reference.customerPhoneNumber ? reference.customerPhoneNumber : '';
        this.report.customer.address = this.getAddressString(address);
        this.report.ticketManagerCustomer = reference.id;
        this.isCustomerFieldVisible = false;
    };
    ReportBuilderComponent.prototype.onCustomerReferenceBlur = function () {
        this.isCustomerFieldVisible = false;
    };
    ReportBuilderComponent.prototype.onDriverReferenceSelected = function (reference) {
        this.selectedDriverReference = reference;
        this.report.customer.name = reference.driverName ? reference.driverName : '';
        this.report.customer.phoneNumber = reference.driverPhoneNumber ? reference.driverPhoneNumber : '';
        this.isDriverFieldVisible = false;
    };
    ReportBuilderComponent.prototype.onDriverReferenceBlur = function () {
        this.isDriverFieldVisible = false;
    };
    return ReportBuilderComponent;
}());
exports.ReportBuilderComponent = ReportBuilderComponent;
function currencyFormatter(params) {
    var negative = false;
    if (params.value < 0) {
        negative = true;
        params.value = Math.abs(params.value);
    }
    // first check if the value passed into here is a valid number,
    // then if so, create a currency formatted string that appends a dollar sign and commas appropriately
    // and rounds amount to the nearest hundredth, otherwise simply return the value unformatted
    if (!isNaN(+params.value) && isFinite(params.value)) {
        var amount = new decimal_js_1.Decimal(+params.value).toFixed(2);
        var amountDollars = new decimal_js_1.Decimal(amount.split('.')[0]).toNumber().toLocaleString();
        var amountCents = amount.split('.')[1];
        return (negative ? '-$' : '$') + amountDollars + '.' + amountCents;
    }
    else {
        return params.value;
    }
}
exports.currencyFormatter = currencyFormatter;
function sameAggFunction(values) {
    if (values.every(function (value) { return value === values[0]; })) {
        return values[0];
    }
}
exports.sameAggFunction = sameAggFunction;
function stackedAggFunction(values) {
    var stackedValues = '';
    for (var i = 0; i < values.length; i++) {
        stackedValues = i + 1 !== values.length ?
            stackedValues + values[i] + ', ' :
            stackedValues + values[i];
    }
    return stackedValues;
}
exports.stackedAggFunction = stackedAggFunction;
