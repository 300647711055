import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'multi-value-cell',
  template: `
    <ng-container *ngFor="let value of valueArray; let i = index">
      <br *ngIf="i !== 0"/>{{ value }}
    </ng-container>
  `,
  styles: [],
})
export class MultiValueCell implements ICellEditorAngularComp {
  value!: string;
  valueArray: string[] = [];
  params: any;

  isPopup(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    if (this.value) { this.valueArray = this.value.split(', '); }
  }

  getValue(): string {
    return this.value;
  }
}
