<div class="container">
  <div class="grid-display">
    <div class="title">Invoices</div>
    <div class="grid-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
    </div>
    <!-- <button class="btn btn-primary add-invoice" (click)="onAddInvoiceClick()">Add New Invoice</button> -->
    <ag-grid-angular
      #invoiceGrid
      class="ag-theme-material invoice-grid"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [pagination]="true"
      [rowModelType]="rowModelType"
      [cacheBlockSize]="cacheBlockSize"
      [maxBlocksInCache]="maxBlocksInCache"
      [frameworkComponents]="frameworkComponents"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
      (gridReady)="onGridReady($event)"
      rowSelection="multiple"
      suppressBrowserResizeObserver="true"
    ></ag-grid-angular>
  </div>
</div>
