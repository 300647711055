import { Reference } from '../reference';

export class JobReference extends Reference {
  organization?: string;
  createdAt?: string;
  destination?: string;
  haulRate?: string;
  invoiceRate?: string;
  jobCode?: string;
  jobName?: string;
  material?: string;
  origin?: string;
  orderNumber?: string;
  display = 'Job Reference';
  [key: string]: any;
}
