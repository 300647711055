"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReadOnlyCell = /** @class */ (function () {
    function ReadOnlyCell() {
    }
    ReadOnlyCell.prototype.ngAfterViewInit = function () { };
    ReadOnlyCell.prototype.agInit = function (params) {
        this.params = params;
        this.value = this.params.value;
    };
    ReadOnlyCell.prototype.getValue = function () {
        throw new Error("Method not implemented.");
    };
    return ReadOnlyCell;
}());
exports.ReadOnlyCell = ReadOnlyCell;
