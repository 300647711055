import { Component } from '@angular/core';

type Link = {
  path: string;
  label: string;
};

@Component({
  selector: 'ticket-nav-bar',
  templateUrl: './nav-bar.component.html',
  styles: [],
})
export class NavBar {
  navLinks: Link[] = [
    { path: '/tickets', label: 'Tickets' },
    { path: '/customers', label: 'Customers' },
    { path: '/jobs', label: 'Jobs' },
    { path: '/drivers', label: 'Drivers' },
    { path: '/invoices', label: 'Invoices' },
    { path: '/settlements', label: 'Driver Settlements' },
  ];
}
