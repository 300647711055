"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var StyleguideComponent = /** @class */ (function () {
    function StyleguideComponent(dialog) {
        this.dialog = dialog;
        // dropdown configuration
        this.dropdownOptions = ['thing one', 'thing two', 'thing three'];
        // dropdowns can also accept arrays of objects, with the propery "name" being displayed,
        // there is an optional 'selected', property, the value returned is the whole object. ie:
        this.actionDropdownOptions = [
            { name: 'preselected option', selected: true },
            { name: 'thing one', id: 1234124 },
            { name: 'thing 2', id: 971234976 },
            { name: 'thing 3', id: 123414212 },
        ];
        this.search = '';
        this.searchableDropdownOptions = [];
        this.searchableDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.groupedOptions = [
            { name: 'whatevs', group: 'group5' },
            { name: 'option name1', group: 'group1' },
            { name: 'option name2', group: 'group1' },
            { name: 'option name3', group: 'group1' },
            { name: 'option name4', group: 'group1' },
            { name: 'option name5', group: 'another group' },
            { name: 'option name6', group: 'group1' },
            { name: 'option name7', group: 'group2' },
            { name: 'option name8', group: 'group2' },
            { name: 'option name9', group: 'group2' },
            { name: 'option name10', group: 'group2' },
            { name: 'option name11', group: 'group2' },
            { name: 'option name11', group: 'group3' },
            { name: 'option name12', group: 'group3' },
            { name: 'option name13', group: 'group3' },
            { name: 'option name14', group: 'group3' },
            { name: 'option name15', group: 'group4' },
            { name: 'option name16', group: 'group4' },
            { name: 'option name17', group: 'group4' }
        ];
        this.groupedDropdownConfig = {
            group: true,
            groupBy: 'group' // optional, defaults to 'group'. Accepts string or function.
        };
    }
    StyleguideComponent.prototype.ngOnInit = function () {
        var userValue = localStorage.getItem('currentUser');
        this.user = userValue ? JSON.parse(userValue) : null;
        this.addMoreOptions();
    };
    StyleguideComponent.prototype.addMoreOptions = function (term) {
        if (term === void 0) { term = 'Option'; }
        for (var i = 1; i <= 100; i++) {
            this.searchableDropdownOptions.push(term + (this.searchableDropdownOptions.length + 1));
        }
    };
    StyleguideComponent.prototype.setSelectedOption = function () { };
    StyleguideComponent.prototype.timeChange = function () { };
    StyleguideComponent.prototype.dropdownSearch = function (term) {
        var _this = this;
        this.searchableDropdownOptions = [];
        this.searchableDropdownConfig.loadingOptions = true;
        this.searchableDropdownConfig = lodash_1.cloneDeep(this.searchableDropdownConfig);
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(function () {
            _this.addMoreOptions(term);
            _this.searchableDropdownConfig.loadingOptions = false;
            _this.searchableDropdownConfig = lodash_1.cloneDeep(_this.searchableDropdownConfig);
        }, 1000);
    };
    StyleguideComponent.prototype.dropdownNextPage = function () {
        var _this = this;
        this.searchableDropdownConfig.loadingOptions = true;
        this.searchableDropdownConfig = lodash_1.cloneDeep(this.searchableDropdownConfig);
        setTimeout(function () {
            _this.addMoreOptions();
            _this.searchableDropdownConfig.loadingOptions = false;
            _this.searchableDropdownConfig = lodash_1.cloneDeep(_this.searchableDropdownConfig);
        }, 1000);
    };
    StyleguideComponent.prototype.openDialog = function () {
        console.log('Open Dialog...');
        this.dialog.open(SampleDialogComponent, {
            width: '250px',
            data: { name: 'Ruckit' }
        });
    };
    return StyleguideComponent;
}());
exports.StyleguideComponent = StyleguideComponent;
var SampleDialogComponent = /** @class */ (function () {
    function SampleDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    SampleDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return SampleDialogComponent;
}());
exports.SampleDialogComponent = SampleDialogComponent;
