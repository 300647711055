"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var job_reference_1 = require("./job-reference");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var JobReferenceSerializer = /** @class */ (function () {
    function JobReferenceSerializer() {
    }
    JobReferenceSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var reference = new job_reference_1.JobReference();
        if (!json) {
            return reference;
        }
        reference.id = json.id;
        reference.organization = json.organization;
        reference.value = json.value;
        reference.createdAt = json.createdAt;
        reference.destination = json.destination;
        reference.haulRate = json.haulRate;
        reference.invoiceRate = json.invoiceRate;
        reference.jobCode = json.jobCode;
        reference.jobName = json.jobName;
        reference.material = json.material;
        reference.origin = json.origin;
        reference.orderNumber = json.orderNumber;
        return reference;
    };
    JobReferenceSerializer.prototype.toJson = function (reference) {
        var json = {
            id: reference.id,
            organization: reference.organization,
            value: reference.value,
            createdAt: reference.createdAt,
            destination: reference.destination,
            haulRate: reference.haulRate,
            invoiceRate: reference.invoiceRate,
            jobCode: reference.jobCode,
            jobName: reference.jobName,
            material: reference.material,
            origin: reference.origin,
            orderNumber: reference.orderNumber
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return JobReferenceSerializer;
}());
exports.JobReferenceSerializer = JobReferenceSerializer;
