"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ExportDialogComponent = /** @class */ (function () {
    function ExportDialogComponent(dialogRef, dialog) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.loading = false;
        this.exported = false;
        this.ticketImages = false;
        this.exportButtonLabel = 'Export Data';
        this.errors = [];
    }
    ExportDialogComponent.prototype.ngOnInit = function () {
        this.exportButtonLabel = this.exported ? 'Exported Successfully' : 'Export Failed';
    };
    ExportDialogComponent.prototype.submit = function () { };
    return ExportDialogComponent;
}());
exports.ExportDialogComponent = ExportDialogComponent;
