"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var http_1 = require("@angular/http");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var ApiService = /** @class */ (function () {
    function ApiService() {
        this.title = 'Ruckit';
        this.baseUrl = environment_1.environment.serverUrl;
        // set token if saved in local storage
        var userValue = localStorage.getItem('currentUser');
        if (userValue) {
            var currentUser = JSON.parse(userValue);
            this.token = currentUser && currentUser.token;
        }
    }
    ApiService.prototype.getStates = function () {
        return [
            { name: 'Alabama', abbreviation: 'AL' },
            { name: 'Alaska', abbreviation: 'AK' },
            { name: 'American Samoa', abbreviation: 'AS' },
            { name: 'Arizona', abbreviation: 'AZ' },
            { name: 'Arkansas', abbreviation: 'AR' },
            { name: 'California', abbreviation: 'CA' },
            { name: 'Colorado', abbreviation: 'CO' },
            { name: 'Connecticut', abbreviation: 'CT' },
            { name: 'Delaware', abbreviation: 'DE' },
            { name: 'District Of Columbia', abbreviation: 'DC' },
            { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
            { name: 'Florida', abbreviation: 'FL' },
            { name: 'Georgia', abbreviation: 'GA' },
            { name: 'Guam', abbreviation: 'GU' },
            { name: 'Hawaii', abbreviation: 'HI' },
            { name: 'Idaho', abbreviation: 'ID' },
            { name: 'Illinois', abbreviation: 'IL' },
            { name: 'Indiana', abbreviation: 'IN' },
            { name: 'Iowa', abbreviation: 'IA' },
            { name: 'Kansas', abbreviation: 'KS' },
            { name: 'Kentucky', abbreviation: 'KY' },
            { name: 'Louisiana', abbreviation: 'LA' },
            { name: 'Maine', abbreviation: 'ME' },
            { name: 'Marshall Islands', abbreviation: 'MH' },
            { name: 'Maryland', abbreviation: 'MD' },
            { name: 'Massachusetts', abbreviation: 'MA' },
            { name: 'Michigan', abbreviation: 'MI' },
            { name: 'Minnesota', abbreviation: 'MN' },
            { name: 'Mississippi', abbreviation: 'MS' },
            { name: 'Missouri', abbreviation: 'MO' },
            { name: 'Montana', abbreviation: 'MT' },
            { name: 'Nebraska', abbreviation: 'NE' },
            { name: 'Nevada', abbreviation: 'NV' },
            { name: 'New Hampshire', abbreviation: 'NH' },
            { name: 'New Jersey', abbreviation: 'NJ' },
            { name: 'New Mexico', abbreviation: 'NM' },
            { name: 'New York', abbreviation: 'NY' },
            { name: 'North Carolina', abbreviation: 'NC' },
            { name: 'North Dakota', abbreviation: 'ND' },
            { name: 'Northern Mariana Islands', abbreviation: 'MP' },
            { name: 'Ohio', abbreviation: 'OH' },
            { name: 'Oklahoma', abbreviation: 'OK' },
            { name: 'Oregon', abbreviation: 'OR' },
            { name: 'Palau', abbreviation: 'PW' },
            { name: 'Pennsylvania', abbreviation: 'PA' },
            { name: 'Puerto Rico', abbreviation: 'PR' },
            { name: 'Rhode Island', abbreviation: 'RI' },
            { name: 'South Carolina', abbreviation: 'SC' },
            { name: 'South Dakota', abbreviation: 'SD' },
            { name: 'Tennessee', abbreviation: 'TN' },
            { name: 'Texas', abbreviation: 'TX' },
            { name: 'Utah', abbreviation: 'UT' },
            { name: 'Vermont', abbreviation: 'VT' },
            { name: 'Virgin Islands', abbreviation: 'VI' },
            { name: 'Virginia', abbreviation: 'VA' },
            { name: 'Washington', abbreviation: 'WA' },
            { name: 'West Virginia', abbreviation: 'WV' },
            { name: 'Wisconsin', abbreviation: 'WI' },
            { name: 'Wyoming', abbreviation: 'WY' }
        ];
    };
    return ApiService;
}());
exports.ApiService = ApiService;
function parseErrors(err) {
    var errors = [];
    if (err.status >= 500) {
        errors.push(err.statusText);
    }
    else if (typeof err._body === 'string') {
        try {
            var body = JSON.parse(err._body);
            if (body.detail) {
                errors.push(body.detail);
            }
            else {
                errors = rescurseErrorObject(body, errors);
            }
        }
        catch (e) { }
    }
    else {
        errors.push(err);
    }
    return errors;
}
exports.parseErrors = parseErrors;
function rescurseErrorObject(obj, errors) {
    lodash_1.each(obj, function (msg, key) {
        if (Array.isArray(msg)) {
            errors = errors.concat(msg.map(function (err) { return (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err; }));
        }
        else if (typeof msg === 'string') {
            errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
        }
        else if (typeof msg === 'object') {
            errors = rescurseErrorObject(msg, errors);
        }
    });
    return errors;
}
function requestHeaders(xhr) {
    var headers = new http_1.Headers();
    var headersObj = {};
    if (!xhr) {
        lodash_1.extend(headersObj, {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        });
    }
    var userValue = localStorage.getItem('currentUser');
    if (!userValue) {
        return;
    }
    var currentUser = JSON.parse(userValue);
    var token = currentUser && currentUser.token;
    var tokenString = 'Token ' + token;
    headersObj['Authorization'] = tokenString;
    lodash_1.each(headersObj, function (value, header) {
        if (xhr) {
            xhr.setRequestHeader(header, value);
        }
        else {
            headers.append(header, value);
        }
    });
    return headers;
}
exports.requestHeaders = requestHeaders;
function externalRequestHeaders(token) {
    var headers = new http_1.Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Token ' + token);
    return headers;
}
exports.externalRequestHeaders = externalRequestHeaders;
function handleError(error) {
    return rxjs_1.throwError(parseErrors(error));
}
exports.handleError = handleError;
