import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'bulk-action-dialog',
  templateUrl: './bulk-action-dialog.component.html',
  styleUrls: ['./bulk-action-dialog.component.scss'],
})
export class BulkActionDialogComponent implements OnInit {
  errors = [];
  selected = 0;
  type = 'ticket';
  action = 'delete';

  callback: any;

  constructor(
    public dialogRef: MatDialogRef<BulkActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.selected = data['selected'] && data['selected'].length;
    this.type = data['type'] ? data['type'] : this.type;
    this.action = data['action'] ? data['action'] : this.action;
  }

  ngOnInit() { }

  processRecords(data: any) {
    this.callback(data);
    this.dialogRef.close();
  }
}
