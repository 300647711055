"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UniqueFilterPipe = /** @class */ (function () {
    function UniqueFilterPipe() {
    }
    UniqueFilterPipe.prototype.transform = function (values, prop) {
        // Remove the duplicate elements
        return Array.from(new Set(values.map(function (x) { return x[prop]; })))
            .map(function (propVal) {
            return values.find(function (y) { return y[prop] === propVal; });
        });
    };
    return UniqueFilterPipe;
}());
exports.UniqueFilterPipe = UniqueFilterPipe;
