import { Report, ReportProfile, ReportContactInfo } from './report';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ReportSerializer {
  fromJson(json: any): Report {
    json = camelcaseKeysDeep(json);
    const report = new Report();
    if (!json) { return report; }

    report.id = json.id;
    report.createdAt = json.createdAt;
    report.createdBy = <ReportProfile>json.createdBy;
    report.lastModified = json.lastModified;
    report.lastModifiedBy = <ReportProfile>json.lastModifiedBy;
    report.ownerOrganization = json.ownerOrganization;
    report.type = json.type;
    report.charges = json.charges;
    report.reportDate = json.reportDate;
    report.dueDate = json.dueDate;
    report.reportNumber = json.reportNumber;
    report.logo = json.logo;
    report.subtotal = json.subtotal;
    report.total = json.total;
    report.sent = json.sent;
    report.isLocked = json.isLocked;
    report.surchargeAmount = json.surchargeAmount;
    report.taxRate = json.taxRate;
    report.sender = <ReportContactInfo>{
      name: json.senderName,
      phoneNumber: json.senderPhoneNumber,
      address: json.senderAddress
    };
    report.customer = <ReportContactInfo>{
      name: json.customerName,
      phoneNumber: json.customerPhoneNumber,
      address: json.customerAddress
    };
    report.notes = json.notes;
    report.pdfUrl = json.pdfUrl;
    report.netTerms = json.netTerms;
    report.customInfo = json.customInfo;
    report.poNumber = json.poNumber;
    report.ticketManagerCustomer = json.ticketManagerCustomer;
    report.lastQuickbooksSync = json.lastQuickbooksSync;
    report.blob = json.blob;

    return report;
  }

  toJson(report: Report): any {
    let json: { [key: string]: any } = {
      id: report.id,
      createdAt: report.createdAt,
      createdBy: report.createdBy,
      lastModified: report.lastModified,
      lastModifiedBy: report.lastModifiedBy,
      ownerOrganization: report.ownerOrganization,
      type: report.type,
      charges: report.charges,
      reportDate: report.reportDate,
      dueDate: report.dueDate,
      reportNumber: report.reportNumber,
      logo: report.logo,
      subtotal: report.subtotal,
      total: report.total,
      sent: report.sent,
      isLocked: report.isLocked,
      surchargeAmount: report.surchargeAmount,
      taxRate: report.taxRate,
      senderName: report.sender && report.sender.name,
      senderPhoneNumber: report.sender && report.sender.phoneNumber,
      senderAddress: report.sender && report.sender.address,
      customerName: report.customer && report.customer.name,
      customerPhoneNumber: report.customer && report.customer.phoneNumber,
      customerAddress: report.customer && report.customer.address,
      notes: report.notes,
      pdfUrl: report.pdfUrl,
      netTerms: report.netTerms,
      customInfo: report.customInfo,
      poNumber: report.poNumber,
      ticketManagerCustomer: report.ticketManagerCustomer,
      lastQuickbooksSync: report.lastQuickbooksSync,
      blob: report.blob
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
