"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var preferences_1 = require("./preferences");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PreferencesSerializer = /** @class */ (function () {
    function PreferencesSerializer() {
    }
    PreferencesSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var preferences = new preferences_1.Preferences();
        if (!json) {
            return preferences;
        }
        preferences.id = json.id;
        preferences.organization = json.organization;
        preferences.profile = json.profile;
        preferences.name = json.name;
        preferences.type = json.type;
        preferences.blob = json.blob;
        return preferences;
    };
    PreferencesSerializer.prototype.toJson = function (preferences) {
        var json = {
            id: preferences.id,
            organization: preferences.organization,
            profile: preferences.profile,
            name: preferences.name,
            type: preferences.type,
            blob: preferences.blob
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return PreferencesSerializer;
}());
exports.PreferencesSerializer = PreferencesSerializer;
