"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var environment_1 = require("../../../environments/environment");
var ticket_service_1 = require("../../tickets/ticket.service");
var upload_service_1 = require("../upload.service");
var ImageIndicatorCell = /** @class */ (function () {
    function ImageIndicatorCell(ticketService, uploadService) {
        this.ticketService = ticketService;
        this.uploadService = uploadService;
    }
    ImageIndicatorCell.prototype.agInit = function (params) {
        this.value = params.value;
        this.rowId = params.data && params.data.id;
        this.ticket = params.data;
        this.value = this.getValue();
    };
    ImageIndicatorCell.prototype.getValue = function () {
        var image = this.ticketService.getImage(this.ticket, { x: 75, y: 75 });
        if (!image) {
            return '';
        }
        return image;
    };
    ImageIndicatorCell.prototype.isPopup = function () {
        return false;
    };
    ImageIndicatorCell.prototype.isCancelBeforeStart = function () {
        return false;
    };
    ImageIndicatorCell.prototype.isCancelAfterEnd = function () {
        return false;
    };
    ImageIndicatorCell.prototype.ngAfterViewInit = function () { };
    ImageIndicatorCell.prototype.fileChange = function (e) {
        var _this = this;
        this.uploadService.getS3Policy().subscribe(function (policy) {
            var uploadFile = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                Promise.resolve(_this.uploadService.generateUUID()).then(function (uuid) {
                    policy['fields']['key'] = 'tickets/' + uuid + '/original.jpg';
                    _this.uploadService.uploadToS3(policy, uploadFile).subscribe(function () {
                        _this.value = environment_1.environment.ticketImageServerUrl + policy['fields']['key'];
                        var updates = {
                            id: _this.rowId,
                            image: _this.value
                        };
                        _this.ticketService.save(updates).subscribe();
                    });
                });
            };
            reader.readAsDataURL(uploadFile);
        }, function () { });
    };
    return ImageIndicatorCell;
}());
exports.ImageIndicatorCell = ImageIndicatorCell;
