import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/resource.service';
import { environment } from '../../../environments/environment';
import { Http } from '@angular/http';
import { JobReference } from './job-reference';
import { JobReferenceSerializer } from './job-reference.serializer';

@Injectable()
export class JobReferenceService extends ResourceService<JobReference> {
  baseUrl = environment.serverUrl + 'reference/jobs/';
  allSelected = false;

  constructor(http: Http) {
    super(http, 'reference/jobs/', new JobReferenceSerializer());
  }
}
