"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var moment = require("moment");
var DatepickerCell = /** @class */ (function () {
    function DatepickerCell() {
    }
    DatepickerCell.prototype.ngAfterViewInit = function () {
        this.picker.open();
    };
    DatepickerCell.prototype.isPopup = function () {
        return false;
    };
    DatepickerCell.prototype.isCancelBeforeStart = function () {
        return false;
    };
    DatepickerCell.prototype.isCancelAfterEnd = function () {
        return false;
    };
    DatepickerCell.prototype.agInit = function (params) {
        this.params = params;
        this.value = params.value;
    };
    DatepickerCell.prototype.getValue = function () {
        return this.value;
    };
    DatepickerCell.prototype.onDateChange = function (e) {
        var _this = this;
        setTimeout(function () {
            _this.value = moment(e.value).format('YYYY-MM-DD');
            _this.params.stopEditing();
        }, 100);
    };
    return DatepickerCell;
}());
exports.DatepickerCell = DatepickerCell;
