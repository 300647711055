import { TruckReference } from './truck-reference';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TruckReferenceSerializer {
  fromJson(json: any): TruckReference {
    json = camelcaseKeysDeep(json);

    const reference = new TruckReference();

    if (!json) { return reference; }

    reference.id = json.id;
    reference.organization = json.organization;
    reference.value = json.value;
    reference.createdAt = json.createdAt;
    reference.licensePlate = json.licensePlate;
    reference.truckCode = json.truckCode;
    reference.truckNumber = json.truckNumber;
    reference.truckType = json.truckType;

    return reference;
  }

  toJson(reference: TruckReference): any {
    const json: { [key: string]: any } = {
      id: reference.id,
      organization: reference.organization,
      value: reference.value,
      createdAt: reference.createdAt,
      licensePlate: reference.licensePlate,
      truckCode: reference.truckCode,
      truckNumber: reference.truckNumber,
      truckType: reference.truckType,
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
