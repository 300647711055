"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../../../environments/environment");
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(router) {
        var _this = this;
        this.router = router;
        this.drawerVisible = false;
        this.visible = true;
        this.version = environment_1.environment.applicationVersion;
        this.menuOptions = [
            { active: false, icon: 'list_alt', label: 'Tickets', route: '/tickets' },
            {
                active: false,
                icon: 'link',
                label: 'References',
                onClick: function () {
                    _this.drawerVisible = true;
                },
                toggled: false,
                children: [
                    { active: false, label: 'Customers', route: '/customers' },
                    { active: false, label: 'Jobs', route: '/jobs' },
                    { active: false, label: 'Drivers', route: '/drivers' },
                    { active: false, label: 'Trucks', route: '/trucks' },
                ],
            },
            { active: false, icon: 'monetization_on', label: 'Invoices', route: '/invoices' },
            { active: false, icon: 'local_shipping', label: 'Settlements', route: '/settlements' },
            { active: false, icon: 'settings', label: 'Settings', route: '/settings/organization' },
        ];
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.visible) {
            this.drawerVisible = false;
        }
        this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationStart; })).subscribe(function (event) {
            var e = event;
            _this.onRoutechange(e);
        });
    };
    SidebarComponent.prototype.onRoutechange = function (event) {
        if (event.url.includes('uploader')) {
            this.drawerVisible = false;
        }
        this.menuOptions.forEach(function (option) {
            if (option.route && option.route === event.url) {
                option.active = true;
            }
            if (option.children) {
                option.children.forEach(function (child) {
                    if (child.route && child.route === event.url) {
                        option.active = true;
                        option.toggled = true;
                        child.active = true;
                    }
                });
            }
        });
    };
    SidebarComponent.prototype.onOptionClick = function (index, childIndex) {
        var _this = this;
        this.menuOptions = this.menuOptions.slice().map(function (option, i) {
            var selected = (index === i);
            if (selected) {
                option.active = true;
                if (childIndex !== undefined && option.children) {
                    option.children = option.children.map(function (child, j) {
                        var childSelected = (j === childIndex);
                        if (childSelected) {
                            child.active = true;
                            if (child.onClick) {
                                child.onClick();
                            }
                            if (child.route && child.route !== _this.router.url) {
                                _this.router.navigateByUrl(child.route);
                            }
                        }
                        if (!childSelected) {
                            child.active = false;
                        }
                        return child;
                    });
                }
                if (childIndex === undefined && option.children) {
                    option.toggled = !option.toggled;
                }
                if (option.onClick) {
                    option.onClick();
                }
                if (option.route && option.route !== _this.router.url) {
                    _this.router.navigateByUrl(option.route);
                }
            }
            if (!selected && childIndex === undefined) {
                option.toggled = false;
                option.active = false;
                if (option.children) {
                    option.children.forEach(function (child) { return child.active = false; });
                }
            }
            return option;
        });
    };
    return SidebarComponent;
}());
exports.SidebarComponent = SidebarComponent;
