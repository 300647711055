import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {
  loading = false;
  exported = false;
  @Input() scope: any;
  @Input() params: any;
  ticketImages = false;
  exportButtonLabel = 'Export Data';
  errors = [];
  callback: any;

  constructor(
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.exportButtonLabel = this.exported ? 'Exported Successfully' : 'Export Failed';
  }

  submit() {  }
}
