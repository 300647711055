"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resource_service_1 = require("../../shared/resource.service");
var environment_1 = require("../../../environments/environment");
var http_1 = require("@angular/http");
var job_reference_serializer_1 = require("./job-reference.serializer");
var JobReferenceService = /** @class */ (function (_super) {
    __extends(JobReferenceService, _super);
    function JobReferenceService(http) {
        var _this = _super.call(this, http, 'reference/jobs/', new job_reference_serializer_1.JobReferenceSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'reference/jobs/';
        _this.allSelected = false;
        return _this;
    }
    return JobReferenceService;
}(resource_service_1.ResourceService));
exports.JobReferenceService = JobReferenceService;
