"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var job_reference_1 = require("../job-reference");
var job_reference_service_1 = require("../job-reference.service");
var job_reference_create_modal_component_1 = require("../job-reference-create-modal/job-reference-create-modal.component");
var material_1 = require("@angular/material");
var JobReferenceSelectorComponent = /** @class */ (function () {
    function JobReferenceSelectorComponent(jobReferenceCreateModal, jobReferenceService) {
        this.jobReferenceCreateModal = jobReferenceCreateModal;
        this.jobReferenceService = jobReferenceService;
        this.visible = true;
        this.placeholder = 'Job Name or Job Code';
        this.value = '';
        this.hasControls = true;
        this.referenceFocused = new core_1.EventEmitter();
        this.referenceSelected = new core_1.EventEmitter();
        this.referenceCreated = new core_1.EventEmitter();
        this.onBlur = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.loading = true;
        this.scrollDistance = 5;
        this.scrollUpDistance = 5;
        this.throttle = 100;
        this.query = '';
        this.editing = false;
        this.references = [];
    }
    JobReferenceSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.query = this.value;
        if (this.jobReference) {
            this.selectedJobReference = this.jobReference;
        }
        this.jobReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    JobReferenceSelectorComponent.prototype.ngAfterViewInit = function () { };
    JobReferenceSelectorComponent.prototype.setSelectedReference = function (reference) {
        this.selectedJobReference = reference;
        this.value = reference.jobName ? reference.jobName : '';
    };
    JobReferenceSelectorComponent.prototype.buildQueryString = function (query) {
        if (query === void 0) { query = ''; }
        if (!query || query === '') {
            return {
                page_size: 10,
            };
        }
        return {
            filters: "(job_name__icontains=" + query + ") | (job_code__icontains=" + query + ")",
            page_size: 10,
        };
    };
    JobReferenceSelectorComponent.prototype.onInputFocus = function () {
        var _this = this;
        this.visible = true;
        this.loading = true;
        this.references = [];
        this.jobReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
        this.referenceFocused.emit(true);
    };
    JobReferenceSelectorComponent.prototype.onInputBlur = function () {
        this.visible = false;
        this.references = [];
        this.onBlur.emit(true);
        if (this.selectedJobReference) {
            if (this.selectedJobReference.jobName && this.selectedJobReference.jobName !== this.value) {
                this.value = this.selectedJobReference.jobName;
            }
        }
        else {
            this.value = '';
        }
    };
    JobReferenceSelectorComponent.prototype.onReferenceClicked = function (event, jobReference) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.referenceSelected.emit(jobReference);
        this.visible = false;
        this.selectedJobReference = jobReference;
        if (jobReference.jobName) {
            this.value = jobReference.jobName;
        }
    };
    JobReferenceSelectorComponent.prototype.onSearchChange = function (query) {
        var _this = this;
        this.query = query;
        this.loading = true;
        this.references = [];
        this.jobReferenceService.list(this.buildQueryString(this.query)).subscribe(function (results) {
            _this.references = results;
            _this.loading = false;
        });
    };
    JobReferenceSelectorComponent.prototype.onCloseClick = function () {
        this.visible = false;
        this.onClose.emit(true);
        this.references = [];
    };
    JobReferenceSelectorComponent.prototype.onAddClick = function () {
        var _this = this;
        var data = {
            width: '500px',
            data: {
                model: this.data,
                mode: 'create',
            },
        };
        var dialog = this.jobReferenceCreateModal.open(job_reference_create_modal_component_1.JobReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (jobReference) {
                _this.referenceCreated.emit(jobReference);
                _this.selectedJobReference = jobReference;
                _this.visible = false;
            });
        };
    };
    JobReferenceSelectorComponent.prototype.onScrollDown = function () {
        var _this = this;
        var newResults = this.jobReferenceService.listNext();
        if (newResults) {
            newResults.subscribe(function (results) {
                _this.references = _this.references.concat(results);
            });
        }
    };
    JobReferenceSelectorComponent.prototype.onScrollUp = function () { };
    JobReferenceSelectorComponent.prototype.onEditReferenceClick = function () {
        var _this = this;
        if (!this.selectedJobReference) {
            return;
        }
        this.visible = false;
        this.references = [];
        var data = {
            width: '500px',
            data: {
                model: this.selectedJobReference,
                mode: 'edit',
            },
        };
        var dialog = this.jobReferenceCreateModal.open(job_reference_create_modal_component_1.JobReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (jobReference) {
                _this.referenceCreated.emit(jobReference);
                _this.selectedJobReference = jobReference;
                _this.references.push(jobReference);
                _this.visible = false;
            });
        };
        return false;
    };
    return JobReferenceSelectorComponent;
}());
exports.JobReferenceSelectorComponent = JobReferenceSelectorComponent;
