import { DriverReference } from './driver-reference';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class DriverReferenceSerializer {
  fromJson(json: any): DriverReference {
    json = camelcaseKeysDeep(json);

    const reference = new DriverReference();

    if (!json) { return reference; }

    reference.id = json.id;
    reference.organization = json.organization;
    reference.value = json.value;
    reference.createdAt = json.createdAt;
    reference.driverCode = json.driverCode;
    reference.driverName = json.driverName;
    reference.carrierName = json.carrierName;
    reference.email = json.driverEmail;
    reference.phoneNumber = json.driverPhoneNumber;
    reference.cdl = json.cdl;

    return reference;
  }

  toJson(reference: DriverReference): any {
    const json: { [key: string]: any } = {
      id: reference.id,
      organization: reference.organization,
      value: reference.value,
      driverCode: reference.driverCode,
      driverName: reference.driverName,
      carrierName: reference.carrierName,
      driverEmail: reference.email,
      cdl: reference.cdl,
      driverPhoneNumber: reference.phoneNumber,
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
