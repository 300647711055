
<div class="organization-container">
  <div class="organization-data">
    <h2 translate>Organization Details</h2>
    <form #editOrganization="ngForm" class="organization-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="flex-field-group">
            <div class="flex-field organization-name">
              <label class="required" translate>Organization Name</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Organization Name' | translate }" matInput [(ngModel)]="organization.name" name="organization-name">
              </mat-form-field>
            </div>
          </div>
          <div class="flex-field-group">
            <div class="flex-field organization-street">
              <label class="required" translate>Address</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Address' | translate }" matInput [(ngModel)]="organization.street" name="organization-street">
              </mat-form-field>
            </div>
            <div class="flex-field organization-address2">
              <label translate>Address 2</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Address 2' | translate }" matInput [(ngModel)]="organization.address2" name="organization-address2">
              </mat-form-field>
            </div>
          </div>
          <div class="flex-field-group">
            <div class="flex-field organization-city">
              <label class="required" translate>City</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'City' | translate }" matInput [(ngModel)]="organization.city" name="organization-city">
              </mat-form-field>
            </div>
            <div class="flex-field organization-zipcode">
              <label class="required" translate>Zip</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Zip' | translate }" matInput [(ngModel)]="organization.zipcode" name="organization-zipcode">
              </mat-form-field>
            </div>
            <div class="flex-field organization-state">
              <label translate>State</label>
              <select name="organization-state" [(ngModel)]="organization.state" (change)="selectState($event.target.value, 'organization')">
                <option value="" selected disabled hidden>{{ 'Select' | translate }}</option>
                <option *ngFor="let stateOption of stateOptions" [value]="stateOption.name">{{ stateOption.name }}</option>
              </select>
            </div>
          </div>
          <div class="flex-field-group">
            <div class="flex-field organization-phone-number">
              <label class="required" translate>Company Phone Number</label>
              <mat-form-field>
                <input type="text" aria-label="{ 'Company Phone Number' | translate }" matInput [(ngModel)]="organization.phoneNumber" name="organization-phone-number">
              </mat-form-field>
            </div>
          </div>

          <div class="flex-field-group">
            <button class="btn btn-primary"
                    (click)="saveOrganization(); false"
                    [disabled]="loading || !editOrganization.form.valid || (editOrganization.form.pristine && !imageChanged)"
                    [ngClass]="{loading: loading}"
                    translate>
              Save Details
            </button>
          </div>
        </div>
        <div class="right-side">
          <div class="field-group logo-image">
            <div class="upload-image-dropzone" [ngClass]="{filled: organization.image || logoImage.file}">
              <img *ngIf="!logoImage.file && organization.image" [src]="organization.image" />
              <img *ngIf="logoImage.file" [src]="logoImage.dataUri" />
              <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event, editOrganization)" #fileInput/>
              <label class="upload-label" for="file">
                <i class="icon-upload"></i>
                <span *ngIf="!organization.image && !logoImage.file" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
                <span *ngIf="organization.image || logoImage.file" translate>Upload a new photo</span>
              </label>
            </div>
        
            <label translate>Your Organization's Logo</label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
