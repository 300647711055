"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resource_1 = require("../shared/resource");
var TicketMeta = /** @class */ (function (_super) {
    __extends(TicketMeta, _super);
    function TicketMeta() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TicketMeta;
}(resource_1.Resource));
exports.TicketMeta = TicketMeta;
var Ticket = /** @class */ (function (_super) {
    __extends(Ticket, _super);
    function Ticket() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Ticket.prototype.url = function (action) {
        return '/tickets/' + this.id + '/' + action;
    };
    Ticket.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        return options;
    };
    return Ticket;
}(resource_1.Resource));
exports.Ticket = Ticket;
var ExternalTicket = /** @class */ (function () {
    function ExternalTicket() {
    }
    return ExternalTicket;
}());
exports.ExternalTicket = ExternalTicket;
