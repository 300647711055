"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".export-modal[_ngcontent-%COMP%]{display:flex;flex-direction:column;width:400px}.export-modal__content[_ngcontent-%COMP%]{display:flex;flex-direction:column;margin-top:25px}.export-modal__content__export-options[_ngcontent-%COMP%]{margin-bottom:25px}.export-modal__content__export-options[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{margin-bottom:15px}.export-modal__content__export-options--group[_ngcontent-%COMP%]{display:flex;flex-direction:column}.export-modal__submit[_ngcontent-%COMP%]{padding:15px 25px;background-color:#eff3f9;width:100%}.export-modal__submit[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:100%;text-align:center;text-transform:uppercase}"];
exports.styles = styles;
