import { Injectable } from '@angular/core';
import { Http, URLSearchParams } from '@angular/http';
import { clone } from 'lodash';

import { environment } from '../../environments/environment';
import { Ticket, ExternalTicket } from './ticket';
import { TicketSerializer, ExternalTicketSerializer } from './ticket.serializer';
import { ResourceService } from '../shared/resource.service';
import { requestHeaders, externalRequestHeaders } from '../shared/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource } from '../shared/resource';

import { DriverReference } from '../references/driver-reference/driver-reference';
import { JobReference } from '../references/job-reference/job-reference';
import { CustomerReference } from '../references/customer-reference/customer-reference';

export type ReferenceEditModel = {
  ticketId: string;
  driver: DriverReference;
  job: JobReference;
  customer: CustomerReference;
};
type ReferenceEditReqBody = {
  driver?: string;
  job?: string;
  customer?: string;
};

@Injectable()
export class TicketService extends ResourceService<Ticket> {
  baseUrl = environment.serverUrl + 'tickets/';
  metaUrl = environment.serverUrl + 'ticketmetas/';
  scaleSyncUrl = environment.serverUrl + 'tickets/origin/scale-sync/';
  ptpRuckitKey = environment.ptpRuckitKey;
  ptpApiKey = environment.ptpApiKey;
  ptpUrl = environment.ptpUrl;
  public nextUri!: string;
  public count: any;
  public search = '';
  public allSelected = false;
  progress$: any;
  progress: any;

  constructor(http: Http) {
    super(http, 'tickets/', new TicketSerializer());
  }

  markInvoiced(ticketIds: string[] = []) {
    let ticketUrl = this.baseUrl + 'mark-invoiced/';

    let params: URLSearchParams = new URLSearchParams();
    params.set('id__in', ticketIds.join(','));

    return this.http.put(ticketUrl, {}, { headers: requestHeaders(), search: params });
  }

  updateFieldReference<T extends Resource>(ticketId: string, reference: T, referenceType: string): Observable<Ticket> {
    const url = `${this.baseUrl}${ticketId}/set-references/`;

    const body = {
      [referenceType]: reference.id,
    };

    return this.http.put(url, body, { headers: requestHeaders() }).pipe(
      map(res => this.captureMetaData(res)),
      map(ticket => this.convertRecord(ticket),
    ));
  }

  updateFieldReferences(referenceUpdates: ReferenceEditModel): Observable<Ticket> {
    const url = `${this.baseUrl}${referenceUpdates.ticketId}/set-references/`;

    let body: ReferenceEditReqBody = {};
    if (referenceUpdates.job && referenceUpdates.job.id) { body.job = referenceUpdates.job.id; }
    if (referenceUpdates.customer && referenceUpdates.customer.id) { body.customer = referenceUpdates.customer.id; }
    if (referenceUpdates.driver && referenceUpdates.driver.id) { body.driver = referenceUpdates.driver.id; }

    return this.http.put(url, body, { headers: requestHeaders() }).pipe(
      map(res => this.captureMetaData(res)),
      map(ticket => this.convertRecord(ticket),
    ));
  };

  getImage(ticket: Ticket, dimensions?: { x: number, y: number }): string | null {
    if (!ticket || !ticket.image) {
      return null;
    }

    if (ticket.image && !dimensions) {
      return ticket.image;
    }

    if (!dimensions) {
      return null;
    }

    try {
      // Unescape URL's since some come in weirdly formated due to backend issue
      const imageURL = new URL(unescape(ticket.image));

      // Remove environment from url, and remove extension
      let path = imageURL.pathname.replace(/(ruckit-platform-dev|ruckit-platform-staging|ruckit-platform)\/?/, '');

      // Build resize URL
      const resizeURL = `${environment.resizeImageServerUrl}${path}?d=${dimensions.x}x${dimensions.y}`;

      return resizeURL;
    } catch (e) {
      return ticket.image;
    }
  }

  saveMeta(model: Ticket): Observable<any> {
    const metaUrl = this.metaUrl;

    model = clone(model);
    model = <Ticket>this.serializer.toJson(model);

    return this.http.put(`${metaUrl}${model.id}/`, model, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => this.convertRecord(res))
    );

  }

  saveExternal(model: any): Observable<any> {
    const url = this.ptpUrl;
    let headers = externalRequestHeaders(this.ptpRuckitKey);
    // this is PTP's auth key; for now we will keep this static
    // until they come up with an auth method for generating a key on the fly
    headers.append('api_key', this.ptpApiKey);
    return this.http.post(url, { ticket: new ExternalTicketSerializer().toJson(model) }, { headers: headers }).pipe(
      map(res => this.convertExternalRecord(res))
    );
  }

  saveScaleSync(model: any): Observable<any> {
    const url = this.scaleSyncUrl;
    let headers = requestHeaders();
    return this.http.post(url, { image: model.image }, { headers: headers }).pipe(
      map(res => this.convertExternalRecord(res))
    );
  }

  link(ticketIds: string[]) {
    // sets up and links tickets by id order. the first id in the array is set as
    // the 'parent' while the rest will be set up as children of that parent.
  }

  unlink(ticketIds: string[]) {
    // sets up and unlinks tickets by id
  }

  convertExternalRecord(data: any): ExternalTicket {
    try {
      data = data.json();
    } catch (err) {
      // Ignore
    }
    return new ExternalTicketSerializer().fromJson(data);
  }
}
