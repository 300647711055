"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var shortid = require("shortid");
// @ts-ignore
var pdfjs = require("pdfjs-dist");
var moment = require("moment");
require("dwt");
var environment_1 = require("../../environments/environment");
var notifications_service_1 = require("../shared/notification/notifications.service");
var authentication_service_1 = require("../shared/authentication.service");
var upload_service_1 = require("../shared/upload.service");
var ocr_service_1 = require("./ocr.service");
var ticket_service_1 = require("../tickets/ticket.service");
var preferences_1 = require("../shared/preferences/preferences");
var preferences_service_1 = require("../shared/preferences/preferences.service");
var job_reference_selector_component_1 = require("../references/job-reference/job-reference-selector/job-reference-selector.component");
var customer_reference_selector_component_1 = require("../references/customer-reference/customer-reference-selector/customer-reference-selector.component");
var driver_reference_selector_component_1 = require("../references/driver-reference/driver-reference-selector/driver-reference-selector.component");
var KEY_CODE;
(function (KEY_CODE) {
    KEY_CODE[KEY_CODE["RIGHT_ARROW"] = 39] = "RIGHT_ARROW";
    KEY_CODE[KEY_CODE["LEFT_ARROW"] = 37] = "LEFT_ARROW";
})(KEY_CODE = exports.KEY_CODE || (exports.KEY_CODE = {}));
var BatchUploadComponent = /** @class */ (function () {
    function BatchUploadComponent(router, route, notificationsService, authenticationService, uploadService, ocrService, ticketService, preferencesService) {
        this.router = router;
        this.route = route;
        this.notificationsService = notificationsService;
        this.authenticationService = authenticationService;
        this.uploadService = uploadService;
        this.ocrService = ocrService;
        this.ticketService = ticketService;
        this.preferencesService = preferencesService;
        this.external = false;
        this.contextId = '';
        this.dateSelected = false;
        this.user = this.authenticationService.user();
        this.loader = {
            active: false,
            type: 'uploading'
        };
        this.uploadIndex = 0;
        this.policyData = {};
        this.model = { ticketDate: '' };
        this.uploadFiles = [];
        this.uploadTotal = 0;
        this.savedTickets = [];
        this.selectedRecordIndex = null;
        this.fieldActive = false;
        // scanning props
        this.scannerActive = false;
        this.detectedScanners = [];
        this.scanDriverDetected = true;
        this.scanResults = [];
        this.isJobFieldVisible = false;
        this.isCustomerFieldVisible = false;
        this.isDriverFieldVisible = false;
        this.jobFieldsVisible = false;
        this.customerFieldsVisible = false;
        this.driverFieldsVisible = false;
    }
    BatchUploadComponent.prototype.keyEvent = function (event) {
        if (this.selectedRecordIndex && this.savedTickets.length > 1 && !this.fieldActive) {
            if (event.keyCode === KEY_CODE.RIGHT_ARROW && this.selectedRecordIndex < (this.savedTickets.length - 1)) {
                this.nextTicket(this.savedTickets[this.selectedRecordIndex]);
            }
            if (event.keyCode === KEY_CODE.LEFT_ARROW && this.selectedRecordIndex !== 0) {
                this.previousTicket(this.savedTickets[this.selectedRecordIndex]);
            }
        }
    };
    BatchUploadComponent.prototype.ticketNumberVisible = function () {
        return (this.savedTickets.length > 0 && this.selectedRecordIndex !== null) || this.savedTickets.length === 0;
    };
    BatchUploadComponent.prototype.ngOnInit = function () {
        var _this = this;
        // here we will set up listeners for any url params and set up
        // the callback_url, features, type model, and other config functions necessary
        var combinedParams = rxjs_1.combineLatest(this.route.url, this.route.params, this.route.queryParams, function (url, params, qparams) { return ({ url: url, params: params, qparams: qparams }); });
        combinedParams.subscribe(function (result) {
            if (result.qparams.tickets) {
                _this.editMode = true;
                var ticketIds = result.qparams.tickets.split(',');
                _this.loadTickets(ticketIds);
            }
            else if (result.url[1] && result.url[1].path === 'external') {
                _this.external = true;
                _this.contextId = result.qparams.contextId;
                if (result.qparams.date) {
                    _this.model.ticketDate = moment(result.qparams.date, 'YYYYMMDD');
                    _this.dateSelected = true;
                }
            }
            else {
                _this.editMode = false;
            }
        });
        if (this.savedTickets.length) {
            this.preselectDropdowns(false);
        }
        this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;
    };
    BatchUploadComponent.prototype.loadTickets = function (ticketIds) {
        var _this = this;
        this.loader.active = true;
        var getTicketRequests = [];
        ticketIds.map(function (id) { return getTicketRequests.push(_this.ticketService.get(id)); });
        rxjs_1.forkJoin(getTicketRequests).subscribe(function (tickets) {
            _this.savedTickets = tickets;
            _this.viewerType = _this.savedTickets.length > 1 ? 'grid' : 'single';
            _this.selectedRecordIndex = _this.savedTickets.length === 1 ? 0 : null;
            if (_this.savedTickets.length > 1) {
                _this.preselectDropdowns(false);
            }
            else {
                _this.preselectDropdowns(true, _this.savedTickets[0]);
            }
        }, function (err) {
            var notification = {
                context: {
                    err: err,
                },
                id: shortid.generate(),
                message: 'An error occured loading these selected tickets. Please refresh and try again.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
        this.loader.active = false;
    };
    // scanner methods
    BatchUploadComponent.prototype.enableScanning = function () {
        var _this = this;
        this.scannerActive = true;
        this.loader = {
            active: true,
            type: 'prescan'
        };
        Dynamsoft.WebTwainEnv.Load();
        Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', function () { _this.Dynamsoft_OnReady(); });
        var dynamsoftPrompt = document.getElementsByClassName('dynamsoft-dialog-wrap')[0];
        if (dynamsoftPrompt) {
            dynamsoftPrompt.setAttribute('style', 'display:block');
        }
    };
    BatchUploadComponent.prototype.disableScanning = function () {
        this.scannerActive = false;
        this.loader = {
            active: false,
            type: 'uploading'
        };
        Dynamsoft.WebTwainEnv.Unload();
    };
    BatchUploadComponent.prototype.Dynamsoft_OnReady = function () {
        var _this = this;
        if (typeof (EnumDWT_ConvertMode) !== 'undefined') {
            this.EnumDWT_ConvertMode = EnumDWT_ConvertMode;
        }
        this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
        if (this.DWObject) {
            this.detectedScanners = [];
            this.DWObject.IfAllowLocalCache = true;
            this.DWObject.ImageCaptureDriverType = 3;
            var vCount_1 = this.DWObject.SourceCount;
            this.DWTSourceCount = vCount_1;
            for (var i = 0; i < vCount_1; i++) {
                this.detectedScanners.push(this.DWObject.GetSourceNameItems(i));
            }
            if (Dynamsoft.Lib.env.bMac) {
                Promise.resolve(this.DWObject.CloseSourceManager())
                    .then(function () {
                    _this.DWObject.ImageCaptureDriverType = 0;
                    return _this.DWObject.OpenSourceManager();
                })
                    .then(function () {
                    for (var j = vCount_1; j < vCount_1 + _this.DWObject.SourceCount; j++) {
                        _this.detectedScanners.push(_this.DWObject.GetSourceNameItems(j));
                    }
                });
            }
            this.loader.active = false;
            // here we determine which scanner to auto-select
            if (this.detectedScanners.length === 1) {
                this.selectedScannerIndex = 0;
            }
            else {
                this.getPreferences();
            }
        }
        else {
            setTimeout(function () {
                _this.loader.active = false;
                var notification = {
                    context: {},
                    id: shortid.generate(),
                    message: 'There was an issue initializing the scanner. Please refresh and try again.',
                    originator: 'uploader',
                    type: notifications_service_1.NotificationType.Danger,
                };
                _this.notificationsService.addNotification(notification);
            }, 5000);
        }
    };
    BatchUploadComponent.prototype.refreshScannerList = function () {
        var _this = this;
        this.loader.active = true;
        Promise.resolve(Dynamsoft.WebTwainEnv.Unload())
            .then(function () { return Dynamsoft.WebTwainEnv.Load(); });
        Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', function () { return _this.Dynamsoft_OnReady(); });
    };
    BatchUploadComponent.prototype.selectScanner = function (index) {
        this.selectedScannerIndex = index;
        this.savePreferences();
    };
    BatchUploadComponent.prototype.startScan = function () {
        var _this = this;
        this.loader = {
            active: true,
            type: 'scanning'
        };
        // TODO: set up default scan options
        var config = {
            IfShowUI: false,
            PixelType: this.external ? EnumDWT_PixelType.TWPT_RGB : EnumDWT_PixelType.TWPT_GRAY,
            PageSize: EnumDWT_CapSupportedSizes.TWSS_NONE,
            Resolution: 125,
            IfFeederEnabled: true,
            IfDuplexEnabled: false,
            IfShowProgressBar: false
        };
        if (Dynamsoft.Lib.env.bMac) {
            Promise.resolve(this.DWObject.CloseSourceManager())
                .then(function () {
                _this.DWObject.ImageCaptureDriverType = 3;
                return _this.DWObject.OpenSourceManager();
            })
                .then(function () {
                if (_this.selectedScannerIndex >= _this.DWTSourceCount) {
                    _this.selectedScannerIndex = _this.selectedScannerIndex - _this.DWTSourceCount;
                    Promise.resolve(_this.DWObject.CloseSourceManager())
                        .then(function () {
                        _this.DWObject.ImageCaptureDriverType = 0;
                        return _this.DWObject.OpenSourceManager();
                    })
                        .then(function () { return _this.acquireScans(config); });
                }
                else {
                    _this.acquireScans(config);
                }
            });
        }
        else {
            this.acquireScans(config);
        }
    };
    BatchUploadComponent.prototype.acquireScans = function (config) {
        var _this = this;
        Promise.resolve(this.DWObject.SelectSourceByIndex(this.selectedScannerIndex))
            .then(function () { return _this.DWObject.CloseSource(); })
            .then(function () { return _this.DWObject.SetOpenSourceTimeout(30000); })
            .then(function () { return _this.DWObject.OpenSource(); })
            .then(function () {
            if (_this.DWObject.ErrorCode === 0) {
                _this.DWObject.AcquireImage(config, function () { return _this.processScans(); }, function (errCode, errString) {
                    _this.loader.active = false;
                    var notification = {
                        context: {},
                        id: shortid.generate(),
                        message: 'Scanner error ' + errCode + ': ' + errString,
                        originator: 'uploader',
                        type: notifications_service_1.NotificationType.Danger,
                    };
                    _this.notificationsService.addNotification(notification);
                    if ((errCode === -2129 && !errString.includes('empty')) || errCode === -1020) {
                        _this.processScans();
                    }
                });
            }
            else {
                var notification = {
                    context: {},
                    id: shortid.generate(),
                    message: 'Source error ' + _this.DWObject.ErrorCode + ': ' + _this.DWObject.ErrorString,
                    originator: 'uploader',
                    type: notifications_service_1.NotificationType.Danger,
                };
                _this.notificationsService.addNotification(notification);
            }
        });
    };
    BatchUploadComponent.prototype.processScans = function () {
        var _this = this;
        // this will hook into the upload pipeline at some point
        // generate records for each image
        this.uploadService.getS3Policy(this.external).subscribe(function (policy) {
            _this.policyData = policy;
            var scanTotal = _this.DWObject.HowManyImagesInBuffer;
            _this.viewerType = scanTotal > 1 ? 'grid' : 'single';
            _this.loader = {
                active: true,
                type: 'uploading'
            };
            _this.DWObject.SelectedImagesCount = 1;
            var _loop_1 = function (i) {
                _this.DWObject.SetSelectedImageIndex(0, i);
                _this.DWObject.GetSelectedImagesSize(EnumDWT_ImageType.IT_JPG);
                // save image to base64
                var imageData = 'data:image/jpeg;base64,' + _this.DWObject.SaveSelectedImagesToBase64Binary();
                var scanIndex = _this.savedTickets.length + i + 1;
                // convert to record
                _this.cropWhiteSpace(imageData).then(function (result) {
                    _this.convertFileToRecord(_this.uploadService.convertDataUriToFile(result, 'scannedImage-' + i + '.jpg'), scanIndex);
                    if (i + 1 === scanTotal) {
                        _this.DWObject.RemoveAllSelectedImages();
                    }
                    else {
                        _this.DWObject.RemoveAllImages();
                    }
                });
            };
            for (var i = 0; i < scanTotal; i++) {
                _loop_1(i);
            }
            _this.scannerActive = false;
        }, function (err) {
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error authenticating with our upload server. Please refresh and try again.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    BatchUploadComponent.prototype.setPreviewMode = function (colNum) {
        this.DWObject.SetViewMode(colNum, colNum);
    };
    BatchUploadComponent.prototype.resetScans = function () {
        this.DWObject.RemoveAllImages();
        this.scanResults = [];
    };
    BatchUploadComponent.prototype.checkForMatchingValue = function (tickets, key) {
        return tickets.every(function (ticket) {
            return ticket[key] === tickets[0][key];
        });
    };
    BatchUploadComponent.prototype.isValidDate = function (input) {
        input = new Date(input);
        return input instanceof Date && !isNaN(input.getTime());
    };
    BatchUploadComponent.prototype.selectedTicketLength = function () {
        var length = 0;
        this.savedTickets.forEach(function (ticket) { return length = ticket.selected ? length + 1 : length; });
        return length;
    };
    BatchUploadComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferencesService.list().pipe(operators_1.map(function (prefs) {
            var pref = prefs.find(function (p) { return p.name === 'uploader'; });
            return pref ? pref : new preferences_1.Preferences;
        })).subscribe(function (preferences) {
            _this.preferences = preferences;
            _this.selectedScannerIndex = _this.preferences.blob && _this.preferences.blob.selectedScannerIndex ?
                _this.preferences.blob.selectedScannerIndex : null;
        });
    };
    BatchUploadComponent.prototype.savePreferences = function () {
        var preferencesObj = __assign({}, this.preferences, { type: 'uploader', name: 'uploader', blob: {
                selectedScannerIndex: this.selectedScannerIndex,
            } });
        this.preferencesService.save(preferencesObj).subscribe();
    };
    BatchUploadComponent.prototype.updateTicket = function (ticket) {
        var _this = this;
        if (ticket) {
            if (this.model.customerName) {
                ticket.customerName = this.model.customerName;
            }
            if (this.model.jobName) {
                ticket.jobName = this.model.jobName;
            }
            if (this.model.ticketDate && this.isValidDate(this.model.ticketDate)) {
                ticket.ticketDate = new Date(this.model.ticketDate).toISOString().split('T')[0];
            }
            if (this.model.quantity) {
                ticket.quantity = this.model.quantity;
                ticket.quantityType = this.model.quantityType;
            }
            if (this.model.invoiceRate) {
                ticket.invoiceRate = this.model.invoiceRate;
            }
            if (this.model.material) {
                ticket.material = this.model.material;
            }
            if (this.model.carrierName) {
                ticket.carrierName = this.model.carrierName;
            }
            if (this.model.truckNumber) {
                ticket.truckNumber = this.model.truckNumber;
            }
            if (this.model.truckType) {
                ticket.truckType = this.model.truckType;
            }
            delete ticket.organization;
            if (!ticket.image) {
                delete ticket.image;
            }
            delete ticket.driver;
            delete ticket.truck;
            delete ticket.customer;
            delete ticket.job;
            delete ticket.jobCode;
            delete ticket.haulRate;
            delete ticket.origin;
            delete ticket.destination;
            return this.ticketService.save(ticket).pipe(operators_1.switchMap(function (ticketRes) {
                var referenceUpdates = { ticketId: ticketRes.id };
                if (_this.selectedJobReference && !!_this.selectedJobReference.id) {
                    referenceUpdates.job = _this.selectedJobReference;
                }
                if (_this.selectedCustomerReference && !!_this.selectedCustomerReference.id) {
                    referenceUpdates.customer = _this.selectedCustomerReference;
                }
                if (_this.selectedDriverReference && !!_this.selectedDriverReference.id) {
                    referenceUpdates.driver = _this.selectedDriverReference;
                }
                if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
                    return rxjs_1.of(ticket);
                }
                else {
                    return _this.ticketService.updateFieldReferences(referenceUpdates);
                }
            }));
        }
        else {
            return rxjs_1.empty();
        }
    };
    BatchUploadComponent.prototype.saveImage = function (updateData) {
        var _this = this;
        this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
            .subscribe(function () { }, function (err) {
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error updating that ticket image.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    BatchUploadComponent.prototype.previousTicket = function (ticket) {
        var _this = this;
        this.updateTicket(ticket).subscribe(function () {
            _this.selectedRecordIndex = _this.selectedRecordIndex - 1;
            if (_this.selectedRecordIndex >= 0) {
                _this.preselectDropdowns(true, _this.savedTickets[_this.selectedRecordIndex]);
            }
            else {
                _this.preselectDropdowns(false);
            }
        }, function (err) {
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error loading the previous ticket.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    BatchUploadComponent.prototype.nextTicket = function (ticket) {
        var _this = this;
        this.updateTicket(ticket).subscribe(function () {
            _this.selectedRecordIndex = _this.selectedRecordIndex + 1;
            if (_this.selectedRecordIndex !== _this.savedTickets.length) {
                _this.preselectDropdowns(true, _this.savedTickets[_this.selectedRecordIndex]);
            }
        }, function (err) {
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error loading the next ticket.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    BatchUploadComponent.prototype.preselectDropdowns = function (single, ticket) {
        if (single === void 0) { single = true; }
        if (single && ticket) {
            var keys = Object.keys(ticket);
            for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                var key = keys_1[_i];
                if (ticket[key]) {
                    if (key === 'ticketDate') {
                        this.model[key] = ticket[key] + 'T00:00:00';
                    }
                    else if (key === 'job' && ticket.job) {
                        this.selectedJobReference = ticket.job;
                        this.jobReferenceSelector.setSelectedReference(ticket.job);
                    }
                    else if (key === 'customer' && ticket.customer) {
                        this.selectedCustomerReference = ticket.customer;
                        this.customerReferenceSelector.setSelectedReference(ticket.customer);
                    }
                    else if (key === 'driver' && ticket.driver) {
                        this.selectedDriverReference = ticket.driver;
                        this.driverReferenceSelector.setSelectedReference(ticket.driver);
                    }
                    else {
                        this.model[key] = ticket[key];
                    }
                }
            }
        }
        else {
            // get our key list from the first ticket in the batch upload group
            var keys = Object.keys(this.savedTickets[0]);
            for (var _a = 0, keys_2 = keys; _a < keys_2.length; _a++) {
                var key = keys_2[_a];
                if (key === 'ticketDate') {
                    this.model.ticketDate = this.checkForMatchingValue(this.savedTickets, 'ticketDate') && this.savedTickets[0].ticketDate ?
                        this.savedTickets[0].ticketDate + 'T00:00:00' : null;
                }
                else {
                    this.model[key] = this.checkForMatchingValue(this.savedTickets, key) ? this.savedTickets[0][key] : null;
                }
            }
        }
    };
    BatchUploadComponent.prototype.fileChange = function (e) {
        var _this = this;
        this.uploadService.getS3Policy(this.external).subscribe(function (policy) {
            _this.policyData = policy;
            _this.uploadFiles = e.target ? Array.from(e.target.files) : e;
            if (_this.uploadFiles.length) {
                _this.viewerType = _this.uploadTotal + _this.uploadFiles.length > 1 ? 'grid' : 'single';
                _this.loader = {
                    active: true,
                    type: 'uploading'
                };
                _this.uploadFiles.forEach(function (file, i) { return _this.convertFileToRecord(file, i); });
            }
        }, function (err) {
            _this.loader.active = false;
            var notification = {
                context: { err: err },
                id: shortid.generate(),
                message: 'There was an error authentication with our upload server.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            _this.notificationsService.addNotification(notification);
            throw err;
        });
    };
    BatchUploadComponent.prototype.batchRecordOperation = function (reset, close) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        if (close === void 0) { close = true; }
        if (this.savedTickets.length > 1) {
            var batchUploadRequests = this.savedTickets.map(function (ticket) {
                ticket.quantity = _this.model.quantity ? _this.model.quantity : ticket.quantity;
                ticket.quantityType = _this.model.quantityType ? _this.model.quantityType : ticket.quantityType;
                var ticketDatetype = _this.model.ticketDate ? _this.model.ticketDate : ticket.ticketDate;
                if (ticketDatetype && _this.isValidDate(ticketDatetype)) {
                    ticket.ticketDate = new Date(ticketDatetype).toISOString().split('T')[0];
                }
                else {
                    delete ticket.ticketDate;
                }
                ticket.truckNumber = _this.model.truckNumber ? _this.model.truckNumber : ticket.truckNumber;
                ticket.truckType = _this.model.truckType ? _this.model.truckType : ticket.truckType;
                ticket.driverName = _this.model.driverName ? _this.model.driverName : ticket.driverName;
                ticket.customerName = _this.model.customerName ? _this.model.customerName : ticket.customerName;
                ticket.jobName = _this.model.jobName ? _this.model.jobName : ticket.jobName;
                ticket.material = _this.model.material ? _this.model.material : ticket.material;
                ticket.carrierName = _this.model.carrierName ? _this.model.carrierName : ticket.carrierName;
                ticket.orderNumber = _this.model.orderNumber ? _this.model.orderNumber : ticket.orderNumber;
                ticket.invoiceRate = _this.model.invoiceRate ? _this.model.invoiceRate : ticket.invoiceRate;
                if (!ticket.image) {
                    delete ticket.image;
                }
                delete ticket.organization;
                delete ticket.driver;
                delete ticket.truck;
                delete ticket.customer;
                delete ticket.job;
                delete ticket.jobCode;
                delete ticket.haulRate;
                delete ticket.origin;
                delete ticket.destination;
                return new Promise(function (resolve) {
                    _this.ticketService.save(ticket).pipe(operators_1.switchMap(function (ticketRes) {
                        var referenceUpdates = { ticketId: ticketRes.id };
                        if (_this.selectedJobReference && !!_this.selectedJobReference.id) {
                            referenceUpdates.job = _this.selectedJobReference;
                        }
                        if (_this.selectedCustomerReference && !!_this.selectedCustomerReference.id) {
                            referenceUpdates.customer = _this.selectedCustomerReference;
                        }
                        if (_this.selectedDriverReference && !!_this.selectedDriverReference.id) {
                            referenceUpdates.driver = _this.selectedDriverReference;
                        }
                        if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
                            return rxjs_1.of(ticket);
                        }
                        else {
                            return _this.ticketService.updateFieldReferences(referenceUpdates);
                        }
                    })).subscribe(function () { }, function (err) {
                        var notification = {
                            context: { err: err },
                            id: shortid.generate(),
                            message: 'There was an error saving one of these tickets.' +
                                (ticket.ticketNumber ? ' (Ticket #' + ticket.ticketNumber + ')' : ''),
                            originator: 'uploader',
                            type: notifications_service_1.NotificationType.Danger,
                        };
                        _this.notificationsService.addNotification(notification);
                        throw err;
                    }, function () { return resolve(); });
                });
            });
            Promise.all(batchUploadRequests).then(function () {
                if (close) {
                    _this.close();
                }
                else {
                    _this.viewerType = 'single';
                    _this.selectedRecordIndex = _this.selectedRecordIndex ? _this.selectedRecordIndex : 0;
                    _this.preselectDropdowns(true, _this.savedTickets[_this.selectedRecordIndex]);
                }
            });
        }
        else {
            var newTicketObj_1 = lodash_1.clone(this.model);
            newTicketObj_1.ticketNumber = newTicketObj_1.ticketNumber || !this.savedTickets[this.selectedRecordIndex] ?
                newTicketObj_1.ticketNumber : this.savedTickets[this.selectedRecordIndex].ticketNumber;
            newTicketObj_1.ticketDate = this.model.ticketDate ?
                typeof this.model.ticketDate === 'object' ?
                    new Date(this.model.ticketDate).toISOString().split('T')[0] :
                    this.model.ticketDate.split('T')[0] : null;
            if (this.savedTickets && this.savedTickets.length) {
                newTicketObj_1.id = this.savedTickets[0].id;
                newTicketObj_1.image = this.savedTickets[0].image;
            }
            ;
            delete newTicketObj_1.organization;
            delete newTicketObj_1.driver;
            delete newTicketObj_1.truck;
            delete newTicketObj_1.customer;
            delete newTicketObj_1.job;
            delete newTicketObj_1.jobCode;
            delete newTicketObj_1.haulRate;
            delete newTicketObj_1.origin;
            delete newTicketObj_1.destination;
            this.ticketService.save(newTicketObj_1).pipe(operators_1.switchMap(function (ticketRes) {
                var referenceUpdates = { ticketId: ticketRes.id };
                if (_this.selectedJobReference && !!_this.selectedJobReference.id) {
                    referenceUpdates.job = _this.selectedJobReference;
                }
                if (_this.selectedCustomerReference && !!_this.selectedCustomerReference.id) {
                    referenceUpdates.customer = _this.selectedCustomerReference;
                }
                if (_this.selectedDriverReference && !!_this.selectedDriverReference.id) {
                    referenceUpdates.driver = _this.selectedDriverReference;
                }
                if (!referenceUpdates.job && !referenceUpdates.customer && !referenceUpdates.driver) {
                    return rxjs_1.of(ticketRes);
                }
                else {
                    return _this.ticketService.updateFieldReferences(referenceUpdates);
                }
            })).subscribe(function () { }, function (err) {
                var notification = {
                    context: { err: err },
                    id: shortid.generate(),
                    message: 'There was an error saving this ticket.' +
                        (newTicketObj_1.ticketNumber ? ' (Ticket #' + newTicketObj_1.ticketNumber + ')' : ''),
                    originator: 'uploader',
                    type: notifications_service_1.NotificationType.Danger,
                };
                _this.notificationsService.addNotification(notification);
                throw err;
            }, function () {
                if (reset) {
                    _this.reset();
                }
                else {
                    _this.close();
                }
            });
        }
    };
    BatchUploadComponent.prototype.close = function (ticket) {
        var _this = this;
        this.disableScanning();
        if (ticket) {
            this.updateTicket(ticket).subscribe(function () {
                _this.router.navigate(['tickets']);
            }, function (err) {
                var notification = {
                    context: { err: err },
                    id: shortid.generate(),
                    message: 'There was an error saving this ticket.' + (ticket.ticketNumber ? ' (Ticket #' + ticket.ticketNumber + ')' : ''),
                    originator: 'uploader',
                    type: notifications_service_1.NotificationType.Danger,
                };
                _this.notificationsService.addNotification(notification);
                throw err;
            });
        }
        else {
            this.router.navigate(['tickets']);
        }
    };
    BatchUploadComponent.prototype.reset = function () {
        this.savedTickets.length = 0;
        this.uploadFiles.length = 0;
        this.uploadTotal = 0;
        this.selectedRecordIndex = null;
        this.model = { ticketDate: '' };
    };
    BatchUploadComponent.prototype.cropWhiteSpace = function (base64Image) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loadImage(base64Image).then(function (image) {
                // draw canvas with base64 image data (maintain size)
                var canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext('2d');
                if (ctx) {
                    // remove any white pixels that the scan driver will try to fill in
                    // and return the cropped image base64 data
                    ctx.drawImage(image, 0, 0);
                    var copy = document.createElement('canvas').getContext('2d');
                    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    var pixel = imageData.data;
                    var bound = {};
                    var x = void 0, y = void 0;
                    for (var i = 0; i < pixel.length; i += 4) {
                        if (pixel[i] !== 255 || pixel[i + 1] !== 255 || pixel[i + 2] !== 255) {
                            x = (i / 4) % canvas.width;
                            y = ~~((i / 4) / canvas.width);
                            if (!bound.top) {
                                bound.top = y;
                            }
                            if (!bound.left) {
                                bound.left = x;
                            }
                            else if (x < bound.left) {
                                bound.left = x;
                            }
                            if (!bound.right) {
                                bound.right = x;
                            }
                            else if (bound.right < x) {
                                bound.right = x;
                            }
                            if (!bound.bottom) {
                                bound.bottom = y;
                            }
                            else if (bound.bottom < y) {
                                bound.bottom = y;
                            }
                        }
                    }
                    var trimHeight = bound.bottom - bound.top, trimWidth = bound.right - bound.left, trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
                    if (copy) {
                        copy.canvas.width = trimWidth;
                        copy.canvas.height = trimHeight;
                        copy.putImageData(trimmed, 0, 0);
                    }
                    resolve(copy ? copy.canvas.toDataURL('image/jpeg', 1.0) : base64Image);
                }
                else {
                    resolve(base64Image);
                }
            });
        });
    };
    BatchUploadComponent.prototype.loadImage = function (base64Image) {
        return new Promise(function (resolve, reject) {
            var image = new Image();
            image.src = base64Image;
            image.onload = function () { return resolve(image); };
            image.onerror = function (e) { return reject(e); };
        });
    };
    BatchUploadComponent.prototype.convertPDFtoImages = function (pdfFile) {
        var _this = this;
        return new Promise(function (resolve) {
            pdfjs.getDocument(pdfFile).then(function (pdf) {
                var pdfImages = [];
                var _loop_2 = function (i) {
                    pdf.getPage(i).then(function (page) {
                        var scale = 2;
                        var viewport = page.getViewport(scale);
                        var canvas = document.createElement('canvas');
                        canvas.setAttribute('id', 'canvas-' + i);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        var task = page.render({ canvasContext: context, viewport: viewport });
                        task.promise.then(function () {
                            pdfImages.push(_this.uploadService.convertDataUriToFile(canvas.toDataURL('image/jpeg'), 'pdfImage-' + i + '.jpg'));
                            if (pdfImages.length === pdf.numPages) {
                                resolve(pdfImages);
                            }
                        });
                    });
                };
                for (var i = 1; i <= pdf.numPages; i++) {
                    _loop_2(i);
                }
            });
        });
    };
    BatchUploadComponent.prototype.convertFileToRecord = function (file, uploadIndex) {
        var _this = this;
        var reader = new FileReader();
        reader.onload = function () { return _this.fileLoad(reader, file, uploadIndex); };
        reader.readAsArrayBuffer(file);
    };
    BatchUploadComponent.prototype.fileLoad = function (reader, file, uploadIndex) {
        var _this = this;
        if (file.type.includes('pdf')) {
            Promise.resolve(this.convertPDFtoImages(reader.result)).then(function (results) {
                var pdfImages = results;
                _this.uploadTotal = _this.uploadTotal + pdfImages.length;
                _this.viewerType = _this.uploadTotal > 1 ? 'grid' : 'single';
                pdfImages.forEach(function (image) { return _this.generateRecordFromImage(image); });
            });
        }
        else if (file.type.startsWith('image/') && !file.type.includes('heic')) {
            this.uploadTotal++;
            this.generateRecordFromImage(file, uploadIndex);
        }
        else {
            var notification = {
                context: {},
                id: shortid.generate(),
                message: 'There was an error uploading this image. Please upload a valid image format (JPEG/PNG/GIF) or a PDF.',
                originator: 'uploader',
                type: notifications_service_1.NotificationType.Danger,
            };
            this.notificationsService.addNotification(notification);
        }
    };
    ;
    BatchUploadComponent.prototype.generateRecordFromImage = function (imageFile, uploadIndex) {
        var _this = this;
        Promise.resolve(this.uploadService.generateUUID()).then(function (uuid) {
            _this.policyData['fields']['key'] = 'tickets/' + uuid + '/original.jpg';
            var ticketData = {
                id: uuid,
                image: environment_1.environment.ticketImageServerUrl + _this.policyData['fields']['key'],
                ticketDate: '',
                ticketNumber: '',
                quantity: ''
            };
            var thisUploadIndex = uploadIndex ? uploadIndex : _this.uploadIndex;
            _this.uploadIndex++;
            _this.uploadService.uploadToS3(_this.policyData, imageFile).subscribe(function () {
                _this.loader.type = 'analyzing';
                _this.ocrService.getResults(ticketData, _this.external).then(function (results) {
                    ticketData.ticketNumber = results.ticketNumber && results.ticketNumber.toString();
                    ticketData.quantity = results.quantity && results.quantity.toString();
                    var detectedDate = results.ticketDate && results.ticketDate.length > 0 ? results.ticketDate.toString() : null;
                    if (detectedDate && _this.isValidDate(detectedDate)) {
                        ticketData.ticketDate = new Date(detectedDate).toISOString().split('T')[0];
                    }
                    else {
                        delete ticketData.ticketDate;
                    }
                    if (_this.external) {
                        var externalTicketData_1 = {
                            quantity: ticketData.quantity,
                            ticketDate: _this.model.ticketDate ? new Date(_this.model.ticketDate).toISOString().split('T')[0] : ticketData.ticketDate,
                            ticketNumber: ticketData.ticketNumber,
                            image: ticketData.image,
                            contextId: _this.contextId
                        };
                        _this.ticketService.saveExternal(externalTicketData_1).subscribe(function (ticketRes) {
                            ticketRes.uploadIndex = thisUploadIndex;
                            _this.savedTickets.push(ticketRes);
                            _this.loader.active = _this.savedTickets.length !== _this.uploadTotal;
                            _this.selectedRecordIndex = !_this.loader.active && _this.savedTickets.length === 1 ? 0 : null;
                        }, function (err) {
                            var notification = {
                                context: { err: err },
                                id: shortid.generate(),
                                message: 'There was an error creating this ticket.' +
                                    (externalTicketData_1.ticketNumber ? ' (Ticket #' + externalTicketData_1.ticketNumber + ')' : ''),
                                originator: 'uploader',
                                type: notifications_service_1.NotificationType.Danger,
                            };
                            _this.notificationsService.addNotification(notification);
                            throw err;
                        });
                    }
                    else {
                        delete ticketData.id;
                        var ticketUploadData_1 = ticketData;
                        _this.ticketService.save(ticketUploadData_1).subscribe(function (ticketRes) {
                            ticketRes.uploadIndex = thisUploadIndex;
                            _this.savedTickets.push(ticketRes);
                            _this.loader.active = _this.savedTickets.length !== _this.uploadTotal;
                            _this.selectedRecordIndex = !_this.loader.active && _this.savedTickets.length === 1 ? 0 : null;
                        }, function (err) {
                            var notification = {
                                context: { err: err },
                                id: shortid.generate(),
                                message: 'There was an error creating this ticket.' +
                                    (ticketUploadData_1.ticketNumber ? ' (Ticket #' + ticketUploadData_1.ticketNumber + ')' : ''),
                                originator: 'uploader',
                                type: notifications_service_1.NotificationType.Danger,
                            };
                            _this.notificationsService.addNotification(notification);
                            throw err;
                        });
                    }
                });
            }, function (err) {
                _this.uploadTotal = Number(_this.uploadTotal) - 1;
                var notification = {
                    context: { err: err },
                    id: shortid.generate(),
                    message: 'There was an error uploading this image.',
                    originator: 'uploader',
                    type: notifications_service_1.NotificationType.Danger,
                };
                _this.notificationsService.addNotification(notification);
                throw err;
            });
        });
    };
    BatchUploadComponent.prototype.toggleView = function (type, index) {
        if (!this.external) {
            this.viewerType = type;
            if (type === 'grid') {
                this.selectedRecordIndex = null;
                this.preselectDropdowns(false);
            }
            else {
                this.selectedRecordIndex = index ? index : 0;
                this.batchRecordOperation(false, false);
            }
        }
    };
    BatchUploadComponent.prototype.onJobReferenceSelected = function (reference) {
        this.selectedJobReference = reference;
        this.isJobFieldVisible = false;
    };
    BatchUploadComponent.prototype.onJobReferenceBlur = function () {
        this.isJobFieldVisible = false;
    };
    BatchUploadComponent.prototype.onJobReferenceCreated = function (reference) {
        this.selectedJobReference = reference;
    };
    BatchUploadComponent.prototype.onCustomerReferenceSelected = function (reference) {
        this.selectedCustomerReference = reference;
        this.isCustomerFieldVisible = false;
    };
    BatchUploadComponent.prototype.onCustomerReferenceBlur = function () {
        this.isCustomerFieldVisible = false;
    };
    BatchUploadComponent.prototype.onCustomerReferenceCreated = function (reference) {
        this.selectedCustomerReference = reference;
    };
    BatchUploadComponent.prototype.onDriverReferenceSelected = function (reference) {
        this.selectedDriverReference = reference;
        this.isDriverFieldVisible = false;
    };
    BatchUploadComponent.prototype.onDriverReferenceBlur = function () {
        this.isDriverFieldVisible = false;
    };
    BatchUploadComponent.prototype.onDriverReferenceCreated = function (reference) {
        this.selectedDriverReference = reference;
    };
    BatchUploadComponent.prototype.onJobReferenceToggle = function () {
        this.jobFieldsVisible = !this.jobFieldsVisible;
    };
    BatchUploadComponent.prototype.onDriverReferenceToggle = function () {
        this.driverFieldsVisible = !this.driverFieldsVisible;
    };
    BatchUploadComponent.prototype.onCustomerReferenceToggle = function () {
        this.customerFieldsVisible = !this.customerFieldsVisible;
    };
    BatchUploadComponent.prototype.onJobReferenceFocused = function () {
        this.isCustomerFieldVisible = false;
        this.isDriverFieldVisible = false;
    };
    BatchUploadComponent.prototype.onDriverReferenceFocused = function () {
        this.isJobFieldVisible = false;
        this.isCustomerFieldVisible = false;
    };
    BatchUploadComponent.prototype.onCustomerReferenceFocused = function () {
        this.isJobFieldVisible = false;
        this.isDriverFieldVisible = false;
    };
    return BatchUploadComponent;
}());
exports.BatchUploadComponent = BatchUploadComponent;
