"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ng2_dragula_1 = require("ng2-dragula");
var OrderData = /** @class */ (function () {
    function OrderData() {
    }
    return OrderData;
}());
exports.OrderData = OrderData;
var ArrangeModalComponent = /** @class */ (function () {
    function ArrangeModalComponent(dragulaService, dialogRef, data) {
        this.dragulaService = dragulaService;
        this.dialogRef = dialogRef;
        this.list = data;
        this.generateOrderData(this.list);
    }
    ArrangeModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dragulaService.createGroup('item-list', {
            moves: function (_el, _container, handle) {
                if (!handle) {
                    handle = new Element();
                }
                return handle.className === 'handle';
            }
        });
        this.dragulaService.dropModel('item-list').subscribe(function (_a) {
            var sourceModel = _a.sourceModel;
            _this.list = sourceModel.filter(function (obj) { return obj !== undefined; });
            _this.generateOrderData(_this.list);
        });
        this.dialogRef.afterClosed().subscribe(function () {
            _this.dragulaService.destroy('item-list');
        });
    };
    ArrangeModalComponent.prototype.generateOrderData = function (list) {
        var _this = this;
        this.orderData = [];
        list.forEach(function (obj, index) {
            var objOrderData = {
                id: obj.id,
                order: index,
                parent: list[0].id !== obj.id ? list[0].id : null
            };
            _this.orderData.push(objOrderData);
        });
    };
    ArrangeModalComponent.prototype.save = function () {
        this.callback(this.orderData);
        this.dialogRef.close();
    };
    ArrangeModalComponent.prototype.unlink = function (item) {
        this.list = this.list.filter(function (obj) { return obj.id !== item.id; });
        this.unlinkCallback(item);
        this.generateOrderData(this.list);
    };
    return ArrangeModalComponent;
}());
exports.ArrangeModalComponent = ArrangeModalComponent;
