"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".styleguide[_ngcontent-%COMP%]{padding:2em;overflow:scroll}.user-dropdown[_ngcontent-%COMP%]{padding:1em 1em 2em;background:#2a81d7}dropdown[_ngcontent-%COMP%]{display:block;margin-bottom:1em}.translate-keys[_ngcontent-%COMP%]{display:none}"];
exports.styles = styles;
