"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("@ngx-translate/core");
var i3 = require("./status-cell.component");
var styles_StatusCell = [".ticket-status[_ngcontent-%COMP%] { font-weight: 700; }\n    .success[_ngcontent-%COMP%] { color: #00c852; }\n    .failure[_ngcontent-%COMP%] { color: #b00120; }\n    .pending[_ngcontent-%COMP%] { color: #f5a623; }"];
var RenderType_StatusCell = i0.ɵcrt({ encapsulation: 0, styles: styles_StatusCell, data: {} });
exports.RenderType_StatusCell = RenderType_StatusCell;
function View_StatusCell_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.TitleCasePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "ticket-status"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "success": 0, "failure": 1, "pending": 2 }), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵppd(5, 1), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ticket-status"; var currVal_1 = _ck(_v, 3, 0, (_co.value === _co.statusList.successValue), (_co.value === _co.statusList.failureValue), (_co.value === _co.statusList.pendingValue)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 6).transform(i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.value)))); _ck(_v, 4, 0, currVal_2); }); }
exports.View_StatusCell_0 = View_StatusCell_0;
function View_StatusCell_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "status-cell", [], null, null, null, View_StatusCell_0, RenderType_StatusCell)), i0.ɵdid(1, 49152, null, 0, i3.StatusCell, [], null, null)], null, null); }
exports.View_StatusCell_Host_0 = View_StatusCell_Host_0;
var StatusCellNgFactory = i0.ɵccf("status-cell", i3.StatusCell, View_StatusCell_Host_0, {}, {}, []);
exports.StatusCellNgFactory = StatusCellNgFactory;
