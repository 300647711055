import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  template: `
    <div>
      <div *ngIf="params.checkboxSelection">
        <mat-checkbox [disableRipple]="true" (click)="selectionChanged()"></mat-checkbox>
      </div>
    </div>
  `,
  styles: [`
    /deep/ mat-checkbox .mat-checkbox-inner-container {
      width: 18px;
      height: 18px;
    }
  `]
})
export class SelectAllHeader {
  public params: any;
  selected = false;

  agInit(params: any): void {
    this.params = params;
  }

  selectionChanged() {
    this.params.service.allSelected = !this.params.service.allSelected;
    this.params.api.forEachNode((node: any) => node.setSelected(this.params.service.allSelected));
  }
}
