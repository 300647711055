import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/resource.service';
import { environment } from '../../../environments/environment';
import { Http } from '@angular/http';
import { TruckReferenceSerializer } from './truck-reference.serializer';
import { TruckReference } from './truck-reference';

@Injectable()
export class TruckReferenceService extends ResourceService<TruckReference> {
  baseUrl = environment.serverUrl + 'reference/trucks/';
  allSelected = false;

  constructor(http: Http) {
    super(http, 'reference/trucks/', new TruckReferenceSerializer());
  }
}

