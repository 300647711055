import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { environment } from '../../../environments/environment';
import { ResourceService } from '../../shared/resource.service';
import { requestHeaders } from '../../shared/api.service';
import { Observable } from 'rxjs';

import { QuickbooksCompany } from './quickbooks';
import { QuickbooksCompanySerializer } from './quickbooks.serializer';
import { map } from 'rxjs/operators';



@Injectable()
export class QuickbooksCompanyService extends ResourceService<QuickbooksCompany> {
  baseUrl = environment.serverUrl + 'qb/companies/';

  constructor(http: Http) {
    super(http, 'qb/companies/', new QuickbooksCompanySerializer());
  }

  downloadQwcFile(companyId: string): Observable<any> {
    const url = this.baseUrl + companyId + '/qwc/';
    let headers = requestHeaders();
    if (headers) { headers.delete('Accept'); }

    return this.http.get(url, { headers: headers }).pipe(
      map(response => this.saveFile(response))
    );
  }
}
