import { Ocr } from './ocr';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class OcrSerializer {
  fromJson(json: any): Ocr {
    json = camelcaseKeysDeep(json);
    const ocr = new Ocr();
    if (!json) { return ocr; }

    ocr.id = json.id;
    ocr.identifier = json.identifier;
    ocr.ocrResult = json.ocrResult;
    if (ocr.ocrResult) {
      try {
        ocr.textAnnotations = ocr.ocrResult['responses'][0]['textAnnotations'];
      } catch (err) {
        console.error(err);
      }
    }
    ocr.ocrResultText = json.ocrResultText;
    if (json.properties) {
      ocr.properties = json.properties;
    } else {
      ocr.properties = {
        date: null,
        customer: null,
        job: null,
        driver: null,
        material: null,
        truck: null,
        truckType: null,
        carrier: null
      };
    }
    ocr.tags = json.tags;
    ocr.createdAt = json.createdAt;
    ocr.updatedAt = json.updatedAt;
    ocr.textMatches = json.textMatches;

    // if (json.attachment) {
    //   ocr.attachment = new ImageSerializer().fromJson({
    //     src: json.attachment,
    //     width: json.attachmentWidth,
    //     height: json.attachmentHeight
    //   });
    // }

    if (ocr.textAnnotations && ocr.textAnnotations.length) {
      ocr.boundingBoxes = ocr.textAnnotations.map((annotation: any) => {
        try {
          const description = annotation['description'];
          const verticies = annotation['boundingPoly']['vertices'];
          const top = Math.min(...verticies.map((v: any) => v['y']));
          const left = Math.min(...verticies.map((v: any) => v['x']));
          const width = Math.max(...verticies.map((v: any) => v['x'])) - left;
          const height = Math.max(...verticies.map((v: any) => v['y'])) - top;
          const fontSize = height * 0.40;

          // Transfor for scaled images
          const displayedWidth = 600;
          const scale = displayedWidth / json.attachmentWidth;

          return {
            description: description,
            top: (top * scale) - (top * scale * 0.05),
            left: left * scale,
            width: width * scale,
            height: (height * scale) / 2,
            fontSize: (fontSize * scale)
          };
        } catch (err) {
          return;
        }
      });
    }

    return ocr;
  }

  toJson(ocr: Ocr): any {
    if (!ocr) { return ocr; }

    const json = {
      id: ocr.id,
      identifier: ocr.identifier,
      properties: ocr.properties,
      tags: ocr.tags,
      attachment: ocr.attachment,
      export: ocr.export
    };

    return decamelizeKeysDeep(json);
  }
}
