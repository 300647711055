"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".ruckit-arrange-items[_ngcontent-%COMP%]{max-height:80vh}mat-icon[_ngcontent-%COMP%]{color:#6f6f6f}mat-icon.handle[_ngcontent-%COMP%]{pointer-events:none}mat-icon.unlink[_ngcontent-%COMP%]{cursor:pointer;margin-left:auto}p[_ngcontent-%COMP%]{font-size:16px;line-height:1.4;margin-bottom:30px}p.instructions[_ngcontent-%COMP%]{margin-bottom:10px}.item[_ngcontent-%COMP%]{border-radius:4px;border:1px solid #e6e6e6;background-color:#f4f4f4;margin-bottom:10px;display:flex;align-items:center;height:160px;padding:20px}.billable-label[_ngcontent-%COMP%]{position:absolute;height:0;margin:0;-webkit-transform:rotate(-90deg) translateY(-94px);transform:rotate(-90deg) translateY(-94px)}.handle[_ngcontent-%COMP%]{cursor:move}.item-image[_ngcontent-%COMP%]{width:124px;height:124px;margin:20px}.item-image[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{max-width:124px;max-height:124px}.item-info[_ngcontent-%COMP%]{margin-right:25px}[mat-dialog-content][_ngcontent-%COMP%]{background-color:#fff;max-height:calc(80vh - 128px);border-radius:0;overflow-y:scroll}.modal-actions[_ngcontent-%COMP%]{border-bottom-left-radius:4px;border-bottom-right-radius:4px;height:68px;background-color:#eff3f9;margin-bottom:0;padding:16px 30px}.modal-actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:100%}"];
exports.styles = styles;
