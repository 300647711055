"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./loader.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./loader.component");
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
exports.RenderType_LoaderComponent = RenderType_LoaderComponent;
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "dot dot1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "dot dot2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "dot dot3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "dot dot4"]], null, null, null, null, null))], null, null); }
function View_LoaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hold tight while we set up your Ruckit experience."])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "dots"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "dot dot1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "dot dot2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "dot dot3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "dot dot4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "/assets/img/truck-with-load.svg"]], null, null, null, null, null))], null, null); }
function View_LoaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Did you know?"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ruckit can help you grow your business with powerful collaboration tools. Check out our "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Help Center"])), (_l()(), i1.ɵted(-1, null, [" to learn more."]))], null, null); }
function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "loader"]], [[8, "hidden", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "mat-table-loader": 0, "intro": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "loader"; var currVal_2 = _ck(_v, 2, 0, _co.matTableLoader, (_co.kind === "full-size")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = (_co.kind !== "full-size"); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.kind === "full-size"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.kind === "full-size"); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDelayedRunning; _ck(_v, 0, 0, currVal_0); }); }
exports.View_LoaderComponent_0 = View_LoaderComponent_0;
function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 180224, null, 0, i3.LoaderComponent, [], null, null)], null, null); }
exports.View_LoaderComponent_Host_0 = View_LoaderComponent_Host_0;
var LoaderComponentNgFactory = i1.ɵccf("my-loader", i3.LoaderComponent, View_LoaderComponent_Host_0, { matTableLoader: "matTableLoader", delay: "delay", kind: "kind", isRunning: "isRunning" }, {}, []);
exports.LoaderComponentNgFactory = LoaderComponentNgFactory;
