"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../../../environments/environment");
var resource_service_1 = require("../resource.service");
var preferences_serializer_1 = require("./preferences.serializer");
var PreferencesService = /** @class */ (function (_super) {
    __extends(PreferencesService, _super);
    function PreferencesService(http) {
        var _this = _super.call(this, http, 'preferences/', new preferences_serializer_1.PreferencesSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'preferences/';
        return _this;
    }
    PreferencesService.prototype.listStatic = function (token) {
        var _this = this;
        var ticketUrl = this.baseUrl;
        var headers = new http_1.Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Token ' + token);
        return this.http.get(ticketUrl, { headers: headers }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    return PreferencesService;
}(resource_service_1.ResourceService));
exports.PreferencesService = PreferencesService;
