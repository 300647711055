import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ResourceService } from '../resource.service';

@Component({
  selector: 'checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.scss']
})
export class CheckboxCell implements ICellEditorAngularComp {
  value!: boolean;
  rowId!: string;
  field!: string;
  service!: any;
  callback!: (updates: Object) => void;

  constructor() { }

  agInit(params: any): void {
    this.value = params.value;
    this.rowId = params.data && params.data.id;
    this.field = params.field;
    this.callback = params.callback;
    this.service = <ResourceService<any>> params.service;
  }

  getValue() {
    return this.value;
  }

  valueChange(value: any) {
    const updates: { [key: string]: any } = {};
    updates['id'] = this.rowId;
    updates[this.field] = value;
    if (this.service) {
      this.service.save(updates).subscribe((result: any) => {
        this.value = result[this.field];
        this.callback(result);
      });
    }
  }
}
