"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var BulkActionDialogComponent = /** @class */ (function () {
    function BulkActionDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.errors = [];
        this.selected = 0;
        this.type = 'ticket';
        this.action = 'delete';
        this.selected = data['selected'] && data['selected'].length;
        this.type = data['type'] ? data['type'] : this.type;
        this.action = data['action'] ? data['action'] : this.action;
    }
    BulkActionDialogComponent.prototype.ngOnInit = function () { };
    BulkActionDialogComponent.prototype.processRecords = function (data) {
        this.callback(data);
        this.dialogRef.close();
    };
    return BulkActionDialogComponent;
}());
exports.BulkActionDialogComponent = BulkActionDialogComponent;
