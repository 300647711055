"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("../../../style/grid.scss.shim.ngstyle");
var i1 = require("./settlement-grid.component.scss.shim.ngstyle");
var i2 = require("@angular/core");
var i3 = require("../../shared/search/search.component.ngfactory");
var i4 = require("../../shared/search/search.component");
var i5 = require("../../../../node_modules/ag-grid-angular/dist/agGridNg2.ngfactory");
var i6 = require("ag-grid-angular/dist/ng2FrameworkFactory");
var i7 = require("ag-grid-angular/dist/ng2FrameworkComponentWrapper");
var i8 = require("ag-grid-angular/dist/agGridNg2");
var i9 = require("../../shared/authentication.service");
var i10 = require("@angular/http");
var i11 = require("../../shared/data.service");
var i12 = require("../settlement/settlement.service");
var i13 = require("./settlement-grid.component");
var i14 = require("@angular/router");
var i15 = require("@angular/material/dialog");
var styles_SettlementGridComponent = [i0.styles, i1.styles];
var RenderType_SettlementGridComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_SettlementGridComponent, data: {} });
exports.RenderType_SettlementGridComponent = RenderType_SettlementGridComponent;
function View_SettlementGridComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { settlementGrid: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 11, "div", [["class", "grid-display"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Settlements"])), (_l()(), i2.ɵeld(5, 0, null, null, 3, "div", [["class", "grid-actions"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 1, "search", [], null, [[null, "searchTermChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTermChange" === en)) {
        var pd_0 = (_co.changeSearch($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTermChange" === en)) {
        var pd_1 = ((_co.search = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SearchComponent_0, i3.RenderType_SearchComponent)), i2.ɵdid(8, 114688, null, 0, i4.SearchComponent, [], { searchTerm: [0, "searchTerm"] }, { searchTermChange: "searchTermChange" }), (_l()(), i2.ɵeld(9, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material settlement-grid"], ["rowSelection", "multiple"], ["suppressBrowserResizeObserver", "true"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AgGridNg2_0, i5.RenderType_AgGridNg2)), i2.ɵprd(512, null, i6.Ng2FrameworkFactory, i6.Ng2FrameworkFactory, [i2.NgZone]), i2.ɵprd(512, null, i7.Ng2FrameworkComponentWrapper, i7.Ng2FrameworkComponentWrapper, []), i2.ɵdid(12, 4898816, [[1, 4], ["settlementGrid", 4]], 1, i8.AgGridNg2, [i2.ElementRef, i2.ViewContainerRef, i6.Ng2FrameworkFactory, i7.Ng2FrameworkComponentWrapper, i2.ComponentFactoryResolver], { columnDefs: [0, "columnDefs"], frameworkComponents: [1, "frameworkComponents"], noRowsOverlayComponentParams: [2, "noRowsOverlayComponentParams"], rowSelection: [3, "rowSelection"], rowModelType: [4, "rowModelType"], maxBlocksInCache: [5, "maxBlocksInCache"], cacheBlockSize: [6, "cacheBlockSize"], noRowsOverlayComponent: [7, "noRowsOverlayComponent"], animateRows: [8, "animateRows"], pagination: [9, "pagination"], suppressBrowserResizeObserver: [10, "suppressBrowserResizeObserver"] }, { gridReady: "gridReady" }), i2.ɵqud(603979776, 2, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.search; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.noRowsOverlayComponentParams; var currVal_5 = "multiple"; var currVal_6 = _co.rowModelType; var currVal_7 = _co.maxBlocksInCache; var currVal_8 = _co.cacheBlockSize; var currVal_9 = _co.noRowsOverlayComponent; var currVal_10 = true; var currVal_11 = true; var currVal_12 = "true"; _ck(_v, 12, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵinlineInterpolate(1, "search-container ", (_co.loading ? "loading" : ""), ""); _ck(_v, 6, 0, currVal_0); }); }
exports.View_SettlementGridComponent_0 = View_SettlementGridComponent_0;
function View_SettlementGridComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "settlement-grid", [], null, null, null, View_SettlementGridComponent_0, RenderType_SettlementGridComponent)), i2.ɵprd(512, null, i9.AuthenticationService, i9.AuthenticationService, [i10.Http, i11.DataService]), i2.ɵprd(512, null, i12.SettlementService, i12.SettlementService, [i10.Http]), i2.ɵdid(3, 49152, null, 0, i13.SettlementGridComponent, [i14.Router, i9.AuthenticationService, i12.SettlementService, i15.MatDialog], null, null)], null, null); }
exports.View_SettlementGridComponent_Host_0 = View_SettlementGridComponent_Host_0;
var SettlementGridComponentNgFactory = i2.ɵccf("settlement-grid", i13.SettlementGridComponent, View_SettlementGridComponent_Host_0, {}, {}, []);
exports.SettlementGridComponentNgFactory = SettlementGridComponentNgFactory;
