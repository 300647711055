"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory");
var i2 = require("@angular/forms");
var i3 = require("@angular/material/checkbox");
var i4 = require("@angular/cdk/a11y");
var i5 = require("@angular/platform-browser/animations");
var i6 = require("@angular/common");
var i7 = require("./select-all-header.component");
var styles_SelectAllHeader = ["mat-checkbox .mat-checkbox-inner-container {\n      width: 18px;\n      height: 18px;\n    }"];
var RenderType_SelectAllHeader = i0.ɵcrt({ encapsulation: 0, styles: styles_SelectAllHeader, data: {} });
exports.RenderType_SelectAllHeader = RenderType_SelectAllHeader;
function View_SelectAllHeader_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectionChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i0.ɵdid(3, 4374528, null, 0, i3.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i0.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { disableRipple: [0, "disableRipple"] }, null)], function (_ck, _v) { var currVal_6 = true; _ck(_v, 3, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).id; var currVal_1 = i0.ɵnov(_v, 3).indeterminate; var currVal_2 = i0.ɵnov(_v, 3).checked; var currVal_3 = i0.ɵnov(_v, 3).disabled; var currVal_4 = (i0.ɵnov(_v, 3).labelPosition == "before"); var currVal_5 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_SelectAllHeader_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectAllHeader_1)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.checkboxSelection; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_SelectAllHeader_0 = View_SelectAllHeader_0;
function View_SelectAllHeader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading-overlay", [], null, null, null, View_SelectAllHeader_0, RenderType_SelectAllHeader)), i0.ɵdid(1, 49152, null, 0, i7.SelectAllHeader, [], null, null)], null, null); }
exports.View_SelectAllHeader_Host_0 = View_SelectAllHeader_Host_0;
var SelectAllHeaderNgFactory = i0.ɵccf("app-loading-overlay", i7.SelectAllHeader, View_SelectAllHeader_Host_0, {}, {}, []);
exports.SelectAllHeaderNgFactory = SelectAllHeaderNgFactory;
