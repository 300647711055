"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ocr_1 = require("./ocr");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var OcrSerializer = /** @class */ (function () {
    function OcrSerializer() {
    }
    OcrSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var ocr = new ocr_1.Ocr();
        if (!json) {
            return ocr;
        }
        ocr.id = json.id;
        ocr.identifier = json.identifier;
        ocr.ocrResult = json.ocrResult;
        if (ocr.ocrResult) {
            try {
                ocr.textAnnotations = ocr.ocrResult['responses'][0]['textAnnotations'];
            }
            catch (err) {
                console.error(err);
            }
        }
        ocr.ocrResultText = json.ocrResultText;
        if (json.properties) {
            ocr.properties = json.properties;
        }
        else {
            ocr.properties = {
                date: null,
                customer: null,
                job: null,
                driver: null,
                material: null,
                truck: null,
                truckType: null,
                carrier: null
            };
        }
        ocr.tags = json.tags;
        ocr.createdAt = json.createdAt;
        ocr.updatedAt = json.updatedAt;
        ocr.textMatches = json.textMatches;
        // if (json.attachment) {
        //   ocr.attachment = new ImageSerializer().fromJson({
        //     src: json.attachment,
        //     width: json.attachmentWidth,
        //     height: json.attachmentHeight
        //   });
        // }
        if (ocr.textAnnotations && ocr.textAnnotations.length) {
            ocr.boundingBoxes = ocr.textAnnotations.map(function (annotation) {
                try {
                    var description = annotation['description'];
                    var verticies = annotation['boundingPoly']['vertices'];
                    var top_1 = Math.min.apply(Math, verticies.map(function (v) { return v['y']; }));
                    var left = Math.min.apply(Math, verticies.map(function (v) { return v['x']; }));
                    var width = Math.max.apply(Math, verticies.map(function (v) { return v['x']; })) - left;
                    var height = Math.max.apply(Math, verticies.map(function (v) { return v['y']; })) - top_1;
                    var fontSize = height * 0.40;
                    // Transfor for scaled images
                    var displayedWidth = 600;
                    var scale = displayedWidth / json.attachmentWidth;
                    return {
                        description: description,
                        top: (top_1 * scale) - (top_1 * scale * 0.05),
                        left: left * scale,
                        width: width * scale,
                        height: (height * scale) / 2,
                        fontSize: (fontSize * scale)
                    };
                }
                catch (err) {
                    return;
                }
            });
        }
        return ocr;
    };
    OcrSerializer.prototype.toJson = function (ocr) {
        if (!ocr) {
            return ocr;
        }
        var json = {
            id: ocr.id,
            identifier: ocr.identifier,
            properties: ocr.properties,
            tags: ocr.tags,
            attachment: ocr.attachment,
            export: ocr.export
        };
        return decamelizeKeysDeep(json);
    };
    return OcrSerializer;
}());
exports.OcrSerializer = OcrSerializer;
