import { Injectable } from '@angular/core';
import { remove } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

export enum NotificationType {
  Success = 'success',
  Danger = 'danger',
  Warn = 'warn',
  Info = 'info',
};

export interface Notification {
  id: string;
  type: NotificationType;
  originator: string; // Store where the error comes from so that we can filter and only display certain types in specific locations
  message: string;
  context: { [key: string]: any }; // Extra info we want to associate this, for easier user error reporting
};

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public notifications: Notification[] = [];
  public notifications$ = new BehaviorSubject<Notification[]>([]);

  constructor() { }

  getNotifications(): Observable<Notification[]> {
    return this.notifications$.asObservable();
  }

  addNotification(notification: Notification) {
    this.notifications.push(notification);

    this.notifications$.next(this.notifications);
  }

  removeNotification(notification: Notification): void {
    remove(this.notifications, (n) => n.id === notification.id);

    this.notifications$.next(this.notifications);
  }
}
