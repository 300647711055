"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var material_1 = require("@angular/material");
var shared_1 = require("../../../app/shared");
var quickbooks_service_1 = require("../../integrations/quickbooks/quickbooks.service");
var organization_service_1 = require("../../organizations/organization.service");
var rxjs_1 = require("rxjs");
var SettingsIntegrationsComponent = /** @class */ (function () {
    function SettingsIntegrationsComponent(authenticationService, organizationService, quickbooksCompanyService, dialog) {
        this.authenticationService = authenticationService;
        this.organizationService = organizationService;
        this.quickbooksCompanyService = quickbooksCompanyService;
        this.dialog = dialog;
        this.loading = false;
        this.hasQuickbooksEnabled = false;
        this.user = this.authenticationService.user();
    }
    SettingsIntegrationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        if (this.user.organization) {
            this.organizationService.get(this.user.organization.id).subscribe(function (org) {
                _this.hasQuickbooksEnabled = org.hasQuickbooksEnabled;
                _this.loading = false;
            });
        }
    };
    SettingsIntegrationsComponent.prototype.toggleQuickbooks = function () {
        var _this = this;
        this.loading = true;
        var organization = {
            id: this.user.organization && this.user.organization.id,
            hasQuickbooksEnabled: this.hasQuickbooksEnabled
        };
        this.organizationService.save(organization).subscribe(function (org) {
            _this.hasQuickbooksEnabled = org.hasQuickbooksEnabled;
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
        }, function () { _this.loading = false; });
    };
    SettingsIntegrationsComponent.prototype.setupQuickbooks = function () {
        var _this = this;
        this.loading = true;
        this.quickbooksCompanyService.list().pipe(operators_1.switchMap(function (companies) {
            return companies.length && companies[0].id ?
                rxjs_1.of(companies[0]) : _this.quickbooksCompanyService.save({});
        }), operators_1.switchMap(function (company) {
            return _this.quickbooksCompanyService.downloadQwcFile(company.id);
        }), operators_1.tap(function () { return window.open('https://s3.amazonaws.com/ruckit-platform/quickbooks/webconnector/Quickbooks_WebConnector.zip', '_blank'); })).subscribe(function () { _this.loading = false; });
    };
    return SettingsIntegrationsComponent;
}());
exports.SettingsIntegrationsComponent = SettingsIntegrationsComponent;
