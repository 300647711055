"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".grid-actions[_ngcontent-%COMP%]{margin-bottom:25px;width:100%}.search-container[_ngcontent-%COMP%], .search-container[_ngcontent-%COMP%]   search[_ngcontent-%COMP%]{width:100%}.settlement-grid[_ngcontent-%COMP%]{width:100%;height:calc(100% - 150px)}.settlement-grid[_ngcontent-%COMP%]     a{text-decoration:none;color:#2a81d7}.selected-indicator[_ngcontent-%COMP%]{position:absolute;bottom:20px;left:50px;color:#2a81d7;font-weight:600}  .ag-tool-panel-wrapper{width:auto}  .ag-theme-material .ag-icon-checkbox-checked:empty{background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDBIMmEyIDIgMCAwIDAtMiAydjE0YTIgMiAwIDAgMCAyIDJoMTRhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJ6TTcgMTRMMiA5bDEuNDEtMS40MUw3IDExLjE3bDcuNTktNy41OUwxNiA1bC05IDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+)}  .cdk-overlay-pane{overflow:hidden}"];
exports.styles = styles;
