"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./notification.component");
var styles_NotificationComponent = [];
var RenderType_NotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationComponent, data: {} });
exports.RenderType_NotificationComponent = RenderType_NotificationComponent;
function View_NotificationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["+"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "alert alert-", _co.level, " ", _co.transition, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_NotificationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.open; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_NotificationComponent_0 = View_NotificationComponent_0;
function View_NotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i0.ɵdid(1, 49152, null, 0, i2.NotificationComponent, [], null, null)], null, null); }
exports.View_NotificationComponent_Host_0 = View_NotificationComponent_Host_0;
var NotificationComponentNgFactory = i0.ɵccf("notification", i2.NotificationComponent, View_NotificationComponent_Host_0, { level: "level" }, {}, ["*"]);
exports.NotificationComponentNgFactory = NotificationComponentNgFactory;
