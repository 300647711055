import { Ticket } from './../../tickets/ticket';
import { Component, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

import { environment } from '../../../environments/environment';

import { TicketService } from '../../tickets/ticket.service';
import { UploadService } from '../upload.service';

@Component({
  selector: 'image-indicator-cell',
  templateUrl: './image-indicator-cell.component.html',
  styleUrls: ['./image-indicator-cell.component.scss'],
  providers: [TicketService, UploadService]
})
export class ImageIndicatorCell implements ICellEditorAngularComp, AfterViewInit {
  value!: string;
  rowId!: string;
  ticket!: Ticket;

  constructor(
    private ticketService: TicketService,
    private uploadService: UploadService
  ) { }

  agInit(params: any): void {
    this.value = params.value;
    this.rowId = params.data && params.data.id;
    this.ticket = <Ticket>params.data;

    this.value = this.getValue();

  }

  getValue(): string {
    const image = this.ticketService.getImage(this.ticket, { x: 75, y: 75 });

    if (!image) {
      return '';
    }

    return image;
  }

  isPopup?(): boolean {
    return false;
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  ngAfterViewInit(): void { }

  fileChange(e: any) {
      this.uploadService.getS3Policy().subscribe(policy => {
      const uploadFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        Promise.resolve(this.uploadService.generateUUID()).then(uuid => {
          policy['fields']['key'] = 'tickets/' + uuid + '/original.jpg';
          this.uploadService.uploadToS3(policy, uploadFile).subscribe(() => {
            this.value = environment.ticketImageServerUrl + policy['fields']['key'];
            const updates = {
              id: this.rowId,
              image: this.value
            };
            this.ticketService.save(updates).subscribe();
          });
        });
      };
      reader.readAsDataURL(uploadFile);
    }, () => {});
  }
}
