"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var truck_reference_1 = require("./truck-reference");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TruckReferenceSerializer = /** @class */ (function () {
    function TruckReferenceSerializer() {
    }
    TruckReferenceSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var reference = new truck_reference_1.TruckReference();
        if (!json) {
            return reference;
        }
        reference.id = json.id;
        reference.organization = json.organization;
        reference.value = json.value;
        reference.createdAt = json.createdAt;
        reference.licensePlate = json.licensePlate;
        reference.truckCode = json.truckCode;
        reference.truckNumber = json.truckNumber;
        reference.truckType = json.truckType;
        return reference;
    };
    TruckReferenceSerializer.prototype.toJson = function (reference) {
        var json = {
            id: reference.id,
            organization: reference.organization,
            value: reference.value,
            createdAt: reference.createdAt,
            licensePlate: reference.licensePlate,
            truckCode: reference.truckCode,
            truckNumber: reference.truckNumber,
            truckType: reference.truckType,
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return TruckReferenceSerializer;
}());
exports.TruckReferenceSerializer = TruckReferenceSerializer;
