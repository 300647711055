"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".toolbar[_ngcontent-%COMP%]{display:flex;flex-direction:row;width:100%;height:60px;padding:0;margin:0;background-color:#2a81d7}.toolbar__logo-nav[_ngcontent-%COMP%]{display:flex;flex-direction:row;width:240px;height:100%;border-right:1px solid rgba(249,249,249,.5)}.toolbar__logo-nav__menu-button[_ngcontent-%COMP%]{padding-top:23px;padding-left:25px}.toolbar__logo-nav__menu-button[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{display:block;font-size:14px;color:#fff}.toolbar__logo-nav__logo[_ngcontent-%COMP%]{padding-top:18px;padding-right:65px;margin-left:25px}.toolbar__title[_ngcontent-%COMP%]{margin-left:25px;width:100%;font-family:'Source Sans Pro',sans-serif;font-size:14px;font-stretch:normal;text-transform:uppercase;line-height:1.14;letter-spacing:1px;color:#fff}.toolbar__user[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-self:flex-end;width:250px;height:100%;padding:0;margin:0 50px 0 0;cursor:pointer}.toolbar__user__image[_ngcontent-%COMP%]{display:inline-block;width:35px;height:35px;border-radius:50%;overflow:hidden;margin-right:15px;margin-top:12px;background-color:#00c853}.toolbar__user__image[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{color:#fff;font-weight:100;font-size:25px;line-height:35px;margin-left:32%}.toolbar__user__image[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}.toolbar__user__info[_ngcontent-%COMP%]{display:flex;flex-direction:column;font-size:12px;margin-top:5px;color:#fff}.toolbar__user__info__name[_ngcontent-%COMP%]{text-transform:uppercase}.toolbar__user__info__org[_ngcontent-%COMP%]{color:#c6c6c6;margin-top:-15px}.toolbar__user[_ngcontent-%COMP%]::after{font-family:icomoon!important;font-weight:500;content:\"\\e904\";top:25px;color:#fff;font-size:32px;line-height:12px;margin-left:10px;position:relative}"];
exports.styles = styles;
