<div class="login-container">
  <div class="login-left-column" >
    <img src="assets/img/logo-ruckit.svg" class="logo" />
  </div>

  <div class="login-right-column" >
    <div>
      <div class="login-form">
        <h1 translate>Log in to your account</h1>

        <notification *ngFor="let error of errors">{{error}}</notification>

        <form (submit)="f.form.valid && login(); false" #f="ngForm">
          <label class="required" translate>Email</label>
          <input type="email" name="email" placeholder="{{ 'Email' | translate }}" required [(ngModel)]="model.email"/>
          <label class="required" translate>Password</label>
          <input type="password" name="password" placeholder="{{ 'Password' | translate }}" required [(ngModel)]="model.password" />
          <div class="actions">
            <button type="submit" class="btn btn-primary"
                    [disabled]="loading || noLocalStorage || !f.form.valid"
                    [ngClass]="{'loading':loading}" translate>Sign In</button>
            <a (click)="forgotPassword()" translate>Forgot Password?</a>
          </div>

          <div class="legal">
            <a href="https://www.goruckit.com/privacy-policy" target="_blank" translate>Privacy Policy</a>
            <a href="https://www.goruckit.com/eula" target="_blank" translate>Terms of Use</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
