import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { AuthenticationService } from '../authentication.service';

@Component({
    selector: 'ruckit-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.scss'],
    providers: [AuthenticationService]
})
export class RuckitForgotPasswordDialogComponent {
    model: any = {};
    loading = false;
    success!: boolean;
    button = {
      title: 'Send',
      disabled: false,
      type: 'primary'
    };
    errors = [];

    constructor(
      public dialogRef: MatDialogRef<RuckitForgotPasswordDialogComponent>,
      private authenticationService: AuthenticationService
    ) { }

    submit() {
      this.loading = true;
      this.authenticationService.forgot(this.model.username)
        .subscribe(
          () => {
            this.success = true;
            this.button = {
              title: 'Email Sent!',
              disabled: true,
              type: 'primary'
            };

            setTimeout(() => {
              this.loading = false;
              this.dialogRef.close();
            }, 4000);
          },
          (err) => {
            this.errors = err;
            this.success = false;
            this.button = {
              title: 'Email Failed!',
              disabled: true,
              type: 'error'
            };
            setTimeout(() => {
              this.loading = false;
              this.dialogRef.close();
              this.button = {
                title: 'Send',
                disabled: false,
                type: 'primary'
              };
            }, 4000);
          }
        );
    }
}
