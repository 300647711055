import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { User } from '../../users/user';

@Component({
  selector: 'reviewer-cell',
  template: `
    <div class="value">{{ (value.amount | translate) || 'N/A' }}</div>
    <div class="label">{{ value.reviewer && 'By ' + value.reviewer }}</div>
  `,
  styles: [`
    .value {
      height: 50%;
      font-weight: 600;
      line-height: 34px;
    }
    .label {
      opacity: 0.75;
      height: 50%;
      font-size: 12px;
      line-height: 16px;
    }
  `]
})
export class ReviewerCell implements ICellEditorAngularComp {
  value!: {
    amount: any,
    reviewer: string
  };
  reviewer!: User

  constructor() { }

  agInit(params: any): void {
    this.value = params.value;
    this.reviewer = params.reviewer;
  }

  getValue(): { amount: any, reviewer: string } {
    return this.value;
  }
}
