"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../../../environments/environment");
var report_serializer_1 = require("./report.serializer");
var resource_service_1 = require("../../shared/resource.service");
var api_service_1 = require("../../shared/api.service");
var ReportService = /** @class */ (function (_super) {
    __extends(ReportService, _super);
    function ReportService(http) {
        var _this = _super.call(this, http, 'reports/', new report_serializer_1.ReportSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'reports/';
        _this.search = '';
        _this.allSelected = false;
        return _this;
    }
    ReportService.prototype.getStatic = function (id, token) {
        var _this = this;
        var ticketUrl = this.baseUrl + (id + "/");
        return this.http.get(ticketUrl, { headers: api_service_1.externalRequestHeaders(token) }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.convertRecord(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ReportService.prototype.add = function (id, ticketIds) {
        if (ticketIds === void 0) { ticketIds = []; }
        var ticketUrl = this.baseUrl + (id + "/add/");
        return this.http.post(ticketUrl, { tickets: ticketIds }, { headers: api_service_1.requestHeaders() });
    };
    ReportService.prototype.send = function (id, email) {
        var ticketUrl = this.baseUrl + (id + "/send/");
        return this.http.post(ticketUrl, { to: [email] }, { headers: api_service_1.requestHeaders() });
    };
    return ReportService;
}(resource_service_1.ResourceService));
exports.ReportService = ReportService;
