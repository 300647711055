"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var quickbooks_1 = require("./quickbooks");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var QuickbooksCompanySerializer = /** @class */ (function () {
    function QuickbooksCompanySerializer() {
    }
    QuickbooksCompanySerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var quickbooksCompany = new quickbooks_1.QuickbooksCompany();
        if (!json) {
            return quickbooksCompany;
        }
        Object.keys(json).forEach(function (key) {
            quickbooksCompany[key] = json[key];
        });
        return quickbooksCompany;
    };
    QuickbooksCompanySerializer.prototype.toJson = function (company) {
        var json = {
            id: company.id,
            organization: company.organization,
            owner: company.owner,
            filePath: company.filePath
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return QuickbooksCompanySerializer;
}());
exports.QuickbooksCompanySerializer = QuickbooksCompanySerializer;
;
