"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app[_ngcontent-%COMP%]{display:flex;flex-direction:column;width:100%;height:100%}.app__sidebar[_ngcontent-%COMP%]{height:100vh;overflow-y:hidden}.app__sidebar__content[_ngcontent-%COMP%]{width:100%}"];
exports.styles = styles;
