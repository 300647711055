import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material';

import { ApiService } from '../../shared/api.service';
import { OrganizationService } from '../../organizations/organization.service';
import { parseErrors } from '../../shared/api.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'ruckit-settings-organization',
  templateUrl: './settings-organization.component.html',
  styleUrls: ['./settings-organization.component.scss'],
  providers: [OrganizationService, ApiService]
})
export class SettingsOrganizationComponent implements OnInit {
  @Input() organization: any;
  loading = true;
  imageChanged = false;
  stateOptions: any[] = [];
  errors: any[] = [];
  logoImage: { [key: string]: any } = {
    dataUri: null,
    file: null
  };
  @ViewChild('editOrganization') editOrganization: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.stateOptions = this.apiService.getStates().map((state) => {
      return { name: state.abbreviation };
    });
    this.loading = false;
  }

  saveOrganization() {
    this.loading = true;
    let _organization = cloneDeep(this.organization);
    Object.keys(_organization).forEach((key) => (_organization[key] == null) && delete _organization[key]);
    this.organizationService.saveWithImage(_organization, this.logoImage.file).subscribe((organization: any) => {
      this.organization = organization;
      this.router.navigate(['/settings', 'organization']);
      this.editOrganization.form.markAsPristine();
      this.editOrganization.form.markAsUntouched();
      this.loading = false;
    }, (err: any) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selectState(state: any, area = 'organization') {
    if (area === 'organization') {
      this.organization.state = state;
    } else {
      this.organization.billingState = state;
    }
  }

  fileChange(e: any) {
    let logoImage = this.logoImage;

    let reader = new FileReader();
    reader.onload = function (loadEvent: any) {
      logoImage.dataUri = loadEvent.target.result;
    };

    let file = e.target.files[0];
    logoImage.file = file;
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }
}
