import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as shortid from 'shortid';

// @ts-ignore
import * as pdfjs from 'pdfjs-dist';

import 'dwt';

import { environment } from '../../environments/environment';

import { NotificationsService, Notification, NotificationType } from '../shared/notification/notifications.service';
import { AuthenticationService } from '../shared/authentication.service';
import { UploadService, S3PolicyData } from '../shared/upload.service';
import { Ticket } from '../tickets/ticket';
import { TicketService } from '../tickets/ticket.service';
import { Preferences } from '../shared/preferences/preferences';
import { PreferencesService } from '../shared/preferences/preferences.service';

@Component({
  selector: 'scale-sync-upload',
  templateUrl: './scale-sync-upload.component.html',
  styleUrls: ['./scale-sync-upload.component.scss'],
  providers: [
    NotificationsService,
    AuthenticationService,
    UploadService,
    TicketService,
    PreferencesService,
  ],
})
export class ScaleSyncUploadComponent implements OnInit {

  external = false;
  contextId = '';
  dateSelected = false;

  user = this.authenticationService.user();
  callback: any;
  loader = {
    active: false,
    type: 'uploading'
  };
  uploadIndex = 0;
  preferences!: Preferences;

  policyData: any = {};
  uploadFiles: any[] = [];
  uploadTotal = 0;
  savedTickets: Ticket[] = [];
  selectedRecordIndex: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    public uploadService: UploadService,
    public ticketService: TicketService,
    private preferencesService: PreferencesService
  ) { }

  ticketNumberVisible(): boolean {
    return (this.savedTickets.length > 0 && this.selectedRecordIndex !== null) || this.savedTickets.length === 0;
  }

  ngOnInit() {
    // here we will set up listeners for any url params and set up
    // the callback_url, features, type model, and other config functions necessary
    let combinedParams = observableCombineLatest(
      this.route.url, this.route.params, this.route.queryParams,
      (url, params, qparams) => ({ url, params, qparams })
    );
    combinedParams.subscribe(_result => {
    });

    this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;
  }

  checkForMatchingValue(tickets: Ticket[], key: string): boolean {
    return tickets.every((ticket) => {
      return ticket[key] === tickets[0][key];
    });
  }

  isValidDate(input: any): boolean {
    input = new Date(input);
    return input instanceof Date && !isNaN(input.getTime());
  }

  selectedTicketLength(): number {
    let length = 0;
    this.savedTickets.forEach(ticket => length = ticket.selected ? length + 1 : length);
    return length;
  }

  getPreferences() {
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => p.name === 'uploader');
        return pref ? pref : new Preferences;
      }),
    ).subscribe(preferences => {
      this.preferences = preferences;
    });
  }

  savePreferences() {
    const preferencesObj = {
      ...this.preferences,
      type: 'scale-sync-uploader',
      name: 'scale-sync-uploader',
      blob: { }
    };
    this.preferencesService.save(preferencesObj).subscribe();
  }

  saveImage(updateData: any) {
    this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
      .subscribe(() => { }, (err: any) => {
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error updating that ticket image.',
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      });
  }

  fileChange(e: any) {
    this.uploadService.getS3Policy(this.external).subscribe((policy: S3PolicyData) => {
      this.policyData = policy;
      this.uploadFiles = e.target ? Array.from(e.target.files) : e;
      if (this.uploadFiles.length) {
        this.loader = {
          active: true,
          type: 'uploading'
        };
        this.uploadFiles.forEach((file, i) => this.convertFileToRecord(file, i));
      }
    }, err => {
      this.loader.active = false;
      const notification: Notification = {
        context: { err },
        id: shortid.generate(),
        message: 'There was an error authentication with our upload server.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
      throw err;
    });
  }

  close() {
    this.router.navigate(['tickets']);
  }

  cropWhiteSpace(base64Image: string) {
    return new Promise((resolve) => {
      this.loadImage(base64Image).then((image: any) => {
        // draw canvas with base64 image data (maintain size)
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext('2d');
        if (ctx) {
          // remove any white pixels that the scan driver will try to fill in
          // and return the cropped image base64 data
          ctx.drawImage(image, 0, 0);
          let copy = document.createElement('canvas').getContext('2d');
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixel = imageData.data;
          let bound: any = {};
          let x, y;

          for (let i = 0; i < pixel.length; i += 4) {
            if (pixel[i] !== 255 || pixel[i + 1] !== 255 || pixel[i + 2] !== 255) {
              x = (i / 4) % canvas.width;
              y = ~~((i / 4) / canvas.width);

              if (!bound.top) {
                bound.top = y;
              }

              if (!bound.left) {
                bound.left = x;
              } else if (x < bound.left) {
                bound.left = x;
              }

              if (!bound.right) {
                bound.right = x;
              } else if (bound.right < x) {
                bound.right = x;
              }

              if (!bound.bottom) {
                bound.bottom = y;
              } else if (bound.bottom < y) {
                bound.bottom = y;
              }
            }
          }

          let trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left,
            trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

          if (copy) {
            copy.canvas.width = trimWidth;
            copy.canvas.height = trimHeight;
            copy.putImageData(trimmed, 0, 0);
          }
          resolve(copy ? copy.canvas.toDataURL('image/jpeg', 1.0) : base64Image);
        } else {
          resolve(base64Image);
        }
      });
    });
  }

  loadImage(base64Image: string) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = base64Image;
      image.onload = () => resolve(image);
      image.onerror = (e) => reject(e);
    });
  }

  convertPDFtoImages(pdfFile: ArrayBuffer) {
    return new Promise((resolve) => {
      pdfjs.getDocument(pdfFile).then((pdf: any) => {
        let pdfImages: File[] = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          pdf.getPage(i).then((page: any) => {
            const scale = 2;
            const viewport = page.getViewport(scale);
            let canvas = document.createElement('canvas');
            canvas.setAttribute('id', 'canvas-' + i);
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const task = page.render({ canvasContext: context, viewport: viewport });
            task.promise.then(() => {
              pdfImages.push(this.uploadService.convertDataUriToFile(canvas.toDataURL('image/jpeg'), 'pdfImage-' + i + '.jpg'));
              if (pdfImages.length === pdf.numPages) { resolve(pdfImages); }
            });
          });
        }
      });
    });
  }

  convertFileToRecord(file: File, uploadIndex?: number) {
    let reader = new FileReader();
    reader.onload = () => this.fileLoad(reader, file, uploadIndex);
    reader.readAsArrayBuffer(file);
  }

  fileLoad(reader: FileReader, file: File, uploadIndex?: number) {
    if (file.type.includes('pdf')) {
      Promise.resolve(this.convertPDFtoImages(<ArrayBuffer>reader.result)).then((results) => {
        const pdfImages: File[] = <File[]>results;
        this.uploadTotal = this.uploadTotal + pdfImages.length;
        pdfImages.forEach((image) => this.generateRecordFromImage(image));
      });
    } else if (file.type.startsWith('image/') && !file.type.includes('heic')) {
      this.uploadTotal++;
      this.generateRecordFromImage(file, uploadIndex);
    } else {
      const notification: Notification = {
        context: {},
        id: shortid.generate(),
        message: 'There was an error uploading this image. Please upload a valid image format (JPEG/PNG/GIF) or a PDF.',
        originator: 'uploader',
        type: NotificationType.Danger,
      };
      this.notificationsService.addNotification(notification);
    }
  };

  generateRecordFromImage(imageFile: any, _uploadIndex?: number) {
    Promise.resolve(this.uploadService.generateUUID()).then(uuid => {
      this.policyData['fields']['key'] = 'tickets/' + uuid + '/original.jpg';
      let ticketData = <Ticket>{
        id: uuid,
        image: environment.ticketImageServerUrl + this.policyData['fields']['key'],
        ticketDate: '',
        ticketNumber: '',
        quantity: ''
      };
      this.uploadIndex++;
      this.uploadService.uploadToS3(this.policyData, imageFile).subscribe(() => {
        this.loader.type = 'analyzing';
        this.ticketService.saveScaleSync({ image: ticketData.image }).subscribe(() => {
          this.loader.active = false;
          this.close();
        }, err => {
          const notification: Notification = {
            context: { err },
            id: shortid.generate(),
            message: 'There was an error creating this ticket.',
            originator: 'uploader',
            type: NotificationType.Danger,
          };
          this.notificationsService.addNotification(notification);
          throw err;
        });
      }, err => {
        this.uploadTotal = Number(this.uploadTotal) - 1;
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error uploading this image.',
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      });
    });
  }
}
