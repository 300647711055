import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions, URLSearchParams } from '@angular/http';
import { uniq } from 'lodash';

import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';
import { User } from '../users/user';
import { UserSerializer } from '../users/user.serializer';
import { Organization } from '../organizations/organization';
import { DataService } from './data.service';

const camelcaseKeysDeep = require('camelcase-keys-deep');
import { parseErrors } from '../shared/api.service';

@Injectable()
export class AuthenticationService {
  public token: string;
  private loggedIn = false;
  private ruckit = false;
  private scaleit = false;
  private posEnabled = false;
  private advancedBilling = false;
  private allDriversEnabled = false;
  private sidebar = true;
  private createJobs = false;
  private leasedOrgs = false;
  private favoriteTags = false;
  baseUrl = environment.serverUrl;

  constructor(
    private http: Http,
    private dataService: DataService
  ) {
    // set token if saved in local storage
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  login(username: string, password: string): Observable<boolean> {
    let params = { username: username, password: password };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/login/', params, { headers: headers }).pipe(
      map(this.extractData),
      catchError((error) => {
        return observableThrowError(parseErrors(error));
      }),
      map(this.storeUser)
    );
  }

  logout(): void {
    // clear token and remove user from local storage to log out
    this.token = null;
    this.loggedIn = false;
    this.dataService.changeData({ authenticated: false, sidebar: false });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('ticketFilters');

    Sentry.configureScope((scope) => {
      scope.setUser({});
    });
  }

  forgot(username: string): Observable<boolean> {
    let params = { username: username };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/forgot/', params, { headers: headers }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      }),
      catchError((error) => {
        return observableThrowError(parseErrors(error));
      })
    );
  }

  reset(token: string, uid: string, password: string): Observable<boolean> {
    let params = { token: token, uid: uid, password: password };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/reset/', params, { headers: headers }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      }),
      catchError((error) => {
        return observableThrowError(parseErrors(error));
      })
    );
  }

  getProfile(token: string, uid: string) {
    let params: URLSearchParams = new URLSearchParams();
    params.set('token', token);
    params.set('uid', uid);

    let requestOptions = new RequestOptions();
    requestOptions.search = params;

    return this.http.get(this.baseUrl + 'auth/reset/', requestOptions).pipe(
      map(this.extractData),
      catchError((error) => {
        return observableThrowError(parseErrors(error));
      })
    );
  }

  isLoggedIn(): boolean {
    let storedUser = localStorage.getItem('currentUser');
    let currentUser = storedUser && JSON.parse(storedUser);
    if (currentUser && currentUser.token) {
      this.loggedIn = true;
    }

    return this.loggedIn;
  }

  user() {
    let storedUser = localStorage.getItem('currentUser');
    let currentUser = storedUser && JSON.parse(storedUser);
    return currentUser;
  }

  isRuckit(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.ruckit) {
      this.ruckit = true;
    }

    return this.ruckit;
  }

  isScaleit(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.posEnabled) {
      this.scaleit = true;
    }

    return this.scaleit;
  }

  hasPOSEnabled(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.posEnabled) {
      this.posEnabled = true;
    }

    return this.posEnabled;
  }

  hasAdvancedBilling(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.advancedBilling) {
      this.advancedBilling = true;
    }

    return this.advancedBilling;
  }

  hasAllDriversEnabled(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.allDriversEnabled) {
      this.allDriversEnabled = true;
    }

    return this.allDriversEnabled;
  }

  canCreateJobs(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if ((currentUser && currentUser.canCreateJobs) ||
      (currentUser && currentUser.organization && currentUser.organization.can_create_jobs)) {
      this.createJobs = true;
    }
    return this.createJobs;
  }

  hasLeasedOrgs(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasLeasedOrgs) {
      this.leasedOrgs = true;
    }

    return this.leasedOrgs;
  }

  hasFavoriteTags(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.favoriteTags && currentUser.favoriteTags.length > 0) {
      this.favoriteTags = true;
    }
    return this.favoriteTags;
  }

  getOrganization(): Organization {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.organization) {
      return currentUser.organization;
    }

    return null;
  }

  updateOrganization(organization): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      currentUser.organization = organization;
      this.storeUser(currentUser);
    }
  }

  myFavoriteTags(): any {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.favoriteTags && currentUser.favoriteTags.map(tag => tag.name);
  }

  getFilterProperty(filter): any {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasOwnProperty('filters')) {
      return currentUser.filters[filter];
    } else {
      return false;
    }
  }

  setFilterProperty(filter, state): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && !currentUser.hasOwnProperty('filters')) {
      currentUser['filters'] = {};
    }
    currentUser['filters'][filter] = state;
    this.storeUser(currentUser);
  }

  displaySidebar(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.sidebar === false) {
      this.sidebar = false;
    } else {
      this.sidebar = true;
    }

    return this.sidebar;
  }

  private extractData(res: Response) {
    let body = res.json().results;
    if (body) {
      return body.map(user => {
        user = camelcaseKeysDeep(user);
        return (new UserSerializer).fromJson(user);
      });
    } else if (res.json()) {
      let user = camelcaseKeysDeep(res.json());
      return (new UserSerializer).fromJson(user);
    } else {
      return [];
    }
  }

  private storeUser(user: User) {
    if (user.token) {
      // set token property
      this.token = user.token;
      let enabledFeatures: string[] = [];
      if (user.organization && user.organization.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.organization.enabledFeatures);
      }
      if (user.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.enabledFeatures);
      }
      const userInfo = {
        username: user.email,
        name: user.name,
        organization: user.organization,
        product: 'ticket-manager',
        driver: user.isDriver,
        carrier: user.isCarrier,
        ruckit: user.isRuckit,
        id: user.id,
        canCreateJobs: user.organization && user.organization.canCreateJobs,
        posEnabled: user.organization && user.organization.posEnabled,
        advancedBilling: user.organization && user.organization.advBillingEnabled,
        scaleit: user.organization && user.organization.posEnabled,
        allDriversEnabled: user.organization && user.organization.allDriversEnabled,
        hasLeasedOrgs: user.organization && user.organization.hasLeasedOrgs,
        favoriteTags: user.favoriteTags, filters: user['filters'] || {},
        enabledFeatures: uniq(enabledFeatures)
      };

      // store user data and JWT token in local storage to persist user session
      localStorage.setItem('currentUser', JSON.stringify({
        ...userInfo,
        token: user.token,
        image: user.image,
      }));

      // return true to indicate successful login
      this.loggedIn = true;

      // sentry integration
      Sentry.configureScope((scope) => {
        scope.setUser(userInfo);
      });

      // fullstory integration
      const fullstory = (<any>window).FS;
      if (fullstory) {
        fullstory.setUserVars({
          product: 'ticket-manager',
        });
        fullstory.identify(user.id, {
          ...userInfo,
          email: user.email,
          displayName: user.email,
          product: 'ticket-manager',
        });
      }

      return true;
    } else {
      // return false to indicate failed login
      this.loggedIn = true;
      return false;
    }
  }

  enabledFeatures(): string[] {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return currentUser && currentUser.enabledFeatures ? currentUser.enabledFeatures : [];
  }

  getFeature(feature: string) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    try {
      if (currentUser.features && currentUser.features[feature]) {
        return currentUser.features[feature];
      }
      if (currentUser.organization.features && currentUser.organization.features[feature]) {
        return currentUser.organization.features[feature];
      }
    } catch {
      return null;
    }
  }
}
