"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var http_1 = require("@angular/http");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var organization_serializer_1 = require("./organization.serializer");
var api_service_1 = require("../shared/api.service");
var resource_service_1 = require("../shared/resource.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var OrganizationService = /** @class */ (function (_super) {
    __extends(OrganizationService, _super);
    function OrganizationService(http) {
        var _this = _super.call(this, http, 'organizations/', new organization_serializer_1.OrganizationSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        _this.count = 0;
        return _this;
    }
    OrganizationService.prototype.saveWithImage = function (organization, logoImage) {
        var _this = this;
        var _organization = decamelizeKeysDeep(lodash_1.clone(organization));
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            delete _organization.image;
            delete _organization.billing_contact;
            delete _organization.email;
            Object.keys(_organization).map(function (key) {
                var value = _organization[key];
                formData.append(key, value);
            });
            if (logoImage) {
                formData.append('image', logoImage, logoImage.name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            if (_organization.id) {
                var organizationUrl = _this.baseUrl + 'organizations/' + _organization.id + '/';
                xhr.open('PUT', organizationUrl, true);
            }
            else {
                var organizationUrl = _this.baseUrl + 'organizations/';
                xhr.open('POST', organizationUrl, true);
            }
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    OrganizationService.prototype.saveOnlyImage = function (organizationId, logoImage) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            formData.append('image', logoImage, logoImage.name);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            if (organizationId) {
                var organizationUrl = _this.baseUrl + 'organizations/' + organizationId + '/';
                xhr.open('PUT', organizationUrl, true);
            }
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    return OrganizationService;
}(resource_service_1.ResourceService));
exports.OrganizationService = OrganizationService;
