"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NavBar = /** @class */ (function () {
    function NavBar() {
        this.navLinks = [
            { path: '/tickets', label: 'Tickets' },
            { path: '/customers', label: 'Customers' },
            { path: '/jobs', label: 'Jobs' },
            { path: '/drivers', label: 'Drivers' },
            { path: '/invoices', label: 'Invoices' },
            { path: '/settlements', label: 'Driver Settlements' },
        ];
    }
    return NavBar;
}());
exports.NavBar = NavBar;
