import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatToolbarModule,
  MatMenuModule,
  MatTabsModule,
  MatTableModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatSelectModule,
  MatOptionModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatSidenavModule,
  MatRadioButton,
  MatRippleModule,
  MatRadioModule
} from '@angular/material';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { ToolbarComponent } from './index';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragulaModule } from 'ng2-dragula';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { environment } from '../../environments/environment';

// Components
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { LoaderComponent } from '../directives/index';
import { NotificationComponent } from './notification/notification.component';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import { NoResultsComponent } from '../shared/index';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { SearchComponent } from './search/search.component';
import {
  SidebarComponent,
  ActionBarComponent,
  NotFoundComponent,
  AlertPanelComponent
} from './index';
import { MenuLinkComponent, MenuToggleComponent } from '../directives/index';
import { NguiMapModule } from '@ngui/map';

// AG Grid Components
import { AgGridModule } from 'ag-grid-angular';
import { JobReferenceCell } from '../references/job-reference/job-reference-cell/job-reference-cell.component';
import { JobReferenceSelectorComponent } from '../references/job-reference/job-reference-selector/job-reference-selector.component';
import {
  AutocompleteCell,
  BulkActionDialogComponent,
  CheckboxCell,
  DatepickerCell,
  GridNotificationBarComponent,
  ImageIndicatorCell,
  MultiValueCell,
  StatusCell,
  SelectAllHeader,
  GridImageToolPanel,
  ReviewerCell,
  NavBar
} from './index';

// Ticket Table
import {
  TicketGridComponent,
  TicketGridDateCell,
  TicketGridDatetimeCell,
  TicketService
} from '../tickets/index';

// Jobs Table
import { JobReferenceService } from '../references/job-reference/job-reference.service';
import { JobReferenceGridComponent } from '../references/job-reference/job-reference-grid/job-reference-grid.component';

// Drivers Table
import { DriverReferenceService } from '../references/driver-reference/driver-reference.service';
import { DriverReferenceGridComponent } from '../references/driver-reference/driver-reference-grid/driver-reference-grid.component';

// Trucks Table
import { TruckReferenceService } from '../references/truck-reference/truck-reference.service';
import { TruckReferenceGridComponent } from '../references/truck-reference/truck-reference-grid/truck-reference-grid.component';

// Upload Components
import { BatchUploadComponent } from '../upload/batch-upload.component';
import { BatchUploadNotificationBarComponent } from '../upload/batch-upload-notification-bar.component';
import { ScaleSyncUploadComponent } from '../upload/scale-sync-upload.component';
import { OcrService } from '../upload/ocr.service';

// Reports
import { ReportService } from '../reports/report/report.service';
import { SendReportDialogComponent } from '../reports/send-report-dialog/send-report-dialog.component';

// Pipes
import {
  TypeFilterPipe,
  StatusFilterPipe,
  MenuFilterPipe,
  RecipientIdFilterPipe,
  SenderIdFilterPipe,
  FilterPipe,
  MomentPipe,
  KindFilterPipe,
  TelephonePipe,
  TitleCasePipe,
  GroupByPipe,
  PropertyFilterPipe,
  SafeHtmlPipe,
  MetersToMilesPipe,
  TimeFromNowPipe,
  JoinPipe,
  SortPipe,
  UniqueFilterPipe
} from '../shared/pipes/index';

// Services
import { UserService } from '../users/user.service';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { FileUploadService } from './file-upload/file-upload.service';
import { PreferencesService } from './preferences/preferences.service';
import { ClipboardModule } from 'ngx-clipboard';
import { CustomerReferenceGridComponent } from '../references/customer-reference/customer-reference-grid/customer-reference-grid.component';
import { CustomerReferenceSelectorComponent } from '../references/customer-reference/customer-reference-selector/customer-reference-selector.component';
import { CustomerReferenceCell } from '../references/customer-reference/customer-reference-cell/customer-reference-cell.component';
import { DecamelPipe } from './pipes/decamel.pipe';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { JobReferenceCreateModalComponent } from '../references/job-reference/job-reference-create-modal/job-reference-create-modal.component';
import { CustomerReferenceCreateModalComponent } from '../references/customer-reference/customer-reference-create-modal/customer-reference-create-modal.component';
import { DriverReferenceCreateModalComponent } from '../references/driver-reference/driver-reference-create-modal/driver-reference-create-modal.component';
import { DriverReferenceCell } from '../references/driver-reference/driver-reference-cell/driver-reference-cell.component';
import { DriverReferenceSelectorComponent } from '../references/driver-reference/driver-reference-selector/driver-reference-selector.component';
import { CustomerReferenceService } from '../references/customer-reference/customer-reference.service';
import { InvoiceService } from '../reports/invoice/invoice.service';
import { TruckReferenceCreateModalComponent } from '../references/truck-reference/truck-reference-create-modal/truck-reference-create-modal.component';
import { TruckReferenceCell } from '../references/truck-reference/truck-reference-cell/truck-reference-cell.component';
import { TruckReferenceSelectorComponent } from '../references/truck-reference/truck-reference-selector/truck-reference-selector.component';
import { ExportModalComponent } from './export-modal/export-modal.component';
import { ReadOnlyCell } from './grid/readonly-cell.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

const mapsApiUrl =
  'https://maps.google.com/maps/api/js?libraries=geometry,visualization,places,drawing&key=' +
  environment.googleMapsKey;

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatRippleModule,
    MatSelectModule,
    MatRadioModule,
    MatOptionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatSidenavModule,
    FlexLayoutModule,
    DragulaModule.forRoot(),
    NguiMapModule.forRoot({
      apiUrl: mapsApiUrl
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClipboardModule,
    AgGridModule.withComponents([
      BulkActionDialogComponent,
      GridImageToolPanel,
      ReviewerCell,
      TicketGridDateCell,
      TicketGridDatetimeCell,
      AutocompleteCell,
      ImageIndicatorCell,
      MultiValueCell,
      StatusCell,
      CheckboxCell,
      JobReferenceCell,
      SelectAllHeader,
      DatepickerCell,
      CustomerReferenceCell,
      DriverReferenceCell,
      TruckReferenceCell,
      NoResultsComponent,
      ReadOnlyCell,
    ]),
    InfiniteScrollModule,
    AngularCropperjsModule
  ],
  providers: [
    DataService,
    UserService,
    AuthenticationService,
    FileUploadService,
    PreferencesService,
    TicketService,
    OcrService,
    JobReferenceService,
    DriverReferenceService,
    TruckReferenceService,
    CustomerReferenceService,
    ReportService,
    InvoiceService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  entryComponents: [
    SendReportDialogComponent,
    JobReferenceCreateModalComponent,
    CustomerReferenceCreateModalComponent,
    DriverReferenceCreateModalComponent,
    TruckReferenceCreateModalComponent,
    ExportModalComponent,
  ],
  declarations: [
    ToolbarComponent,
    ImageEditorComponent,
    DropdownComponent,
    LoaderComponent,
    NoResultsComponent,
    NotificationComponent,
    PaginationComponent,
    ActionMenuComponent,
    TypeFilterPipe,
    StatusFilterPipe,
    MenuFilterPipe,
    RecipientIdFilterPipe,
    SenderIdFilterPipe,
    FilterPipe,
    MomentPipe,
    KindFilterPipe,
    TelephonePipe,
    TitleCasePipe,
    GroupByPipe,
    PropertyFilterPipe,
    SafeHtmlPipe,
    MetersToMilesPipe,
    TimeFromNowPipe,
    JoinPipe,
    SortPipe,
    UniqueFilterPipe,
    DecamelPipe,
    SearchComponent,
    SidebarComponent,
    ActionBarComponent,
    NotFoundComponent,
    AlertPanelComponent,
    MenuLinkComponent,
    MenuToggleComponent,
    AutocompleteCell,
    ReadOnlyCell,
    BulkActionDialogComponent,
    SendReportDialogComponent,
    CheckboxCell,
    DatepickerCell,
    GridNotificationBarComponent,
    ImageIndicatorCell,
    MultiValueCell,
    StatusCell,
    SelectAllHeader,
    NavBar,
    TicketGridComponent,
    JobReferenceCell,
    JobReferenceSelectorComponent,
    TruckReferenceCell,
    TruckReferenceSelectorComponent,
    TicketGridDateCell,
    TicketGridDatetimeCell,
    GridImageToolPanel,
    ReviewerCell,
    BatchUploadComponent,
    BatchUploadNotificationBarComponent,
    ScaleSyncUploadComponent,
    JobReferenceGridComponent,
    DriverReferenceGridComponent,
    TruckReferenceGridComponent,
    CustomerReferenceGridComponent,
    CustomerReferenceSelectorComponent,
    DriverReferenceSelectorComponent,
    CustomerReferenceCell,
    DriverReferenceCell,
    PhoneNumberPipe,
    JobReferenceCreateModalComponent,
    CustomerReferenceCreateModalComponent,
    DriverReferenceCreateModalComponent,
    TruckReferenceCreateModalComponent,
    ExportModalComponent,
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    TranslateModule,
    ToolbarComponent,
    FormsModule,
    LoaderComponent,
    ImageEditorComponent,
    DropdownComponent,
    NotificationComponent,
    MatInputModule,
    PaginationComponent,
    NoResultsComponent,
    DragulaModule,
    MatFormFieldModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TypeFilterPipe,
    StatusFilterPipe,
    MenuFilterPipe,
    RecipientIdFilterPipe,
    SenderIdFilterPipe,
    FilterPipe,
    MomentPipe,
    KindFilterPipe,
    TelephonePipe,
    TitleCasePipe,
    GroupByPipe,
    PropertyFilterPipe,
    SafeHtmlPipe,
    MetersToMilesPipe,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    ActionMenuComponent,
    TimeFromNowPipe,
    JoinPipe,
    SortPipe,
    UniqueFilterPipe,
    SearchComponent,
    SidebarComponent,
    ActionBarComponent,
    NotFoundComponent,
    AlertPanelComponent,
    MenuLinkComponent,
    MenuToggleComponent,
    NguiMapModule,
    FlexLayoutModule,
    CustomerReferenceSelectorComponent,
    DriverReferenceSelectorComponent,
    AgGridModule,
    NavBar,
    ExportModalComponent,
  ]
})
export class SharedModule {}
