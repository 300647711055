import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdAutocompleteData } from './cd-autocomplete-data.model';
import { CdAutocompleteItem } from './cd-autocomplete-item.model';

@Component({
  selector: 'cd-autocomplete',
  templateUrl: './cd-autocomplete.component.html',
  styleUrls: ['./cd-autocomplete.component.scss']
})
export class CdAutocompleteComponent {
  autocompleteList: any[] = [];
  lastSelected = {};
  left!: number;
  show = false;
  top!: number;

  get data(): CdAutocompleteData[] {
    return this.autocompleteList;
  }
  @Input() set data(data: CdAutocompleteData[]) {
    if (data && data.length > 0) {
      this.show = true;
    } else {
      this.lastSelected = {};
      // this.show = false;
    }
    this.autocompleteList = data;
  }
  @Input() set hide(hide: boolean) {
    if (hide) {
      this.show = false;
    }
  }

  @Output() onChanged = new EventEmitter<string>();

  addValue(value: CdAutocompleteItem) {
    this.onChanged.emit(value.id);
    this.lastSelected = value;
  }
}
