"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".ruckit-driver-reference-cell[_ngcontent-%COMP%]{padding:10px;background-color:#fff;width:500px}"];
exports.styles = styles;
