import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Observable, zip, Subject, from } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { debounce, find as _find } from 'lodash';
import shortid = require('shortid');
import * as moment from 'moment';

import { AgGridNg2 } from 'ag-grid-angular';
import {
  GridReadyEvent,
  ColumnApi,
  GridApi,
  ValueSetterParams
} from 'ag-grid-community';
import { CellContextMenuEvent } from 'ag-grid-community/dist/lib/events';
import { SelectAllHeader } from '../shared/grid/select-all-header.component';
import { DatepickerCell } from '../shared/grid/datepicker-cell.component';
import { TicketGridDateCell } from '../tickets/ticket-date-cell.component';
import { TicketGridDatetimeCell } from '../tickets/ticket-datetime-cell.component';
import { AutocompleteCell } from '../shared/grid/autocomplete-cell.component';
import { ImageIndicatorCell } from '../shared/grid/image-indicator-cell.component';
import { CheckboxCell } from '../shared/grid/checkbox-cell.component';
import { JobReferenceCell } from '../references/job-reference/job-reference-cell/job-reference-cell.component';
import { CustomerReferenceCell } from '../references/customer-reference/customer-reference-cell/customer-reference-cell.component';

import { GridImageToolPanel } from '../shared/grid/grid-image-panel.component';
import { BulkActionDialogComponent } from '../shared/grid/bulk-action-dialog.component';

import {
  NotificationsService,
  Notification,
  NotificationType
} from '../shared/notification/notifications.service';
import { AuthenticationService } from '../shared/authentication.service';
import { ReportService } from '../reports/report/report.service';
import { Ticket } from './ticket';
import { TicketService } from './ticket.service';
import { LocationOwnerService } from '../location-owners/location-owner.service';
import { JobReferenceService } from '../references/job-reference/job-reference.service';
import { TruckReferenceService } from '../references/truck-reference/truck-reference.service';
import { Preferences } from '../shared/preferences/preferences';
import { PreferencesService } from '../shared/preferences/preferences.service';
import { DriverReferenceCell } from '../references/driver-reference/driver-reference-cell/driver-reference-cell.component';
import { OcrResults } from '../upload/ocr';
import { OcrService } from '../upload/ocr.service';
import { FilterValues } from '../shared/filter';
import { TruckReferenceCell } from '../references/truck-reference/truck-reference-cell/truck-reference-cell.component';
import { NoResultsComponent, ActionMenuOption, StatusCell, ReviewerCell } from '../shared';
import {
  ExportOption,
  ExportModalComponent
} from '../shared/export-modal/export-modal.component';
import { ReadOnlyCell } from '../shared/grid/readonly-cell.component';

enum ActionType {
  Export = 'Export',
  Delete = 'Delete',
  Process = 'Run Data Detection',
  Edit = 'Edit',
  CreateInvoice = 'Create Invoice',
  CreateSettlement = 'Create Settlement',
  TransactionProExport = 'Transaction Pro Export'
}

interface OcrUpdates extends OcrResults {
  id: string;
  ticketDate: string;
}

@Component({
  selector: 'ticket-grid',
  templateUrl: './ticket-grid.component.html',
  styleUrls: ['../../style/grid.scss', './ticket-grid.component.scss'],
  providers: [
    TicketService,
    LocationOwnerService,
    JobReferenceService,
    TruckReferenceService,
    PreferencesService,
    AuthenticationService,
    NotificationsService,
    OcrService
  ]
})
export class TicketGridComponent {
  @ViewChild('ticketGrid') ticketGrid!: AgGridNg2;

  preferences!: Preferences;
  gridApi!: GridApi;
  columnApi!: ColumnApi;

  user = this.authenticationService.user();
  enabledFeatures: string[] = this.authenticationService.enabledFeatures() || [];
  usePandasExport = this.enabledFeatures.includes('usePandasExport');
  useLaserficheExport = this.enabledFeatures.includes('useLaserficheExport');
  transactionProTicketExportFields = this.authenticationService.getFeature('transactionProTicketExportFields');
  allowScaleSyncUploader = this.enabledFeatures.includes('allowScaleSyncUploader');
  directReferenceEditing = this.enabledFeatures.includes('directReferenceEditing');
  hasTicketQcVt = this.enabledFeatures.includes('hasTicketQcVt');
  loading = true;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  rowModelType = 'serverSide';
  cacheBlockSize = 100;
  maxBlocksInCache = 10;

  frameworkComponents = {
    ticketImageToolPanel: GridImageToolPanel,
    jobReferenceCell: JobReferenceCell,
    customerReferenceCell: CustomerReferenceCell,
    driverReferenceCell: DriverReferenceCell,
    truckReferenceCell: TruckReferenceCell,
    selectAllHeader: SelectAllHeader,
    autocompleteEditor: AutocompleteCell,
    datepickerCell: DatepickerCell,
    customNoRowsOverlay: NoResultsComponent,
    readOnlyCell: ReadOnlyCell
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'tickets',
    onClick: () => this.openBatchUploadDialog()
  };

  statusBar = {
    statusPanels: []
  };

  sideBar = {
    toolPanels: [
      {
        id: 'ticketImage',
        labelDefault: 'Ticket Image',
        labelKey: 'ticketImage',
        iconKey: 'ticket-image',
        toolPanel: 'ticketImageToolPanel'
      },
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel'
      }
    ]
  };

  defaultColDef = {
    editable: true,
    sortable: true,
    filter: 'agSetColumnFilter',
    filterParams: { newRowsAction: 'keep' },
    cellEditor: 'autocompleteEditor',
    cellEditorParams: {
      service: this.ticketService
    },
    onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e)
  };

  columnDefs: any[] = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 65,
      maxWidth: 65,
      minWidth: 65,
      editable: false,
      filter: false,
      checkboxSelection: true,
      suppressMovable: true,
      suppressNavigable: true,
      suppressMenu: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.ticketService,
        selected: this.ticketService.allSelected
      }
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      editable: false,
      cellRendererFramework: TicketGridDatetimeCell,
      width: 160,
      sortable: true
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      valueGetter: (params: any) => {
        if (!params || !params.data || !params.data.createdBy) {
          return 'Unknown';
        }

        const { firstName, lastName } = params.data.createdBy;

        return `${firstName} ${lastName}`;
      },
      cellRendererFramework: ReadOnlyCell,
      editable: false,
      filter: true,
      width: 200,
      sortable: true,
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Source',
      field: 'ticketOrigin',
      cellRendererFramework: ReadOnlyCell,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('ticketOrigin')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      width: 200,
      sortable: true,
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Ticket Date',
      field: 'ticketDate',
      filter: 'agDateColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      editable: true,
      cellEditor: 'datepickerCell',
      cellEditorParams: {
        service: this.ticketService
      },
      cellRendererFramework: TicketGridDateCell,
      width: 130,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Image',
      field: 'image',
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Missing Image';
          }

          return 'Has Image';
        },
        values: ['True', 'False'],
        newRowsAction: 'keep'
      },
      cellRendererFramework: ImageIndicatorCell,
      editable: false,
      width: 100,
      onCellClicked: (e: ValueSetterParams) => this.openImagePanel(e),
      sortable: true
    },
    {
      headerName: 'Verified',
      field: 'verified',
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Verified';
          }

          return 'Unverified';
        },
        values: ['True', 'False'],
        newRowsAction: 'keep'
      },
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        service: this.ticketService,
        field: 'verified',
        callback: (updates: Object) => this.updateRowData(<Ticket>updates)
      },
      editable: false,
      sortable: true
    },
    {
      headerName: 'Billable',
      field: 'billable',
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Billable';
          }
          return 'Non-billable';
        },
        values: ['True', 'False'],
        newRowsAction: 'keep'
      },
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        service: this.ticketService,
        field: 'billable'
      },
      editable: false,
      sortable: true
    },
    {
      headerName: 'Ticket #',
      field: 'ticketNumber',
      editable: true,
      filter: false,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      width: 110,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Driver',
      field: 'driverName',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: this.directReferenceEditing ? undefined : 'driverReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 250,
      sortable: true
    },
    {
      headerName: 'Truck',
      field: 'truckNumber',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: this.directReferenceEditing ? undefined : 'truckReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 250,
      sortable: true
    },
    {
      headerName: 'Truck #',
      field: 'truckNumber',
      filter: 'agSetColumnFilter',
      editable: true,
      width: 110,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Truck Type',
      field: 'truckType',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.truckReferenceService
            .getValuesForFieldQuery('value')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      width: 160,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Order #',
      field: 'orderNumber',
      filter: 'agSetColumnFilter',
      editable: true,
      width: 110,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Customer',
      field: 'customerName',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: this.directReferenceEditing ? undefined : 'customerReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 250,
      sortable: true
    },
    {
      headerName: 'Material',
      field: 'material',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 140,
      sortable: true
    },
    {
      headerName: 'Carrier Name',
      field: 'carrierName',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 140,
      sortable: true
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('quantity')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Quantity Type',
      field: 'quantityType',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('quantityType')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Invoice Rate',
      field: 'invoiceRate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('invoiceRate')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Rate',
      field: 'rate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('rate')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Origin',
      field: 'origin',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('origin')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Destination',
      field: 'destination',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('destination')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Location Owner',
      field: 'locationOwner',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.locationOwnerService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 140,
      sortable: true
    },
    {
      headerName: 'Haul Rate',
      field: 'haulRate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('haulRate')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 120,
      sortable: true
    },
    {
      headerName: 'Job',
      field: 'jobName',
      filter: 'agSetColumnFilter',
      editable: true,
      cellEditor: this.directReferenceEditing ? undefined : 'jobReferenceCell',
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      width: 300,
      sortable: true
    },
    {
      headerName: 'Job Code',
      field: 'jobCode',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.ticketService
            .getValuesForFieldQuery('jobCode')
            .subscribe(fields => {
              fields.push('-- Blank --');
              params.success(fields);
            });
        },
        newRowsAction: 'keep'
      },
      editable: true,
      width: 500,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    {
      headerName: 'Invoiced',
      field: 'invoiced',
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        service: this.ticketService,
        field: 'invoiced'
      },
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Invoiced';
          }

          return 'Not Invoiced';
        },
        values: ['True', 'False'],
        newRowsAction: 'keep'
      },
      sortable: true
    },
    {
      headerName: 'Review Status',
      field: 'tripReviewedStatus',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          params.success(['Approved', 'Rejected', 'Pending']);
        },
        newRowsAction: 'keep'
      },
      cellRendererFramework: StatusCell,
      cellRendererParams: {
        statusList: {
          pendingValue: 'pending',
          successValue: 'approved',
          failureValue: 'rejected'
        }
      },
      editable: false,
      width: 150,
      sortable: true
    },
    {
      headerName: 'Reviewer',
      field: 'tripReviewedAt',
      valueGetter: (params: any) => {
        if (!params || !params.data || !params.data.tripReviewedBy) {
          return '';
        }
        const reviewer = params.data.tripReviewedBy;
        return `${reviewer.firstName} ${reviewer.lastName}`;
      },
      filter: true,
      editable: false,
    },
    {
      headerName: 'Reviewed At',
      field: 'tripReviewedAt',
      editable: false,
      cellRendererFramework: TicketGridDateCell,
      width: 140,
      sortable: true
    },
    {
      headerName: 'Ticket Notes',
      field: 'ticketNotes',
      filter: false,
      editable: true,
      width: 500,
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        service: this.ticketService
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChange(e),
      sortable: true
    },
    
  ];

  actionOptions: ActionMenuOption[] = [];

  ticketFiltersString: string = <string>localStorage.getItem('ticketFilters');
  ticketFilters = this.ticketFiltersString && this.ticketFiltersString.length && this.ticketFiltersString[0] === '{' ?
                  JSON.parse(this.ticketFiltersString) : {};

  constructor(
    private router: Router,
    public ticketService: TicketService,
    private locationOwnerService: LocationOwnerService,
    private reportService: ReportService,
    private bulkActionDialog: MatDialog,
    private exportDialog: MatDialog,
    private truckReferenceService: TruckReferenceService,
    private preferencesService: PreferencesService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationsService,
    private ocrService: OcrService,
    public snackBar: MatSnackBar
  ) {
    this.searchChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(search => {
        this.loading = true;
        this.search = search;
        this.ticketService.search = this.search;
        this.refreshTable();
      });
    if (this.hasTicketQcVt) {
      this.columnDefs = this.columnDefs.concat([
        {
          headerName: 'Plant QC',
          field: 'tripPlantQc',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripPlantQc) {
              return '';
            }
            const reviewer = params.data.tripPlantQcReviewer;
            return {
              amount: params.data.tripPlantQc,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCell,
          editable: false,
          width: 150,
          sortable: false
        },
        {
          headerName: 'Plant VT',
          field: 'tripPlantVt',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripPlantVt) {
              return '';
            }
            const reviewer = params.data.tripPlantVtReviewer;
            return {
              amount: params.data.tripPlantVt,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCell,
          editable: false,
          width: 150,
          sortable: false
        },
        {
          headerName: 'Roadway QC',
          field: 'tripRoadwayQc',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripRoadwayQc) {
              return '';
            }
            const reviewer = params.data.tripRoadwayQcReviewer;
            return {
              amount: params.data.tripRoadwayQc,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCell,
          editable: false,
          width: 150,
          sortable: false
        },
        {
          headerName: 'Roadway VT',
          field: 'tripRoadwayVt',
          valueGetter: (params: any) => {
            if (!params || !params.data || !params.data.tripRoadwayVt) {
              return '';
            }
            const reviewer = params.data.tripRoadwayVtReviewer;
            return {
              amount: params.data.tripRoadwayVt,
              reviewer: `${reviewer.firstName} ${reviewer.lastName}`
            };
          },
          cellRendererFramework: ReviewerCell,
          editable: false,
          width: 150,
          sortable: false
        }
      ]);
    }
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.api || !e.columnApi) {
      return;
    }

    this.enabledFeatures = this.authenticationService.enabledFeatures() || [];

    this.gridApi = e.api;
    this.columnApi = e.columnApi;

    this.ticketGrid.defaultColDef = this.defaultColDef;
    this.ticketGrid.gridOptions.getContextMenuItems = (params: any) =>
      this.getContextMenuItems(params);
    this.ticketGrid.gridOptions.getRowNodeId = (data: any) => {
      return data.id;
    };
    this.gridApi.addEventListener('modelUpdated', () => {
      this.loading = false;
      if (this.ticketService.allSelected) {
        this.gridApi.forEachNode(node =>
          node.setSelected(this.ticketService.allSelected)
        );
      }
    });
    this.gridApi.setServerSideDatasource(this.ticketService);

    this.applyPreferences();
    this.ticketService.getFilterValues().subscribe(filters => {
      this.setupFilters(filters);
    });

    let exportOptions: ActionMenuOption[] = [];
    let exportOption = {
      name: ActionType.Export,
      onClick: () => this.onExport(),
      disabled: () => false
    };

    if (this.useLaserficheExport) {
      exportOption = {
        name: ActionType.Export,
        onClick: () => this.onLaserficheExport(),
        disabled: () => false
      };
    }

    if (this.usePandasExport) {
      exportOption = {
        name: ActionType.Export,
        onClick: () => this.onPandasExport(),
        disabled: () => false
      };
    }

    exportOptions.push(exportOption);

    if (this.transactionProTicketExportFields && this.transactionProTicketExportFields.length) {
      exportOption = {
        name: ActionType.TransactionProExport,
        onClick: () => this.onTransactionProTicketExport(),
        disabled: () => false
      };
      exportOptions.push(exportOption);
    }

    this.actionOptions = [
      ...exportOptions,
      {
        name: ActionType.Edit,
        onClick: () => this.openBatchUploadDialog(),
        disabled: () => this.numberOfTicketsSelected() === 0
      },
      {
        name: ActionType.Delete,
        onClick: () => this.onDeleteTicket(),
        disabled: () => this.numberOfTicketsSelected() === 0
      },
      {
        name: ActionType.Process,
        onClick: () => this.onProcessTicket(),
        disabled: () => this.numberOfTicketsSelected() === 0
      },
      {
        name: ActionType.CreateInvoice,
        onClick: () => this.onCreateInvoice(),
        disabled: () => this.numberOfTicketsSelected() === 0
      },
      {
        name: ActionType.CreateSettlement,
        onClick: () => this.onCreateSettlement(),
        disabled: () => this.numberOfTicketsSelected() === 0
      }
    ];

    if (this.ticketFilters) { this.gridApi.setFilterModel(this.ticketFilters); }
  }

  setupFilters(filterValues: FilterValues): void {
    this.columnApi
      .getAllColumns()
      .filter(column => {
        return Object.keys(filterValues).indexOf(column.getId()) > -1;
      })
      .forEach(column => {
        const id = column.getId();
        let newColDef = column.getUserProvidedColDef();
        newColDef.filterParams = {
          values: filterValues[id],
          newRowsAction: 'keep'
        };
        column.setColDef(newColDef, column.getUserProvidedColDef());
      });
  }

  autoSizeAll() {
    const allColumnIds: any[] = [];

    this.columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  getContextMenuItems(params: CellContextMenuEvent) {
    let exportOptions: any[] = [];
    let exportOption = {
      name: 'Export',
      action: () => this.onExport()
    };

    if (this.useLaserficheExport) {
      exportOption = {
        name: 'Export LF',
        action: () => this.onLaserficheExport()
      };
    }

    exportOptions.push(exportOption);

    if (this.transactionProTicketExportFields && this.transactionProTicketExportFields.length) {
      exportOption = {
        name: ActionType.TransactionProExport,
        action: () => this.onTransactionProTicketExport()
      };
      exportOptions.push(exportOption);
    }

    if (this.usePandasExport) {
      exportOption = {
        name: 'Export',
        action: () => this.onPandasExport()
      };
    }

    return [
      {
        name: 'Edit',
        action: () => this.openBatchUploadDialog()
      },
      {
        name: 'Delete',
        action: () => this.onDeleteTicket(params)
      },
      {
        name: 'Run Data Detection',
        action: () => this.onProcessTicket(params)
      },
      'copy',
      'separator',
      {
        name: 'Create Invoice',
        action: () => this.onCreateInvoice()
      },
      {
        name: 'Create Settlement',
        action: () => this.onCreateSettlement()
      },
      ...exportOptions
    ];
  }

  applyPreferences() {
    // Only update or create a default preference until UI for selection is in place
    this.preferencesService
      .list()
      .pipe(
        map(prefs => {
          const pref = prefs.find(p => p.name === 'tickets-default');

          return pref ? pref : new Preferences();
        })
      )
      .subscribe(
        preferences => {
          this.preferences = preferences;

          if (preferences.type === 'tickets' && preferences.blob) {
            let createdAtColumn: any = _find(preferences.blob.columnState, {colId: 'createdAt'});
            if (createdAtColumn && createdAtColumn['width'] < 160) {
              createdAtColumn['width'] = 160;
            }
            this.columnApi.setColumnState(preferences.blob.columnState);
            this.gridApi.setSortModel(preferences.blob.sortState);
          }

          let eventList = [
            'gridColumnsChanged',
            'columnPinned',
            'columnVisible',
            'columnResized',
            'columnMoved'
          ];

          eventList.forEach(event => {
            this.gridApi.addEventListener(
              event,
              debounce(() => this.savePreferences(), 300)
            );
          });
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured saving your preferences.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  savePreferences() {
    const columnData = this.columnApi.getColumnState();
    const preferencesObj = {
      ...this.preferences,
      profile: this.user.id,
      type: 'tickets',
      name: 'tickets-default',
      blob: {
        columnState: columnData
      }
    };

    this.preferencesService.save(preferencesObj).subscribe(
      () => {},
      error => {
        const notification: Notification = {
          context: {
            error
          },
          id: shortid.generate(),
          message: 'An error occured saving your preferences.',
          originator: 'tickets',
          type: NotificationType.Danger
        };

        this.notificationService.addNotification(notification);
      }
    );
  }

  onExportSelected(includeImages = false) {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    this.ticketService
      .exportGrid(
        'tickets',
        selected,
        undefined,
        this.ticketFilters,
        this.search,
        undefined,
        includeImages
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAll(includeImages = false) {
    this.ticketService
      .exportGrid(
        'tickets',
        undefined,
        undefined,
        this.ticketFilters,
        this.search,
        false,
        includeImages
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedLaserfiche() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    this.ticketService
      .exportGridLaserfiche(
        selected,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllLaserfiche() {
    this.ticketService
      .exportGridLaserfiche(
        undefined,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedPandas() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    this.ticketService
      .exportGridPandas(
        selected,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllPandas() {
    this.ticketService
      .exportGridPandas(
        undefined,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportSelectedTransactionPro() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);
    this.ticketService
      .exportGridPandas(
        selected,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected,
        this.transactionProTicketExportFields
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onExportAllTransactionPro() {
    this.ticketService
      .exportGridPandas(
        undefined,
        undefined,
        this.ticketFilters,
        this.search,
        this.ticketService.allSelected,
        this.transactionProTicketExportFields
      )
      .subscribe(
        () => {
          this.openSnackBar('Export Successfully Sent to E-Mail', 'OK');
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured exporting your data.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
  }

  onEdit(params: CellContextMenuEvent) {
    this.gridApi.deselectAll();
    params.node.setSelected(true);
    this.openBatchUploadDialog();
  }

  updateRowData(ticket: Ticket) {
    const row = this.gridApi.getRowNode(ticket.id);
    row.setData(ticket);
  }

  onExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption,
      includeImages: boolean
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelected(includeImages);
      } else {
        this.onExportAll(includeImages);
      }
    };
  }

  onLaserficheExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1,
        imagesExportable: false
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelectedLaserfiche();
      } else {
        this.onExportAllLaserfiche();
      }
    };
  }

  onPandasExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1,
        imagesExportable: false
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelectedPandas();
      } else {
        this.onExportAllPandas();
      }
    };
  }

  onTransactionProTicketExport() {
    const selected = this.gridApi.getSelectedRows().map((s: any) => s.id);

    const dialog = this.exportDialog.open(ExportModalComponent, {
      data: {
        selected: selected && selected.length >= 1,
        imagesExportable: false
      }
    });

    dialog.componentInstance.callback = (
      exportOption: ExportOption
    ) => {
      if (exportOption === ExportOption.Selected) {
        this.onExportSelectedTransactionPro();
      } else {
        this.onExportAllTransactionPro();
      }
    };
  }

  onDeleteTicket(params?: CellContextMenuEvent) {
    let selected: any;
    if (this.numberOfTicketsSelected() > 0) {
      selected = this.gridApi.getSelectedRows().map(s => s.id);
    } else {
      if (!params!.api) {
        return;
      }
      selected = params!.api!.getSelectedRows();
    }
    if (!selected || !selected.length) {
      selected = [params!.node.data];
      params!.node.setSelected(true);
    }
    const dialog = this.bulkActionDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected
      }
    });

    dialog.componentInstance.callback = () => {
      const tickets = zip(
        ...selected.map((t: Ticket) => {
          return this.deleteTicket(t);
        })
      );

      tickets.subscribe(
        () => {
          this.refreshTable(true);
        },
        error => {
          const notification: Notification = {
            context: {
              error
            },
            id: shortid.generate(),
            message: 'An error occured deleting your selections.',
            originator: 'tickets',
            type: NotificationType.Danger
          };

          this.notificationService.addNotification(notification);
        }
      );
    };
  }

  onProcessTicket(params?: CellContextMenuEvent) {
    let selected: any[];
    if (this.numberOfTicketsSelected() > 0) {
      selected = this.gridApi.getSelectedRows();
    } else {
      if (!params!.api) {
        return;
      }
      selected = params!.api!.getSelectedRows();
    }
    if (!selected || !selected.length) {
      selected = [params!.node.data];
      params!.node.setSelected(true);
    }
    const dialog = this.bulkActionDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected,
        action: 'process'
      }
    });

    dialog.componentInstance.callback = () => {
      selected.forEach((ticket: Ticket) => {
        from(this.processTicket(ticket)).subscribe((result: OcrResults) => {
          let updatedColumns: string[] = [];
          let ocrTicketData: OcrUpdates = <OcrUpdates>{ id: ticket.id };
          if (result.ticketNumber) {
            ocrTicketData.ticketNumber = result.ticketNumber;
            updatedColumns.push('ticketNumber');
          }
          if (result.ticketDate) {
            ocrTicketData.ticketDate = moment(result.ticketDate).format(
              'YYYY-MM-DD'
            );
            updatedColumns.push('ticketDate');
          }
          if (result.quantity) {
            ocrTicketData.quantity = result.quantity;
            updatedColumns.push('quantity');
          }
          this.saveTicket(ocrTicketData).subscribe(
            (ticketUpdates: Ticket) => {
              const row = this.gridApi.getRowNode(ticket.id);
              this.gridApi.flashCells({
                rowNodes: [row],
                columns: updatedColumns
              });
              row.setData(ticketUpdates);
            },
            error => {
              const notification: Notification = {
                context: {
                  error
                },
                id: shortid.generate(),
                message: 'An error occured processing your selections.',
                originator: 'tickets',
                type: NotificationType.Danger
              };
              this.notificationService.addNotification(notification);
            }
          );
        });
      });
    };
  }

  processTicket(ticket: Ticket): Promise<OcrResults> {
    if (!ticket.image) {
      return Promise.resolve(<OcrResults>{});
    }
    return this.ocrService.getResults(ticket);
  }

  onCellValueChange(e: ValueSetterParams): void {
    if (e.newValue === e.oldValue) {
      return;
    }

    const ticket = {
      ...e.data
    };

    this.saveTicket(ticket).subscribe(updates => {
      const row = this.gridApi.getRowNode(updates.id);
      row.setData(updates);
    });
  }

  onCreateInvoice() {
    const selectedTickets: Ticket[] = this.gridApi.getSelectedRows();
    const ticketIds = selectedTickets.map(ticket => ticket.id);
    const reportData = {
      type: 'invoice',
      blob: {}
    };
    this.reportService.save(reportData).subscribe(report => {
      this.reportService.add(report.id, ticketIds).subscribe(() => {
        this.router.navigate(['invoices', report.id, 'edit']);
      });
    });
  }

  onCreateSettlement() {
    const selectedTickets: Ticket[] = this.gridApi.getSelectedRows();
    const ticketIds = selectedTickets.map(ticket => ticket.id);
    const reportData = {
      type: 'expense',
      blob: {}
    };

    this.reportService.save(reportData).subscribe(report => {
      this.reportService.add(report.id, ticketIds).subscribe(() => {
        this.router.navigate(['settlements', report.id, 'edit']);
      });
    });
  }

  openImagePanel(e: any): void {
    if (e.data.image) {
      this.gridApi.openToolPanel('ticketImage');
    }
  }

  onFirstDataRendered() {}

  changeSearch(term?: string) {
    this.searchChanged.next(term);
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  saveTicket(ticket: any): Observable<Ticket> {
    delete ticket.driver;
    delete ticket.organization;
    delete ticket.ticket_date_raw;
    delete ticket.truck;

    return this.ticketService.save(ticket);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 5000 });
  }

  deleteTicket(ticket: any): Observable<any> {
    return this.ticketService.remove(ticket);
  }

  numberOfTicketsSelected(): Number {
    return this.gridApi && this.gridApi.getSelectedRows().length;
  }

  openBatchUploadDialog() {
    const selectedTickets: Ticket[] = this.gridApi.getSelectedRows();
    if (selectedTickets) {
      let constructedParams: any = {};
      selectedTickets.map(ticket => {
        constructedParams.tickets = constructedParams.tickets
          ? constructedParams.tickets + ',' + ticket.id
          : ticket.id;
      });
      this.router.navigate(['uploader'], { queryParams: constructedParams });
    } else {
      this.router.navigate(['uploader']);
    }
  }

  openScaleSyncUploadDialog() {
    this.router.navigate(['scale-sync-uploader']);
  }

  addRow() {
    this.saveTicket({}).subscribe(() => {
      const sort = [{ colId: 'createdAt', sort: 'desc' }];
      this.gridApi.setSortModel(sort);
      this.refreshTable();
    });
  }

  isExternalFilterPresent() {}

  doesExternalFilterPass() {}

  onFilterChanges() {
    this.ticketFilters = this.gridApi.getFilterModel();
    localStorage.setItem('ticketFilters', JSON.stringify(this.ticketFilters));
  }
}
