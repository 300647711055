"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var index_1 = require("../shared/index");
var material_1 = require("@angular/material");
var data_service_1 = require("../shared/data.service");
var forgot_password_dialog_component_1 = require("../shared/forgot-password-dialog/forgot-password-dialog.component");
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, route, dataService, authenticationService, forgotPasswordDialog) {
        this.router = router;
        this.route = route;
        this.dataService = dataService;
        this.authenticationService = authenticationService;
        this.forgotPasswordDialog = forgotPasswordDialog;
        this.model = {};
        this.loading = false;
        this.noLocalStorage = false;
        this.errors = [];
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        // reset login status
        this.authenticationService.logout();
        this.route.queryParams.subscribe(function (params) {
            if (params['returnUrl'] && params['returnUrl'].length) {
                var urlParts = params['returnUrl'].split('?');
                _this.returnUrl = urlParts[0];
                _this.returnParams = urlParts[1];
            }
        });
        try {
            localStorage.setItem('localStorage', '1');
        }
        catch (e) {
            this.noLocalStorage = true;
            this.errors.push('This website does not support mobile browsers in Private Browsing mode.');
        }
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.authenticationService.login(this.model.email, this.model.password)
            .subscribe(function (result) {
            if (result === true) {
                _this.dataService.changeData({ authenticated: result });
                _this.loading = false;
                if (_this.returnUrl && _this.returnUrl.length && _this.returnUrl !== '/logout' && _this.returnUrl !== '/') {
                    _this.router.navigate([_this.returnUrl]);
                }
                else {
                    _this.router.navigate(['/tickets']);
                }
            }
            else {
                _this.errors.push('Email or password is incorrect');
                _this.loading = false;
            }
        }, function (err) {
            _this.loading = false;
            _this.errors = err;
        });
    };
    LoginComponent.prototype.logout = function () {
        this.loading = true;
        this.authenticationService.logout();
        this.loading = false;
        this.router.navigate(['/login']);
    };
    LoginComponent.prototype.forgotPassword = function () {
        var dialogRef = this.forgotPasswordDialog.open(forgot_password_dialog_component_1.RuckitForgotPasswordDialogComponent, {
            width: '444px'
        });
        dialogRef.componentInstance.model = Object.assign({}, this.model);
        dialogRef.afterClosed().subscribe();
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
