"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var enterprise = require("ag-grid-enterprise");
enterprise.LicenseManager.setLicenseKey('Ruckit_Inc_Ticket-manager_1Devs_1Deployment_11_April_2020__MTU4NjU1OTYwMDAwMA==4491fa2d150377f9a4eb5cd6b8c41141');
var environment_1 = require("../environments/environment");
var Sentry = require("@sentry/browser");
// Gross but so are sentrys ts defs
// cast to any so we can add a beforeSend
if (!['local-dev', 'dev'].includes(environment_1.environment.errorTrackingEnv)) {
    Sentry.init({
        dsn: 'https://3b14efc7832d4b29952f7758c3c72de0@sentry.io/246434',
        environment: environment_1.environment.errorTrackingEnv,
        release: environment_1.environment.applicationVersion
    });
}
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    };
    return SentryErrorHandler;
}());
exports.SentryErrorHandler = SentryErrorHandler;
var MyHammerConfig = /** @class */ (function (_super) {
    __extends(MyHammerConfig, _super);
    function MyHammerConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.overrides = {
            // override hammerjs default configuration
            pan: { threshold: 5 },
            swipe: {
                velocity: 0.4,
                threshold: 20,
                direction: 31 // /!\ ugly hack to allow swipe in all direction
            }
        };
        return _this;
    }
    return MyHammerConfig;
}(platform_browser_1.HammerGestureConfig));
exports.MyHammerConfig = MyHammerConfig;
var ɵ0 = { hasBackdrop: true }, ɵ1 = {}, ɵ2 = [];
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
var AppModule = /** @class */ (function () {
    function AppModule(appRef) {
        this.appRef = appRef;
    }
    return AppModule;
}());
exports.AppModule = AppModule;
