"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var truck_reference_service_1 = require("../truck-reference.service");
var truck_reference_1 = require("../truck-reference");
var truck_reference_create_modal_component_1 = require("../truck-reference-create-modal/truck-reference-create-modal.component");
var TruckReferenceSelectorComponent = /** @class */ (function () {
    function TruckReferenceSelectorComponent(truckReferenceCreateModal, truckReferenceService) {
        this.truckReferenceCreateModal = truckReferenceCreateModal;
        this.truckReferenceService = truckReferenceService;
        this.visible = true;
        this.placeholder = 'Truck #';
        this.value = '';
        this.hasControls = true;
        this.referenceFocused = new core_1.EventEmitter();
        this.referenceSelected = new core_1.EventEmitter();
        this.referenceCreated = new core_1.EventEmitter();
        this.onBlur = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.loading = true;
        this.scrollDistance = 5;
        this.scrollUpDistance = 5;
        this.throttle = 100;
        this.query = '';
        this.editing = false;
        this.references = [];
    }
    TruckReferenceSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.query = this.value;
        this.truckReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    TruckReferenceSelectorComponent.prototype.ngAfterViewInit = function () { };
    TruckReferenceSelectorComponent.prototype.setSelectedReference = function (reference) {
        this.selectedTruckReference = reference;
        this.value = reference.truckNumber ? reference.truckNumber : '';
    };
    TruckReferenceSelectorComponent.prototype.buildQueryString = function (query) {
        if (query === void 0) { query = ''; }
        if (!query || query === '') {
            return {
                page_size: 10,
            };
        }
        return {
            filters: "(truck_number__icontains=" + query + ") | (truck_number__icontains=" + query + ")",
            page_size: 10,
        };
    };
    TruckReferenceSelectorComponent.prototype.onInputFocus = function () {
        var _this = this;
        this.visible = true;
        this.loading = true;
        this.references = [];
        this.referenceFocused.emit(true);
        this.truckReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    TruckReferenceSelectorComponent.prototype.onInputBlur = function () {
        this.visible = false;
        this.references = [];
        this.onBlur.emit(true);
        if (this.selectedTruckReference) {
            if (this.selectedTruckReference.truckNumber && this.selectedTruckReference.truckNumber !== this.value) {
                this.value = this.selectedTruckReference.truckNumber;
            }
        }
        else {
            this.value = '';
        }
    };
    TruckReferenceSelectorComponent.prototype.onReferenceClicked = function (event, truckReference) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.referenceSelected.emit(truckReference);
        this.visible = false;
        this.selectedTruckReference = truckReference;
        this.value = truckReference.truckNumber ? truckReference.truckNumber : '';
    };
    TruckReferenceSelectorComponent.prototype.onSearchChange = function (query) {
        var _this = this;
        query = query.toUpperCase();
        this.loading = true;
        this.references = [];
        this.truckReferenceService.list(this.buildQueryString(query)).subscribe(function (results) {
            _this.references = results;
            _this.loading = false;
        });
    };
    TruckReferenceSelectorComponent.prototype.onCloseClick = function () {
        this.visible = false;
        this.onClose.emit(true);
        this.references = [];
    };
    TruckReferenceSelectorComponent.prototype.onAddClick = function () {
        var _this = this;
        var data = {
            width: '500px',
            data: {
                model: this.data,
                mode: 'create',
            }
        };
        var dialog = this.truckReferenceCreateModal.open(truck_reference_create_modal_component_1.TruckReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (truckReference) {
                _this.referenceCreated.emit(truckReference);
                _this.selectedTruckReference = truckReference;
                _this.visible = false;
            });
        };
    };
    TruckReferenceSelectorComponent.prototype.onScrollDown = function () {
        var _this = this;
        var newResults = this.truckReferenceService.listNext();
        if (newResults) {
            newResults.subscribe(function (results) {
                _this.references = _this.references.concat(results);
            });
        }
    };
    TruckReferenceSelectorComponent.prototype.onScrollUp = function () { };
    TruckReferenceSelectorComponent.prototype.onEditReferenceClick = function () {
        var _this = this;
        if (!this.selectedTruckReference) {
            return;
        }
        this.visible = false;
        this.references = [];
        var data = {
            width: '500px',
            data: {
                model: this.selectedTruckReference,
                mode: 'edit',
            },
        };
        var dialog = this.truckReferenceCreateModal.open(truck_reference_create_modal_component_1.TruckReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (truckReference) {
                _this.referenceCreated.emit(truckReference);
                _this.selectedTruckReference = truckReference;
                _this.references.push(truckReference);
                _this.visible = false;
            });
        };
        return false;
    };
    return TruckReferenceSelectorComponent;
}());
exports.TruckReferenceSelectorComponent = TruckReferenceSelectorComponent;
