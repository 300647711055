'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/http");
var environment_1 = require("../../../environments/environment");
var AlertPanelComponent = /** @class */ (function () {
    function AlertPanelComponent(http, compiler) {
        this.http = http;
        this.compiler = compiler;
        this.alert = 'Update Available';
        this.updateNoticeHidden = false;
        this.updateAvailable = false;
        this.applicationVersion = environment_1.environment.applicationVersion;
    }
    AlertPanelComponent.prototype.ngOnInit = function () {
        this.getRevision();
    };
    AlertPanelComponent.prototype.compareVersions = function () {
        if (this.applicationVersion &&
            this.releasedVersion &&
            this.applicationVersion.toLowerCase().trim() !==
                this.releasedVersion.toLowerCase().trim()) {
            this.updateAvailable = true;
        }
        else {
            this.updateAvailable = false;
        }
    };
    AlertPanelComponent.prototype.getRevision = function () {
        var _this = this;
        var expire = Date.now();
        this.http.get('REVISION?expire=' + expire).subscribe(function (response) {
            _this.releasedVersion = response.text();
            _this.compareVersions();
        }, function () {
            _this.updateNoticeHidden = true;
        });
    };
    AlertPanelComponent.prototype.reload = function () {
        this.compiler.clearCache();
        window.location.reload(true);
    };
    return AlertPanelComponent;
}());
exports.AlertPanelComponent = AlertPanelComponent;
