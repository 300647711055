"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./grid-notification-bar.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("ngx-clipboard");
var i3 = require("@angular/common");
var i4 = require("../notification/notifications.service");
var i5 = require("./grid-notification-bar.component");
var styles_GridNotificationBarComponent = [i0.styles];
var RenderType_GridNotificationBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridNotificationBarComponent, data: {} });
exports.RenderType_GridNotificationBarComponent = RenderType_GridNotificationBarComponent;
function View_GridNotificationBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["ngxClipboard", ""]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.ClipboardDirective, [i2.ClipboardService], { targetElm: [0, "targetElm"], cbContent: [1, "cbContent"] }, null), i1.ɵpid(0, i3.JsonPipe, []), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeNotification(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], function (_ck, _v) { var currVal_1 = ""; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_v.context.$implicit.context)), ""); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "alert alert-", _v.context.$implicit.type, " ", _co.transition, ""); _ck(_v, 1, 0, currVal_0); var currVal_3 = _v.context.$implicit.message; _ck(_v, 4, 0, currVal_3); }); }
function View_GridNotificationBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_GridNotificationBarComponent_1)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.notifications$)); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_GridNotificationBarComponent_0 = View_GridNotificationBarComponent_0;
function View_GridNotificationBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "grid-notification-bar", [], null, null, null, View_GridNotificationBarComponent_0, RenderType_GridNotificationBarComponent)), i1.ɵprd(512, null, i4.NotificationsService, i4.NotificationsService, []), i1.ɵdid(2, 114688, null, 0, i5.GridNotificationBarComponent, [i4.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_GridNotificationBarComponent_Host_0 = View_GridNotificationBarComponent_Host_0;
var GridNotificationBarComponentNgFactory = i1.ɵccf("grid-notification-bar", i5.GridNotificationBarComponent, View_GridNotificationBarComponent_Host_0, {}, {}, []);
exports.GridNotificationBarComponentNgFactory = GridNotificationBarComponentNgFactory;
