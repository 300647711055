"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var charge_1 = require("./charge");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ChargeSerializer = /** @class */ (function () {
    function ChargeSerializer() {
    }
    ChargeSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var charge = new charge_1.Charge();
        if (!json) {
            return charge;
        }
        charge.id = json.id;
        charge.ownerOrganization = json.ownerOrganization;
        charge.ticket = json.ticket;
        charge.report = json.report;
        charge.punchcard = json.punchcard;
        charge.trip = json.trip;
        charge.quantity = json.quantity;
        charge.rate = json.rate;
        charge.total = json.total;
        charge.description = json.description;
        charge.parent = json.parent;
        charge.order = json.order;
        charge.attachments = json.attachments;
        charge.blob = json.blob;
        return charge;
    };
    ChargeSerializer.prototype.toJson = function (charge) {
        var json = {
            id: charge.id,
            ownerOrganization: charge.ownerOrganization,
            ticket: charge.ticket,
            report: charge.report,
            punchcard: charge.punchcard,
            trip: charge.trip,
            quantity: charge.quantity,
            rate: charge.rate,
            total: charge.total,
            description: charge.description,
            parent: charge.parent,
            order: charge.order,
            attachments: charge.attachments,
            blob: charge.blob
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return ChargeSerializer;
}());
exports.ChargeSerializer = ChargeSerializer;
