import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DriverReferenceService } from '../driver-reference.service';
import { DriverReference } from '../driver-reference';
import {
  DriverReferenceFormModel,
  DriverReferenceDialogData,
  DriverReferenceCreateModalComponent
} from '../driver-reference-create-modal/driver-reference-create-modal.component';

@Component({
  selector: 'driver-reference-selector',
  templateUrl: './driver-reference-selector.component.html',
  styleUrls: ['./driver-reference-selector.component.scss'],
  providers: [DriverReferenceService],
})
export class DriverReferenceSelectorComponent implements OnInit {
  @Input() visible = true;
  @Input() placeholder = 'Driver Name or Driver Code';
  @Input() value = '';
  @Input() data!: DriverReferenceFormModel;
  @Input() driverReference?: DriverReference;
  @Input() hasControls = true;

  @Output() referenceFocused: EventEmitter<boolean> = new EventEmitter();
  @Output() referenceSelected: EventEmitter<DriverReference> = new EventEmitter();
  @Output() referenceCreated: EventEmitter<DriverReference> = new EventEmitter();
  @Output() onBlur: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('driverReferenceInput', { read: ViewContainerRef }) driverReferenceInput: any;

  selectedDriverReference?: DriverReference;
  loading = true;
  scrollDistance = 5;
  scrollUpDistance = 5;
  throttle = 100;
  query = '';
  editing = false;

  references: DriverReference[] = [];

  constructor(
    private driverReferenceCreateModal: MatDialog,
    private driverReferenceService: DriverReferenceService,
  ) { }

  ngOnInit() {
    this.query = this.value;

    this.driverReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  ngAfterViewInit(): void { }

  public setSelectedReference(reference: DriverReference): void {
    this.selectedDriverReference = reference;
    this.value = reference.driverName ? reference.driverName : '';
  }

  buildQueryString(query = ''): { filters?: string, page_size?: number } {
    if (!query || query === '') {
      return {
        page_size: 10,
      };
    }

    return {
      filters: `(driver_name__icontains=${query}) | (driver_code__icontains=${query})`,
      page_size: 10,
    };
  }

  onInputFocus(): void {
    this.visible = true;
    this.loading = true;

    this.references = [];

    this.referenceFocused.emit(true);

    this.driverReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  onInputBlur(): void {
    this.visible = false;
    this.references = [];
    this.onBlur.emit(true);

    if (this.selectedDriverReference) {
      if (this.selectedDriverReference.driverName && this.selectedDriverReference.driverName !== this.value) {
        this.value = this.selectedDriverReference.driverName;
      }
    } else {
      this.value = '';
    }
  }

  onReferenceClicked(event: any, driverReference: DriverReference): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.referenceSelected.emit(driverReference);
    this.visible = false;

    this.selectedDriverReference = driverReference;

    this.value = driverReference.driverName ? driverReference.driverName : '';
  }

  onSearchChange(query: string): void {
    query = query.toUpperCase();

    this.loading = true;
    this.references = [];

    this.driverReferenceService.list(this.buildQueryString(query)).subscribe(results => {
      this.references = results;
      this.loading = false;
    });
  }

  onCloseClick(): void {
    this.visible = false;
    this.onClose.emit(true);
    this.references = [];
  }

  onAddClick(): void {
    const data: DriverReferenceDialogData = {
      width: '500px',
      data: {
        model: this.data,
        mode: 'create',
      }
    };

    const dialog = this.driverReferenceCreateModal.open(DriverReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<DriverReference>) => {
      response$.subscribe((driverReference) => {
        this.referenceCreated.emit(driverReference);
        this.selectedDriverReference = driverReference;

        this.visible = false;
      });
    };
  }

  onScrollDown(): void {
    const newResults = this.driverReferenceService.listNext();
    if (newResults) {
      newResults.subscribe(results => {
        this.references = [...this.references, ...results];
      });
    }
  }

  onScrollUp(): void { }

  onEditReferenceClick() {
    if (!this.selectedDriverReference) {
      return;
    }

    this.visible = false;
    this.references = [];

    const data: DriverReferenceDialogData = {
      width: '500px',
      data: {
        model: this.selectedDriverReference,
        mode: 'edit',
      },
    };

    const dialog = this.driverReferenceCreateModal.open(DriverReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<DriverReference>) => {
      response$.subscribe((driverReference) => {
        this.referenceCreated.emit(driverReference);
        this.selectedDriverReference = driverReference;

        this.references.push(driverReference);

        this.visible = false;
      });
    };

    return false;
  }

}
