"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var customer_reference_service_1 = require("../customer-reference.service");
var customer_reference_1 = require("../customer-reference");
var customer_reference_create_modal_component_1 = require("../customer-reference-create-modal/customer-reference-create-modal.component");
var CustomerReferenceSelectorComponent = /** @class */ (function () {
    function CustomerReferenceSelectorComponent(customerReferenceCreateModal, customerReferenceService) {
        this.customerReferenceCreateModal = customerReferenceCreateModal;
        this.customerReferenceService = customerReferenceService;
        this.visible = true;
        this.placeholder = 'Customer Name or Customer Code';
        this.value = '';
        this.hasControls = true;
        this.referenceFocused = new core_1.EventEmitter();
        this.referenceSelected = new core_1.EventEmitter();
        this.referenceCreated = new core_1.EventEmitter();
        this.onBlur = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.loading = true;
        this.scrollDistance = 5;
        this.scrollUpDistance = 5;
        this.throttle = 100;
        this.query = '';
        this.editing = false;
        this.references = [];
    }
    CustomerReferenceSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.query = this.value;
        if (this.customerReference) {
            this.selectedCustomerReference = this.customerReference;
        }
        this.customerReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    CustomerReferenceSelectorComponent.prototype.ngAfterViewInit = function () { };
    CustomerReferenceSelectorComponent.prototype.setSelectedReference = function (reference) {
        this.selectedCustomerReference = reference;
        this.value = reference.customerName ? reference.customerName : '';
    };
    CustomerReferenceSelectorComponent.prototype.buildQueryString = function (query) {
        if (query === void 0) { query = ''; }
        if (!query || query === '') {
            return {
                page_size: 10,
            };
        }
        return {
            filters: "(customer_name__icontains=" + query + ") | (customer_code__icontains=" + query + ")",
            page_size: 10,
        };
    };
    CustomerReferenceSelectorComponent.prototype.onInputFocus = function () {
        var _this = this;
        this.visible = true;
        this.loading = true;
        this.references = [];
        this.referenceFocused.emit(true);
        this.customerReferenceService.list(this.buildQueryString(this.query)).subscribe(function (references) {
            _this.references = references;
            _this.loading = false;
        });
    };
    CustomerReferenceSelectorComponent.prototype.onInputBlur = function () {
        this.visible = false;
        this.references = [];
        this.onBlur.emit(true);
        if (this.selectedCustomerReference) {
            if (this.selectedCustomerReference.customerName && this.selectedCustomerReference.customerName !== this.value) {
                this.value = this.selectedCustomerReference.customerName;
            }
        }
        else {
            this.value = '';
        }
    };
    CustomerReferenceSelectorComponent.prototype.onReferenceClicked = function (event, customerReference) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.referenceSelected.emit(customerReference);
        this.visible = false;
        this.selectedCustomerReference = customerReference;
        this.value = customerReference.customerName ? customerReference.customerName : '';
    };
    CustomerReferenceSelectorComponent.prototype.onSearchChange = function (query) {
        var _this = this;
        query = query.toUpperCase();
        this.loading = true;
        this.references = [];
        this.customerReferenceService.list(this.buildQueryString(query)).subscribe(function (results) {
            _this.references = results;
            _this.loading = false;
        });
    };
    CustomerReferenceSelectorComponent.prototype.onCloseClick = function () {
        this.visible = false;
        this.onClose.emit(true);
        this.references = [];
    };
    CustomerReferenceSelectorComponent.prototype.onAddClick = function () {
        var _this = this;
        var data = {
            width: '500px',
            data: {
                model: this.data,
                mode: 'create',
            }
        };
        var dialog = this.customerReferenceCreateModal.open(customer_reference_create_modal_component_1.CustomerReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (customerReference) {
                _this.referenceCreated.emit(customerReference);
                _this.selectedCustomerReference = customerReference;
                _this.visible = false;
            });
        };
    };
    CustomerReferenceSelectorComponent.prototype.onScrollDown = function () {
        var _this = this;
        var newResults = this.customerReferenceService.listNext();
        if (newResults) {
            newResults.subscribe(function (results) {
                _this.references = _this.references.concat(results);
            });
        }
    };
    CustomerReferenceSelectorComponent.prototype.onScrollUp = function () { };
    CustomerReferenceSelectorComponent.prototype.onEditReferenceClick = function () {
        var _this = this;
        if (!this.selectedCustomerReference) {
            return;
        }
        this.visible = false;
        this.references = [];
        var data = {
            width: '500px',
            data: {
                model: this.selectedCustomerReference,
                mode: 'edit',
            },
        };
        var dialog = this.customerReferenceCreateModal.open(customer_reference_create_modal_component_1.CustomerReferenceCreateModalComponent, data);
        dialog.componentInstance.callback = function (response$) {
            response$.subscribe(function (customerReference) {
                _this.referenceCreated.emit(customerReference);
                _this.selectedCustomerReference = customerReference;
                _this.references.push(customerReference);
                _this.visible = false;
            });
        };
        return false;
    };
    return CustomerReferenceSelectorComponent;
}());
exports.CustomerReferenceSelectorComponent = CustomerReferenceSelectorComponent;
